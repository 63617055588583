import get from 'lodash/get';
import { Fee } from 'models/fees';

export const GOT_PREVIEW = 'got-preview';

const gotPreview = ({
  coupon_code: couponCode,
  coupon_amount: couponAmount,
  user_credit_amount: userCreditAmount,
  final_price: finalPrice,
  quote_price: quotePrice,
  events,
  fees,
  venue_name: venueName,
  error,
}, {
  currency,
} = {}) => (
  {
    type: GOT_PREVIEW,
    payload: {
      checkoutTotal: parseFloat(get(finalPrice, currency)),
      couponCode,
      couponAmount: parseFloat(get(couponAmount, currency)),
      error,
      events,
      fees: fees.map(fee => (new Fee(fee, { currency }))),
      subTotal: parseFloat(get(quotePrice, currency)),
      userCreditAmount: parseFloat(get(userCreditAmount, currency)),
      venueName,
    },
  }
);

export default gotPreview;
