import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import env from 'lib/env';

import { ExperimentsConsumer } from 'providers/experiments-provider';

const { UNBOUNCE_ID } = env();

const propTypes = {
  affiliatePartner: PropTypes.instanceOf(Map),
  isOrganic: PropTypes.bool,
  displayCustomerIntercept: PropTypes.bool,
};

const defaultProps = {
  affiliatePartner: Map(),
  isOrganic: true,
  displayCustomerIntercept: false,
};

const CustomerIntercept = ({
  affiliatePartner,
  isOrganic,
  displayCustomerIntercept,
}) => {
  if (!isOrganic || !displayCustomerIntercept || (affiliatePartner && !affiliatePartner.get('marketingEligible'))) { return null; }
  return (<script src={`//${UNBOUNCE_ID}.js.ubembed.com`} async />);
};

CustomerIntercept.propTypes = propTypes;
CustomerIntercept.defaultProps = defaultProps;

const CustomerInterceptWrapper = props => (
  <ExperimentsConsumer><CustomerIntercept {...props} /></ExperimentsConsumer>
);

export default CustomerInterceptWrapper;
