import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';

import Brand from 'models/brand';

import * as AppContext from 'lib/app-context';

const propTypes = {
  appContext: PropTypes.string.isRequired,
  brand: PropTypes.instanceOf(Brand).isRequired,
  onHide: PropTypes.func.isRequired,
};

const SummaryOfServices = ({ appContext, brand, onHide }) => {
  if (appContext === AppContext.MOBILE) {
    return (
      <div className="background-color-white margin-bottom-80 container">
        <div className="row background-color-white">
          <div className="modal-header background-color-white border-color-white">
            <div className="text-align-center">
              <div className="padding-bottom-xs-10 padding-bottom-sm-40 text-weight-black text-size-sm-24 text-size-xs-18 text-color-dark-slate">
                SUMMARY OF SERVICES
              </div>
            </div>
          </div>
        </div>
        <div className="row background-color-white">
          <div className="modal-body padding-top-0 background-color-white">
            <div className="content-mobile text-weight-book text-size-14">
              <ReactMarkdown source={brand.summaryOfServices} />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div id="summaryOfServiceModal" className="modal" tabIndex="-1" role="dialog">
      <div className="container background-color-white border-radius-4 margin-top-sm-100 margin-top-xs-0 padding-horizontal-xs-20 padding-bottom-sm-40">
        <div className="row text-align-right">
          <div
            className="padding-top-10 padding-right-10 float-right iconified-font iconified-x text-size-18 text-color-blue text-color-hover-blue clickable"
            onClick={onHide}
            type="button"
          />
        </div>
        <div className="row text-align-center">
          <div className="text-align-center">
            <div className="padding-bottom-xs-10 padding-bottom-sm-40 text-weight-black text-size-sm-24 text-size-xs-18 text-color-dark-slate">
              SUMMARY OF SERVICES
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 padding-horizontal-sm-80 padding-horizontal-xs-0 padding-bottom-xs-20 padding-bottom-sm-0">
            <div className="content text-weight-book text-size-16">
              <ReactMarkdown source={brand.summaryOfServices} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

SummaryOfServices.propTypes = propTypes;
export default SummaryOfServices;
