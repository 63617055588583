import { takeEvery, takeLatest, call, put, select } from 'redux-saga/effects';
import capitalize from 'lodash/capitalize';
import cookie from 'react-cookie';

import env from 'lib/env/';

import { SEND_APP_DOWNLOAD_TEXT } from 'action-creators/brand/send-app-download-text';
import addMessage from 'action-creators/messaging/add-message';
import addMessageAndScrollToTop from 'action-creators/messaging/add-message-and-scroll-to-top';
import { BUSINESS_ENROLL } from 'action-creators/brand/business-enroll';
import { DISMISS_ANNOUNCEMENT_BANNER } from 'action-creators/brand/dismiss-announcement-banner';
import { SEND_CONTACT_INQUIRY } from 'action-creators/brand/send-contact-inquiry';
import { INITIALIZE_PRESS_RELEASE_PAGE } from 'action-creators/app/initialize-press-release-page';
import { CLEAR_PARTNER_COOKIES } from 'action-creators/brand/clear-partner-cookies';
import gotPressRelease from 'action-creators/app/got-press-release';

import ClientApi from 'lib/api/client';
import {
  appTextError,
  appTextSuccess,
  businessEnrollSuccess,
  businessEnrollError,
} from 'lib/common/messages';

const { COOKIE_DOMAIN } = env();

const getUser = state => state.account.user;
const getPortalAffiliateId = state => state.checkout.getPortalAffiliateId;
const getRequestQueue = state => state.requests.requestQueue;
const getPressReleases = state => state.brand.pressReleases;
const reload = () => { window.location.reload(); };

const postAppDownloadText = ({ phone, accessToken, requestQueue }) => (
  ClientApi
    .postAppDownloadText({ params: { phone }, accessToken, requestQueue })
    .then(({ body }) => ({ body }))
    .catch(({ error }) => ({ error }))
);

function* sendAppDownloadText(action) {
  const { phone } = action.payload;
  const requestQueue = yield select(getRequestQueue);
  const user = yield select(getUser);
  const { token } = user;

  const { error } = yield call(postAppDownloadText, { phone, accessToken: token, requestQueue });
  if (error) {
    yield put(addMessage(appTextError));
  } else {
    yield put(addMessage(appTextSuccess));
  }
}

// Used the enterprise API, no accessToken accepted
const postBusinessEnrollment = ({
  companyName,
  companyEmail,
  companySize,
  userEmail,
  password,
  firstname,
  lastname,
  phone,
  pwa,
  requestQueue,
}) => (
  ClientApi
    .businessEnroll({
      data: {
        companyName,
        companyEmail,
        companySize,
        userEmail,
        password,
        firstname,
        lastname,
        phone,
        pwa,
      },
      requestQueue,
    })
    .then(({ body }) => ({ body }))
    .then(({ response }) => ({ error: response }))
);

function* businessEnroll(action) {
  const requestQueue = yield select(getRequestQueue);
  const pwa = yield select(getPortalAffiliateId);
  const {
    companyName,
    companyEmail,
    companySize,
    userEmail,
    password,
    firstname,
    lastname,
    phone,
  } = action.payload;

  const { body, error } = yield call(postBusinessEnrollment, {
    companyName,
    companyEmail,
    companySize,
    userEmail,
    password,
    firstname,
    lastname,
    phone,
    pwa,
    requestQueue,
  });
  if (error) {
    const { body: errorBody } = error;
    let { message } = errorBody;
    if (typeof message === 'object') {
      message = capitalize(Object.values(message).join(', '));
    }

    yield put(addMessageAndScrollToTop(businessEnrollError(message)));
    return;
  }

  const { login_uri: businessURL } = body;
  yield put(addMessageAndScrollToTop(businessEnrollSuccess(businessURL)));
}

function* dismissAnnouncementBanner() {
  yield call(cookieAnnouncementDismsisal);
}

function cookieAnnouncementDismsisal() {
  cookie.save('announcement-dismissed', true, { path: '/', domain: COOKIE_DOMAIN });
}

const postContactInquiry = ({ fullName, email, phone, message, accessToken, requestQueue }) => (
  ClientApi
    .postContactInquiry({ fullName, email, phone, message }, accessToken, requestQueue)
    .then(({ body }) => ({ body }))
    .catch(({ error }) => ({ error }))
);

function* sendContactInquiry(action) {
  const requestQueue = yield select(getRequestQueue);
  const user = yield select(getUser);
  const { token } = user;
  const { fullName, email, phone, message } = action.payload;

  yield call(postContactInquiry, {
    fullName,
    email,
    phone,
    message,
    accessToken: token,
    requestQueue,
  });
}

function* getPressRelease(action) {
  const { pressReleaseSlug } = action.payload;
  const pressReleases = yield select(getPressReleases);
  const release = pressReleases.find(p => (
    (p.date_posted === pressReleaseSlug) || (p.url_slug_slug === pressReleaseSlug)
  ));
  if (release) yield put(gotPressRelease({ release }));
}

const deletePartnerCookies = () => {
  cookie.remove('portal_affiliate_id', { path: '/' });
};

function* clearPartnerCookies() {
  yield call(deletePartnerCookies);
  yield call(reload);
}

export default function* root() {
  yield takeEvery(SEND_APP_DOWNLOAD_TEXT, sendAppDownloadText);
  yield takeEvery(SEND_CONTACT_INQUIRY, sendContactInquiry);
  yield takeLatest(BUSINESS_ENROLL, businessEnroll);
  yield takeLatest(DISMISS_ANNOUNCEMENT_BANNER, dismissAnnouncementBanner);
  yield takeLatest(INITIALIZE_PRESS_RELEASE_PAGE, getPressRelease);
  yield takeEvery(CLEAR_PARTNER_COOKIES, clearPartnerCookies);
}
