import { Record } from 'immutable';

export default class PostalAddress extends Record({
  '@context': 'http://schema.org',
  '@type': 'PostalAddress',
  'streetAddress': null,
  'addressLocality': null,
  'addressRegion': null,
  'postalCode': null,
}) {
  constructor(props) {
    if (!props) {
      super();
      return;
    }

    const { address: streetAddress,
            city: addressLocality,
            state: addressRegion,
            postalCode } = props;

    super({ streetAddress, addressLocality, addressRegion, postalCode });
  }
}
