import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const propTypes = {
  buttonClassName: PropTypes.string,
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  onTouchEnd: PropTypes.func,
  textClassName: PropTypes.string,
  canSubmit: PropTypes.bool,
};

const defaultProps = {
  buttonClassName: '',
  onClick: null,
  onTouchEnd: null,
  textClassName: '',
  id: '',
  canSubmit: true,
};

// Ignore the pure function linting error.
// We user a ref call on this button in some places
// This is ONLY defined properly if the component extends React.Component
class ProgressButton extends Component {
  render() {
    const { buttonClassName, children, id, loading, onTouchEnd, textClassName, canSubmit } = this.props;

    const progressClasses = cx({
      'progress-btn-outer': true,
      'clickable': canSubmit,
      'btn-disabled': !canSubmit && !loading,
      'state-loading': loading,
    });

    const onClick = canSubmit ? this.props.onClick : () => {};

    return (
      <div
        className={`${buttonClassName} ${progressClasses}`}
        id={id}
        onClick={onClick}
        onTouchEnd={onTouchEnd}
        ref={(btn) => { this.btnRef = btn; }}
      >
        <span className="progress-btn-wrap">
          <div className={`${textClassName} text`}>
            { children }
          </div>
          <span className="progress-btn">
            <span className="progress-btn-inner" />
          </span>
        </span>
      </div>
    );
  }
}

ProgressButton.propTypes = propTypes;
ProgressButton.defaultProps = defaultProps;
export default ProgressButton;
