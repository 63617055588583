import React from 'react';
import { Record } from 'immutable';
import moment from 'moment-timezone';
import { FormattedMessage } from 'react-intl';

import { preciseHumanizeRange } from 'lib/date-lib';

export default class ShuttleTime extends Record({
  startTime: null,
  endTime: null,
  minTravelTime: null,
  maxTravelTime: null,
  minFrequency: null,
  maxFrequency: null,
  onDemand: null,
  onDemandPhone: null,
  bufferStartTime: null,
  bufferEndTime: null,
}) {
  constructor(props, bufferStartTime, bufferEndTime) {
    if (!props) {
      super();
      return;
    }

    const startTime = moment.parseZone((props.start_time));
    const endTime = moment.parseZone((props.end_time));

    let minTravelTime = null;
    if (props.travel_time && props.travel_time.minimum) {
      minTravelTime = moment.duration(props.travel_time.minimum, 'minutes');
    }

    let maxTravelTime = null;
    if (props.travel_time && props.travel_time.maximum) {
      maxTravelTime = moment.duration(props.travel_time.maximum, 'minutes');
    }

    let minFrequency = null;
    if (props.frequency && props.frequency.minimum) {
      minFrequency = moment.duration(props.frequency.minimum, 'minutes');
    }

    let maxFrequency = null;
    if (props.frequency && props.frequency.maximum) {
      maxFrequency = moment.duration(props.frequency.maximum, 'minutes');
    }

    super({
      startTime,
      endTime,
      minTravelTime,
      maxTravelTime,
      minFrequency,
      maxFrequency,
      onDemand: props.on_demand,
      onDemandPhone: props.phone && props.phone.on_demand,
      bufferStartTime,
      bufferEndTime,
    });
  }

  activeTimeRange(format, splitString, formatId = null) {
    const start = this.activeStartTime.format(format);
    const end = this.activeEndTime.format(format);

    if (start === end) { return start; }

    if (formatId) {
      return (<FormattedMessage
        id={formatId}
        defaultMessage="{start}{splitString}{end}"
        values={{ start, end, splitString }}
      />);
    }
    return `${start}${splitString}${end}`;
  }

  get humanizedFrequencyRange() {
    return preciseHumanizeRange(this.minFrequency, this.maxFrequency);
  }

  get humanizedTravelTimeRange() {
    return preciseHumanizeRange(this.minTravelTime, this.maxTravelTime, false);
  }

  get activeStartTime() {
    if (!this.bufferStartTime) { return this.startTime; }

    if (this.startTime < this.bufferStartTime) {
      return this.bufferStartTime;
    }

    return this.startTime;
  }

  get activeEndTime() {
    if (!this.bufferEndTime) { return this.endTime; }

    if (this.endTime > this.bufferEndTime) {
      return this.bufferEndTime;
    }

    return this.endTime;
  }

  get frequencies() {
    const frequencies = [];

    if (this.minFrequency) { frequencies.push(this.minFrequency); }
    if (this.maxFrequency) { frequencies.push(this.maxFrequency); }

    return frequencies;
  }
}
