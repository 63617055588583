import { Record } from 'immutable';

import { fetchMessages, addMessages } from 'lib/messaging';
import { Message, Messages } from 'models/messages';

import { ADD_MESSAGE } from 'action-creators/messaging/add-message';
import { SET_MESSAGES } from 'action-creators/messaging/set-messages';
import { DISMISS_MESSAGE } from 'action-creators/messaging/dismiss-message';
import { RELOAD_MESSAGES } from 'action-creators/messaging/reload-messages';
import { GOT_QUOTES } from 'action-creators/search/got-quotes';

export class MessagingState extends Record({
  messages: Messages(),
}) {
  constructor(props) {
    if (!props) {
      super();
      return;
    }

    let messages = fetchMessages({ newPageLoad: true });

    if (props.message) {
      messages = addMessages([new Message(props.message)]);
    }

    if (props.messages) {
      messages = addMessages((props.messages || []).map(m => new Message(m)));
    }

    super({
      messages,
    });
  }
}

export default function messagingReducer(state = new MessagingState(), action = null) {
  switch (action.type) {
    case ADD_MESSAGE: {
      const { message } = action.payload;
      const messages = addMessages([message]);

      if (window && window.pwMessaging) {
        window.pwMessaging.render();
      }

      return state.merge({
        messages,
      });
    }
    case DISMISS_MESSAGE: {
      const { messages } = action;
      return state.merge({
        messages,
      });
    }
    case SET_MESSAGES: {
      const messages = action.messages || state.messages;
      return state.merge({
        messages,
      });
    }
    case RELOAD_MESSAGES: {
      const { messages } = action;
      return state.merge({
        messages,
      });
    }
    default:
      return state;
  }
}
