export const CHECKOUT_SET_SHOULD_SCROLL_TO_ERROR = 'checkout-set-should-scroll-to-error';

/**
 * @param  {boolean} [shouldScrollToError]
 *         if we should scroll to the highest required field with an error
 * @return {{payload: boolean, type: string}}
 *         redux action
 */
export function setShouldScrollToError(shouldScrollToError) {
  return {
    type: CHECKOUT_SET_SHOULD_SCROLL_TO_ERROR,
    payload: shouldScrollToError,
  };
}
