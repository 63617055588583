import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment-timezone';
import { List, Map, OrderedMap } from 'immutable';

import MapboxAutocomplete from 'containers/mapbox/autocomplete';
import HourlyPicker from 'components/common/mobile-destination-time-picker/hourly-picker';
import MonthlyPicker from 'components/common/mobile-destination-time-picker/monthly-picker';
import { PARKING_UNAVAILABLE_MESSAGE } from 'components/checkout/modal-notices';

import Coordinates from 'models/coordinates';
import Search from 'models/search';
import Venue from 'models/venue';
import Event from 'models/event';
import Hub from 'models/hub';

import changeTimesCreator from 'action-creators/search/change-times';
import changeSearchDestinationCreator from 'action-creators/search/change-search-destination';
import changeMonthlyStartCreator from 'action-creators/checkout/change-monthly-start';
import trackEventCreator from 'action-creators/analytics/track-event';
import dismissTimePickerPromptCreator from 'action-creators/search/dismiss-time-picker-prompt';

const propTypes = {
  destination: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Venue),
    PropTypes.instanceOf(Event),
    PropTypes.instanceOf(Hub),
  ]).isRequired,
  isParkingNearMeSearch: PropTypes.bool,
  searchStartTime: PropTypes.instanceOf(moment).isRequired,
  searchEndTime: PropTypes.instanceOf(moment).isRequired,
  monthlyStart: PropTypes.instanceOf(moment).isRequired,
  timezone: PropTypes.string.isRequired,
  parkingType: PropTypes.string.isRequired,
  geoIPLocation: PropTypes.instanceOf(Coordinates).isRequired,
  timesChange: PropTypes.func.isRequired,
  changeSearchDestination: PropTypes.func.isRequired,
  changeMonthlyStart: PropTypes.func.isRequired,
  quoteTimesChange: PropTypes.func,
  displayModal: PropTypes.bool.isRequired,
  messages: PropTypes.instanceOf(List),
  currentSearch: PropTypes.instanceOf(Search).isRequired,
  app: PropTypes.string.isRequired,
  trackEvent: PropTypes.func.isRequired,
  displayAutocomplete: PropTypes.bool,
  variation: PropTypes.string,
  error: PropTypes.instanceOf(Map),
  locations: PropTypes.instanceOf(OrderedMap).isRequired,
  dismissTimePickerPrompt: PropTypes.func.isRequired,
};

const defaultProps = {
  messages: List(),
  isParkingNearMeSearch: false,
  displayAutocomplete: true,
  quoteTimesChange: null,
  variation: '',
  error: Map(),
};

class MobileDestinationTimePicker extends Component {
  constructor(props) {
    super(props);

    this.onSearch = this.onSearch.bind(this);
    this.timesChange = this.timesChange.bind(this);
    this.updateAutocompleteValue = this.updateAutocompleteValue.bind(this);
  }

  componentDidMount() {
    if (this.props.displayAutocomplete) { this.updateAutocompleteValue(); }
  }

  componentDidUpdate(prevProps) {
    const { displayAutocomplete, displayModal } = this.props;
    if (!displayAutocomplete) { return; }

    if (prevProps.displayModal && !displayModal) {
      if (this.autocomplete.autocomplete) {
        this.autocomplete.autocomplete.clearInput();
      }
    }

    if (!prevProps.displayModal && displayModal) {
      this.updateAutocompleteValue();
    }
  }

  get currentQuote() {
    const { currentSearch, locations } = this.props;

    return currentSearch.getSelectedQuote(locations);
  }

  get startTime() {
    // Cerebral doesn't have a good way to reconcile state props and props
    // passed in, so we have this getter to do it for us.
    const { searchStartTime, variation } = this.props;
    const { currentQuote } = this;

    if (variation === 'checkout' && currentQuote) {
      return currentQuote.startTime;
    }

    return searchStartTime;
  }

  get endTime() {
    // Cerebral doesn't have a good way to reconcile state props and props
    // passed in, so we have this getter to do it for us.
    const { searchEndTime, variation } = this.props;
    const { currentQuote } = this;

    if (variation === 'checkout' && currentQuote) {
      return currentQuote.endTime;
    }

    return searchEndTime;
  }

  get maxEndTime() {
    const { variation } = this.props;
    const { currentQuote } = this;
    if (!(variation === 'checkout' && currentQuote)) { return null; }

    return currentQuote.maxEndTime;
  }

  get isAutoExtended() {
    if (!this.maxEndTime) { return false; }
    if (this.props.error.get('message') === PARKING_UNAVAILABLE_MESSAGE) { return false; }

    return !this.endTime.isSame(this.maxEndTime);
  }

  timesChange(input) {
    const { variation, quoteTimesChange, timesChange } = this.props;
    this.props.dismissTimePickerPrompt();

    if (variation === 'checkout' && typeof quoteTimesChange === 'function') {
      return quoteTimesChange(input);
    }

    return timesChange(input);
  }

  onSearch({ place, event }) {
    const { changeSearchDestination } = this.props;
    changeSearchDestination({ place, event });
  }

  updateAutocompleteValue() {
    const { destination, isParkingNearMeSearch } = this.props;
    let destinationName = typeof (destination) === 'string' ? destination : destination.name;
    if (isParkingNearMeSearch) { destinationName = 'Current Location'; }
    if (this.autocomplete.autocomplete) {
      this.autocomplete.autocomplete.setInput(destinationName);
    }
  }

  renderAutocomplete() {
    const {
      geoIPLocation,
      currentSearch,
      app,
      trackEvent,
      destination,
      isParkingNearMeSearch,
    } = this.props;
    const { lat, lng } = destination;

    let destinationName = typeof (destination) === 'string' ? destination : destination.name;
    if (isParkingNearMeSearch) { destinationName = 'Current Location'; }

    return (
      <div className="row">
        <MapboxAutocomplete
          className="gplaces-autocomplete"
          buttonClassName="btn btn-blue"
          dropdownClassName="location-suggestion-bar-search-modal"
          geoIPLocation={geoIPLocation}
          inputClassName="gplaces-input-dropdown mobile"
          placeholder="Address, Place, or Event"
          fullscreen={false}
          showCancel={false}
          onSearch={this.onSearch}
          ref={(autocomplete) => { this.autocomplete = autocomplete; }}
          temporaryInputValue={destinationName}
          currentDestination={{ lat, lng }}
          currentSearch={currentSearch}
          app={app}
          trackEvent={trackEvent}
          id="mobile-search-autocomplete"
          daily
        />
      </div>
    );
  }

  renderPicker() {
    const {
      timezone,
      monthlyStart,
      changeMonthlyStart,
      messages,
      parkingType,
    } = this.props;

    if (parkingType === Search.MONTHLY_PARKING_TYPE) {
      return (
        <MonthlyPicker
          monthlyStart={monthlyStart}
          changeMonthlyStart={changeMonthlyStart}
        />
      );
    }

    return (
      <HourlyPicker
        startTime={this.startTime}
        endTime={this.endTime}
        timezone={timezone}
        timesChange={this.timesChange}
        messages={messages}
      />
    );
  }

  renderAutoExtendCopy() {
    if (!this.isAutoExtended) { return null; }

    return (
      <p className="margin-top-40 text-color-dark-slate text-size-16 text-weight-book">At this facility, { this.maxEndTime.format('h:mm A') } costs the same as the time you selected. The extra time has been added to your booking—stay until { this.maxEndTime.format('h:mm A') } if you like!</p>
    );
  }

  render() {
    const { displayAutocomplete } = this.props;

    return (
      <div className="col-xs-12">
        { displayAutocomplete ? this.renderAutocomplete() : null }
        { this.renderPicker() }
        { this.renderAutoExtendCopy() }
      </div>
    );
  }
}

MobileDestinationTimePicker.propTypes = propTypes;
MobileDestinationTimePicker.defaultProps = defaultProps;

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    timesChange: changeTimesCreator,
    changeSearchDestination: changeSearchDestinationCreator,
    changeMonthlyStart: changeMonthlyStartCreator,
    trackEvent: trackEventCreator,
    dismissTimePickerPrompt: dismissTimePickerPromptCreator,
  }, dispatch)
);

const mapStateToProps = (state) => {
  const {
    event,
    currentSearch,
    geoIPLocation,
    venue,
    timezone,
    locations,
    error,
  } = state.search;

  const {
    startTime: searchStartTime,
    endTime: searchEndTime,
    destination,
    parkingType,
    isParkingNearMeSearch,
  } = currentSearch;

  const {
    displayModal,
  } = state.modal;

  const {
    messages,
  } = state.messaging;

  const {
    monthlyStart,
  } = state.checkout;

  const {
    name: app,
  } = state.app;

  return {
    event,
    currentSearch,
    geoIPLocation,
    locations,
    venue,
    timezone,
    searchStartTime,
    searchEndTime,
    destination,
    parkingType,
    isParkingNearMeSearch,
    displayModal,
    messages,
    monthlyStart,
    app,
    error,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileDestinationTimePicker);
