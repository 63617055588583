import React from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import Brand from 'models/brand';

const propTypes = {
  brand: PropTypes.instanceOf(Brand).isRequired,
  href: PropTypes.string,
  affiliatePartner: PropTypes.instanceOf(Map),
};

const defaultProps = {
  href: '/',
  affiliatePartner: Map(),
};

const NavLogo = ({ href, brand, affiliatePartner }) => {
  const { displayName, logoURL } = brand;
  const brandingImageUrl = affiliatePartner.get('brandingImageUrl');
  const logo = brandingImageUrl || logoURL;
  const style = logo ? { backgroundImage: `url(${logo})` } : { };

  return (
    <a href={href} className="brand-header-logo" style={style} title={`${displayName}`} />
  );
};

NavLogo.propTypes = propTypes;
NavLogo.defaultProps = defaultProps;

export default NavLogo;
