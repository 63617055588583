import React from 'react';
import PropTypes from 'prop-types';

import Coordinates from 'models/coordinates';

import { MapboxAutoCompleteService } from 'lib/mapbox/autocomplete';
import Insights from '@parkwhiz-js/insights-sdk';

import { redirectToParkingNearMe, renderMessageAndScrollToTop } from 'components/mapbox/base';
import ProgressButton from 'components/common/buttons/progress-button';

import { Message } from 'models/messages';
import { denyGeolocationPermission } from 'lib/common/messages';
import { addMessages } from 'lib/messaging';
import { FIND_PARKING_BUTTON_CLICK } from 'lib/analytics/events';
import * as AppContext from 'lib/app-context';
import { FormattedMessage } from 'react-intl';

/**
 * Don't use this unless you have to. I'm serious.
 */
const propTypes = {
  buttonClassName: PropTypes.string,
  className: PropTypes.string,
  geoIPLocation: PropTypes.instanceOf(Coordinates).isRequired,
  image: PropTypes.string,
  imageSrcSet: PropTypes.string,
  imageClassName: PropTypes.string,
  addMessageAndScrollToTop: PropTypes.func,
  hubPageUrl: PropTypes.string,
  text: PropTypes.string,
  trackEvent: PropTypes.func,
  appContext: PropTypes.string,
  app: PropTypes.string,
  insights: PropTypes.instanceOf(Insights),
};

const defaultProps = {
  addMessageAndScrollToTop: null,
  buttonClassName: '',
  className: '',
  image: null,
  imageSrcSet: null,
  imageClassName: '',
  hubPageUrl: null,
  text: null,
  app: 'Home',
  appContext: AppContext.MOBILE,
  track: () => {},
  trackEvent: () => {},
  insights: null,
};

class MapboxGeolocation extends React.Component {
  static submitForm(coordinates) {
    window.location = MapboxAutoCompleteService.getCurrentLocationURL(coordinates);
  }

  static redirectToHubMap(hubPageUrl) {
    window.location = hubPageUrl;
  }

  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
    this.collectCoordinates = this.collectCoordinates.bind(this);

    this.state = {
      fetching: false,
      latitude: null,
      loading: false,
      longitude: null,
    };
  }

  componentDidMount() {
    this.autocomplete = new MapboxAutoCompleteService(this.props.geoIPLocation, this.props.insights);
  }

  componentDidUpdate(_, prevState) {
    const { latitude, longitude, fetching } = this.state;
    const { latitude: prevLatitude, longitude: prevLongitude } = prevState;
    if ((latitude !== prevLatitude) && (longitude !== prevLongitude) && (!fetching)) {
      redirectToParkingNearMe({ latitude, longitude });
    }
  }

  onClick(e) {
    e.preventDefault();
    e.stopPropagation();
    if (this.props.appContext === AppContext.MOBILE && this.props.app === 'Home') {
      this.props.trackEvent(FIND_PARKING_BUTTON_CLICK);
    }
    const { hubPageUrl } = this.props;
    if (hubPageUrl) {
      this.constructor.redirectToHubMap(hubPageUrl);
    } else {
      const { latitude, longitude } = this.state;
      if (!latitude || !longitude) {
        this.autocomplete.getCurrentLocation(this.collectCoordinates);
        this.setState({ fetching: true, loading: true });
      } else {
        redirectToParkingNearMe({ latitude, longitude });
      }
    }
  }

  collectCoordinates(args) {
    const { latitude, longitude, successfulRetrieval } = args;
    const { addMessageAndScrollToTop } = this.props;

    if (successfulRetrieval) {
      redirectToParkingNearMe({ latitude, longitude });
    } else if (addMessageAndScrollToTop) {
      addMessageAndScrollToTop(denyGeolocationPermission);
    } else if (window.pwMessaging) {
      addMessages([new Message(denyGeolocationPermission)]);
      renderMessageAndScrollToTop();
    }
  }

  renderButton() {
    const { buttonClassName } = this.props;
    const { loading } = this.state;
    return (
      <ProgressButton
        buttonClassName={`${buttonClassName} fout-enabled`}
        loading={loading}
        onTouchEnd={this.onClick}
      >
        <span className="iconified iconified-current-location iconified-space-right" />
        <FormattedMessage id="common.find-nearby-parking" defaultMessage="Find Parking Nearby" />
      </ProgressButton>
    );
  }

  renderImage() {
    const { image, imageSrcSet, imageClassName } = this.props;

    return (
      <img
        className={`${imageClassName} clickable`}
        onClick={this.onClick}
        src={image}
        srcSet={imageSrcSet}
      />
    );
  }

  renderText() {
    const { text, className } = this.props;

    return (
      <div className={className} onClick={this.onClick}>
        <span className="iconified iconified-current-location iconified-space-right" />
        <span className="border-style-solid border-side-bottom border-color-white">
          { text }
        </span>
      </div>
    );
  }

  render() {
    const { image, text } = this.props;

    let content;
    if (image) {
      content = this.renderImage();
    } else if (text) {
      content = this.renderText();
    } else {
      content = this.renderButton();
    }

    return (
      <div ref={(button) => { this.button = button; }}>
        { content }
      </div>
    );
  }
}

MapboxGeolocation.propTypes = propTypes;
MapboxGeolocation.defaultProps = defaultProps;

export default MapboxGeolocation;
