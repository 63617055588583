import NewPaymentMethod from 'models/braintree/new-payment-method';

export const INITIALIZE_NEW_PAYMENT_METHOD = 'initialize-new-payment-method';

export default function initializeNewPaymentMethod() {
  const paymentMethod = new NewPaymentMethod();

  return {
    type: INITIALIZE_NEW_PAYMENT_METHOD,
    payload: {
      paymentMethod,
    },
  };
}
