import { stringify, parse } from 'query-string';

export const addQuery = (url, additionalQueryParams = {}) => {
  const query = stringify(additionalQueryParams);
  const existingParams = Object.values(parse(url)).some(p => p);

  let fullQueryString = url;
  if (query) {
    if (existingParams) {
      fullQueryString = `${fullQueryString}&${query}`;
    } else {
      fullQueryString = `${fullQueryString}?${query}`;
    }
  }
  return fullQueryString;
};

export const redirectToParkingNearMe = ({ latitude, longitude }, additionalQueryParams = {}) => {
  window.location = addQuery(
    `/parking-near-me?lat=${latitude}&lng=${longitude}`,
    additionalQueryParams,
  );
};

export const renderMessageAndScrollToTop = () => {
  if (window.pwMessaging) { window.pwMessaging.render(); }
  // Ensure error message at top of form is visible.
  window.scroll({ top: 0, left: 0, behavior: 'smooth' });
};
