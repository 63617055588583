import pickBy from 'lodash/pickBy';
import isString from 'lodash/isString';
import isBoolean from 'lodash/isBoolean';

export const CHANGE_USER_INFO = 'change-user-info';
const changeUserInfo = payload => ({
  type: CHANGE_USER_INFO,
  payload: pickBy(payload, v => isString(v) || isBoolean(v)),
});
export default changeUserInfo;
