export const GET_BOOKING_COUNTS = 'get-booking-counts';

export default function getBookingCounts({ query, type }) {
  return {
    type: GET_BOOKING_COUNTS,
    payload: {
      query,
      type,
    },
  };
}
