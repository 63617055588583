import { Set, OrderedMap } from 'immutable';

const displayName = (field) => {
  switch (field) {
    case 'cvv':
      return 'CVV';
    case 'expirationMonth':
      return 'expiration month';
    case 'expirationYear':
      return 'expiration year';
    case 'number':
      return 'credit card number';
    case 'postalCode':
      return 'zip code';
    default:
      return field;
  }
};

export const HANDLE_PAYMENT_ERROR = 'handle-payment-error';

/**
 * @typedef  {Object} FieldError
 * @property {String} field
 *           name of the field which has an error
 */

/**
 * @param  {Array.<FieldError>} fieldErrors
 *         List of field names which have errors. Usually returned from an API response.
 * @return {{payload: {requiredFieldErrors: OrderedMap<String, RequiredField>}, type: string}}
 *         redux action with a map of required fields which have errors.
 */
const handlePaymentError = (fieldErrors) => {
  let errorFields = Set();

  if (fieldErrors && fieldErrors.map) { errorFields = Set(fieldErrors.map(e => e.field)); }

  if (errorFields.get('billingAddress')) {
    errorFields = errorFields.delete('billingAddress').add('postalCode');
  }

  // braintree returns an `expirationDate` error field when `expirationMonth` or `expirationYear` are bad.
  // we'll mark both as wrong and circle back if need be. it's better than before - we weren't marking either.
  if (errorFields.get('expirationDate')) {
    errorFields = errorFields
      .delete('expirationDate')
      .add('expirationMonth')
      .add('expirationYear');
  }

  let requiredFieldErrors = OrderedMap();
  errorFields.forEach((field) => {
    requiredFieldErrors = requiredFieldErrors.set(field, {
      isValid: false,
      isPotentiallyValid: false,
      isEmpty: false,
      displayName: displayName(field),
    });
  });

  return {
    type: HANDLE_PAYMENT_ERROR,
    payload: { requiredFieldErrors },
  };
};
export default handlePaymentError;
