import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { OrderedMap, Map } from 'immutable';

import Clipboard from 'clipboard';

import UserModel from 'models/user';
import HubModel from 'models/hub';
import EventModel from 'models/event';
import VenueModel from 'models/venue';
import SearchModel from 'models/search';
import Quote from 'models/quote';
import Booking from 'models/booking';
import DeepLink from 'models/deep-link';
import TrackingProperties from 'models/tracking-properties';
import env from 'lib/env';
import Brand from 'models/brand';

import clearPartnerCookies from 'action-creators/brand/clear-partner-cookies';

const { ADMIN_HOST, PORTAL_URL } = env();

export class Admin extends Component {
  constructor(props) {
    super(props);

    this.openConsole = this.openConsole.bind(this);
    this.clearPartnerCookies = this.clearPartnerCookies.bind(this);
  }

  componentWillMount() {
    this.setState({
      display: false,
    });
  }

  componentDidUpdate(_, prevState) {
    if (!prevState.display && this.state.display) {
      this.clipboard = new Clipboard('.clipboard');
    }
  }

  openConsole(e) {
    e.preventDefault();

    this.setState({
      display: true,
    });
  }

  clearPartnerCookies(e) {
    e.preventDefault();
    e.stopPropagation();

    this.props.clearPartnerCookies();
  }

  renderLocationLink() {
    if (this.props.currentSearch && this.props.currentSearch.selectedLocationId) {
      return (
        <span className="admin-link">
          <a target="_blank" rel="noopener noreferrer" href={`${ADMIN_HOST}/locations/${this.props.currentSearch.selectedLocationId}`}>
            Location
          </a>
        </span>
      );
    }
    return null;
  }

  renderEventLink() {
    if (this.props.event) {
      return (
        <span className="admin-link">
          Event:
          <a target="_blank" rel="noopener noreferrer" href={`${ADMIN_HOST}/events/${this.props.event.id}`}>
            {this.props.event.name}
          </a>
        </span>
      );
    }
    return null;
  }

  renderVenueLink() {
    if (this.props.venue) {
      return (
        <span className="admin-link">
          Venue:
          <a target="_blank" rel="noopener noreferrer" href={`${ADMIN_HOST}/venues/${this.props.venue.id}`}>
            {this.props.venue.name}
          </a>
        </span>
      );
    }
    return null;
  }

  renderHubLink() {
    if (this.props.hub) {
      return (
        <span className="admin-link">
          Hub:
          <a target="_blank" rel="noopener noreferrer" href={`${ADMIN_HOST}/hubs/${this.props.hub.id}/edit`}>
            {this.props.hub.name}
          </a>
        </span>
      );
    }
    return null;
  }

  renderBookingLink() {
    if (this.props.parkingPasses.size > 0) {
      return (
        <span className="admin-link">
          Booking:
          <a target="_blank" rel="noopener noreferrer" href={`${ADMIN_HOST}/bookings/${this.props.parkingPasses.first().id}`}>
            {this.props.parkingPasses.first().id}
          </a>
        </span>
      );
    }
    return null;
  }

  renderDeepLink() {
    const { app, trackingProperties, couponCode, portalAffiliateId, brand } = this.props;
    let deepLink;

    switch (app) {
      case 'Parking Pass': {
        const subject = new Booking(this.props.subject);
        deepLink = subject.getDeepLink(brand, {}, true).withTrackingProperties(trackingProperties);
        break;
      }
      case 'Search':
      case 'Hub':
      case 'Venue':
      {
        const subject = new SearchModel(this.props.subject);
        deepLink = subject.getDeepLink(
          brand,
          {},
          trackingProperties,
          couponCode,
          portalAffiliateId,
          true,
          app,
        );
        break;
      }
      case 'Home': {
        deepLink = new DeepLink({
          view: 'home',
          trackingParams: { pid: 'home_banner' },
          trackingProperties,
          coupon: couponCode,
          portalAffiliateId,
          admin: true,
          brand,
        });
        break;
      }
      case 'Checkout': {
        const subject = new Quote(this.props.subject);
        deepLink = subject.getDeepLink(
          brand,
          {},
          trackingProperties,
          couponCode,
          portalAffiliateId,
          true,
        );
        break;
      }
      default:
        return null;
    }

    return (
      <span
        data-clipboard-text={deepLink.url}
        className="clipboard admin-link clickable text-color-blue"
      >
        Copy Deep Link
      </span>
    );
  }

  renderAffiliateId() {
    const { portalAffiliateId } = this.props;
    if (portalAffiliateId) {
      return (
        <span className="admin-link">
          Affiliate ID:
          <a target="_blank" rel="noopener noreferrer" href={`${PORTAL_URL}/accounts/${portalAffiliateId}`}>
            {portalAffiliateId}
          </a>
        </span>
      );
    }
    return null;
  }

  renderCookieClearLink() {
    const { portalAffiliateId } = this.props;
    if (portalAffiliateId) {
      return (
        <span className="admin-link">
          <a onClick={this.clearPartnerCookies}>
            Clear Partner Cookies
          </a>
        </span>
      );
    }
    return null;
  }

  render() {
    if (!this.props.user || !this.props.user.admin) { return null; }
    if (this.state.display) {
      return (
        <div className="admin-console background-white" id="admin-console">
          { this.renderLocationLink() }
          { this.renderEventLink() }
          { this.renderVenueLink() }
          { this.renderHubLink() }
          { this.renderBookingLink() }
          { this.renderDeepLink() }
          { this.renderAffiliateId() }
          { this.renderCookieClearLink() }
        </div>
      );
    }
    return (
      <div className="admin-console hidden-print" id="admin-console" key="admin-console">
        <a className="admin-open btn-primary hidden-xs" onClick={this.openConsole}>
          Open Admin Console
        </a>
      </div>
    );
  }
}

Admin.defaultProps = {
  user: null,
  hub: null,
  event: null,
  venue: null,
  currentSearch: null,
  parkingPasses: null,
  couponCode: null,
  portalAffiliateId: null,
  subject: null,
};

Admin.propTypes = {
  app: PropTypes.string.isRequired,
  user: PropTypes.instanceOf(UserModel),
  hub: PropTypes.instanceOf(HubModel),
  event: PropTypes.instanceOf(EventModel),
  venue: PropTypes.instanceOf(VenueModel),
  currentSearch: PropTypes.instanceOf(SearchModel),
  parkingPasses: PropTypes.instanceOf(OrderedMap),
  trackingProperties: PropTypes.instanceOf(TrackingProperties).isRequired,
  couponCode: PropTypes.string,
  portalAffiliateId: PropTypes.string,
  subject: PropTypes.oneOfType([
    PropTypes.instanceOf(Map),
    PropTypes.instanceOf(SearchModel),
    PropTypes.object,
    PropTypes.string,
  ]),
  brand: PropTypes.instanceOf(Brand).isRequired,
  clearPartnerCookies: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    clearPartnerCookies,
  }, dispatch)
);

const mapStateToProps = (state) => {
  const {
    user,
  } = state.account;

  const {
    name: app,
  } = state.app;

  const {
    currentSearch,
    hub,
    event,
    venue,
  } = state.search;

  const {
    parkingPasses,
  } = state.bookings;

  const {
    couponCode,
    portalAffiliateId,
  } = state.checkout;

  const {
    trackingProperties,
  } = state.analytics;

  const { deepLink } = state.deepLinks;
  const { subject } = deepLink;

  const { brand } = state.brand;

  return {
    app,
    user,
    currentSearch,
    hub,
    event,
    venue,
    parkingPasses,
    couponCode,
    portalAffiliateId,
    trackingProperties,
    subject,
    brand,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Admin);

