// Libraries
import moment from 'moment-timezone';
import React from 'react';
import PropTypes from 'prop-types';
import { DayPickerSingleDateController } from 'react-dates';

class DatePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hoverDate: null,
    };

    moment.locale(props.locale);

    this.initialVisibleMonth = this.initialVisibleMonth.bind(this);
    this.onDayMouseEnter = this.onDayMouseEnter.bind(this);
    this.onDayMouseLeave = this.onDayMouseLeave.bind(this);

    this.isSelected = this.isSelected.bind(this);
    this.isBlocked = this.isBlocked.bind(this);
  }

  onDayMouseEnter(day) {
    this.setState({
      hoverDate: day,
    });
  }

  onDayMouseLeave() {
    this.setState({
      hoverDate: null,
    });
  }

  initialVisibleMonth() {
    return this.props.date;
  }

  isBlocked(day) {
    return day.diff(this.props.minDate) < 0;
  }

  isHovered(day) {
    return day.isSame(this.state.hoverDate);
  }

  isSelected(day) {
    // Formats to 10/04/2016 for example
    return day.format('L') === this.props.date.format('L');
  }

  render() {
    return (
      <DayPickerSingleDateController
        date={this.props.date}
        onDateChange={this.props.onDateChange}
        initialVisibleMonth={this.initialVisibleMonth}
        navNext={<span className="iconified-font iconified-chevron-right text-color-blue" />}
        navPrev={<span className="iconified-font iconified-chevron-left text-color-blue" />}
        numberOfMonths={1}
        hideKeyboardShortcutsPanel
        enableOutsideDays={false}
        isOutsideRange={this.isBlocked}
        isDayBlocked={this.isBlocked}
        focused
      />
    );
  }
}

DatePicker.propTypes = {
  // Variables
  date: PropTypes.instanceOf(moment).isRequired,
  minDate: PropTypes.instanceOf(moment).isRequired,

  // Functions
  onDateChange: PropTypes.func.isRequired,
};

export default DatePicker;
