import PaymentMethod from 'models/payment-method';
import { SAVED_PAYMENT_METHOD } from 'action-creators/payment-methods/saved-payment-method';


export default function savedBusinessProfile(businessProfile){
  const paymentMethod = businessProfile._embedded['pw:payment_methods'][0];
  return {
    type: SAVED_PAYMENT_METHOD,
    payload: {
      paymentMethod: new PaymentMethod(paymentMethod),
    },
  };
}
