import Search from 'models/search';

export const SEARCH_BOUNDS_CHANGE = 'search-bounds-change';

export default function searchBoundsChange({ bounds, zoomLevel, lat, lng }) {
  let ne;
  let neLat;
  let neLng;
  let sw;
  let swLat;
  let swLng;

  if (!bounds) {
    const rawBounds = Search.boundsWithBackup({ lat, lng });
    neLat = rawBounds[0].split(',')[0];
    neLng = rawBounds[0].split(',')[1];
    swLat = rawBounds[1].split(',')[0];
    swLng = rawBounds[1].split(',')[1];
  } else {
    ne = bounds.getNorthEast();
    sw = bounds.getSouthWest();
    ({ lat: neLat, lng: neLng } = ne);
    ({ lat: swLat, lng: swLng } = sw);
  }
  const allBounds = [
    `${neLat},${swLng}`,
    `${neLat},${neLng}`,
    `${swLat},${neLng}`,
    `${swLat},${swLng}`,
  ];

  return {
    type: SEARCH_BOUNDS_CHANGE,
    payload: {
      lat,
      lng,
      zoomLevel,
      bounds: allBounds,
      searchType: 'bounds',
    },
  };
}
