import cookie from 'react-cookie';
import moment from 'moment-timezone';
import env from 'lib/env';
import get from 'lodash/get';

import SearchModel from 'models/search';

const { COOKIE_DOMAIN } = env();

export function biasSearch(lat, lng) {
  const expiry = moment().add(2, 'weeks').toDate();

  cookie.save('PLACES_LOCATION_BIAS', `${lat},${lng}`, { path: '/', expires: expiry, domain: COOKIE_DOMAIN });
}

export function isUnsupportedBrowser(userAgent) {
  if (!userAgent) { return false; }
  const info = getBrowserInfo(userAgent);
  return ((info.name === 'MSIE' || info.name === 'IE') && info.version < 10);
}

export function getBrowserInfo(ua) {
  let tem;
  let M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return { name: 'IE', version: (tem[1] || '') };
  }
  if (M[1] === 'Chrome') {
    tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
    if (tem != null) return { name: tem[1].replace('OPR', 'Opera'), version: tem[2] };
  }
  try {
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
  } catch (err) {
    M = ['unknown', 23];
  }
  if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
  return { name: M[0], version: M[1] };
}

export function usesLargeFormatLocationDetails(props) {
  // Check if this is a monthly search by checking current search method and search type
  const monthly = get(props, 'currentSearch.isMonthlySearch', false) || get(props, 'currentSearch.parkingType', '') === SearchModel.MONTHLY_PARKING_TYPE;
  // Check for various methods of assigning airport
  const airport = get(props, 'currentSearch.destination.enhancedAirport', false) || get(props, 'airport', false);
  // Use large format if either monthly or airport is our search
  return monthly || airport;
}

export function isMonthlyAvailable(slugInfo) {
  return Object.values(slugInfo).some(value => get(value, 'availability.monthly', false));
}
