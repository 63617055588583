import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import ReactMarkdown from 'react-markdown';

import ArrowToggle from 'components/common/arrow-toggle';

import * as AppContext from 'lib/app-context';

const propTypes = {
  appContext: PropTypes.string.isRequired,
  footerQuestions: PropTypes.instanceOf(List).isRequired,
  onHide: PropTypes.func.isRequired,
};

class FAQ extends Component {
  renderQuestions() {
    const { footerQuestions } = this.props;
    return (
      footerQuestions.map(({ question, answer }) => {
        const headline = <span className="text-weight-medium text-size-sm-18 text-size-xs-14">{question}</span>;
        const toggle = <ReactMarkdown source={answer} className="text-weight-book text-size-sm-16 text-size-xs-14" />;
        return <ArrowToggle key={question} target={headline} toggle={toggle} />;
      })
    );
  }

  render() {
    if (this.props.appContext === AppContext.MOBILE) {
      return (
        <div className="container">
          <div className="row background-color-white">
            <div className="modal-header background-color-white border-color-white">
              <div className="text-align-center">
                <div className="padding-bottom-xs-10 padding-bottom-sm-40 text-weight-black text-size-sm-24 text-size-xs-18 text-color-dark-slate">
                  FREQUENTLY ASKED QUESTIONS
                </div>
              </div>
            </div>
          </div>
          <div className="modal-body padding-top-0 background-color-white">
            <div className="content-mobile">{ this.renderQuestions() }</div>
          </div>
        </div>
      );
    }

    return (
      <div id="faqModal" className="modal" tabIndex="-1" role="dialog">
        <div className="container background-color-white border-radius-4 margin-top-sm-100 margin-top-xs-0 padding-horizontal-xs-20 padding-bottom-sm-40">
          <div className="row text-align-right">
            <div
              className="padding-top-10 padding-right-10 float-right iconified-font iconified-x text-size-18 text-color-blue text-color-hover-blue clickable"
              onClick={this.props.onHide}
              type="button"
            />
          </div>
          <div className="row text-align-center">
            <div className="text-align-center">
              <div className="padding-bottom-xs-10 padding-bottom-sm-40 text-weight-black text-size-sm-24 text-size-xs-18 text-color-dark-slate">
                FREQUENTLY ASKED QUESTIONS
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 padding-horizontal-sm-80 padding-horizontal-xs-0 padding-bottom-xs-20 padding-bottom-sm-0">
              <div className="content">
                { this.renderQuestions() }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

FAQ.propTypes = propTypes;
export default FAQ;
