import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Booking from 'models/booking';
import { Location } from 'models/locations';
import Brand from 'models/brand';

import BrandName from 'containers/common/brand-name';

import StarRatings from 'components/bookings/review-form/star-ratings';
import PositiveReviewResponse from 'components/bookings/review-form/positive-review-response';
import NegativeReviewResponse from 'components/bookings/review-form/negative-review-response';
import ThinNextArrow from 'components/svgs/icons/thin-next-arrow';

const propTypes = {
  booking: PropTypes.instanceOf(Booking).isRequired,
  location: PropTypes.instanceOf(Location).isRequired,
  brand: PropTypes.instanceOf(Brand).isRequired,
  dismiss: PropTypes.func.isRequired,
  createReview: PropTypes.func.isRequired,
};

class BookingReviewForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
      rating: null,
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(e) {
    e.stopPropagation();
    e.preventDefault();

    const { createReview, booking, location } = this.props;
    const comment = this.comments.value;
    const rating = this.rating.state.selectedRating;
    if (rating) {
      createReview({ bookingId: booking.id, locationId: location.id, comment, rating, authorizationCode: booking.authorizationCode });
      this.setState({ submitted: true, rating });
    }
  }

  render() {
    const { booking, location, brand } = this.props;
    const { submitted, rating } = this.state;
    if (submitted) {
      if (rating >= 3) {
        return <PositiveReviewResponse dismiss={this.props.dismiss} />;
      }
      return <NegativeReviewResponse dismiss={this.props.dismiss} />;
    }

    const submitClasses = cx({
      'btn': true,
      'btn-lg': true,
      'btn-primary': true,
      'btn-bp': brand.isBestParking,
    });

    return (
      <div className="background-color-white text-center border-radius-4 position-relative padding-vertical-30 padding-horizontal-50">
        <div className="row">
          <div className="col-xs-12">
            <div className="text-size-24 text-color-slate margin-top-0 margin-bottom-20 text-weight-bold">
              How was your experience?
            </div>
            <div className="text-size-16 text-color-slate text-weight-book margin-bottom-20">
              We take parking seriously, and we’d love to know how you enjoyed using <BrandName />.
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-10 col-sm-offset-1">
            <div className="text-color-dark-slate text-size-20 text-weight-book row margin-bottom-20">
              {location.name}
            </div>
            <div className="row margin-bottom-10 text-color-dark-slate">
              <div className="col-xs-5 col-sm-4 col-sm-offset-1">
                <div className="text-size-12 uppercase">
                  {booking.startTime.format('ddd MMM d')}
                </div>
                <div className="text-size-24">
                  {booking.startTime.format('h:mmA')}
                </div>
              </div>
              <div className="col-xs-2 col-gutter-sm-15 col-gutter-xs-8">
                <div className="middle-icon padding-top-15">
                  <ThinNextArrow />
                </div>
              </div>
              <div className="col-xs-5 col-sm-4">
                <div className="text-size-12 uppercase">
                  {booking.endTime.format('ddd MMM d')}
                </div>
                <div className="text-size-24">
                  {booking.endTime.format('h:mmA')}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 text-size-14 text-weight-light text-color-dark-slate">
                PARKING PASS# <span className="text-color-slate text-weight-bold">{booking.id}</span>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12">
                <form onSubmit={this.onSubmit}>
                  <div className="row">
                    <div className="col-xs-12">
                      <StarRatings
                        reviewRating={this.props.reviewRating}
                        ref={(r) => { this.rating = r; }}
                      />
                    </div>
                    <div className="col-xs-12">
                      <div className="margin-top-10 margin-bottom-20">
                        <textarea
                          placeholder="Your comments"
                          className="padding-10 form-control"
                          ref={(c) => { this.comments = c; }}
                        />
                      </div>
                      <div className="margin-bottom-20">
                        <button type="submit" onClick={this.onSubmit} className={submitClasses}>
                          Submit Review
                        </button>
                      </div>
                      <div className="margin-bottom-20">
                        <a className="btn btn-link" onClick={this.props.dismiss}>Skip this</a>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

BookingReviewForm.propTypes = propTypes;
export default BookingReviewForm;
