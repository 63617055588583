import { Record } from 'immutable';
import Insights from '@parkwhiz-js/insights-sdk';

import TrackingProperties from 'models/tracking-properties';

export class AnalyticsState extends Record({
  insights: new Insights(),
  trackingProperties: new TrackingProperties(),
}) {
  constructor(props) {
    if (!props) {
      super();
      return;
    }

    super({
      insights: props.insights,
      trackingProperties: new TrackingProperties(props.trackingProperties),
    });
  }
}

export default function analyticsReducer(state = new AnalyticsState(), action = null) {
  switch (action.type) {
    default:
      return state;
  }
}
