import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import VisibilitySensor from 'react-visibility-sensor';

import SearchSuggestions from 'models/search-suggestions';

import { HOUR_BUFFER_MODAL_IMPRESSION, HOUR_BUFFER_MODAL_CONFIRM_IMPRESSION, HOUR_BUFFER_MODAL_CONFIRM_CLICK } from 'lib/analytics/events';

import MinimumStartTimeBufferNotice from 'components/search/search-restrictions/minimum-start-time-buffer-notice';
import MinimumStartTimeBuffer from 'components/search/search-restrictions/minimum-start-time-buffer';
import MinimumDuration from 'components/search/search-restrictions/minimum-duration';

const propTypes = {
  searchSuggestions: PropTypes.instanceOf(SearchSuggestions),
  onDismiss: PropTypes.func.isRequired,
  trackEvent: PropTypes.func.isRequired,
};

const defaultProps = {
  searchSuggestions: new SearchSuggestions(),
};

class SearchRestrictions extends Component {
  constructor(props) {
    super(props);

    this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
    this.handleConfirmVisibilityChange = this.handleConfirmVisibilityChange.bind(this);
    this.onDismiss = this.onDismiss.bind(this);
  }

  onDismiss() {
    this.props.onDismiss();
    this.props.trackEvent(HOUR_BUFFER_MODAL_CONFIRM_CLICK);
  }

  handleVisibilityChange(visible) {
    if (visible) {
      this.props.trackEvent(HOUR_BUFFER_MODAL_IMPRESSION);
    }
  }

  handleConfirmVisibilityChange(visible) {
    if (visible) {
      this.props.trackEvent(HOUR_BUFFER_MODAL_CONFIRM_IMPRESSION);
    }
  }

  render() {
    const { searchSuggestions } = this.props;

    return (
      <VisibilitySensor onChange={this.handleVisibilityChange}>
        <div className="background-color-white padding-top-xs-120 padding-top-sm-0">
          <div className="row margin-bottom-10">
            <div className="col-xs-6 col-xs-offset-3 col-sm-4 col-sm-offset-4" >
              <div className="col-xs-12 margin-bottom-10">
                <img src="/static/images/zag-clock@2x.png" alt="Search Restrictions" />
              </div>
              <div className="col-xs-12 text-size-xs-14 text-size-sm-16 text-weight-bold text-color-dark-slate uppercase text-align-center margin-bottom-20">
                <FormattedMessage
                  id="search.important"
                  defaultMessage="IMPORTANT!"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-xs-10 col-xs-offset-1 text-size-14 text-weight-book text-color-dark-slate text-align-left ">
                <MinimumStartTimeBufferNotice hours={searchSuggestions.minimumStartTimeBuffer} />
                <div className="margin-bottom-10">
                  <FormattedMessage
                    id="search.availability-limitation-notice"
                    defaultMessage="Availability may be limited if you are trying to book parking:"
                  />
                </div>
                <ul className="padding-left-15">
                  <MinimumStartTimeBuffer hours={searchSuggestions.minimumStartTimeBuffer} />
                  <MinimumDuration hours={searchSuggestions.minimumDuration} />
                </ul>
              </div>
            </div>
          </div>
          <div className="row margin-bottom-20">
            <div className="col-xs-4 col-xs-offset-4" >
              <VisibilitySensor onChange={this.handleConfirmVisibilityChange}>
                <button id="search-restrictions-modal-got-it" className="btn btn-primary btn-blue" onClick={this.onDismiss}>
                  <FormattedMessage
                    id="common.got-it"
                    defaultMessage="GOT IT"
                    className="text-size-14"
                  />
                </button>
              </VisibilitySensor>
            </div>
          </div>
        </div>
      </VisibilitySensor>
    );
  }
}

SearchRestrictions.propTypes = propTypes;
SearchRestrictions.defaultProps = defaultProps;
export default SearchRestrictions;

