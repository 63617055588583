import { Map } from 'immutable';

/**
 * @typedef   {*} RequiredField
 *
 * Validation state of some required field for some form.
 * Examples are credit card info for checkout, search fields, etc.
 * See lib/required-fields.js and components/common/required-fields.jsx
 *
 * See https://github.com/braintree/card-validator for more info on isValid, isPotentiallyValid
 *
 * @property  {boolean} displayName
 *            field name
 * @property  {boolean} [isEmpty]
 *            if the field is empty. we don't always care about this.
 * @property  {boolean} [isPotentiallyValid]
 *            When false the field is definitely invalid. Credit card fields must always have this property.
 *
 * @property  {boolean} isValid
 *            When true the field is definitely valid.
 */

/**
 * Logic used mainly in sagas to generate a map of the
 * required form fields for an operation (such as user checkout or search)
 * which have errors.
 *
 * @param  {object} args
 *         arguments object
 * @param  {Set.<String>} args.requiredFields
 *         list of required field names
 * @param  {Map.<RequiredField>} args.fields
 *         all fields
 * @return {Map<any, any>}
 *         Immutable.Map of required fields with errors
 */
export const generateRequiredFieldErrors = ({ requiredFields, fields }) => {
  let requiredFieldErrors = Map();
  requiredFields.forEach((field) => {
    if (fields.get(field) && !fields.get(field).isValid && !fields.get(field).isPotentiallyValid) {
      requiredFieldErrors = requiredFieldErrors.set(field, fields.get(field));
    }
  });
  return requiredFieldErrors;
};
