import { Record, fromJS } from 'immutable';
import camelCase from 'lodash/camelCase';

export default class BookingCounts extends Record({
  upcoming: fromJS({
    all: fromJS({
      bookings: 0,
      monthlyBookings: 0,
    }),
    businessPurchase: fromJS({
      bookings: 0,
      monthlyBookings: 0,
    }),
  }),
  past: fromJS({
    all: fromJS({
      bookings: 0,
      monthlyBookings: 0,
    }),
    businessPurchase: fromJS({
      bookings: 0,
      monthlyBookings: 0,
    }),
  }),
  cancelled: fromJS({
    all: fromJS({
      bookings: 0,
      monthlyBookings: 0,
    }),
    businessPurchase: fromJS({
      bookings: 0,
      monthlyBookings: 0,
    }),
  }),
}) {
  constructor(props) {
    if (!props) {
      super();
      return;
    }

    const upcoming = fromJS(props.upcoming);
    const past = fromJS(props.past);
    const cancelled = fromJS(props.cancelled);

    super({
      upcoming,
      past,
      cancelled,
    });
  }
  setCount(type, query, count) {
    const period = query[0];
    const payment = query[1] || 'all';
    return this.setIn([period, payment, camelCase(type)], count);
  }

  pastCount(filter = null) {
    if (!filter) {
      return this._getCount('past', 'all');
    }
    return this._getCount('past', filter);
  }

  cancelledCount(filter = null) {
    if (!filter) {
      return this._getCount('cancelled', 'all');
    }
    return this._getCount('cancelled', filter);
  }

  upcomingCount(filter = null) {
    if (!filter) {
      return this._getCount('upcoming', 'all');
    }
    return this._getCount('upcoming', filter);
  }

  _getCount(period, filter) {
    return this.getIn([period, filter, 'bookings'], 0) + this.getIn([period, filter, 'monthlyBookings'], 0);
  }
}
