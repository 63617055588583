import { Record } from 'immutable';
import converter from 'number-to-words';
import moment from 'moment-timezone';

export const EVENT_PRICING = 'EventPricing';

export default class Pricing extends Record({
  id: null,
  startTime: null,
  endTime: null,
  availability: null,
  pricingType: null, // TODO is this a thing returned?
  event_departure: null, // TODO is this a thing returned?
  event_name: null, // TODO is this a thing returned?
  locationId: null,
  requireLicensePlate: null,
  pricingCode: null,
  eventId: null,
  type: null,
  earlyBird: null,
  eventArrivalBuffer: null,
  eventDepartureBuffer: null,
  instructions: null,
  disclaimers: null,
  arriveBy: null,
  departAfter: null,
  minParkingHours: null,
  mobileEnabled: true,
}) {
  constructor(props) {
    if (!props) {
      super();
      return;
    }

    const arriveBy = props.arrive_by && moment(props.arrive_by, 'hh:mm:ss');
    const departAfter = props.depart_after && moment(props.depart_after, 'hh:mm:ss');

    let eventName;
    let eventDepartureBuffer;
    if (props.event) {
      eventName = props.event.name;
      eventDepartureBuffer = props.event.additional_time;
    } else {
      eventName = props.event_name;
      eventDepartureBuffer = props.event_departure;
    }

    super({
      id: props.id,
      startTime: props.start_time, // TODO this is incorrect and ambiguous
      endTime: props.end_time, // TODO this is incorrect and ambiguous
      availability: props.space_availability ? props.space_availability.status : null,
      pricingType: props.pricing_type,
      event_departure: props.event_departure,
      event_name: eventName,
      locationId: props.location_id,
      requireLicensePlate: props.require_license_plate,
      pricingCode: props.pricing_code,
      eventId: props.event_id,
      type: props.type,
      earlyBird: props.early_bird,
      eventArrivalBuffer: parseFloat(props.event_arrival_buffer),
      eventDepartureBuffer: parseFloat(eventDepartureBuffer),
      instructions: props.instructions,
      disclaimers: props.disclaimers,
      arriveBy,
      departAfter,
      minParkingHours: props.min_parking_hours,
      mobileEnabled: props.mobile_enabled === false ? false : true,
    });
  }

  get instructionsRequired() {
    const { arriveBy, departAfter, minParkingHours } = this;
    return arriveBy || departAfter || minParkingHours ? true : false;
  }

  get eventDeparture() {
    // Want to still return 'zero' for zero ints
    const buffer = parseFloat(this.eventDepartureBuffer);
    return buffer != null && !isNaN(buffer) ? converter.toWords(this.eventDepartureBuffer) : null;
  }
}
