import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Brand from 'models/brand';

const propTypes = {
  onHide: PropTypes.func.isRequired,
  footerButtonText: PropTypes.string,
  brand: PropTypes.instanceOf(Brand).isRequired,
};

const defaultProps = {
  footerButtonText: 'Close',
};

const ButtonFooter = ({ onHide, footerButtonText, brand }) => {
  const buttonClasses = cx({
    'btn btn-lg btn-primary text-size-14': true,
    'btn-bp': brand.isBestParking,
  });
  return (
    <a className={buttonClasses} onClick={onHide}>
      {footerButtonText}
    </a>
  );
};

ButtonFooter.propTypes = propTypes;
ButtonFooter.defaultProps = defaultProps;
export default ButtonFooter;
