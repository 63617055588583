export const GOT_QUOTES = 'got-quotes';

const gotQuotes = ({
  locations,
  selectedLocation,
  selectedQuote,
  currentSearch,
}) => ({
  type: GOT_QUOTES,
  payload: {
    locations,
    selectedLocation,
    selectedQuote,
    currentSearch,
  },
});

export default gotQuotes;
