import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { injectIntl, intlShape } from 'react-intl';
import VisibilitySensor from 'react-visibility-sensor';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';

import { VALID_COUNTRY_CODES, countryByLocale } from 'lib/locale';
import { LOCALE_SELECTION_IMPRESSION, LOCALE_SELECTION_CLICK } from 'lib/analytics/events';
import { isVisibleOnMobile } from 'lib/analytics/helpers';

import Brand from 'models/brand';

import LocaleLink from 'components/common/locale-link';

import trackEventCreator from 'action-creators/analytics/track-event';

const propTypes = {
  intl: intlShape.isRequired,
  routerLocation: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  appContext: PropTypes.string.isRequired,
  mobileMenu: PropTypes.bool,
  showMobileMenu: PropTypes.bool,
  trackEvent: PropTypes.func.isRequired,
  brand: PropTypes.instanceOf(Brand).isRequired,
};

const defaultProps = {
  mobileMenu: false,
  showMobileMenu: false,
};

export class LocaleSelection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };

    this.toggle = this.toggle.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onVisibilityChange = this.onVisibilityChange.bind(this);
    this.trackEvent = this.trackEvent.bind(this);
  }

  get currentCountry() {
    return countryByLocale(get(this.props.intl, 'locale', 'en-us'));
  }

  get countries() {
    const { open } = this.state;
    const { currentCountry } = this;
    const sortedCountries = sortBy(VALID_COUNTRY_CODES, c => c !== currentCountry);
    if (open) {
      return sortedCountries;
    }

    return [sortedCountries[0]];
  }

  trackEvent(event) {
    this.props.trackEvent({
      ...event,
      properties: {
        locale: this.currentCountry,
      },
    });
  }

  toggle() {
    this.setState({ open: !this.state.open });
  }

  onVisibilityChange(visible) {
    const { mobileMenu, showMobileMenu, appContext } = this.props;
    if (visible && isVisibleOnMobile({ mobileMenu, showMobileMenu, appContext })) {
      this.trackEvent(LOCALE_SELECTION_IMPRESSION);
    }
  }

  onClick() {
    this.trackEvent(LOCALE_SELECTION_CLICK);
  }

  render() {
    const { countries, currentCountry } = this;
    const { routerLocation, trackEvent, appContext, brand } = this.props;

    if (brand.isBestParking) { return null; }

    const localeLinks = countries.map((c, i) => (<LocaleLink
      country={c}
      selected={c === currentCountry}
      routerLocation={routerLocation}
      appContext={appContext}
      toggle={this.toggle}
      trackEvent={trackEvent}
      key={`locale-link-${c}`}
      analyticsPosition={i}
    />));

    return (
      <VisibilitySensor onChange={v => this.onVisibilityChange(v)} partial>
        <div
          className="locale-selection margin-bottom-10 border-side-all border-color-white border-style-solid border-width-2 clickable"
          onClick={this.onClick}
        >
          { localeLinks }
        </div>
      </VisibilitySensor>
    );
  }
}

const mapStateToProps = (state) => {
  const { appContext } = state.app;
  const { brand } = state.brand;
  const { location: routerLocation } = state.router;
  return {
    routerLocation,
    appContext,
    brand,
  };
};
const mapDispatchToProps = dispatch => (
  bindActionCreators({
    trackEvent: trackEventCreator,
  }, dispatch)
);

LocaleSelection.propTypes = propTypes;
LocaleSelection.defaultProps = defaultProps;
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(LocaleSelection));
