import Booking from 'models/booking';
import { Location } from 'models/locations';


export const GOT_FREQUENT_LOCATIONS = 'got-frequent-locations';

export default function gotFrequentLocations({ results }) {
  const locations = [];
  const bookings = {};

  for (let i = 0; i < results.length; i++) {
    const location = results[i];
    const booking = location._embedded['pw:most_recent_booking'];

    locations.push(new Location(location));
    bookings[results[i].id] = new Booking(booking);
  }

  return {
    type: GOT_FREQUENT_LOCATIONS,
    payload: {
      bookings,
      locations,
    },
  };
}
