export const CHANGE_PAYMENT_METHOD = 'change-payment-method';
const changePaymentMethod = ({ selectedPaymentMethod, isPaypal, newPayment }) => ({
  type: CHANGE_PAYMENT_METHOD,
  payload: {
    selectedPaymentMethod,
    isPaypal: !!isPaypal,
    newPayment: !!newPayment,
  },
});
export default changePaymentMethod;
