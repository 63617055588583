import { Message } from 'models/messages';

export const ADD_MESSAGE = 'add-message';

export default function addMessage(m) {
  const message = new Message(m);

  return {
    type: ADD_MESSAGE,
    payload: {
      message,
    },
  };
}
