// External
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import ShowAll from 'components/bookings/show-all';
import { filterByName, sortByTimeOrName } from 'models/event';
import Event from 'components/venue/events/event';
import Search from 'components/svgs/icons/search';
import Scroll from 'react-scroll';

class Events extends Component {
  constructor(props) {
    super(props);
    this.showAll = this.showAll.bind(this);
    let numFilteredEvents = null;
    if (this.props.events) {
      numFilteredEvents = this.props.events.size;
    }
    this.state = {
      expanded: false,
      eventFilterText: this.props.filterEventName || '',
      sortBy: 'date',
      numFilteredEvents,
    };

    this.showModal = this.showModal.bind(this);
  }

  componentDidMount() {
    this.setState({ mounted: true });
    this.showModal = this.showModal.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleNameClick = this.handleNameClick.bind(this);
    this.handleDateClick = this.handleDateClick.bind(this);
    this.filterAndSortEvents = this.filterAndSortEvents.bind(this);
  }

  overEventLimit() {
    return this.props.events.size > this.props.initialCount;
  }

  showAll() {
    if (this.state.expanded) {
      const venueEventsWrapperOffset = document.getElementsByClassName('venue-events-wrapper')[0].offsetTop;
      Scroll.animateScroll.scrollTo(venueEventsWrapperOffset, {
        duration: 0,
      });
    }
    this.setState({
      expanded: !this.state.expanded,
    });
  }

  renderEventCount() {
    if (!this.overEventLimit()) { return null; }
    return (
      <div className="event-count"><strong>{this.state.numFilteredEvents}</strong> events found</div>
    );
  }

  renderBrowseAll() {
    if (this.props.events.size <= this.props.mobileCount) { return null; }

    return (
      <div className="visible-lte-sm event-browse stroke-white" onClick={this.showModal} >
        BROWSE ALL EVENTS
        <Search />
      </div>
    );
  }

  renderModalContent() {
    const { sortBy } = this.state;
    const { events, venue } = this.props;
    const eventListClasses = cx({
      'venue-events': true,
    });

    return (
      <div className="venue-events-wrapper">
        <div className="container">
          <div className="event-modal-title">{`${this.props.venue.name} Parking`}</div>
          <div className="event-modal-subtitle">Your space is waiting.</div>
          <div className="event-filter">
            {this.renderEventFilters()}
          </div>
          {this.renderEventCount()}
          <div className={eventListClasses}>
            {this.renderEvents('modal')}
          </div>
        </div>
      </div>
    );
  }

  renderEventFilters() {
    if (!(this.overEventLimit() || this.props.modal)) { return null; }

    const { sortBy } = this.state;
    const btnPillClasses = cx({
      'btn-pill': true,
      'clickable': true,
      'noselect': true,
      'active': sortBy === 'date',
    });
    const namePillClasses = cx({
      'btn-pill-choice': true,
      'selected': sortBy === 'name',
    });
    const datePillClasses = cx({
      'btn-pill-choice': true,
      'selected': sortBy === 'date',
    });

    return (
      <div className="row">
        <div className="col-md-5">
          <input type="text" value={this.state.eventFilterText} onChange={this.handleFilterChange} className="event-filter-box" placeholder="Search Event Name or Date" />
        </div>
        <div className="col-md-7 padding-top-lte-sm text-center-xs text-left-md">
          Sort by&nbsp;&nbsp;
          <div className={btnPillClasses}>
            <div className={namePillClasses} onClick={this.handleNameClick}>
              Name
            </div>
            <div className={datePillClasses} onClick={this.handleDateClick}>
              Date
            </div>
          </div>
        </div>
      </div>
    );
  }

  handleNameClick() {
    this.setState({ sortBy: 'name' });
  }

  handleDateClick() {
    this.setState({ sortBy: 'date' });
  }

  showModal(e) {
    e.preventDefault();
    e.stopPropagation();

    const { venue, events } = this.props;

    this.props.changeModalState({
      displayModal: true,
      body: 'eventList',
      footer: 'generalFooter',
      bodyProps: {
        events,
        venue,
        currentSearch: this.props.currentSearch,
        modal: true,
        onClick: this.props.onClick,
      },
    });
  }

  filterAndSortEvents(events) {
    const filteredEvents = filterByName(events, this.state.eventFilterText);
    return sortByTimeOrName(filteredEvents, this.state.sortBy);
  }

  handleFilterChange(keyboardEvent) {
    const eventFilterText = keyboardEvent.target.value;
    this.setState({ eventFilterText });

    const filteredEvents = filterByName(this.props.events, eventFilterText);
    this.setState({ numFilteredEvents: filteredEvents.size });
  }

  renderEvents(source) {
    let { events, venue } = this.props;
    const { expanded } = this.state;
    events = this.filterAndSortEvents(events);
    if (!events.size) { return null; }
    const eventNodes = [];

    for (let i = 0; i < events.size; i += 2) {
      const eventSlice = events.slice(i, i + 2);
      const eventClasses = cx({
        'row': true,
        'hidden': !expanded && source !== 'modal' && i > this.props.initialCount - 1,
        'hidden-lte-sm': i > this.props.mobileCount - 1,
      });

      eventNodes.push(
        <div key={`event-row-${i}`} className={eventClasses}>
          { eventSlice.map(event => <Event event={event} timezone={venue.timezone} venue={venue} currentSearch={this.props.currentSearch} key={`venue-event-${event.id}`} onClick={this.props.onClick} />).toArray()}
        </div>,
      );
    }

    return eventNodes;
  }

  render() {
    const { events, venue } = this.props;
    if (!events || !events.size) { return null; }
    if (this.props.modal) { return this.renderModalContent(); }

    const eventListClasses = cx({
      'venue-events': true,
      'row': true,
      'with-packages': venue.packagesAvailable,
    });

    return (
      <div className="venue-events-wrapper">
        <div className="container">
          <h2 className="h2 strong row-centered padding-2x">Book now and spend more time on the fun.</h2>
          <div className="event-filter hidden-lte-sm">
            {this.renderEventFilters()}
          </div>
          <div className="hidden-lte-sm">{this.renderEventCount()}</div>
          <div className={eventListClasses}>
            <div className="col-xs-12">
              {this.renderEvents()}
            </div>
            <div className="hidden-lte-sm col-xs-12">
              <ShowAll
                text="Events"
                onClick={this.showAll}
                expanded={this.state.expanded}
                count={this.state.numFilteredEvents}
                limit={this.props.initialCount}
                className="background-off-white-hoverable vertical-padding"
              />
            </div>
          </div>
        </div>
        {this.renderBrowseAll()}
      </div>
    );
  }
}

export default Events;
