import { Record } from 'immutable';

export default class MonthlyPricing extends Record({
  id: null,
  contactInformation: null,
  instructions: null,
  description: null,
  name: null,
}) {
  constructor(props) {
    super({
      id: props.id,
      contactInformation: props.contact_information,
      instructions: props.instructions,
      description: props.description,
      name: props.name,
    });
  }
}
