import { Record, Map } from 'immutable';
import moment from 'moment-timezone';
import get from 'lodash/get';
import isArray from 'lodash/isArray';

import Availability from 'models/availability';
import Meta from 'models/meta';

export function filterByName(events, filterText) {
  return events.filter(hasName, { filterText });
}

export function sortByTimeOrName(events, sortType) {
  if (sortType === 'name') {
    return events.sort(sortEventsByName);
  }
  return events.sort(sortEventsByTime);
}

export function sortEvents(events) {
  return events.sort(
    (a, b) => sortEventsByTime(a, b),
  );
}

function hasName(event) {
  let matched = true;
  const filterText = this.filterText.toLowerCase().split(' ');
  const dateAndEvent = `${event.name.toLowerCase()}${event.startTime.format('YYYYMMDDMMMM').toLowerCase()}`;
  filterText.forEach((searchTerm) => {
    if (!dateAndEvent.match(searchTerm)) {
      matched = false;
    }
  });
  return matched;
}

function sortEventsByName(eventA, eventB) {
  if (eventA.name.toUpperCase() < eventB.name.toUpperCase()) {
    return -1;
  } else if (eventA.name.toUpperCase() > eventB.name.toUpperCase()) {
    return 1;
  }
  return eventA.id > eventB.id ? 1 : -1;
}

function sortEventsByTime(a, b) {
  if (a.startTime.diff(b.startTime) < 0) {
    return -1;
  } else if (a.startTime.diff(b.startTime) > 0) {
    return 1;
  }
  return a.id > b.id ? 1 : -1;
}

export default class Event extends Record({
  id: null,
  name: null,
  startTime: null,
  endTime: null,
  url: null,
  availability: null,
  venueId: null,
  meta: null,
}) {
  constructor(props, timezone) {
    if (!props) {
      super();
      return;
    }

    const availability = props.availability && Object.prototype.hasOwnProperty.call(props.availability, 'available') ? new Availability(props.availability) : null;

    let startTime = moment(props.start_time || props.start || props.startTime);
    let endTime = moment(props.end_time || props.end || props.endTime);

    if (timezone) {
      startTime = startTime.tz(timezone);
      endTime = endTime.tz(timezone);
    }

    const meta = Meta.getSeoMeta(props);

    super({
      id: props.id,
      name: props.name,
      startTime,
      endTime,
      url: props.site_url || props.url || props.parkwhiz_url,
      availability,
      venueId: props.venue_id,
      meta,
    });
  }

  get availabilityDataUnavailable() {
    return this.availability === null;
  }

  get available() {
    return this.availability && this.availability.available > 0;
  }

  get approximateLowPrice() {
    return this.availability && this.availability.approximateLowPrice ? this.availability.approximateLowPrice : 0;
  }

  get approximateHighPrice() {
    return this.availability && this.availability.approximateHighPrice ? this.availability.approximateHighPrice : 0;
  }

  get isExpired() {
    const eventExpirationTime = this.endTime.clone().add(4, 'hours');

    return moment().isAfter(eventExpirationTime);
  }

  get isValid() {
    return !!this.id;
  }

  get monthlyAvailable() {
    return false;
  }

  // Default right now is a 1 hour buffer to start and end time
  static getDefaultTimes(startTime, endTime, timezone) {
    return {
      startTime: moment(startTime).subtract(1, 'hours').tz(timezone),
      endTime: moment(endTime).add(1, 'hours').tz(timezone),
    };
  }

  static formatPrice(price) {
    let formatted = String(price);
    const cents = formatted.split('.')[1];

    if (cents && cents.length < 2) {
      formatted = `${price}0`;
    }
    formatted = `$${price}`;

    return formatted;
  }

  static tryConstruct(props, timezone) {
    try {
      const event = new Event(props, timezone);
      return { event: event.isValid ? event : null, error: null };
    } catch (err) {
      return { event: null, error: err };
    }
  }
}

export function getEventsFromEmbedded({ body }) {
  const events = {};

  if (isArray(body)) {
    body.forEach((element) => {
      const event = get(element, ['_embedded', 'pw:event'], null);
      if (event) {
        const newEvent = new Event(event);

        if (newEvent.id) {
          events[newEvent.id] = newEvent;
        }
      }
    });
  }

  return sortEvents(new Map(events));
}

export function Events(response, timezone) {
  const events = {};
  if (response && response.filter) {
    const filtered = response.filter(r => r);
    if (filtered.length) {
      filtered.forEach((event) => {
        const newEvent = new Event(event, timezone);
        // Events can be returned with an undefined id...not sure how but it's happening
        if (newEvent.id) {
          events[newEvent.id] = newEvent;
        }
      });
    }
  }

  return sortEvents(new Map(events));
}
