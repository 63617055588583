import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import kebabCase from 'lodash/kebabCase';

import { Amenity as AmenityModel } from 'models/amenities';

const Amenity = ({ amenity, className }) => {
  let messageId = `common.amenity-${kebabCase(amenity.key)}`;
  if (!amenity.enabled) { messageId = `${messageId}-disabled`; }

  return (
    <div className={`text-size-12 text-weight-book text-color-dark-slate text-center ${className}`} key={amenity.key}>
      <div className={`${amenity.icon()}`} />
      <div>
        <FormattedMessage id={messageId} defaultMessage={amenity.message()} />
      </div>
    </div>
  );
};

Amenity.propTypes = {
  amenity: PropTypes.instanceOf(AmenityModel).isRequired,
  className: PropTypes.string,
};

Amenity.defaultProps = {
  className: '',
};

export default Amenity;
