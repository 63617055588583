import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import AppLinkComponent, { propTypes, defaultProps } from 'components/common/app-link';

import trackEventCreator from 'action-creators/analytics/track-event';

const AppLink = props => <AppLinkComponent {...props} />;

const mapStateToProps = (state, ownProps) => {
  const { brand } = state.brand;
  const { appContext } = state.app;
  const { trackingProperties } = state.analytics;

  return {
    brand,
    appContext,
    trackingProperties,
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    trackEvent: trackEventCreator,
  }, dispatch)
);

AppLink.propTypes = propTypes;
AppLink.defaultProps = defaultProps;
export default connect(mapStateToProps, mapDispatchToProps)(AppLink);
