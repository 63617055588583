import { Record, List, Map } from 'immutable';
import get from 'lodash/get';
import isArray from 'lodash/isArray';

import Image from 'models/image';
import Availability from 'models/availability';
import SearchSuggestions from 'models/search-suggestions';

import { imageCdnAsset } from 'lib/common/url-helpers';
import * as RoutingStyle from 'lib/routing-style';

import Meta from 'models/meta';
export default class Venue extends Record({
  id: null,
  name: null,
  city: null,
  state: null,
  country: null,
  url: null,
  address: null,
  postalCode: null,
  description: null,
  msa: null,
  primarilyTransient: null,
  lat: null,
  lng: null,
  timezone: null,
  packageAvailability: null,
  monthlyAvailability: null,
  transientAvailability: null,
  meta: null,
  type: null,
  images: null,
  enhancedAirport: false,
  searchSuggestions: new SearchSuggestions(),
  slug: null,
}) {
  static get eventSlugRegEx() { return /.*-\d+\/?$/; }

  // Don't call this directly unless you're really sure of your input and are prepared to handle exceptions.
  // Use static tryConstruct method
  constructor(props) {
    if (!props) {
      super();
      return;
    }

    const meta = Meta.getSeoMeta(props);

    let images = List();

    if (props.images) {
      for (let i = 0; i < props.images.length; i++) {
        images = images.insert(props.images[i].position, new Image(props.images[i].sizes));
      }
    }

    const availability = props.availability ? props.availability : {};
    const packageAvailability = availability.package ? new Availability(availability.package) : null;
    const monthlyAvailability = availability.monthly ? new Availability(availability.monthly) : null;
    const transientAvailability = availability.transient ? new Availability(availability.transient) : null;

    let url = props.site_url || props.url || props.parkwhiz_url;
    if (!url && props.slug) {
      url = props.slug;
      if (props.daily) {
        url = `${url}?daily=1`;
      }
    }


    super({
      id: props.id,
      name: props.name,
      address: props.address1 || props.address,
      city: props.city,
      state: props.state,
      country: props.country,
      postalCode: props.postal_code || props.postalCode,
      url,
      description: props.description,
      msa: props.msa,
      primarilyTransient: props.primarily_transient,
      lat: props.coordinates ? props.coordinates[0] : null,
      lng: props.coordinates ? props.coordinates[1] : null,
      timezone: props.timezone,
      packageAvailability,
      monthlyAvailability,
      transientAvailability,
      meta,
      type: props.venue_type,
      images,
      enhancedAirport: props.enhanced_airport || props.enhancedAirport,
      searchSuggestions: new SearchSuggestions(get(props, '_embedded.pw:search_suggestions', props.searchSuggestions)),
      slug: url,
    });
  }

  get currency() {
    switch (this.country) {
      case 'CA':
        return 'CAD';
      case 'US':
      default:
        return 'USD';
    }
  }

  /*
   * We need a separate data unavailable functions, because otherwise
   * if we combine the functions, we won't know if it's actually unavailable
   * or if the cache data isn't there and we have to populate it. Only applies
   * to monthly and packages
   */
  get monthlyDataUnavailable() {
    return this.monthlyAvailability === null;
  }

  get monthlyAvailable() {
    return !!(this.monthlyAvailability && this.monthlyAvailability.available > 0);
  }

  get packageDataUnavailable() {
    return this.packageAvailability === null;
  }

  get packagesAvailable() {
    return !!(this.packageAvailability && this.packageAvailability.available > 0);
  }

  get transientAvailable() {
    return !!(this.transientAvailability && this.transientAvailability.available > 0);
  }

  get completeAddress() {
    const { address, city, state, postalCode } = this;
    return `${address}, ${city}, ${state}, ${postalCode}`;
  }

  get displayVenueImage() {
    let imageLocation = '';

    if (this.images && this.images.size > 0) {
      imageLocation = this.images.getIn(['0', 'imageSizes', 'venueGallery', 'url']);
    } else {
      imageLocation = imageCdnAsset('/images/venue-image-default.jpg');
    }
    return imageLocation;
  }

  get isAirportVenue() {
    return this.type === 'Airport';
  }

  get isSportsVenue() {
    return this.type === 'Sports';
  }

  get isValid() {
    return !!this.id;
  }

  static validURL(url, { routingStyle = RoutingStyle.PARKWHIZ } = {}) {
    let params = url.split('/');
    params = params.filter(value => value !== '');

    if (routingStyle === RoutingStyle.BESTPARKING) {
      return this.validBestParkingURL(params);
    }

    return this.validParkWhizURL(params);
  }

  /*
   * Venue URL Rules
   * 1) Any url with only one resource will be a venue URL
   * 2) They will always have "-parking" in the URL
   * 3) It should accept any query argument except for daily and monthly
   * 4) If there are any additional resources (e.g. packages) it won't be a venue url
   */
  static validParkWhizURL(params) {
    if (params.length === 0) { return false; }
    if (params.length === 1) { return true; }

    if (params[0].includes('-parking')) {
      if (params[1].includes('?')) {
        let valid = true;
        ['daily', 'monthly'].forEach((arg) => {
          if (params[1].includes(arg)) { valid = false; }
        });
        return valid;
      } else if (params[1].match('packages')) {
        return false;
      } else if (!params[1].match(this.eventSlugRegEx)) {
        return true;
      }
    }

    return false;
  }

  static validBestParkingURL(splitPath) {
    if (
      splitPath[splitPath.length - 1].includes('daily') ||
      splitPath[splitPath.length - 1].includes('monthly')
    ) {
      return false;
    }

    // URL params like "?event_name=___" can come in as their own
    // element. Ignore those.
    const pieces = splitPath.filter(section => section[0] !== '?');

    if (pieces.length === 3 && pieces[1] === 'destinations') {
      return true;
    }

    return false;
  }

  static tryConstruct(props) {
    try {
      const venue = new Venue(props);
      return { venue: venue.isValid ? venue : null, error: null };
    } catch (err) {
      return { venue: null, error: err };
    }
  }
}

export function getVenuesFromEmbedded({ body }) {
  const venues = {};

  if (isArray(body)) {
    body.forEach((element) => {
      const venue = get(element, ['_embedded', 'pw:venue'], null);
      if (venue) {
        const newVenue = new Venue(venue);

        if (newVenue.id) {
          venues[newVenue.id] = newVenue;
        }
      }
    });
  }

  return new Map(venues);
}

export function Venues(response) {
  const venues = {};
  if (response && response.filter) {
    const resp = response.filter(r => r);
    if (resp.length) {
      response.forEach((venue) => {
        const newVenue = new Venue(venue);
        if (newVenue.id) {
          venues[newVenue.id] = newVenue;
        }
      });
    }
  }

  return new Map(venues);
}
