export const SELECT_VEHICLE = 'select-vehicle';

export default function selectVehicle({ vehicleId }) {
  return {
    type: SELECT_VEHICLE,
    payload: {
      selectedVehicleId: vehicleId,
    },
  };
}
