import React from 'react';
import PropTypes from 'prop-types';

import RatingsCar from 'components/svgs/icons/ratings-car';

const propTypes = {
  rating: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  visible: PropTypes.bool,
};

const defaultProps = {
  rating: null,
  visible: true,
};

const LocationRating = ({ rating, visible }) => {
  if (!rating || !visible) { return null; }

  return (
    <div className="rate-this-location text-size-16 text-color-dark-slate">
      <span className="small-svg"><RatingsCar /></span>
      <span className="text-weight-bold margin-left-10">RATED: <span className="text-color-green text-size-18">{rating}</span> <span className="text-size-12 text-weight-book">out of 5</span></span>
    </div>
  );
};

LocationRating.propTypes = propTypes;
LocationRating.defaultProps = defaultProps;
export default LocationRating;
