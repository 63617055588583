import React, { Component } from 'react';
import PropTypes from 'prop-types';
import VisibilitySensor from 'react-visibility-sensor';
import cx from 'classnames';
import get from 'lodash/get';
import lowerCase from 'lodash/lowerCase';

import { countryName, localeRedirectURL } from 'lib/locale';
import { imageCdnAsset, parseDomain } from 'lib/common/url-helpers';
import { OPTION_IMPRESSION, OPTION_CLICK } from 'lib/analytics/events';
import { isVisibleOnMobile } from 'lib/analytics/helpers';

const propTypes = {
  country: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  routerLocation: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  toggle: PropTypes.func.isRequired,
  trackEvent: PropTypes.func.isRequired,
  appContext: PropTypes.string.isRequired,
  mobileMenu: PropTypes.bool,
  showMobileMenu: PropTypes.bool,
  analyticsPosition: PropTypes.number.isRequired,
};

const defaultProps = {
  selected: false,
  mobileMenu: false,
  showMobileMenu: false,
};

class LocaleLink extends Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
    this.onVisibilityChange = this.onVisibilityChange.bind(this);
    this.trackEvent = this.trackEvent.bind(this);
  }

  onClick() {
    const { toggle, selected } = this.props;
    if (selected) { toggle(); }

    this.trackEvent(OPTION_CLICK);
  }

  get text() {
    return countryName(this.props.country);
  }

  get imageURL() {
    const { country } = this.props;
    return imageCdnAsset(`/images/flags/flag-${lowerCase(country)}.png`);
  }

  get redirectURL() {
    const { country, routerLocation } = this.props;
    const { tld, domain, subdomain } = parseDomain(get(window, 'location.host', 'www.parkwhiz.com'));
    const path = `${get(routerLocation, 'pathname', '')}${get(routerLocation, 'search', '')}`;
    return localeRedirectURL({ tld, domain, subdomain, path }, country);
  }

  trackEvent(event) {
    this.props.trackEvent({
      ...event,
      name: this.props.country,
      position: `${this.props.analyticsPosition}`,
    });
  }

  onVisibilityChange(visible) {
    const { mobileMenu, showMobileMenu, appContext } = this.props;
    if (visible && isVisibleOnMobile({ mobileMenu, showMobileMenu, appContext })) {
      this.trackEvent(OPTION_IMPRESSION);
    }
  }

  render() {
    const { selected } = this.props;
    const linkClasses = cx({
      'display-block': true,
      'padding-10': true,
      'text-size-12': true,
      'text-weight-bold': true,
      'noselect': true,
      'text-color-white': true,
      'background-color-hover-transparent-white': true,
      'text-color-hover-white': true,
    });

    const flag = (
      <img
        src={this.imageURL}
        alt={`Flag of ${this.text}`}
        className="flag-icon display-inline-block margin-right-10"
      />
    );

    if (selected) {
      return (
        <VisibilitySensor onChange={v => this.onVisibilityChange(v)} partial>
          <div onClick={this.onClick} className={linkClasses}>
            {flag}
            {this.text}
            <span className="iconified-font iconified-point-down float-right text-size-6 margin-top-5" />
          </div>
        </VisibilitySensor>
      );
    }

    return (
      <VisibilitySensor onChange={v => this.onVisibilityChange(v)} partial>
        <a href={this.redirectURL} onClick={this.onClick} className={linkClasses}>
          <div>
            {flag}
            {this.text}
          </div>
        </a>
      </VisibilitySensor>
    );
  }
}

LocaleLink.propTypes = propTypes;
LocaleLink.defaultProps = defaultProps;
export default LocaleLink;
