import cookie from 'react-cookie';
import { Message, Messages, sortMessages } from 'models/messages';

/**
 * Source of message truth.  For now we're using the cookies, if that changes,
 * edit these functions, OK?????
*/

/**
 * Fetch all stored notification messages
 * @return {object} messages - JSON formatted messages
*/
function getRawMessagesFromStore() {
  const rawMessages = cookie.select(/^pw-messages-.*/i);
  for (let key in rawMessages) {
    // Ensure that blank/invalid values are deleted & not returned
    if (!rawMessages[key]) {
      cookie.remove(key, { path: '/' });
      delete rawMessages[key];
    }
  }
  return rawMessages;
}

/**
 * Save or overwrite message cookie
 * @param {Message} message - Message class instance
*/
function saveMessageToStore(message) {
  cookie.save(message.id, message.toCookie(), { path: '/' });
}

/**
 * Remove message cookie
 * @param {Message} message - Message class instance
*/
function removeMessageFromStore(message) {
  cookie.remove(message.id, { path: '/' });

  /**
   * Because we don't know what a cookie's domain is when it's set
   * from another repo like widget, we need to cycle through all
   * possible domains to ensure that the message is fully removed.
   */
  if (typeof window !== 'undefined') {
    const host = document.location.host.split('.');
    const maxIndex = host.length - 1;
    let index;
    for (index = 0; index < maxIndex; index++) {
      const domain = host.slice(index).join('.');
      cookie.remove(message.id, { domain, path: '/' });
    }
  }
}

/**
 * Set a message dismiss timeout
 * @param {Message} message - Message class instance
 * @param {messageTimeoutCallback} callback - Callback after message dismiss timeout expires
*/
function setMessageTimeout(message, callback) {
  setTimeout(() => {
    const newMessageList = dismissMessages([message]);
    callback(newMessageList);
  }, message.dismissTimeout);
}

/**
 * Wrapper for Messages sort based on their created_at time
 * @return {Messages} messages - Immutable list of sorted Message instances
*/
function sortMessagesByTime(messages) {
  return sortMessages(messages);
}

/**
 * Up-to-date, sorted list of notification messages
 * @return {Messages} messages - immutable List of Message instances
*/
function currentMessageList() {
  return sortMessagesByTime(Messages(getRawMessagesFromStore()));
}

/**
 * Process message after it has been loaded and displayed in the browser
 * Side effects:
 *   - If resetErrorsOnSuccess is true, and the message being processed is a success, all error messages get cleared
 *   - If dismissTimeoutTypes includes the type of the processed message, a message timeout will be called with callback onStateChange
 *   - viewed_at will be set with the current time
 * @param {Message} message - Message class instance
 * @param {function} onStateChange[Optional] - Callback when message timeout expires
 * @param {array} dismissTimeoutTypes[Optional] - List of message types that should expire after a given timeout
 * @param {boolean} resetErrorsOnSuccess[Optional] - Specifies whether all error messages should be cleared, should message be of type 'success'
 * @return {Message} message - Modified Message class instance
*/
function processMessage(message, { onStateChange = () => { }, dismissTimeoutTypes = ['success'], resetErrorsOnSuccess = true} = {}) {
  if (!(message instanceof Message)) { return; }

  if (onStateChange && dismissTimeoutTypes.includes(message.type)) {
    setMessageTimeout(message, onStateChange);
  }

  if (resetErrorsOnSuccess && message.type === 'success') {
    const messageList = Messages(getRawMessagesFromStore());
    dismissMessages(messageList.filterNot(m => m.type === 'success').toArray());
  }

  // Set the view time
  const modifiedMessage = message.setViewedAt();
  saveMessageToStore(modifiedMessage);
}

/**
 * Process a list of messages after they have been loaded and displayed in the browser
 * Side effects:
 *   - If resetErrorsOnSuccess is true, and the message being processed is a success, all error messages get cleared
 *   - If dismissTimeoutTypes includes the type of the processed message, a message timeout will be called with callback onStateChange
 *   - viewed_at will be set with the current time
 * @param {Messages} messages - Single Message instance or array of Message instances
 * @param {function} onStateChange[Optional] - Callback when message timeout expires
 * @param {array} dismissTimeoutTypes[Optional] - List of message types that should expire after a given timeout
 * @param {boolean} resetErrorsOnSuccess[Optional] - Specifies whether all error messages should be cleared, should message be of type 'success'
 * @return {Messages} messages - immutable List of Message instances, see currentMessageList
*/
export function processMessages(messages, { onStateChange = () => { }, dismissTimeoutTypes = ['success'], resetErrorsOnSuccess = true} = {}) {
  messages = [].concat(messages);
  messages.forEach((message) => processMessage(message, { onStateChange, dismissTimeoutTypes, resetErrorsOnSuccess }));
}

/**
 * Fetches a list of up-to-date notification messages
 * @param {boolean} newPageLoad[Optional] - Defines the context in which this function is called.  If true, all non-persistant, viewed messages
 *                                          will be deleted and not returned. Is destructive.
 * @return {Messages} messages - Up-to-date, sorted Immutable list of Message instances.  See dismissMessages & currentMessageList
*/
export function fetchMessages({ newPageLoad = false } = {}) {
  let messages = Messages(getRawMessagesFromStore());

  if (!newPageLoad) { return messages; }

  const messagesToDelete = messages.filterNot(m => m.persistent || !m.viewedAt);
  return dismissMessages(messagesToDelete.toArray());
}

/**
 * Adds message to browser state (cookies currently)
 * @param {Message} message - Message instance
 * @param {function} onMessageDismiss[Optional] - Callback after message expires
 * @param {boolean} resetErrorsOnSuccess[Optional] - Specifies whether error messages should be removed if the message being added is of type 'success'
 * @return {Messages} messages - Immutable List of sorted Message instances.  See currentMessageList
*/
function addMessage(message) {
  if (!(message instanceof Message)) { return; }
  saveMessageToStore(message);
}

/**
 * Adds messages to browser state (cookies currently)
 * @param {Messages} messages - Single Message instance or array of Message instances
 * @param {function} onMessageDismiss[Optional] - Callback after message expires
 * @param {boolean} resetErrorsOnSuccess[Optional] - Specifies whether error messages should be removed if the message being added is of type 'success'
 * @return {Messages} messages - Immutable List of sorted Message instances.  See currentMessageList
*/
export function addMessages(messages) {
  try {
    messages = [].concat(messages);
    messages.forEach((message) => addMessage(message));
  } catch (e) {
    console.log(e);
  }
  return currentMessageList();
}

/**
  * Removes message from browser state (cookies currently)
  * @param {Message} message - Message instance
  * @return {Messages} messages - Immutable List of sorted Message instances.  See currentMessageList
*/
function dismissMessage(message) {
  if (!(message instanceof Message)) { return; }
  removeMessageFromStore(message);
}

/**
  * Removes messages from browser state (cookies currently)
  * @param {Messages} messages - Single Message instance or array of Message instances
  * @return {Messages} messages - Immutable List of sorted Message instances.  See currentMessageList
*/
export function dismissMessages(messages) {
  messages = [].concat(messages);
  messages.forEach((message) => { dismissMessage(message) })
  return currentMessageList();
}

export function serverWrapper(req, res, boundTarget, ...args) {
  const unplug = cookie.plugToRequest(req, res);
  const response = boundTarget(...args);
  unplug();
  return response;
}
