import Pricing from 'models/pricing';

export const GOT_PRICING = 'got-pricing';

const gotPricing = ({ body }) => (
  {
    type: GOT_PRICING,
    payload: {
      pricing: new Pricing(body),
    },
  }
);

export default gotPricing;
