import React from 'react';


export default class Checkmark extends React.Component {
  constructor(props) {
    super(props);

    let color;
    switch(this.props.color) {
      case "green":
        color = "#10d492";
        break;
      default:
        color = "#2AB7DF";
        break;
    }
    this.state = { color };
  }

  render() {
    return (
      <svg width="14px" height="11px" viewBox="0 0 14 11" version="1.1" className={this.props.className}>
        <defs></defs>
          <g  stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="Location-Detail" transform="translate(-108.000000, -1096.000000)" fill={this.state.color}>
                  <g transform="translate(0.000000, 406.000000)" id="Know-before-you-go">
                      <g transform="translate(29.000000, 614.000000)">
                          <g id="Group-7" transform="translate(73.000000, 72.000000)">
                              <g id="Group-3" transform="translate(6.000000, 1.000000)">
                                  <path d="M8.25793316,11.5967911 L8.25793316,0.460427429 L9.84884225,0.460427429 L9.84884225,12.3922456 L9.84884225,13.1877002 L3.48520589,13.1877002 L3.48520589,11.5967911 L8.25793316,11.5967911 Z" id="Rectangle-220" transform="translate(6.667024, 6.824064) rotate(43.000000) translate(-6.667024, -6.824064) "></path>
                              </g>
                          </g>
                      </g>
                  </g>
              </g>
          </g>
      </svg>
    );
  }
}
