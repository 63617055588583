import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const propTypes = {
  hours: PropTypes.number,
};

const defaultProps = {
  hours: null,
};

const MinimumStartTimeBufferNotice = ({ hours }) => {
  if (!hours) { return null; }

  return (
    <p>
      <FormattedMessage
        id="search.airport-start-time-notice"
        defaultMessage="Some facilities near this airport require that you book a space at least {hours} hours before the reservation start time."
        values={{ hours }}
      />
    </p>
  );
};

MinimumStartTimeBufferNotice.propTypes = propTypes;
MinimumStartTimeBufferNotice.defaultProps = defaultProps;
export default MinimumStartTimeBufferNotice;
