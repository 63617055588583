import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import cx from 'classnames';

import Brand from 'models/brand';
import Search from 'models/search';

import { HOUR_BUFFER_MODAL_CLOSE_IMPRESSION, HOUR_BUFFER_MODAL_CLOSE_CLICK } from 'lib/analytics/events';

import GeneralHeader from 'components/common/modal/general-header';
import GeneralFooter from 'components/common/modal/general-footer';
import ButtonFooter from 'components/common/modal/button-footer';
import MobileDestinationTimePicker from 'containers/common/mobile-destination-time-picker';
import LocationDetail from 'containers/search/list/location-detail';
import About from 'components/venue/about';
import Events from 'components/venue/events';
import GateButton from 'components/parking-passes/validation-section/gate-button-modal';
import ParkingCities from 'components/common/parking-cities';
import SummaryOfServices from 'components/common/summary-of-services';
import FAQ from 'components/common/faq';
import PartnerInterstitialBody from 'components/modal/partner-interstitial-body';
import SearchPickerHeader from 'containers/common/modal/search-picker-header';
import SearchRestrictions from 'components/search/search-restrictions';

import setModalState from 'action-creators/modal/set-modal-state';
import dismissPartnerModalCreator from 'action-creators/brand/dismiss-partner-modal';
import dismissRestrictionsCreator from 'action-creators/search/dismiss-restrictions-modal';
import trackEventCreator from 'action-creators/analytics/track-event';

import * as AppContext from 'lib/app-context';

const MOBILE_TIME_PICKER = 'mobileDestinationTimePicker';
const LOCATION_DETAIL = 'locationDetail';
const ABOUT_VENUE = 'aboutVenue';
const EVENT_LIST = 'eventList';
const GATE_BUTTON = 'gateButton';
const EXEC_BIO = 'execBio';
const PARKING_CITIES = 'parkingCities';
const SUMMARY_OF_SERVICES = 'summaryOfServices';
const FAQ_MODAL = 'faq';
export const BUTTON_FOOTER_TYPE = 'buttonFooter';
export const NO_FOOTER_TYPE = 'noFooter';
export const PARTNER_MODAL = 'partner';
export const SEARCH_RESTRICTIONS_MODAL = 'search-restrictions';


const propTypes = {
  appContext: PropTypes.string.isRequired,
  changeModalState: PropTypes.func.isRequired,
  displayModal: PropTypes.bool.isRequired,

  header: PropTypes.string,
  body: PropTypes.string,
  footer: PropTypes.string,

  currentSearch: PropTypes.instanceOf(Search),

  /* eslint-disable react/forbid-prop-types */
  headerProps: PropTypes.object,
  bodyProps: PropTypes.object,
  footerProps: PropTypes.object,
  /* eslint-enable */
  brand: PropTypes.instanceOf(Brand).isRequired,
  closePartnerModal: PropTypes.func.isRequired,
  closeRestrictionsModal: PropTypes.func.isRequired,
  trackEvent: PropTypes.func.isRequired,
};

const defaultProps = {
  header: '',
  body: '',
  footer: '',

  headerProps: {},
  bodyProps: {},
  footerProps: {},

  currentSearch: null,
};

export class Modal extends Component {
  static parseProps(props) {
    if (props && props.toObject) { return props.toObject(); }
    return props || {};
  }

  constructor(props) {
    super(props);
    if (props.changeModalState) {
      this.onHide = props.changeModalState.bind(null, { displayModal: false, preventModalReset: true });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!this.onHide && nextProps.changeModalState) {
      this.onHide = this.props.changeModalState.bind(null, { displayModal: false, preventModalReset: true });
    }
  }

  shouldComponentUpdate(nextProps) {
    if (!this.props.displayModal && !nextProps.displayModal) { return false; }
    return true;
  }

  getHeaderComponent(type, props) {
    let headerComponent;
    switch (type) {
      case 'searchPickerHeader':
        Object.assign(props, { onHide: this.onHide });
        headerComponent = <SearchPickerHeader {...props} />;
        break;
      case 'emptyHeader':
        break;
      default:
        headerComponent = <GeneralHeader {...props} />;
        break;
    }

    return headerComponent ? <div className="mobile-modal-header">{headerComponent}</div> : null;
  }

  getBodyComponent(type, props) {
    let bodyComponent;
    switch (type) {
      case MOBILE_TIME_PICKER: {
        Object.assign(props, { onHide: this.onHide });
        bodyComponent = <MobileDestinationTimePicker {...props} />;
        break;
      }
      case LOCATION_DETAIL: {
        bodyComponent = <LocationDetail {...props} />;
        break;
      }
      case ABOUT_VENUE: {
        bodyComponent = <About {...props} />;
        break;
      }
      case EVENT_LIST: {
        bodyComponent = <Events {...props} />;
        break;
      }
      case GATE_BUTTON: {
        bodyComponent = <GateButton {...props} />;
        break;
      }
      case PARKING_CITIES: {
        Object.assign(props, { onHide: this.onHide });
        bodyComponent = <ParkingCities {...props} />;
        break;
      }
      case SUMMARY_OF_SERVICES: {
        Object.assign(props, { onHide: this.onHide });
        bodyComponent = <SummaryOfServices {...props} />;
        break;
      }
      case FAQ_MODAL: {
        Object.assign(props, { onHide: this.onHide });
        bodyComponent = <FAQ {...props} />;
        break;
      }
      case PARTNER_MODAL: {
        Object.assign(props, { closeModal: this.onHide });
        bodyComponent = <PartnerInterstitialBody {...props} />;
        break;
      }
      case SEARCH_RESTRICTIONS_MODAL: {
        const { currentSearch, trackEvent } = this.props;
        const onDismiss = () => {
          this.onHide();
          this.props.closeRestrictionsModal({ currentSearch });
        };
        Object.assign(props, { onDismiss, trackEvent });
        bodyComponent = <SearchRestrictions {...props} />;
        break;
      }
      default: {
        bodyComponent = <div />;
        break;
      }
    }

    return bodyComponent ? <div className="mobile-modal-body">{bodyComponent}</div> : null;
  }

  getFooterComponent(type, props = {}) {
    const { footerButtonText } = props;

    let footerComponent;
    switch (type) {
      case NO_FOOTER_TYPE:
        break;
      case BUTTON_FOOTER_TYPE: {
        footerComponent = (
          <ButtonFooter
            brand={this.props.brand}
            footerButtonText={footerButtonText}
            onHide={this.onHide}
          />
        );
        break;
      }
      default: {
        let onHide;
        if (this.props.body === SEARCH_RESTRICTIONS_MODAL) {
          const { currentSearch } = this.props;
          onHide = () => {
            this.onHide();
            this.props.closeRestrictionsModal({ currentSearch });
            this.props.trackEvent(HOUR_BUFFER_MODAL_CLOSE_CLICK);
          };
          Object.assign(props, { impressionEvent: HOUR_BUFFER_MODAL_CLOSE_IMPRESSION });
        } else {
          onHide = this.onHide;
        }

        Object.assign(props, { onHide });
        footerComponent = <GeneralFooter {...props} />;
        break;
      }
    }
    return footerComponent ? <div className="mobile-modal-footer">{footerComponent}</div> : null;
  }

  // TODO: Rename the classes to not be mobile specific
  render() {
    const {
      appContext,
      displayModal,
      header,
      body,
      footer,
      headerProps,
      bodyProps,
      footerProps,
    } = this.props;

    const modalClasses = cx('datepair fade', {
      'desktop-modal': appContext === AppContext.DESKTOP,
      'full-height': body === MOBILE_TIME_PICKER || body === PARTNER_MODAL,
      'blue-modal': body === MOBILE_TIME_PICKER,
      'modal-hidden': !displayModal,
    });

    const headerComponent = this.getHeaderComponent(header, Modal.parseProps(headerProps));
    const bodyComponent = this.getBodyComponent(body, Modal.parseProps(bodyProps));
    const footerComponent = this.getFooterComponent(footer, Modal.parseProps(footerProps));

    return (
      <div id="search-time-form" key="modal-wrapper" className={modalClasses} data-keyboard="false" tabIndex="-1" role="dialog" aria-hidden="true">
        <div className="mobile-modal-dialog">
          { headerComponent }
          { bodyComponent }
          { footerComponent }
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    changeModalState: setModalState,
    closePartnerModal: dismissPartnerModalCreator,
    closeRestrictionsModal: dismissRestrictionsCreator,
    trackEvent: trackEventCreator,
  }, dispatch)
);

const mapStateToProps = (state) => {
  const { appContext } = state.app;
  const {
    displayModal,
    header,
    body,
    footer,
    headerProps,
    bodyProps,
    footerProps,
  } = state.modal;

  const {
    brand,
  } = state.brand;

  const {
    currentSearch,
  } = state.search;

  return {
    appContext,
    displayModal,
    header,
    body,
    footer,
    headerProps,
    bodyProps,
    footerProps,
    brand,
    currentSearch,
  };
};

Modal.propTypes = propTypes;
Modal.defaultProps = defaultProps;
export default connect(mapStateToProps, mapDispatchToProps)(Modal);
