import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Location } from 'models/locations';
import Seller from 'models/seller';
import Search from 'models/search';

import SimpleCarousel from 'components/common/simple-carousel';

const propTypes = {
  app: PropTypes.string,
  location: PropTypes.instanceOf(Location).isRequired,
  seller: PropTypes.instanceOf(Seller),
  maxHeight: PropTypes.number.isRequired,
  currentSearch: PropTypes.instanceOf(Search),
  width: PropTypes.number,
  trackEvent: PropTypes.func,
};

const defaultProps = {
  width: 500,
  seller: null,
  app: '',
  currentSearch: new Search(),
  trackEvent: () => {},
};

const LocationImageGallery = ({
  location,
  seller,
  width,
  maxHeight,
  app,
  trackEvent,
  currentSearch,
}) => {
  const imageUrls = location.galleryImages;

  if (!location.getLargestScaledImageHeight) { return <div className={imageUrls ? 'area to-edges' : null} />; }
  let sellerMessage;
  if (seller) {
    sellerMessage = (<FormattedMessage
      id="common.operated-by"
      defaultMessage="Operated by {seller}"
      values={{ seller: seller.name }}
    />);
  }

  return (
    <div className={imageUrls ? 'area to-edges' : null}>
      <SimpleCarousel
        urls={imageUrls}
        message={sellerMessage}
        slide={0}
        height={location.getLargestScaledImageHeight(width)}
        width={width}
        maxHeight={maxHeight}
        trackEvent={trackEvent}
        app={app}
        currentSearch={currentSearch}
      />
    </div>
  );
};

LocationImageGallery.propTypes = propTypes;
LocationImageGallery.defaultProps = defaultProps;
export default LocationImageGallery;
