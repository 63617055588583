import React from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';

import Amenity from 'components/common/amenity-list/amenity';

const AmenityList = ({ amenities, className, amenityClassName }) => {
  if (!amenities) return null;
  const validAmenities = amenities.filter(a => a.display());
  return (
    <div className={`${className}`}>
      <div className="row">
        { validAmenities.map(a => <Amenity amenity={a} key={`amenity-${a.key}`} className={amenityClassName} />) }
      </div>
    </div>
  );
};

AmenityList.propTypes = {
  amenities: PropTypes.instanceOf(List),
  className: PropTypes.string,
  amenityClassName: PropTypes.string,
};

AmenityList.defaultProps = {
  amenities: List(),
  className: '',
  amenityClassName: '',
};

export default AmenityList;

