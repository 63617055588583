// DYNAMIC APPS
export const CHECKOUT_APP = 'Checkout';
export const HOME_APP = 'Home';
export const HUB_APP = 'Hub';
export const LOCATION_DETAILS_APP = 'Location Details';
export const OAUTH_APP = 'OAuth Page';
export const PARKING_PASS_APP = 'Parking Pass';
export const PASSWORD_RECOVERY_APP = 'Password Recovery';
export const RECEIPT_APP = 'Receipt';
export const SEARCH_APP = 'Search';
export const SIGN_IN_APP = 'Sign In';
export const SIGN_UP_APP = 'Sign Up';
export const VENUE_APP = 'Venue';
export const SHIPPING_ADDRESS_APP = 'SHIPPING_ADDRESS_APP';

// ACCOUNT APPS
export const ACCOUNT_SETTINGS_APP = 'AccountSettings';
export const BOOKINGS_APP = 'Bookings';
export const CANCEL_APP = 'Cancel';
export const PAYMENT_METHODS_APP = 'PaymentMethods';
export const VEHICLES_APP = 'Vehicles';

// STATIC APPS
export const AFFILIATE_TERMS_APP = 'Affiliate Terms';
export const BUSINESS_APP = 'Business Page';
export const ERROR_APP = 'Error';
export const HOW_IT_WORKS_APP = 'How It Works Page';
export const LEGAL_APP = 'Legal';
export const OUR_APPS_APP = 'Our Apps Page';
export const PRESS_APP = 'Press';
