import React from 'react';
import { imageCdnAsset } from 'lib/common/url-helpers';

const GeneralErrorHeader = ({ handleClose }) => (
  <div className="margin-bottom-40">
    <div className="row">
      <div className="col-xs-12 text-align-right">
        <span className="iconified-font iconified-x clickable text-color-blue text-color-hover-blue text-size-20 padding-left-10 padding-bottom-10" onClick={handleClose} />
      </div>
    </div>
    <div className="row">
      <div className="col-xs-8 col-xs-offset-2">
        <img src={imageCdnAsset('/images/checkout/general-error-notice@2x.png')} alt="Error" />
      </div>
    </div>
  </div>
);

export default GeneralErrorHeader;

