export const DELETE_PAYMENT_METHOD = 'delete-payment-method';

export default function deletePaymentMethod(paymentMethodId) {
  return {
    type: DELETE_PAYMENT_METHOD,
    payload: {
      paymentMethodId,
    },
  };
}
