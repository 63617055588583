import { Map, Record } from 'immutable';
import { camelize } from 'humps';

const IMAGE_SIZES = [
  'logo',
  'gallery_thumb',
  'search_thumb',
  'res_ticket',
  'gallery',
  'hub_frontpage',
  'tm_thumb',
  'icon',
  'venue_gallery',
  'original',
];

export class ImageSize extends Record({
  url: null,
  width: null,
  height: null,
}) {
  constructor(props) {
    super({
      url: props.URL,
      width: props.width,
      height: props.height,
    });
  }
}
export default class Image extends Record({
  imageSizes: null,
  position: null,
}) {
  constructor(props) {
    if (!props) {
      super();
      return;
    }
    // Mapping images by size for easy lookup
    let imageMap = Map();
    for (const size in props) {
      if (IMAGE_SIZES.includes(size)) {
        const imageSize = props[size];
        imageMap = imageMap.set(camelize(size), new ImageSize(imageSize));
      }
    }

    super({ imageSizes: imageMap, position: props.position });
  }
}
