import { OrderedMap, Record } from 'immutable';

export class Vehicle extends Record({
  id: null,
  label: null,
  isDefault: null,
  plateNumber: null,
  plateState: null,
}) {
  constructor(props) {
    const { id, label, plate_number: plateNumber, plate_state: plateState, default: isDefault } = props;

    super({
      id,
      label,
      isDefault,
      plateNumber,
      plateState,
    });
  }

  get editURL() {
    return `/account/vehicles/edit_vehicle/${this.id}`;
  }
}

export function Vehicles(props) {
  const vehicles = {};
  if (props && props.forEach) {
    props.filter(v => v).forEach((vehicle) => {
      const newVehicle = new Vehicle(vehicle);
      vehicles[newVehicle.id] = newVehicle;
    });
  }

  return new OrderedMap(vehicles);
}
