import get from 'lodash/get';
// This class dupes the old cerebral controller, to give GTM access to a limited amount of the app state

// We want the app state to be private, so that this isn't a backdoor into it
let _store;

export default class Controller {
  constructor(store) {
    _store = store;

    this.get = this.get.bind(this);
  }

  get(field) {
    switch (field) {
      case 'receipt':
        return _store.getState().bookings.receipt;
      case 'currentSearch':
        return _store.getState().search.currentSearch;
      case 'checkout.bookings':
        return _store.getState().bookings.bookings;
      case 'user':
        return _store.getState().account.user;
      case 'locations':
        return _store.getState().search.locations;
      case 'app':
        return _store.getState().app.name;
      case 'parkingPasses':
        return _store.getState().bookings.parkingPasses;
      case 'checkout.affiliateId':
        return _store.getState().checkout.affiliateId;
      case 'user.bookingCounts':
        return _store.getState().account.user.bookingCounts;
      case 'checkout.couponCode':
        return _store.getState().checkout.couponCode;
      case 'currency':
        return get(_store.getState(), 'search.selectedLocation.currency', 'USD');
      default:
        return null;
    }
  }
}
