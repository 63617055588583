import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import MapboxPrediction from 'models/predictions/mapbox';
import EventPrediction from 'models/predictions/event';

const propTypes = {
  // Models/Variables/etc.
  prediction: PropTypes.oneOfType([
    PropTypes.instanceOf(MapboxPrediction),
    PropTypes.instanceOf(EventPrediction),
  ]).isRequired,
  predictionKey: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,

  // Functions
  setSelection: PropTypes.func.isRequired,
};

class MapboxAutocompletePrediction extends React.Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
    this.onMouseOver = this.onMouseOver.bind(this);
  }

  onClick() {
    this.props.setSelection(this.props.predictionKey, true);
  }

  onMouseOver() {
    this.props.setSelection(this.props.predictionKey);
  }

  render() {
    const { prediction, selected, predictionKey } = this.props;
    const iconClasses = cx({
      'location-suggestion-pin': true,
      'iconified-font': true,
      'iconified-pin': predictionKey.type === 'places',
      'iconified-ticket': predictionKey.type === 'events',
      'text-color-red': selected,
      'text-color-light-grey': !selected,
    });

    return (
      <div
        className={`location-suggestion ${selected ? 'hover' : ''}`}
        key={prediction.placeId}
        onClick={this.onClick}
        onMouseOver={this.onMouseOver}
      >
        <div className={iconClasses} />
        <div className="location-suggestion-text">
          <div className="location-suggestion-main-text">
            { prediction.mainText }
          </div>
          <div className="location-suggestion-secondary-text">
            { prediction.secondaryText }
          </div>
        </div>
      </div>
    );
  }
}

MapboxAutocompletePrediction.propTypes = propTypes;
export default MapboxAutocompletePrediction;
