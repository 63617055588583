import PropTypes from 'prop-types';

export const routerLocationShape = PropTypes.shape({
  pathname: PropTypes.string,
  search: PropTypes.string,
});

export const analyticsEventShape = PropTypes.shape({
  analytics_id: PropTypes.string,
  client_id: PropTypes.string,
  event_name: PropTypes.string,
  name: PropTypes.string,
  page_name: PropTypes.string,
  page_type: PropTypes.string,
  page_uuid: PropTypes.string,
  properties: PropTypes.array,
  timestamp: PropTypes.number,
  type: PropTypes.string,
});

export const dataCollectorShape = PropTypes.shape({
  deviceData: PropTypes.string,
  rawDeviceData: PropTypes.object,
  teardown: PropTypes.func,
});

export const braintreeClientInstanceShape = PropTypes.shape({
  getConfiguration: PropTypes.func,
  _clientApiBaseUrl: PropTypes.string,
  _configuration: PropTypes.object,
  _request: PropTypes.func,
});

export const paypalInstanceShape = PropTypes.shape({
  _assetsUrl: PropTypes.string,
  _authorizationInProgress: PropTypes.bool,
  _isDebug: PropTypes.bool,
  _loadingFrameUrl: PropTypes.string,
});

export const applePayInstanceShape = PropTypes.shape({
  createPaymentRequest: PropTypes.func,
  performValidation: PropTypes.func,
  merchantIdentifier: PropTypes.string,
});
