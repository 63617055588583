import Brand from 'models/brand';

export const GOT_BRAND = 'got-brand';
const gotBrand = ({ brand }) => ({
  type: GOT_BRAND,
  payload: {
    brand: new Brand(brand),
  },
});
export default gotBrand;
