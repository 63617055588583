import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const propTypes = {
  target: PropTypes.element.isRequired,
  toggle: PropTypes.element.isRequired,
};

class ArrowToggle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
    };
    this.toggleContent = this.toggleContent.bind(this);
  }

  toggleContent(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ active: !this.state.active });
  }

  render() {
    const textClasses = cx({
      'text-color-dark-slate': !this.state.active,
      'text-color-blue': this.state.active,
    });
    const arrowClasses = cx({
      'float-right': true,
      'iconified-font': true,
      'iconified-chevron-down': !this.state.active,
      'iconified-chevron-up': this.state.active,
      'clickable': true,
    });
    const toggleClasses = cx({
      'padding-top-20': true,
      'hidden-xs hidden': !this.state.active,
    });
    return (
      <div className="padding-top-20">
        <div onClick={this.toggleContent} className={textClasses}>
          <span className={arrowClasses} />
          { this.props.target }
        </div>
        <div className={toggleClasses}>
          { this.props.toggle }
        </div>
        <hr className="margin-top-30" />
      </div>
    );
  }
}

ArrowToggle.propTypes = propTypes;
export default ArrowToggle;
