import { createStore, combineReducers, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import { loadableReady } from '@loadable/component';
import Insights from '@parkwhiz-js/insights-sdk';
import { canUseDOM } from 'exenv';
import { hydrate } from 'react-dom';
import React from 'react';
import createSagaMiddleware from 'redux-saga';
import { ConnectedRouter, connectRouter, routerMiddleware } from 'connected-react-router';
import FontFaceObserver from 'fontfaceobserver';
import PropTypes from 'prop-types';

import { composeWithDevTools } from 'redux-devtools-extension';

import BookingRequest from 'models/requests/bookings';

import { AppProvider } from 'providers/app-provider';
import { ExperimentsProvider } from 'providers/experiments-provider';

// External Libraries
import externals from 'externals';
import initStore from 'lib/init-store';
import history from 'lib/history';
import Controller from 'lib/controller';

// Initial State
import searchReducer from 'reducers/search';
import bookingsReducer from 'reducers/bookings';
import accountReducer from 'reducers/account';
import requestsReducer from 'reducers/requests';
import paymentMethodsReducer from 'reducers/payment-methods';
import messagingReducer from 'reducers/messaging';
import deepLinksReducer from 'reducers/deep-links';
import checkoutReducer from 'reducers/checkout';
import analyticsReducer from 'reducers/analytics';
import modalReducer from 'reducers/modal';
import appReducer from 'reducers/app';
import brandReducer from 'reducers/brand';

import App from 'containers';

import analyticsSaga from 'sagas/analytics';
import accountSaga from 'sagas/account';
import appSaga from 'sagas/app';
import bookingsSaga from 'sagas/bookings';
import brandSaga from 'sagas/brand';
import checkoutSaga from 'sagas/checkout';
import deepLinksSaga from 'sagas/deep-links';
import messagingSaga from 'sagas/messaging';
import modalSaga from 'sagas/modal';
import parkingPassesSaga from 'sagas/parking-passes';
import paymentMethodsSaga from 'sagas/payment-methods';
import routerSaga from 'sagas/router';
import searchSaga from 'sagas/search';

import initializeTranslations from 'action-creators/app/initialize-translations';
import addCallbackToRequestQueue from 'action-creators/request-queue/add-callback-to-request-queue';
import updateRequestQueueLength from 'action-creators/request-queue/update-request-queue-length';

import env, { isProduction } from 'lib/env';
import { cdnServeWebpack } from 'lib/cdn';
import isWebView from 'lib/is-web-view';

import apiParsingMiddleware from 'middleware/api-parser';

// IE Shim
(() => {
  function CustomEvent(event, params) {
    const eventParams = params || { bubbles: false, cancelable: false, detail: null };
    const evt = document.createEvent('CustomEvent');
    evt.initCustomEvent(event, eventParams.bubbles, eventParams.cancelable, eventParams.detail);
    return evt;
  }

  CustomEvent.prototype = window.Event.prototype;

  window.CustomEvent = CustomEvent;
})();

// Initial State
const {
  EXPERIMENTS_BASE_URL,
  INSIGHTS_KEY,
} = env();

cdnServeWebpack();

const initialState = window.__INITIAL_STATE__;
initialState.insights = new Insights({
  ...initialState.insights,
  baseURL: EXPERIMENTS_BASE_URL,
  apiKey: INSIGHTS_KEY,
});

function associationInit(state) {
  let latitude;
  let longitude;
  // Safari does not have the permissions API defined, and so we will not proactively track
  // Instead, we'll reactively track on geolocation acquisition in search
  if (navigator.geolocation && navigator.permissions) {
    navigator.permissions.query({ name: 'geolocation' }).then((p) => {
      if (p.state === 'granted') {
        navigator.geolocation.getCurrentPosition((pos) => {
          ({ latitude, longitude } = pos.coords);
        });
      }
    });
  }

  const { trackingProperties } = state.analytics;
  const utmData = trackingProperties.forAssociation;

  if (canUseDOM) {
    const { user } = state.account;
    const { appContext } = state.app;
    const { portalAffiliateId } = state.checkout;

    window.BookingRequest = BookingRequest;

    state.analytics.insights.associate({
      parkwhizID: user.id,
      emailSubscriberID: trackingProperties.mailchimpEmailId,
      platform: `${appContext}_web`,
      userAgent: initialState.userAgent,
      location: [{ longitude, latitude }],
      referringURL: document.referrer,
      affiliateID: portalAffiliateId,
      utmData,
    });
  }

  // Safari does not have the permissions API defined, and so we will not proactively track
  // Instead, we'll reactively track on geolocation acquisition in search
  if (canUseDOM && navigator.geolocation && navigator.permissions) {
    navigator.permissions.query({ name: 'geolocation' }).then((p) => {
      if (p.state === 'granted') {
        navigator.geolocation.watchPosition((position) => {
          initialState.insights.associate({
            location: [{
              latitude: position.coords.latitude,
              longitude: position.coords.longitude,
            }],
          });
        });
      }
    });
  }
}

function initClient() {
  const font = new FontFaceObserver('Overpass', { weight: 400 });
  font.load().then(() => {
    document.body.className += ' font-loaded';
  });

  const monitor = !isProduction() ? { sagaMonitor: window.__SAGA_MONITOR_EXTENSION__ } : {};
  const sagaMiddleware = createSagaMiddleware(monitor);
  const middleware = [
    sagaMiddleware,
    routerMiddleware(history),
    apiParsingMiddleware,
  ];

  let appliedMiddleware = applyMiddleware(...middleware);
  if (!isProduction()) {
    appliedMiddleware = composeWithDevTools(appliedMiddleware);
  }
  const store = createStore(
    combineReducers({
      account: accountReducer,
      analytics: analyticsReducer,
      app: appReducer,
      bookings: bookingsReducer,
      brand: brandReducer,
      checkout: checkoutReducer,
      deepLinks: deepLinksReducer,
      messaging: messagingReducer,
      modal: modalReducer,
      paymentMethods: paymentMethodsReducer,
      requests: requestsReducer,
      router: connectRouter(history),
      search: searchReducer,
    }),
    initStore(initialState),
    appliedMiddleware,
  );
  window.controller = new Controller(store);
  if (!isProduction()) {
    window.store = store;
  }

  // This should be moved into an immutable, saga pattern, but is not critical
  const updateQueueLength = () => store.dispatch(updateRequestQueueLength());
  store.dispatch(addCallbackToRequestQueue(updateQueueLength));

  externals();
  associationInit(store.getState());

  sagaMiddleware.run(accountSaga);
  sagaMiddleware.run(analyticsSaga);
  sagaMiddleware.run(appSaga);
  sagaMiddleware.run(brandSaga);
  sagaMiddleware.run(bookingsSaga);
  sagaMiddleware.run(checkoutSaga);
  sagaMiddleware.run(deepLinksSaga);
  sagaMiddleware.run(messagingSaga);
  sagaMiddleware.run(modalSaga);
  sagaMiddleware.run(parkingPassesSaga);
  sagaMiddleware.run(paymentMethodsSaga);
  sagaMiddleware.run(routerSaga);
  sagaMiddleware.run(searchSaga);

  // Translations that were in the initial state from the server must be fetched again.
  // If any translations contained HTML, it was escaped to prevent an XSS attack.
  store.dispatch(initializeTranslations());

  const ClientApp = ({ mounted = false, insights }) => (
    <AppProvider mounted={mounted} isWebView={isWebView()}>
      <ExperimentsProvider insights={insights}>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <App />
          </ConnectedRouter>
        </Provider>
      </ExperimentsProvider>
    </AppProvider>
  );

  ClientApp.propTypes = {
    insights: PropTypes.instanceOf(Insights).isRequired,
    mounted: PropTypes.bool,
  };

  ClientApp.defaultProps = { mounted: false };

  const container = document.getElementById('root');

  loadableReady(() => {
    hydrate(<ClientApp insights={initialState.insights} />, container);
    hydrate(<ClientApp mounted insights={initialState.insights} />, container);
  });
}


initClient();
