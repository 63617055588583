import { takeEvery, takeLatest, call, put, select } from 'redux-saga/effects';
import { push, LOCATION_CHANGE } from 'connected-react-router';
import get from 'lodash/get';
import result from 'lodash/result';
import url from 'url';

import history from 'lib/history';
import * as PageTitles from 'lib/page-titles';
import * as Apps from 'lib/app-names';

import Search from 'models/search';
import Meta from 'models/meta';

import { UPDATE_SEARCH } from 'action-creators/search/update-search';
import { SEARCH_CHANGE_PARKING_TYPE } from 'action-creators/search/change-parking-type';
import { SET_SELECTED_LOCATION } from 'action-creators/search/set-selected-location';
import { REPLACE_LOCATION } from 'action-creators/router/replace-location';
import { SET_CURRENT_SEARCH_AND_CHANGE_BOUNDS } from 'action-creators/search/set-current-search-and-change-bounds';
import { SEARCH_BOUNDS_CHANGE } from 'action-creators/search/bounds-change';
import { TOGGLE_MAP } from 'action-creators/search/toggle-map';
import { GOT_QUOTES } from 'action-creators/search/got-quotes';
import setError from 'action-creators/checkout/set-error';
import clearError from 'action-creators/checkout/clear-error';
import setCurrentSearch from 'action-creators/search/set-current-search';
import getQuote from 'action-creators/checkout/get-quote';

import { PARKING_UNAVAILABLE_MESSAGE } from 'components/checkout/modal-notices';

const getApp = state => state.app.name;
const getAppContext = state => state.app.appContext;
const getCurrentSearch = state => state.search.currentSearch;
const getVenue = state => state.search.venue;
const getEvent = state => state.search.event;
const getHub = state => state.search.hub;
const getLocations = state => state.search.locations;
const getDisplayMap = state => state.search.displayMap;
const getRouterLocation = state => state.router.location;
const getSelectedLocation = state => state.search.selectedLocation;
const getSelectedQuote = state => state.search.selectedQuote;
const getEventPackageIds = state => state.search.eventPackageIds;
const getEventPackageId = state => state.search.eventPackageId;
const getRoutingStyle = state => state.brand.brand.routingStyle;
const getBrand = state => state.brand.brand;
const getRequestQueueLength = state => state.requests.requestQueueLength;
const getMeta = state => state.app.meta;

const createLocationState = ({
  app,
  currentSearch,
  selectedLocation,
  selectedQuote,
  venue: v,
  event: e,
  hub: h,
  displayMap,
}) => {
  const locationId = selectedLocation && selectedLocation.id;
  const quoteId = selectedQuote && selectedQuote.id;
  let venue = v;
  let hub = h;
  let event = e;

  if (event) {
    if (event.toJSON) { event = event.toJSON(); }
    event = JSON.stringify(event);
  }
  if (venue) {
    if (venue.toJSON) { venue = venue.toJSON(); }
    venue = JSON.stringify(venue);
  }
  if (hub) {
    if (hub.toJSON) { hub = hub.toJSON(); }
    hub = JSON.stringify(hub);
  }

  return {
    app: {
      name: app,
    },
    search: {
      currentSearch: result(currentSearch, 'toJSON', null),
      selectedLocation: selectedLocation && JSON.stringify(result(selectedLocation, 'toJSON', {})),
      selectedQuote: selectedQuote && JSON.stringify(result(selectedQuote, 'toJSON', {})),
      locationId,
      quoteId,
      displayMap,
      event,
      venue,
      hub,
    },
  };
};

export function* pushLocation(action) {
  let { pathname } = action.payload || {};
  const { keepRoute } = action.payload || {};
  if (keepRoute) { return; }
  const app = yield select(getApp);
  const routerLocation = yield select(getRouterLocation);
  const currentSearch = yield select(getCurrentSearch);
  let selectedQuote = yield select(getSelectedQuote);
  let selectedLocation = yield select(getSelectedLocation);

  if (!pathname) {
    const locationId = selectedLocation && selectedLocation.id;
    const locations = yield select(getLocations);
    const location = (locationId ? locations.get(locationId.toString()) : null);
    const displayMap = yield select(getDisplayMap);
    const appContext = yield select(getAppContext);
    const eventPackageId = yield select(getEventPackageId);
    const eventPackageIds = yield select(getEventPackageIds);
    const routingStyle = yield select(getRoutingStyle);

    if (app === 'Checkout') {
      if (!selectedQuote) {
        const { quote_id: quoteId } = url.parse(routerLocation.search, true).query;
        yield put.resolve(getQuote({ quoteId }));
        selectedLocation = yield select(getSelectedLocation);
        selectedQuote = yield select(getSelectedQuote);
      }

      if (selectedLocation && selectedQuote.id && selectedQuote && locations.get(selectedLocation.id.toString())) {
        pathname = `/reserve/?quote_id=${selectedQuote.id}`;
        yield put(clearError());
      } else {
        const requestQueueLength = yield select(getRequestQueueLength);
        if (requestQueueLength === 0) {
          yield put(setError({ error: { message: PARKING_UNAVAILABLE_MESSAGE } }));
        }
        return;
      }
    } else if (app === 'Search') {
      pathname = currentSearch.route(
        location || selectedLocation,
        displayMap,
        appContext,
        {
          eventPackageId: eventPackageId && eventPackageId.toString(),
          eventPackageIds,
          routingStyle,
        },
      );
    }
  }

  const locationState = yield call(createLocationState, { app, currentSearch, selectedQuote, selectedLocation });

  if (pathname && pathname !== `${routerLocation.pathname}${routerLocation.search}`) {
    yield put(push(pathname, locationState));
  }
}

export function* replaceLocation() {
  const routerLocation = yield select(getRouterLocation);
  let currentSearch = yield select(getCurrentSearch);
  const selectedLocation = yield select(getSelectedLocation);
  const selectedQuote = yield select(getSelectedQuote);
  const displayMap = yield select(getDisplayMap);
  const venue = yield select(getVenue);
  const event = yield select(getEvent);
  const hub = yield select(getHub);
  const app = yield select(getApp);

  if (!(currentSearch && currentSearch.lat && currentSearch.lng)) {
    currentSearch = get(routerLocation, ['state', 'search', 'currentSearch'], false);
  }
  if (!currentSearch) {
    const locationState = yield call(createLocationState, {
      app,
      currentSearch,
      selectedLocation,
      selectedQuote,
      venue,
      event,
      hub,
      displayMap,
    });
    yield call(history.replace, `${routerLocation.pathname}${routerLocation.search}${routerLocation.hash}`, locationState);
  } else if (typeof currentSearch === 'string') {
    currentSearch = JSON.parse(currentSearch);
    const newSearch = new Search(currentSearch);
    const shouldGetQuotes = ['Checkout', 'Venue'].includes(app);
    yield put(setCurrentSearch({ newSearch, shouldGetQuotes }));
  }

  if (app === 'Search' && currentSearch && !get(routerLocation, ['state', 'search', 'currentSearch'], false)) {
    const locationState = yield call(createLocationState, {
      app,
      currentSearch,
      selectedLocation,
      selectedQuote,
      venue,
      event,
      hub,
      displayMap,
    });
    yield call(history.replace, `${routerLocation.pathname}${routerLocation.search}${routerLocation.hash}`, locationState);
  }
}

const setDocumentTitle = (pageTitle) => { document.title = pageTitle; };

const getResultsTitle = ({ currentSearch, brand, meta }) => {
  const { parkingType } = currentSearch;
  const { MONTHLY_PARKING_TYPE } = Search;
  let resultsTitle = get(meta, 'title', currentSearch.title);
  if (parkingType === MONTHLY_PARKING_TYPE) {
    resultsTitle = get(meta, 'monthlyTitle', resultsTitle);
  }
  return Meta.title({ destinationType: currentSearch.destinationType, currentSearch, brand, override: resultsTitle });
};

const getPageTitle = ({ app, currentSearch, brand, meta }) => {
  switch (app) {
    case Apps.SEARCH_APP:
    case Apps.HUB_APP:
    case Apps.VENUE_APP:
      return getResultsTitle({ currentSearch, brand, meta });
    case 'Parking Pass':
      return PageTitles.PARKING_PASS_PAGE_TITLE;
    case 'Checkout':
      return PageTitles.CHECKOUT_PAGE_TITLE;
    case Apps.BUSINESS_APP:
      return PageTitles.BUSINESS_PAGE_TITLE;
    case 'Home':
      return replaceBrandName(PageTitles.HOME_PAGE_TITLE, brand);
    case Apps.HOW_IT_WORKS_APP:
      return PageTitles.HOW_IT_WORKS_PAGE_TITLE;
    case 'OAuth Page':
      return PageTitles.OAUTH_PAGE_TITLE;
    case Apps.OUR_APPS_APP:
      return replaceBrandName(PageTitles.OUR_APPS_PAGE_TITLE, brand);
    case 'Receipt':
      return replaceBrandName(PageTitles.RECEIPT_PAGE_TITLE, brand);
    case 'Sign In':
      return replaceBrandName(PageTitles.SIGN_IN_PAGE_TITLE, brand);
    case 'Sign Up':
      return PageTitles.SIGN_UP_PAGE_TITLE;
    case Apps.PRESS_APP:
      return PageTitles.PRESS_PAGE_TITLE;
    case Apps.SHIPPING_ADDRESS_APP:
      return PageTitles.SHIPPING_ADDRESS_PAGE_TITLE;
    default:
      return replaceBrandName(PageTitles.HOME_PAGE_TITLE, brand);
  }
};

function replaceBrandName(text, brand) {
  const brandName = get(brand, 'displayName', 'ParkWhiz');
  return text.replace('ParkWhiz', brandName);
}

export function* setPageTitle() {
  const app = yield select(getApp);
  const brand = yield select(getBrand);
  const currentSearch = yield select(getCurrentSearch);
  const meta = yield select(getMeta);
  const pageTitle = yield call(getPageTitle, { app, currentSearch, brand, meta });
  yield call(setDocumentTitle, pageTitle);
}

export default function* root() {
  yield takeEvery(UPDATE_SEARCH, pushLocation);
  yield takeEvery(SEARCH_CHANGE_PARKING_TYPE, pushLocation);
  yield takeEvery(SET_SELECTED_LOCATION, pushLocation);
  yield takeEvery(TOGGLE_MAP, pushLocation);
  yield takeEvery(GOT_QUOTES, pushLocation);
  yield takeEvery(REPLACE_LOCATION, replaceLocation);
  yield takeEvery(SEARCH_BOUNDS_CHANGE, replaceLocation);
  yield takeEvery(SET_CURRENT_SEARCH_AND_CHANGE_BOUNDS, replaceLocation);
  yield takeLatest(LOCATION_CHANGE, setPageTitle);
}
