import { Map, Record } from 'immutable';

const featureFlagDefaults = {
  name: null,
  slug: null,
  platforms: {},
};

class FeatureFlag extends Record(featureFlagDefaults) {
  constructor(props) {
    if (!props) {
      super();
      return;
    }

    const { name, slug } = props;
    const platforms = new Map(props.platforms);

    super({ name, slug, platforms });
  }

  activeFor(platform) {
    return this.platforms.get(platform);
  }
}

export default class FeatureFlags extends Record({ flags: {} }) {
  constructor(props) {
    if (!props) {
      super();
      return;
    }

    let flags = new Map();
    if (props && props.forEach) {
      props.forEach((featureFlag) => { flags = flags.set(featureFlag.slug, new FeatureFlag(featureFlag)); });
    }
    super({ flags });
  }

  /* Current prod feature JSON, we aren't parsing this file but need
      to return the right values
      [
  {
    "name": "Deep Link Interstitials",
    "slug": "deep-link-interstitials",
    "platforms": {
      "desktop-web": false,
      "mobile-web": true,
      "ios": true,
      "android": false
    }
  },
  {
    "name": "Venue Deep Link Banner",
    "slug": "venue-deep-link-banner",
    "platforms": {
      "desktop-web": false,
      "mobile-web": true,
      "ios": false,
      "android": false
    }
  },
  {
    "name": "Checkout Deep Link Banner",
    "slug": "checkout-deep-link-banner",
    "platforms": {
      "desktop-web": false,
      "mobile-web": true,
      "ios": false,
      "android": false
    }
  }
]
*/
  desktopActivated(slug) {
    return false;
  }

  mobileActivated(slug) {
    switch(slug) {
      case "deep-link-interstitials":
        return true;
      case "checkout-deep-link-banner":
        return true;
      default:
        return false;
    }
  }
}
