import Hub from 'models/hub';
export const GOT_NEARBY_METROS = 'got-nearby-metros';
const gotNearbyMetros = ({ hubs, routingStyle }) => ({
  type: GOT_NEARBY_METROS,
  payload: {
    nearbyMetros: hubs.map(h => new Hub({
      ...h,
      url: routingStyle === 'bestparking' ? `/${h.site_url}/` : `/p/${h.site_url}/map/`,
    })),
  },
});
export default gotNearbyMetros;
