import { Vehicles } from 'models/vehicles';

export const GOT_VEHICLES = 'got-vehicles';

export default function gotVehicles(vehicleResults) {
  const vehicles = Vehicles(vehicleResults);

  return {
    type: GOT_VEHICLES,
    payload: {
      vehicles,
    },
  };
}
