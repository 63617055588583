import moment from 'moment-timezone';
import { invalidEndTimeChangeWarning, invalidStartTimeChangeWarning } from 'lib/common/messages';

export const EARLIER_BOOKABLE_HOURS = 0.5;

export default function calculateStartAndEndTimes(desiredTimes, currentTimes, { endChanged = false } = {}) {
  let { startTime, endTime } = currentTimes;
  let error;
  let endTimeChanged = endChanged;

  if (desiredTimes.startTime) {
    ({ startTime, error } = validatedStartTime(desiredTimes.startTime));
    if (!error && endChanged) {
      ({ endTime, error } = validatedEndTime(endTime, { startTime }));
    } else if (!error) {
      const duration = currentTimes.endTime.diff(currentTimes.startTime, 'minutes');
      ({ endTime, error } = validatedEndTime(endTime, { startTime, duration }));
    }
  }

  if (desiredTimes.endTime && !error) {
    ({ endTime, error } = validatedEndTime(desiredTimes.endTime));
    if (!error) {
      ({ startTime, error } = validatedStartTime(currentTimes.startTime, { endTime }));
    }
    endTimeChanged = true;
  }

  return { startTime, endTime, endTimeChanged, error };
}

export function validatedStartTime(startTime, { endTime = null } = {}) {
  if (endTime && endTime.isBefore(startTime)) {
    startTime = endTime.clone().subtract(1, 'hours');
  }
  const isValid = startTime && startTime.isAfter(moment().subtract(EARLIER_BOOKABLE_HOURS, 'hours'));
  if (!isValid) {
    startTime.hours(moment().hours());
    if (moment().minutes() >= 30) {
      startTime.minutes(30);
    } else {
      startTime.minutes(0);
    }
    return { startTime, error: invalidStartTimeChangeWarning };
  }
  return { startTime };
}

export function validatedEndTime(endTime, { startTime = null, duration = null } = {}) {
  if (startTime && startTime.isAfter(endTime)) {
    endTime = startTime.clone().add(1, 'hours');
  } else if (startTime && duration) {
    endTime = startTime.clone().add(Math.max(duration, 30), 'minutes');
  }

  const isValid = endTime && endTime.isAfter(moment().subtract(EARLIER_BOOKABLE_HOURS, 'hours'));

  if (!isValid) {
    endTime.hours(moment().hours());
    if (moment().minutes() >= 30) {
      endTime.minutes(30);
    } else {
      endTime.minutes(0);
    }
    return { endTime, error: invalidEndTimeChangeWarning };
  }
  return { endTime };
}
