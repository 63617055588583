import React from 'react';

const StarburstCar = () => (
  <svg width="220px" height="148px" viewBox="0 0 220 148" version="1.1">
    <defs>
      <rect id="path-1" x="0" y="0" width="1219" height="1285" />
      <mask id="mask-2" maskContentUnits="userSpaceOnUse" maskUnits="objectBoundingBox" x="0" y="0" width="1219" height="1285" fill="white">
        <use xlinkHref="#path-1" />
      </mask>
      <filter x="-50%" y="-50%" width="200%" height="200%" filterUnits="objectBoundingBox" id="filter-3">
        <feOffset dx="0" dy="4" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur stdDeviation="3" in="shadowOffsetOuter1" result="shadowBlurOuter1" />
        <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.1 0" type="matrix" in="shadowBlurOuter1" result="shadowMatrixOuter1" />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <g id="Thank-You" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="thank-you-for-feedback" transform="translate(-494.000000, -441.000000)">
        <polygon id="BG_grey" fill="#ECECEC" points="0 649.60897 0 96 1212 96 1212 1290 0 1290" />
        <polygon id="BG_grey" fill="#ECECEC" points="0 644.60897 0 91 1212 91 1212 1285 0 1285" />
        <use id="Rectangle-136" stroke="#979797" mask="url(#mask-2)" strokeWidth="2" fillOpacity="0.5" fill="#303030" xlinkHref="#path-1" />
        <g id="popup" filter="url(#filter-3)" transform="translate(285.000000, 298.000000)" fill="#FFFFFF">
          <rect id="Mask-Copy" x="0" y="0" width="640" height="426" rx="4" />
        </g>
        <path d="M615.551719,450.323096 C615.551719,449.917091 615.098802,449.754689 614.759113,449.700555 L607.653967,448.712611 L604.46939,442.554873 C604.342007,442.297737 604.101394,442 603.77586,442 C603.450325,442 603.209713,442.297737 603.08233,442.554873 L599.897752,448.712611 L592.792606,449.700555 C592.438764,449.754689 592,449.917091 592,450.323096 C592,450.566699 592.183998,450.796768 592.353842,450.972703 L597.505781,455.763558 L596.288564,462.530303 C596.274411,462.625037 596.260257,462.706238 596.260257,462.800973 C596.260257,463.152843 596.444255,463.477647 596.854711,463.477647 C597.052863,463.477647 597.236861,463.40998 597.420858,463.315245 L603.77586,460.121342 L610.130861,463.315245 C610.300705,463.40998 610.498857,463.477647 610.697008,463.477647 C611.107465,463.477647 611.277309,463.152843 611.277309,462.800973 C611.277309,462.706238 611.277309,462.625037 611.263155,462.530303 L610.045939,455.763558 L615.183724,450.972703 C615.367722,450.796768 615.551719,450.566699 615.551719,450.323096 L615.551719,450.323096 Z" id="star" stroke="#F5A623" />
        <path d="M712.551719,450.323096 C712.551719,449.917091 712.098802,449.754689 711.759113,449.700555 L704.653967,448.712611 L701.46939,442.554873 C701.342007,442.297737 701.101394,442 700.77586,442 C700.450325,442 700.209713,442.297737 700.08233,442.554873 L696.897752,448.712611 L689.792606,449.700555 C689.438764,449.754689 689,449.917091 689,450.323096 C689,450.566699 689.183998,450.796768 689.353842,450.972703 L694.505781,455.763558 L693.288564,462.530303 C693.274411,462.625037 693.260257,462.706238 693.260257,462.800973 C693.260257,463.152843 693.444255,463.477647 693.854711,463.477647 C694.052863,463.477647 694.236861,463.40998 694.420858,463.315245 L700.77586,460.121342 L707.130861,463.315245 C707.300705,463.40998 707.498857,463.477647 707.697008,463.477647 C708.107465,463.477647 708.277309,463.152843 708.277309,462.800973 C708.277309,462.706238 708.277309,462.625037 708.263155,462.530303 L707.045939,455.763558 L712.183724,450.972703 C712.367722,450.796768 712.551719,450.566699 712.551719,450.323096 L712.551719,450.323096 Z" id="star" stroke="#F5A623" />
        <path d="M566.551719,475.323096 C566.551719,474.917091 566.098802,474.754689 565.759113,474.700555 L558.653967,473.712611 L555.46939,467.554873 C555.342007,467.297737 555.101394,467 554.77586,467 C554.450325,467 554.209713,467.297737 554.08233,467.554873 L550.897752,473.712611 L543.792606,474.700555 C543.438764,474.754689 543,474.917091 543,475.323096 C543,475.566699 543.183998,475.796768 543.353842,475.972703 L548.505781,480.763558 L547.288564,487.530303 C547.274411,487.625037 547.260257,487.706238 547.260257,487.800973 C547.260257,488.152843 547.444255,488.477647 547.854711,488.477647 C548.052863,488.477647 548.236861,488.40998 548.420858,488.315245 L554.77586,485.121342 L561.130861,488.315245 C561.300705,488.40998 561.498857,488.477647 561.697008,488.477647 C562.107465,488.477647 562.277309,488.152843 562.277309,487.800973 C562.277309,487.706238 562.277309,487.625037 562.263155,487.530303 L561.045939,480.763558 L566.183724,475.972703 C566.367722,475.796768 566.551719,475.566699 566.551719,475.323096 L566.551719,475.323096 Z" id="star" stroke="#F5A623" />
        <path d="M523.551719,513.323096 C523.551719,512.917091 523.098802,512.754689 522.759113,512.700555 L515.653967,511.712611 L512.46939,505.554873 C512.342007,505.297737 512.101394,505 511.77586,505 C511.450325,505 511.209713,505.297737 511.08233,505.554873 L507.897752,511.712611 L500.792606,512.700555 C500.438764,512.754689 500,512.917091 500,513.323096 C500,513.566699 500.183998,513.796768 500.353842,513.972703 L505.505781,518.763558 L504.288564,525.530303 C504.274411,525.625037 504.260257,525.706238 504.260257,525.800973 C504.260257,526.152843 504.444255,526.477647 504.854711,526.477647 C505.052863,526.477647 505.236861,526.40998 505.420858,526.315245 L511.77586,523.121342 L518.130861,526.315245 C518.300705,526.40998 518.498857,526.477647 518.697008,526.477647 C519.107465,526.477647 519.277309,526.152843 519.277309,525.800973 C519.277309,525.706238 519.277309,525.625037 519.263155,525.530303 L518.045939,518.763558 L523.183724,513.972703 C523.367722,513.796768 523.551719,513.566699 523.551719,513.323096 L523.551719,513.323096 Z" id="star" stroke="#F5A623" />
        <path d="M518.551719,450.323096 C518.551719,449.917091 518.098802,449.754689 517.759113,449.700555 L510.653967,448.712611 L507.46939,442.554873 C507.342007,442.297737 507.101394,442 506.77586,442 C506.450325,442 506.209713,442.297737 506.08233,442.554873 L502.897752,448.712611 L495.792606,449.700555 C495.438764,449.754689 495,449.917091 495,450.323096 C495,450.566699 495.183998,450.796768 495.353842,450.972703 L500.505781,455.763558 L499.288564,462.530303 C499.274411,462.625037 499.260257,462.706238 499.260257,462.800973 C499.260257,463.152843 499.444255,463.477647 499.854711,463.477647 C500.052863,463.477647 500.236861,463.40998 500.420858,463.315245 L506.77586,460.121342 L513.130861,463.315245 C513.300705,463.40998 513.498857,463.477647 513.697008,463.477647 C514.107465,463.477647 514.277309,463.152843 514.277309,462.800973 C514.277309,462.706238 514.277309,462.625037 514.263155,462.530303 L513.045939,455.763558 L518.183724,450.972703 C518.367722,450.796768 518.551719,450.566699 518.551719,450.323096 L518.551719,450.323096 Z" id="star" stroke="#F5A623" />
        <path d="M711.759113,512.700555 L704.653967,511.712611 L701.46939,505.554873 C701.342007,505.297737 701.101394,505 700.77586,505 C700.450325,505 700.209713,505.297737 700.08233,505.554873 L696.897752,511.712611 L689.792606,512.700555 C689.438764,512.754689 689,512.917091 689,513.323096 C689,513.566699 689.183998,513.796768 689.353842,513.972703 L694.505781,518.763558 L693.288564,525.530303 C693.274411,525.625037 693.260257,525.706238 693.260257,525.800973 C693.260257,526.152843 693.444255,526.477647 693.854711,526.477647 C694.052863,526.477647 694.236861,526.40998 694.420858,526.315245 L700.77586,523.121342 L707.130861,526.315245 C707.300705,526.40998 707.498857,526.477647 707.697008,526.477647 C708.107465,526.477647 708.277309,526.152843 708.277309,525.800973 C708.277309,525.706238 708.277309,525.625037 708.263155,525.530303 L707.045939,518.763558 L712.183724,513.972703 C712.367722,513.796768 712.551719,513.566699 712.551719,513.323096 C712.551719,512.917091 712.098802,512.754689 711.759113,512.700555 Z" id="star" stroke="#F5A623" />
        <path d="M663.551719,475.323096 C663.551719,474.917091 663.098802,474.754689 662.759113,474.700555 L655.653967,473.712611 L652.46939,467.554873 C652.342007,467.297737 652.101394,467 651.77586,467 C651.450325,467 651.209713,467.297737 651.08233,467.554873 L647.897752,473.712611 L640.792606,474.700555 C640.438764,474.754689 640,474.917091 640,475.323096 C640,475.566699 640.183998,475.796768 640.353842,475.972703 L645.505781,480.763558 L644.288564,487.530303 C644.274411,487.625037 644.260257,487.706238 644.260257,487.800973 C644.260257,488.152843 644.444255,488.477647 644.854711,488.477647 C645.052863,488.477647 645.236861,488.40998 645.420858,488.315245 L651.77586,485.121342 L658.130861,488.315245 C658.300705,488.40998 658.498857,488.477647 658.697008,488.477647 C659.107465,488.477647 659.277309,488.152843 659.277309,487.800973 C659.277309,487.706238 659.277309,487.625037 659.263155,487.530303 L658.045939,480.763558 L663.183724,475.972703 C663.367722,475.796768 663.551719,475.566699 663.551719,475.323096 L663.551719,475.323096 Z" id="star" stroke="#F5A623" />
        <g id="zag" transform="translate(543.000000, 507.000000)">
          <path d="M23.5987297,20.5713885 C25.3840511,16.7237648 28.1541014,12.0476672 32.0930169,8.64614475 C35.1352687,8.18411126 39.3143619,7.8176709 44.2460122,7.55478978 C49.1456388,7.28394256 54.7657987,7.14055285 60.7142015,7.14055285 C72.6030013,7.14055285 83.2829062,7.73004386 89.3674098,8.64614475 C97.2612526,15.4332574 100.415587,27.4062978 100.415587,27.4062978 L21.0448394,27.4062978 C21.0448394,27.4062978 21.8214142,24.4110462 23.5987297,20.5713885 M114.56304,27.2577605 C110.532341,24.1305169 107.717954,19.5809958 107.717954,19.5809958 C107.717954,19.5809958 99.4257221,2.61084039 94.249736,1.80694443 C87.5822136,0.764529892 75.5167503,0.0578081686 62.0041416,0.0578081686 C48.5181675,0.0578081686 36.443826,0.764529892 29.79406,1.80694443 C24.6003175,2.61084039 16.3169641,19.5809958 16.3169641,19.5809958 C16.3169641,19.5809958 13.5114553,24.1305169 9.48075601,27.2577605 C0.691345646,32.5140033 -0.445062967,44.3162561 0.336217955,54.3958747 C0.176410494,56.3570274 0.114263148,58.5478648 0.114263148,60.9595527 C0.114263148,72.3024363 1.6679468,81.5074868 3.56787995,81.5074868 L17.7729876,81.5074868 C18.6963196,81.5074868 19.5219915,79.3696535 20.1523431,75.8978831 C28.7908242,76.5162646 41.8240105,76.8077873 62.0041416,76.8077873 C72.1164026,76.8077873 80.4352688,76.7194471 87.3069896,76.5781027 C94.1431977,76.4279244 99.5677732,76.2070738 103.873696,75.8978831 C104.504048,79.3696535 105.338598,81.5074868 106.26193,81.5074868 L120.467038,81.5074868 C122.375849,81.5074868 123.929533,72.3024363 123.929533,60.9595527 C123.929533,58.5478648 123.849629,56.3570274 123.707578,54.3958747 C124.488859,44.3162561 123.35245,32.5140033 114.56304,27.2577605 Z" id="Fill-8" fill="#78D1ED" />
          <path d="M114.605843,41.0832963 C114.527992,41.1607598 114.458791,41.2382234 114.37229,41.3070799 C108.671876,45.8688227 103.205014,45.1888648 100.912738,44.79294 C98.2052573,44.3539798 97.0980448,42.1936073 99.8401258,39.9815924 C104.052723,36.5731958 108.931379,34.9894964 112.996925,35.488706 C115.713055,35.7985603 116.517514,39.4135263 114.605843,41.0832963" id="Path" fill="#FFFFFF" />
          <path d="M23.4597617,44.79294 C21.1588357,45.1888648 15.7006239,45.8688227 10.0088595,41.3070799 C9.93100867,41.2382234 9.85315779,41.1607598 9.74935661,41.0832963 C7.86363528,39.4135263 8.65944428,35.7985603 11.3409746,35.488706 C15.4584212,34.9894964 20.3111261,36.5731958 24.5410239,39.9815924 C27.2658048,42.1936073 26.1758924,44.3539798 23.4597617,44.79294" id="Path" fill="#FFFFFF" />
          <path d="M81.7441211,69.4779936 L42.7321797,69.4779936 C42.7321797,69.4779936 30.9420964,70.3128786 30.9420964,58.9859851 L94.4597649,58.9859851 C94.4597649,69.262817 81.7441211,69.4779936 81.7441211,69.4779936" id="Path" fill="#FFFFFF" />
        </g>
        <g id="Group" transform="translate(494.000000, 441.000000)" />
      </g>
    </g>
  </svg>
);

export default StarburstCar;
