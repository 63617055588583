import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import MapboxAutocompleteComponent, { propTypes, defaultProps } from 'components/mapbox/autocomplete';

import changeUserInput from 'action-creators/search/change-user-input';

class MapboxAutocomplete extends Component {
  render() {
    return (
      <MapboxAutocompleteComponent
        {...this.props}
        ref={(autocomplete) => { this.autocomplete = autocomplete; }}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { brand } = state.brand;
  const { user } = state.account;
  const { requestQueue } = state.requests;
  const { insights } = state.analytics;
  const { hub, venue, currentSearch } = state.search;

  return {
    brand,
    routingStyle: brand.routingStyle,
    token: user.token,
    requestQueue,
    insights,
    hub,
    venue,
    currentSearch,
    ...ownProps,
  };
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    changeUserInput,
  }, dispatch)
);

MapboxAutocomplete.propTypes = propTypes;
MapboxAutocomplete.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(MapboxAutocomplete);

