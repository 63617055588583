import React from 'react';

import NoticeHeader from 'components/checkout/modal-notices/notice-headers/notice-header';

import BrandName from 'containers/common/brand-name';

const PricePremiumHeader = ({ instructionsRequired }) => {
  const parkingInstructions = <NoticeHeader>To get this price, YOU MUST:</NoticeHeader>;
  return (
    <div>
      <NoticeHeader headerImgSrc="/images/checkout/zag-deal.png">
        Price Alert
      </NoticeHeader>
      <p>Heads up — <BrandName /> prices are sometimes higher than standard rates at this location. But by reserving a space, you'll skip the headache of hunting for parking!</p>
      { instructionsRequired ? parkingInstructions : null }
    </div>
  );
};

export default PricePremiumHeader;
