import { Record, OrderedMap } from 'immutable';

import Booking from 'models/booking';

export default class BookingsGroup extends Record({
  bookings: OrderedMap(),
  totalCount: 0,
  paginationTotalPages: 0,
  paginationPerPage: null,
  paginationCurrentPage: null,
  paginationAmountReturned: null,
}) {
  static fromAPIResponse({ body, headers }) {
    return new BookingsGroup({
      bookings: body,
      totalCount: parseInt(headers['x-pagination-total'], 10),
      paginationTotalPages: parseInt(headers['x-pagination-total-pages'], 10),
      paginationPerPage: parseInt(headers['x-pagination-per-page'], 10),
      paginationCurrentPage: parseInt(headers['x-pagination-current-page'], 10),
    });
  }

  constructor(props) {
    if (!props) {
      super();

      return;
    }
    let bookings = OrderedMap();

    if (props.bookings) {
      bookings = props.bookings.reduce((acc, booking) => acc.set(booking.id, new Booking(booking)), bookings);
    }

    super({
      ...props,
      bookings,
    });
  }
}
