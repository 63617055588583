export const GET_BOOKINGS = 'get-bookings';

export default function getBookings({ type, query, bookingsQuery, bookingRequest, fetchAll }) {
  return {
    type: GET_BOOKINGS,
    payload: {
      type,
      bookingsQuery,
      query,
      fetchAll,
      bookingRequest,
    },
  };
}
