export const DELETE_VEHICLE = 'delete-vehicle';

export default function deleteVehicle(vehicleId) {
  return {
    type: DELETE_VEHICLE,
    payload: {
      vehicleId: `${vehicleId}`,
    },
  };
}
