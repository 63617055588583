import { Vehicle } from 'models/vehicles';

export const SAVED_VEHICLE = 'saved-vehicle';

const savedVehicle = ({ body }) => {
  const vehicle = new Vehicle(body);
  return {
    type: SAVED_VEHICLE,
    payload: { vehicle },
  };
};

export default savedVehicle;
