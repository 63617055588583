import { Record, Map } from 'immutable';

export default class Availability extends Record({
  approximateLowPrice: null,
  approximateHighPrice: null,
  available: null,
}) {
  constructor(props) {
    if (props && typeof props === 'object') {
      super({
        approximateLowPrice: props.approximate_low,
        approximateHighPrice: props.approximate_high,
        available: props.available,
      });
    } else {
      super({ available: false });
    }
  }

  get isAvailable() {
    return !!(this.available && this.available > 0);
  }
}

export function Availabilities(availabilities) {
  if (!availabilities || typeof availabilities !== 'object') { return Map(); }

  const a = {};
  Object.keys(availabilities).forEach((k) => {
    a[k] = new Availability(availabilities[k]);
  });

  return Map(a);
}
