import { Record, List, Map } from 'immutable';
import moment from 'moment-timezone';
import get from 'lodash/get';

import User from 'models/user';
import { AddOn } from 'models/addons';


export default class Receipt extends Record({
  userCreditAmount: null,
  quotePrice: null,
  subTotal: null,
  finalPrice: null,
  couponCode: null,
  couponAmount: null,
  addOns: null,
  purchaseTime: null,
  paymentType: null,
  expenseMemo: null,
  user: null,
}) {
  constructor(props, { currency } = {}) {
    if (!props) {
      super();
      return;
    }

    const rawAddOns = props.add_ons ? props.add_ons.map(addOn => (
      new AddOn(Object.assign(addOn, { price: addOn.quote_price }), { currency })
    )) : [];
    const addOns = List(rawAddOns);

    super({
      userCreditAmount: get(props, ['user_credit_amount', currency]),
      quotePrice: get(props, ['quote_price', currency]),
      subTotal: get(props, ['subtotal', currency]),
      finalPrice: get(props, ['final_price', currency]),
      couponAmount: get(props, ['coupon_amount', currency]),
      couponCode: props.coupon_code,
      paymentType: props.payment_type,
      purchaseTime: moment(props.purchase_time),
      expenseMemo: props.expense_memo && props.expense_memo !== '' ? props.expense_memo : null,
      addOns,
      user: props.user ? new User(props.user) : null,
    });
  }

  toLineItemProps() {
    const props = this.toJS();
    props.subTotal = props.quotePrice;

    props.checkoutTotal = props.finalPrice;
    delete props.finalPrice;

    props.selectedAddOns = new Map({ purchased: props.addOns });
    delete props.addOns;

    return props;
  }
}
