import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const propTypes = {
  rating: PropTypes.number.isRequired,
  selectedRating: PropTypes.number,
  hoveredRating: PropTypes.number,
  onHover: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

const defaultProps = {
  selectedRating: null,
  hoveredRating: null,
};

const StarRating = ({
  rating,
  selectedRating,
  hoveredRating,
  onHover,
  onSelect,
}) => {
  let active = false;

  if (hoveredRating >= rating) {
    active = true;
  } else if (selectedRating >= rating) {
    active = true;
  }

  const classNames = cx({
    'star-rating': true,
    'active': active,
  });

  return (
    <div
      className="clickable padding-horizontal-5 display-inline-block"
      onClick={() => { onSelect(rating); }}
      onMouseEnter={() => { onHover(rating); }}
      onMouseLeave={() => { onHover(null); }}
    >
      <div
        className={classNames}
      />
    </div>
  );
};

StarRating.propTypes = propTypes;
StarRating.defaultProps = defaultProps;
export default StarRating;
