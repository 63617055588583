import { takeEvery, call, select, fork } from 'redux-saga/effects';

import { TRACK_EVENT } from 'action-creators/analytics/track-event';
import { ASSOCIATE } from 'action-creators/analytics/associate';

import { pageProps } from 'lib/analytics/page-properties';
import { EventProperties } from '@parkwhiz-js/insights-sdk';

const getInsights = state => state.analytics.insights;
const getApp = state => state.app.name;
const getCurrentSearch = state => state.search.currentSearch;
const getHub = state => state.search.hub;
const getVenue = state => state.search.venue;
const getUser = state => state.account.user;

function track({ insights, pageProperties, eventProperties }) {
  try {
    insights.trackAnalyticsEvent({
      ...pageProperties,
      ...eventProperties,
    });
  } catch (e) {
    console.warn(e);
  }
}

export function* trackEvent(action) {
  const { eventProperties } = action.payload;
  const insights = yield select(getInsights);
  const app = yield select(getApp);
  const currentSearch = yield select(getCurrentSearch);
  const hub = yield select(getHub);
  const venue = yield select(getVenue);
  const pageProperties = yield call(pageProps, { app, currentSearch, hub, venue });

  if (eventProperties.eventName === EventProperties.PAGEVIEW.eventName) {
    pageProperties.name = pageProperties.pageName;
  }

  yield call(track, { insights, pageProperties, eventProperties });
}

export function* associate() {
  const insights = yield select(getInsights);
  const user = yield select(getUser);
  insights.associate({ parkwhizID: user.id });
}

export function* watchChangeAnalyticsState() {
  yield takeEvery(TRACK_EVENT, trackEvent);
  yield takeEvery(ASSOCIATE, associate);
}

export default function* root() {
  yield fork(watchChangeAnalyticsState);
}
