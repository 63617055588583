export const GET_PREVIEW = 'get-preview';

const getPreview = ({ quoteId, couponCode, autoApplyCoupon }) => (
  {
    type: GET_PREVIEW,
    payload: {
      quoteId,
      couponCode,
      autoApplyCoupon,
    },
  }
);
export default getPreview;
