import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Modal } from 'react-bootstrap';
import { Set } from 'immutable';
import get from 'lodash/get';

import Search from 'models/search';

import * as AppContext from 'lib/app-context';
import { HOUR_BUFFER_MODAL_CLOSE_CLICK, HOUR_BUFFER_MODAL_CLOSE_IMPRESSION } from 'lib/analytics/events';

import SearchRestrictions from 'components/search/search-restrictions';

import dismissSearchRestrictionsCreator from 'action-creators/search/dismiss-restrictions-modal';
import trackEvent from 'action-creators/analytics/track-event';

const propTypes = {
  app: PropTypes.string.isRequired,
  appContext: PropTypes.string.isRequired,
  restrictionsModalDismissals: PropTypes.instanceOf(Set).isRequired,
  currentSearch: PropTypes.instanceOf(Search),
  dismissSearchRestrictions: PropTypes.func.isRequired,
  trackEvent: PropTypes.func.isRequired,
};

const defaultProps = {
  currentSearch: null,
};

export class SearchRestrictionModal extends Component {
  constructor(props) {
    super(props);

    this.onDismiss = this.onDismiss.bind(this);
    this.onDismissClick = this.onDismissClick.bind(this);
  }

  componentDidMount() {
    if (this.shouldRender) {
      this.props.trackEvent(HOUR_BUFFER_MODAL_CLOSE_IMPRESSION);
    }
  }

  get shouldRender() {
    const { app, appContext, currentSearch, restrictionsModalDismissals } = this.props;
    const searchSuggestions = get(currentSearch, 'destination.searchSuggestions', null);
    if (!searchSuggestions) { return false; }

    return appContext === AppContext.DESKTOP && searchSuggestions.shouldDisplay({
      app,
      search: currentSearch,
      dismissals: restrictionsModalDismissals,
    });
  }

  onDismiss() {
    const { dismissSearchRestrictions, currentSearch } = this.props;
    dismissSearchRestrictions({ currentSearch });
  }

  onDismissClick() {
    this.props.trackEvent(HOUR_BUFFER_MODAL_CLOSE_CLICK);
    this.onDismiss();
  }

  render() {
    const { shouldRender } = this;
    const { currentSearch } = this.props;
    const searchSuggestions = get(currentSearch, 'destination.searchSuggestions', null);
    return (
      <Modal className="text-size-16 hidden-xs" show={shouldRender} onHide={this.onDismiss}>
        <div className="container-fluid padding-horizontal-15">
          <div className="row text-align-right">
            <div
              type="button"
              className="col-xs-12 padding-top-10 float-right iconified-font iconified-x text-size-18 text-color-blue text-color-hover-blue clickable"
              onClick={this.onDismissClick}
            />
          </div>
          <SearchRestrictions
            searchSuggestions={searchSuggestions}
            shouldRender={shouldRender}
            onDismiss={this.onDismiss}
            trackEvent={this.props.trackEvent}
          />
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    name: app,
    appContext,
  } = state.app;

  const {
    currentSearch,
    restrictionsModalDismissals,
  } = state.search;

  return {
    app,
    currentSearch,
    appContext,
    restrictionsModalDismissals,
  };
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    dismissSearchRestrictions: dismissSearchRestrictionsCreator,
    trackEvent,
  }, dispatch)
);

SearchRestrictionModal.propTypes = propTypes;
SearchRestrictionModal.defaultProps = defaultProps;
export default connect(mapStateToProps, mapDispatchToProps)(SearchRestrictionModal);
