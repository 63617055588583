import MobileDetect from 'mobile-detect';

import { AccountState } from 'reducers/account';
import { AnalyticsState } from 'reducers/analytics';
import { AppState } from 'reducers/app';
import { BookingsState } from 'reducers/bookings';
import { BrandState } from 'reducers/brand';
import { CheckoutState } from 'reducers/checkout';
import { DeepLinksState } from 'reducers/deep-links';
import { MessagingState } from 'reducers/messaging';
import { ModalState } from 'reducers/modal';
import { PaymentMethodsState } from 'reducers/payment-methods';
import { RequestsState } from 'reducers/requests';
import { SearchState } from 'reducers/search';

import * as AppContext from 'lib/app-context';

const initStore = (initialState) => {
  let appContext;
  const md = new MobileDetect(initialState.userAgent);
  if (md.mobile() && md.phone()) {
    appContext = AppContext.MOBILE;
  } else if (md.mobile() && md.tablet()) {
    appContext = AppContext.TABLET;
  } else {
    appContext = AppContext.DESKTOP;
  }

  return Object.preventExtensions({
    account: new AccountState(initialState),
    analytics: new AnalyticsState(initialState),
    app: new AppState({ ...initialState, appContext }),
    bookings: new BookingsState(initialState),
    brand: new BrandState(initialState),
    checkout: new CheckoutState(initialState),
    deepLinks: new DeepLinksState(initialState),
    messaging: new MessagingState(initialState),
    modal: new ModalState({ ...initialState, appContext }),
    paymentMethods: new PaymentMethodsState(initialState),
    requests: new RequestsState(initialState),
    search: new SearchState({ ...initialState, appContext }),
  });
};

export default initStore;
