import { getEventsFromEmbedded } from 'models/event';
import { getLocationsFromEmbedded } from 'models/locations';
import { getVenuesFromEmbedded } from 'models/venue';
import BookingsGroup from 'models/bookings-group';

export const GOT_UPCOMING_BOOKINGS = 'GOT_UPCOMING_BOOKINGS';
const gotUpcomingBookings = ({ body, queryType, bookingType, user, headers }) => ({
  type: GOT_UPCOMING_BOOKINGS,
  payload: { body, queryType, bookingType, user, headers },
  models: {
    events: getEventsFromEmbedded,
    locations: getLocationsFromEmbedded,
    upcomingBookings: BookingsGroup.fromAPIResponse,
    venues: getVenuesFromEmbedded,
  },
});
export default gotUpcomingBookings;
