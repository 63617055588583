import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { countryByLocale } from 'lib/locale';

import { Location } from 'models/locations';

const propTypes = {
  intl: intlShape.isRequired,
  location: PropTypes.instanceOf(Location).isRequired,
};

export const CurrencySymbol = ({ intl, location }) => {
  if (!location) { return null; }

  if (location.currency === 'CAD') {
    return 'C$';
  }

  if (countryByLocale(intl.locale) === 'CA') {
    return 'US$';
  }

  return '$';
};

CurrencySymbol.propTypes = propTypes;

export default injectIntl(CurrencySymbol);
