import { Record, OrderedMap } from 'immutable';

import { BusinessProfiles } from 'models/business-profile';

export const CREDIT_CARD_TYPE = 'credit_card';
export const BUSINESS_ACCOUNT_TYPE = 'Enterprise';
export const BUSINESS_PROFILE_TYPE = 'business_profile';

export default class PaymentMethod extends Record({
  id: null,
  type: null,
  lastFourDigits: null,
  label: null,
  cardType: null,
  accountName: null,
  expirationMonth: null,
  expirationYear: null,
  postalCode: null,
  isDefault: false,
  businessProfile: null,
}) {
  constructor(props) {
    super({
      id: props.id,
      type: props.type,
      lastFourDigits: props.last_4,
      label: props.label,
      cardType: props.card_type,
      accountName: props.account_name,
      expirationMonth: props.expiration_month,
      expirationYear: props.expiration_year,
      postalCode: props.postal_code,
      isDefault: !!(props.is_default),
      businessProfile: props._embedded && props._embedded['pw:business_profiles'] ? BusinessProfiles(props._embedded['pw:business_profiles']) : null,
    });
  }

  get editURL() {
    if (this.type === BUSINESS_PROFILE_TYPE) {
      return `/account/payment-methods/business-profiles/${this.id}`;
    }
    return `/account/payment-methods/${this.id}`;
  }

  get connectURL() {
    if (this.type === BUSINESS_PROFILE_TYPE) {
      return `/account/payment-methods/business-profiles/${this.id}/connect/`;
    }
    return null;
  }

  get paymentMethodType() {
    return this.get('cardType').toLowerCase();
  }
}

const typeValue = (type) => {
  switch (type) {
    case CREDIT_CARD_TYPE:
      return 1;
    case BUSINESS_PROFILE_TYPE:
      return 0;
    case BUSINESS_ACCOUNT_TYPE:
      return -1;
    default:
      return 0;
  }
};

const sortPaymentMethodsByType = (a, b) => (typeValue(b.type) - typeValue(a.type));

export function sortPaymentMethods(paymentMethods) {
  return paymentMethods.sort(
    (a, b) => sortPaymentMethodsByType(a, b),
  );
}

export function PaymentMethods(response) {
  let paymentMethods = new OrderedMap();
  response.forEach((paymentMethod) => {
    paymentMethods = paymentMethods.set(paymentMethod.id, new PaymentMethod(paymentMethod));
  });

  return sortPaymentMethods(paymentMethods);
}

export function hasBusinessProfile(paymentMethods) {
  return paymentMethods.reduce((v, p) => (v || p.type === BUSINESS_PROFILE_TYPE), false);
}

export function hasBusinessMethod(paymentMethods) {
  return paymentMethods.reduce((v, p) => (
    v || p.type === BUSINESS_PROFILE_TYPE || p.type === BUSINESS_ACCOUNT_TYPE
  ), false);
}

export function defaultPaymentMethod(paymentMethods) {
  return paymentMethods.filter(p => p.isDefault).first();
}

export function getPaymentMethodType(existingCard) {
  if (!existingCard) { return null; }
  return existingCard.get('cardType').toLowerCase();
}
