// External
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import moment from 'moment-timezone';

import JsonLD from 'components/common/json-ld';
import EventSchema from 'models/schema.org/event';
import EventModel from 'models/event';
import Venue from 'models/venue';
import Search from 'models/search';

const propTypes = {
  event: PropTypes.instanceOf(EventModel).isRequired,
  venue: PropTypes.instanceOf(Venue).isRequired,
  currentSearch: PropTypes.instanceOf(Search).isRequired,
  timezone: PropTypes.string,
};

const defaultProps = {
  timezone: '',
  onClick: () => {},
};

class Event extends Component {
  get search() {
    const { event, venue } = this.props;
    const startTime = moment(event.startTime).subtract(1, 'hour');
    const endTime = moment(event.endTime).add(1, 'hour');

    return this.props.currentSearch.merge({
      parkingType: 'Event',
      destination: event,
      destinationType: 'event',
      startTime,
      defaultStartTime: startTime,
      endTime,
      defaultEndTime: endTime,
      anchorLat: venue.lat,
      anchorLng: venue.lng,
      lat: venue.lat,
      lng: venue.lng,
    });
  }

  renderEventLink() {
    const { event } = this.props;

    if (event.available) {
      return (
        <div className="event-parking text-color-green">
          Find parking
        </div>
      );
    }
    return (
      <div className="event-parking">
        SOLD OUT
      </div>
    );
  }

  render() {
    const { event, venue, timezone } = this.props;

    if (!event) { return null; }

    const time = timezone ? moment(event.startTime || event.get('startTime')).tz(timezone) : event.startTime;
    const eventClasses = cx({
      'event-item': true,
      'col-md-6': true,
      'col-xs-12': true,
      'disabled': !event.available,
    });

    return (
      <div className={eventClasses}>
        <Link
          to={{
            pathname: event.url,
            search: '?view=list',
            state: {
              app: {
                name: 'Search',
              },
              search: {
                currentSearch: this.search.toJSON(),
                event: JSON.stringify(event.toJSON()),
                venue: JSON.stringify(venue.toJSON()),
                displayMap: false,
              },
            },
          }}
        >
          <div className="row">
            <div className="col-lg-2 col-xs-3 padding-right-0-xxs">
              <div className="event-date">
                <div className="event-day">{time.format('D')}</div>
                <div className="event-month">{time.format('MMM')}</div>
              </div>
            </div>
            <div className="event-details col-lg-10 col-xs-9">
              <div className="event-time">@ {time.format('h:mmA')}</div>
              <div className="event-name">{event.name}</div>
              { this.renderEventLink() }
            </div>
            <JsonLD schema={new EventSchema({ event, venue })} />
          </div>
        </Link>
      </div>
    );
  }
}

Event.propTypes = propTypes;
Event.defaultProps = defaultProps;

export default Event;
