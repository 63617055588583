export const SUBMIT_CHECKOUT = 'submit-checkout';
const submitCheckout = ({ paymentMethodNonce, deviceData, savedPaymentToken, expenseMemo }) => ({
  type: SUBMIT_CHECKOUT,
  payload: {
    paymentMethodNonce,
    deviceData,
    savedPaymentToken,
    expenseMemo,
  },
});
export default submitCheckout;
