import { Record } from 'immutable';
import get from 'lodash/get';

export default class Announcement extends Record({
  showBanner: false,
  bannerTitle: null,
  bannerBody: null,
  bannerLinkURL: null,
  bannerLinkText: null,
}) {
  constructor(props) {
    if (!get(props, 'show_banner', false)) {
      super();
      return;
    }

    const {
      show_banner: showBanner,
      banner_title: bannerTitle,
      banner_body: bannerBody,
      banner_link_url: bannerLinkURL,
    } = props;

    let {
      banner_link_text: bannerLinkText,
    } = props;

    if (bannerLinkURL && !bannerLinkText) {
      bannerLinkText = 'Learn More';
    }

    super({
      showBanner,
      bannerTitle,
      bannerBody,
      bannerLinkURL,
      bannerLinkText,
    });
  }
}
