import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const propTypes = {
  filter: PropTypes.string,
  currentFilter: PropTypes.string,
  onFilter: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.string,
  ]).isRequired,
  xsCols: PropTypes.number,
};

const defaultProps = {
  filter: null,
  currentFilter: null,
  onFilter: () => {},
  xsCols: 6,
};

class FilterOption extends Component {
  constructor(props) {
    super(props);

    this.onFilter = this.onFilter.bind(this);
  }

  onFilter() {
    const { filter, onFilter } = this.props;
    onFilter({ filter });
  }

  render() {
    const { children, filter, currentFilter, xsCols } = this.props;

    const classNames = cx({
      'col-md-2': true,
      [`col-xs-${xsCols}`]: true,
      'h5-sg': true,
      'text-center': true,
      'clickable': filter !== currentFilter,
      'text-color-dark-slate': filter === currentFilter,
    });

    return (
      <div className={classNames} onClick={this.onFilter}>
        {children}
      </div>
    );
  }
}

FilterOption.propTypes = propTypes;
FilterOption.defaultProps = defaultProps;

export default FilterOption;
