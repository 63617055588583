import { Map, Record } from 'immutable';

export default class HubDestination extends Record({
  name: null,
  linkUrl: null,
  bpLinkUrl: null,
  hubId: null,
  modified: null,
  uid: null,
  created: null,
  id: null,
  imageUrl: null,
  category: null,
}) {
  constructor(props) {
    if (!props) {
      super();
      return;
    }
    super({
      name: props.name,
      linkUrl: props.link_url,
      bpLinkUrl: props.bp_link_url,
      hubId: props.hub_id,
      modified: props.modified,
      uid: props._uid,
      created: props.created,
      id: props._id,
      imageUrl: props.image_url,
      category: props.category && props.category.toLowerCase(),
    });
  }
}

export function HubDestinations(response) {
  if (response) {
    if (Array.isArray(response)) {
      return Map(response.map((hubDestination, index) => {
        const newHubDestination = new HubDestination(hubDestination);
        return [index, newHubDestination];
      }));
    } else if (typeof(response) === 'object') {
      return Map(Object.keys(response).map((key) => {
        const newHubDestination = new HubDestination(response[key]);
        return [key, newHubDestination];
      }));
    }
  }

  return Map();
}
