import React, { createContext, Component } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';

const ExperimentsContext = createContext({
  mounted: false,
});

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
};

class ExperimentsProvider extends Component {
  static getExperimentsFromInsights(insights) {
    const displayCustomerIntercept = get(insights, ['experiments', 'customer-intercept'], 'hide') === 'display';
    const limitEventZoomToFirstTen = get(insights, ['experiments', 'limit-event-zoom-to-ten'], 'control') === 'experimental';
    const isFullTransparency = get(insights, ['experiments', 'web-fee-transparency'], 'Stealth-increase') === 'Full-transparency';
    const isCheckoutSurprise = get(insights, ['experiments', 'web-fee-transparency'], 'Stealth-increase') === 'Checkout-surprise';

    return {
      displayCustomerIntercept,
      limitEventZoomToFirstTen,
      isFullTransparency,
      isCheckoutSurprise,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const state = ExperimentsProvider.getExperimentsFromInsights(nextProps.insights);
    if (!isEqual(state, prevState)) {
      return state;
    }
    return null;
  }

  constructor(props) {
    super(props);
    this.state = ExperimentsProvider.getExperimentsFromInsights(props.insights);
  }

  render() {
    return (
      <ExperimentsContext.Provider value={this.state}>
        {this.props.children}
      </ExperimentsContext.Provider>
    );
  }
}
ExperimentsProvider.propTypes = {
  ...propTypes,
  displayCustomerIntercept: PropTypes.bool,
  limitEventZoomToFirstTen: PropTypes.bool,
  isFullTransparency: PropTypes.bool,
  isCheckoutSurprise: PropTypes.bool,
};
ExperimentsProvider.defaultProps = {
  displayCustomerIntercept: false,
  limitEventZoomToFirstTen: false,
  isFullTransparency: false,
  isCheckoutSurprise: false,
};

const ExperimentsConsumer = (props) => {
  const { children } = props;

  return (
    <ExperimentsContext.Consumer>
      {({
        displayCustomerIntercept,
        limitEventZoomToFirstTen,
        isFullTransparency,
        isCheckoutSurprise,
      } = {
        displayCustomerIntercept: false,
        limitEventZoomToFirstTen: false,
        isFullTransparency: false,
        isCheckoutSurprise: false,
      }) => (
        React.Children.map(children, child => React.cloneElement(child, {
          displayCustomerIntercept,
          limitEventZoomToFirstTen,
          isFullTransparency,
          isCheckoutSurprise,
          ...props,
        }))
      )}
    </ExperimentsContext.Consumer>
  );
};
ExperimentsConsumer.propTypes = propTypes;

export { ExperimentsProvider, ExperimentsConsumer };
export default ExperimentsContext;
