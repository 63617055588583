import React from 'react';

const ParkingNotAvailable = () => (
  <p className="text-color-dark-slate text-size-16 text-weight-book" key="parking-not-available">
    Parking is not available for that time. Change your time or select a different parking facility.
  </p>
);

export default ParkingNotAvailable;

