import { Record } from 'immutable';

import RequestQueue from 'lib/api/request-queue';

import { ADD_CALLBACK_TO_REQUEST_QUEUE } from 'action-creators/request-queue/add-callback-to-request-queue';
import { UPDATE_REQUEST_QUEUE_LENGTH } from 'action-creators/request-queue/update-request-queue-length';

export class RequestsState extends Record({
  requestQueueLength: 0,
  requestQueue: new RequestQueue(),
}) {}

export default function requestsReducer(state = new RequestsState(), action) {
  switch (action.type) {
    case ADD_CALLBACK_TO_REQUEST_QUEUE: {
      const { callback } = action.payload;
      const { requestQueue } = state;
      requestQueue.addCallback(callback);

      return state.merge({
        requestQueue,
      });
    }
    case UPDATE_REQUEST_QUEUE_LENGTH: {
      return state.merge({
        requestQueueLength: state.requestQueue.length('fg'),
      });
    }
    default: {
      return state;
    }
  }
}
