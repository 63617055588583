import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';

import DatePicker from 'components/common/date-picker';

const propTypes = {
  monthlyStart: PropTypes.instanceOf(moment).isRequired,
  changeMonthlyStart: PropTypes.func.isRequired,
};

class MonthlyPicker extends Component {
  constructor(props) {
    super(props);

    this.updateMonthlyStart = this.updateMonthlyStart.bind(this);
  }

  updateMonthlyStart(monthlyStart) {
    this.props.changeMonthlyStart({ monthlyStart });
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-xs-12 text-center text-color-dark-slate text-size-14 with-bottom-margin">
            Select Start Date
          </div>
        </div>
        <div className="row clean-calendar">
          <div className="picker-date-wrapper">
            <DatePicker
              date={this.props.monthlyStart}
              minDate={moment().startOf('day')}
              onDateChange={this.updateMonthlyStart}
            />
          </div>
        </div>
      </div>
    );
  }
}

MonthlyPicker.propTypes = propTypes;
export default MonthlyPicker;
