
export const SET_DEFAULT_VEHICLE = 'set-default-vehicle';

export default function setDefaultVehicle(vehicleId) {
  return {
    type: SET_DEFAULT_VEHICLE,
    payload: {
      defaultVehicleId: vehicleId,
    },
  };
}
