import { Record, List, Map, OrderedMap, Set } from 'immutable';
import moment from 'moment-timezone';
import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';

import { Locations, sortLocations } from 'models/locations';
import Search, { VENUE_TYPE, EVENT_TYPE } from 'models/search';
import Event, { Events } from 'models/event';
import Venue from 'models/venue';
import Hub from 'models/hub';
import { Sellers } from 'models/seller';
import Coordinates from 'models/coordinates';
import Brand from 'models/brand';
import { StreetParkingSegments } from 'models/street-parking-segment';

import * as AppContext from 'lib/app-context';
import * as RoutingStyle from 'lib/routing-style';

import { HIGHLIGHT_LOCATION } from 'action-creators/search/highlight-location';
import { SET_SELECTED_LOCATION } from 'action-creators/search/set-selected-location';
import { SET_LOCATION } from 'action-creators/search/set-location';
import { GET_SELECTED_LOCATION } from 'action-creators/search/get-selected-location';
import { GOT_QUOTES } from 'action-creators/search/got-quotes';
import { CHANGE_USER_INPUT } from 'action-creators/search/change-user-input';
import { CHANGE_SORT } from 'action-creators/search/change-sort';
import { TOGGLE_MAP } from 'action-creators/search/toggle-map';
import { SET_MAP_REFRESH } from 'action-creators/search/set-map-refresh';
import { SEARCH_BOUNDS_CHANGE } from 'action-creators/search/bounds-change';
import { SEARCH_CHANGE_PARKING_TYPE } from 'action-creators/search/change-parking-type';
import { UPDATE_CURRENT_SEARCH } from 'action-creators/search/update-current-search';
import { UPDATE_SEARCH } from 'action-creators/search/update-search';
import { PARKING_NEAR_ME_SEARCH } from 'action-creators/search/parking-near-me-search';
import { DISMISS_TIME_PICKER_PROMPT } from 'action-creators/search/dismiss-time-picker-prompt';
import { DISMISS_CURATION } from 'action-creators/search/dismiss-curation';
import { SHOW_CURATION } from 'action-creators/search/show-curation';
import { SELECT_QUOTE } from 'action-creators/search/select-quote';
import { INITIALIZE_SEARCH } from 'action-creators/search/initialize-search';
import { INITIALIZE_VENUE } from 'action-creators/search/initialize-venue';
import { INITIALIZE_HUB } from 'action-creators/search/initialize-hub';
import { INITIALIZE_CHECKOUT } from 'action-creators/checkout/initialize-checkout';
import { CLEANUP_SEARCH_STATE } from 'action-creators/search/cleanup-search-state';
import { CLEANUP_VENUE_STATE } from 'action-creators/search/cleanup-venue-state';
import { CLEANUP_HUB_STATE } from 'action-creators/search/cleanup-hub-state';
import { ON_POP_STATE } from 'action-creators/router/on-pop-state';
import { GOT_VENUE } from 'action-creators/search/got-venue';
import { GOT_VENUE_EVENTS } from 'action-creators/search/got-venue-events';
import { GOT_EVENT } from 'action-creators/search/got-event';
import { GOT_HUB } from 'action-creators/search/got-hub';
import { RESET_CURRENT_SEARCH } from 'action-creators/search/reset-current-search';
import { REVERT_SEARCH } from 'action-creators/search/revert-search';
import { GOT_SELECTED_QUOTE } from 'action-creators/search/got-selected-quote';
import { GOT_LOCATION_SELLER } from 'action-creators/search/got-location-seller';
import { SET_CURRENT_SEARCH_AND_CHANGE_BOUNDS } from 'action-creators/search/set-current-search-and-change-bounds';
import { GOT_NEARBY_METROS } from 'action-creators/search/got-nearby-metros';
import { SET_CURRENT_SEARCH } from 'action-creators/search/set-current-search';
import { CLEAR_LOCATIONS } from 'action-creators/search/clear-locations';
import { SCROLL_TO_LOCATION } from 'action-creators/search/scroll-to-location';
import { UNSET_SCROLLED_LOCATION } from 'action-creators/search/unset-scrolled-location';
import { GOT_STREET_PARKING } from 'action-creators/search/got-street-parking';
import { TOGGLE_STREET_PARKING } from 'action-creators/search/toggle-street-parking';
import { DISMISS_RESTRICTIONS_MODAL } from 'action-creators/search/dismiss-restrictions-modal';
import { SEARCH_MODIFY_REQUIRED_FIELD_LIST } from 'action-creators/search/modify-required-field-list';
import { SEARCH_SET_REQUIRED_FIELD_ERRORS } from 'action-creators/search/set-required-field-errors';

export class SearchState extends Record({
  brand: new Brand(),
  curationDismissed: false,
  currentSearch: new Search(),
  destination: null,
  displayMap: false,
  endTime: moment(),
  error: Map(),
  event: null,
  eventListings: List(),
  eventName: null,
  eventPackageId: null,
  eventPackageIds: List(),
  events: Events(),
  forceMapRefresh: false,
  geoIPLocation: new Coordinates(),
  highlightedLocation: null,
  hub: null,
  hubInitialized: false,
  initialized: false,
  locations: Locations(),
  monthlyAvailable: false,
  nearbyMetros: new List(),
  nearestMetro: null,
  packages: List(),
  // previousSearch should ONLY be updated in GOT_QUOTES
  // changing it anywhere else will break the logic around
  // how to handle receipt of new quotes
  previousSearch: new Search(),
  requestingLocation: false,
  requiredFieldErrors: OrderedMap(),
  requiredFields: Set(),
  restrictionsModalDismissals: Set(),
  scrolledLocation: null,
  searchRequestId: null,
  searchText: null,
  selectedLocation: null,
  selectedQuote: null,
  sellers: Sellers(),
  showStreetParking: false,
  startTime: moment(),
  streetParking: StreetParkingSegments(),
  timePickerPromptDismissed: false,
  timezone: 'America/Chicago',
  transientListings: List(),
  venue: null,
  venueId: null,
  venueInitialized: false,
}) {
  constructor(props) {
    if (!props) {
      super();
      return;
    }

    const {
      previousSearch = {},
      events = null,
      sellers = {},
      geoIPLocation = null,
      curationDismissed = false,
      appContext,
      eventPackageId,
      eventName,
      displayMap,
      brand,
      searchRequestId,
      directAffiliateTraffic,
      searchText,
      locale,
    } = props || {};

    let {
      selectedLocation = null,
      selectedQuote = null,
      locations = null,
      hub = null,
      event = null,
      venue = null,
      eventPackageIds,
      timePickerPromptDismissed = false,
      restrictionsModalDismissals = [],
    } = props || {};

    let { currentSearch = {} } = props || {};
    const timezone = props.timezone || currentSearch.timezone || 'America/Chicago';

    currentSearch.startTime = moment.tz(currentSearch.startTime, timezone);
    currentSearch.endTime = moment.tz(currentSearch.endTime, timezone);
    currentSearch.defaultStartTime = moment.tz(currentSearch.defaultStartTime, timezone);
    currentSearch.defaultEndTime = moment.tz(currentSearch.defaultEndTime, timezone);

    const prevTimezone = (previousSearch && previousSearch.timezone) || timezone;
    previousSearch.startTime = moment.tz(previousSearch.startTime, prevTimezone);
    previousSearch.endTime = moment.tz(previousSearch.endTime, prevTimezone);
    previousSearch.defaultStartTime = moment.tz(previousSearch.defaultStartTime, prevTimezone);
    previousSearch.defaultEndTime = moment.tz(previousSearch.defaultEndTime, prevTimezone);

    hub = hub && new Hub(hub);
    venue = venue && Venue.tryConstruct(venue).venue;
    event = event && new Event(event);

    if (event) {
      currentSearch.destination = event;
      currentSearch.destinationType = 'event';
      previousSearch.destination = event;
      previousSearch.destinationType = 'event';
    } else if (venue) {
      currentSearch.destination = venue;
      currentSearch.destinationType = 'venue';
      previousSearch.destination = venue;
      previousSearch.destinationType = 'venue';
    } else if (hub) {
      currentSearch.destination = hub;
      currentSearch.destinationType = 'hub';
      previousSearch.destination = hub;
      previousSearch.destinationType = 'hub';
    } else if (props.search_term) {
      currentSearch.destination = props.search_term;
      currentSearch.destinationType = 'keyword';
      previousSearch.destination = props.search_term;
      previousSearch.destinationType = 'keyword';
    } else {
      currentSearch.destination = '';
      previousSearch.destination = '';
    }

    const { non_bookable_rules: nonBookableRules } = brand || {};

    locations = Locations(locations, { locale, nonBookableRules });
    if (currentSearch.selectedLocationId && locations.size > 0) {
      selectedLocation = locations.get(currentSearch.selectedLocationId.toString());
      if (
        !currentSearch.selectedQuoteId &&
        selectedLocation &&
        !selectedLocation.hasMultipleQuotes &&
        selectedLocation.isAvailable
      ) {
        selectedQuote = selectedLocation.getQuote();
        currentSearch.selectedQuoteId = selectedQuote.id;
      } else if (currentSearch.selectedQuoteId) {
        selectedQuote = selectedLocation.getQuoteById(currentSearch.selectedQuoteId);
      }
    }

    currentSearch.mobileOnly = appContext === AppContext.MOBILE;
    previousSearch.mobileOnly = appContext === AppContext.MOBILE;

    currentSearch = new Search(currentSearch);

    let nearbyMetros = List();
    if (props.nearbyMetros) {
      nearbyMetros = List(props.nearbyMetros.map(m => (new Hub({
        ...m,
        url: brand.routing_style === RoutingStyle.BESTPARKING ? `/${m.site_url}/` : `/p/${m.site_url}/map/`,
      }))));
    }

    eventPackageIds = List(eventPackageIds);

    // If the user is a widget customer, hide the time picker prompt.
    // Rather than complicate the time picker component with this logic
    // we're piggybacking on dismissal.
    timePickerPromptDismissed = timePickerPromptDismissed || directAffiliateTraffic;
    restrictionsModalDismissals = Set(restrictionsModalDismissals);

    super({
      locations,
      currentSearch: new Search(currentSearch),
      previousSearch: new Search(previousSearch),
      highlightedLocation: selectedLocation,
      selectedLocation,
      selectedQuote,
      packages: props.packages,
      monthlyAvailable: (venue ? venue.monthlyAvailable : props.monthlyAvailable),
      destination: props.destination,
      startTime: currentSearch.startTime,
      endTime: currentSearch.endTime,
      timezone,
      event,
      events: Events(events, timezone),
      eventName,
      venue,
      venueId: (venue && venue.id),
      hub,
      sellers: Sellers(sellers),
      geoIPLocation: new Coordinates(geoIPLocation),
      timePickerPromptDismissed: (timePickerPromptDismissed === true || timePickerPromptDismissed === 'true'),
      curationDismissed: !!curationDismissed,
      nearbyMetros,
      eventPackageIds,
      eventPackageId,
      displayMap,
      searchRequestId,
      searchText,
      restrictionsModalDismissals,
    });
  }
}

export default function searchReducer(state = new SearchState(), action = null) {
  switch (action.type) {
    case HIGHLIGHT_LOCATION: {
      const highlightedLocation = state.locations.get(action.payload.locationId);
      return state.merge({
        highlightedLocation,
      });
    }
    case SCROLL_TO_LOCATION: {
      const scrolledLocation = state.locations.get(action.payload.locationId);
      return state.merge({
        scrolledLocation,
      });
    }
    case UNSET_SCROLLED_LOCATION: {
      return state.merge({
        scrolledLocation: null,
      });
    }
    case SET_LOCATION:
    case SET_SELECTED_LOCATION: {
      const { selectedLocation, selectedLocationId, displayMap = state.displayMap } = action.payload;
      let { selectedQuote, selectedQuoteId } = action.payload;
      if (!selectedQuote && selectedLocation) { selectedQuote = selectedLocation.getQuote(); }
      if (!selectedQuoteId && selectedQuote) { selectedQuoteId = selectedQuote.id; }
      const { currentSearch } = state;
      const { eventPackageId } = selectedQuote || {};

      if (
        get(selectedLocation, 'id', null) === get(state.selectedLocation, 'id', null) &&
        get(selectedQuote, 'id', null) === get(state.selectedQuote, 'id', null)
      ) {
        return state;
      }

      return state.merge({
        selectedLocation,
        selectedQuote,
        eventPackageId,
        displayMap,
        currentSearch: currentSearch.merge({ selectedLocationId, selectedQuoteId }),
      });
    }
    case CHANGE_USER_INPUT: {
      const { userInput } = action.payload;
      const { currentSearch } = state;
      return state.merge({
        currentSearch: currentSearch.set('userInput', userInput),
      });
    }
    case CHANGE_SORT: {
      const { sort } = action.payload;
      const { locations, currentSearch } = state;

      return state.merge({
        locations: sortLocations(locations, { sort }),
        currentSearch: currentSearch.set('sort', sort),
      });
    }
    case TOGGLE_MAP:
      return state.merge({
        displayMap: !state.displayMap,
      });
    case GET_SELECTED_LOCATION:
      return state.merge({
        requestingLocation: true,
      });
    case SEARCH_BOUNDS_CHANGE: {
      const { lat, lng, bounds, zoomLevel } = action.payload;
      const { currentSearch } = state;
      return state.merge({
        currentSearch: currentSearch.merge({ lat, lng, bounds, zoomLevel }),
      });
    }
    case SEARCH_CHANGE_PARKING_TYPE: {
      const { clearSelected = true, parkingType } = action.payload;
      const { currentSearch } = state;

      if (!clearSelected) {
        return state.merge({ currentSearch: currentSearch.merge({ parkingType }) });
      }

      return state.merge({
        currentSearch: currentSearch.merge({ parkingType, selectedLocationId: null, selectedQuoteId: null }),
        selectedLocation: null,
        selectedQuote: null,
      });
    }
    case PARKING_NEAR_ME_SEARCH: {
      const { searchAttributes } = action.payload;
      const { currentSearch } = state;

      return state.merge({
        currentSearch: currentSearch.merge({ searchAttributes }),
      });
    }
    case UPDATE_CURRENT_SEARCH: {
      const { newSearch } = action.payload;
      let { venue, event } = action.payload;
      let { displayMap } = action.payload;
      if (isUndefined(displayMap)) {
        ({ displayMap } = state);
      }

      if (!venue && newSearch.destinationType === VENUE_TYPE) {
        venue = newSearch.destination;
      }

      if (!event && newSearch.destinationType === EVENT_TYPE) {
        event = newSearch.destination;
        venue = state.venue;
      }

      let { selectedLocation, selectedQuote } = state;
      if (!newSearch.selectedLocationId || !newSearch.selectedQuoteId) {
        [selectedLocation, selectedQuote] = [null, null];
      }
      return state.merge({
        currentSearch: newSearch,
        selectedLocation,
        selectedQuote,
        event,
        venue,
        displayMap,
      });
    }
    case REVERT_SEARCH: {
      const { currentSearch } = action.payload;
      return state.merge({
        currentSearch,
      });
    }
    case UPDATE_SEARCH: {
      const {
        currentSearch,
        hub = null,
        event = null,
        venue = null,
        events,
        clearLocations,
        monthlyAvailable,
        timezone,
        retainSelection,
      } = action.payload;

      let {
        selectedLocation,
        selectedQuote,
      } = action.payload;

      if (isUndefined(selectedLocation) || retainSelection) {
        ({ selectedLocation } = state);
      }

      if (isUndefined(selectedQuote) || retainSelection) {
        ({ selectedQuote } = state);
      }

      const locations = clearLocations ? Locations() : state.locations;

      return state.merge({
        currentSearch,
        monthlyAvailable,
        hub,
        event,
        venue,
        events: events || Events(),
        selectedLocation,
        selectedQuote,
        locations,
        timezone: timezone || state.timezone,
      });
    }
    // REMINDER: this is the only place that previousSearch should be updated
    // It should ONLY be updated to match currentSearch on quote receipt
    case GOT_QUOTES: {
      const {
        locations,
        selectedLocation,
        selectedQuote,
        currentSearch,
        searchRequestId,
      } = action.payload;

      return state.merge({
        locations,
        selectedLocation,
        selectedQuote,
        currentSearch,
        previousSearch: currentSearch,
        searchRequestId,
      });
    }
    case GOT_SELECTED_QUOTE: {
      const { selectedLocation, selectedQuote } = action.payload;
      let { locations, currentSearch } = state;
      locations = locations.set(selectedLocation.id, selectedLocation);
      currentSearch = currentSearch.merge({
        selectedLocationId: selectedLocation.id,
        selectedQuoteId: selectedQuote.id,
      });

      return state.merge({
        currentSearch,
        locations,
        selectedLocation,
        selectedQuote,
      });
    }
    case SET_MAP_REFRESH: {
      const { forceMapRefresh } = action.payload;

      return state.merge({
        forceMapRefresh,
      });
    }
    case SELECT_QUOTE: {
      const { quote } = action.payload;
      if (!quote) { return state; }

      const { currentSearch } = state;
      const { locations } = state;
      const selectedLocation = currentSearch.getSelectedLocation(locations);
      const { quotes } = selectedLocation;
      // Bail if re-selecting current quote or a quote not belonging to the current location
      if (currentSearch.getSelectedQuote(locations) === quote || !quotes.find(q => (q === quote))) {
        return state;
      }

      return state.merge({
        currentSearch: currentSearch.set('selectedQuoteId', quote.id),
        previousSearch: currentSearch,
      });
    }
    case DISMISS_TIME_PICKER_PROMPT:
      return state.merge({
        timePickerPromptDismissed: true,
      });
    case DISMISS_CURATION:
      return state.merge({
        curationDismissed: true,
      });
    case SHOW_CURATION:
      return state.merge({
        curationDismissed: false,
      });
    case INITIALIZE_SEARCH:
      return state.merge({
        initialized: true,
      });
    case INITIALIZE_VENUE:
      return state.merge({
        initialized: false,
        venueInitialized: true,
      });
    case INITIALIZE_HUB:
      return state.merge({
        initialized: false,
        hubInitialized: true,
      });
    case CLEANUP_SEARCH_STATE:
      return state.merge({
        initialized: false,
      });
    case CLEANUP_VENUE_STATE:
      return state.merge({
        venueInitialized: false,
      });
    case CLEANUP_HUB_STATE:
      return state.merge({
        hubInitialized: false,
      });
    case GOT_VENUE: {
      const { venue } = action.payload;
      return state.merge({
        venue,
      });
    }
    case GOT_VENUE_EVENTS: {
      const { events } = action.payload;
      return state.merge({
        events,
      });
    }
    case GOT_EVENT: {
      const { event } = action.payload;
      return state.merge({
        event,
      });
    }
    case GOT_HUB: {
      const { hub } = action.payload;
      return state.merge({
        hub,
      });
    }
    case GOT_NEARBY_METROS: {
      const { nearbyMetros } = action.payload;
      return state.merge({ nearbyMetros });
    }
    case ON_POP_STATE: {
      const { historyState } = action.payload;
      if (historyState && historyState.search && historyState.search.currentSearch) {
        const currentSearch = new Search(JSON.parse(historyState.search.currentSearch));
        const event = historyState.search.event && new Event(JSON.parse(historyState.search.event));
        const venue = historyState.search.venue && new Venue(JSON.parse(historyState.search.venue));

        return state.merge({
          currentSearch,
          event,
          venue,
          displayMap: !!historyState.search.displayMap,
        });
      }

      return state;
    }
    case INITIALIZE_CHECKOUT: {
      const { locationId, quoteId } = action.payload;
      if (get(state, ['selectedQuote', 'id'], null) === quoteId) { return state; }

      const selectedLocation = state.locations.get(locationId);
      const selectedQuote = selectedLocation ? selectedLocation.getQuoteById(quoteId) : null;

      return state.merge({
        selectedLocation,
        selectedQuote,
      });
    }
    case RESET_CURRENT_SEARCH: {
      const {
        currentSearch,
        nearbyMetros,
        monthlyAvailable,
        hub,
        venue,
        events,
      } = action.payload;

      return state.merge({
        currentSearch,
        nearbyMetros,
        monthlyAvailable,
        hub,
        venue,
        events,
      });
    }
    case GOT_LOCATION_SELLER: {
      const { seller } = action.payload;

      return state.merge({
        sellers: state.sellers.set(seller.id.toString(), seller),
      });
    }
    case SET_CURRENT_SEARCH: {
      const { newSearch } = action.payload;
      let { monthlyAvailable } = state;
      if (!newSearch.isParkingNearMeSearch) {
        ({ monthlyAvailable } = newSearch.destination);
      }
      return state.merge({
        currentSearch: newSearch,
        monthlyAvailable,
      });
    }
    case SET_CURRENT_SEARCH_AND_CHANGE_BOUNDS: {
      const currentSearch = state.currentSearch.merge(action.payload);
      return state.merge({ currentSearch });
    }
    case CLEAR_LOCATIONS:
      return state.merge({ locations: Locations() });
    case GOT_STREET_PARKING: {
      const { body } = action.payload;
      const streetParking = state.streetParking.merge(StreetParkingSegments(body));

      return state.merge({ streetParking });
    }
    case TOGGLE_STREET_PARKING: {
      const { showStreetParking = !state.showStreetParking } = action.payload || {};
      return state.merge({ showStreetParking });
    }
    case DISMISS_RESTRICTIONS_MODAL: {
      const { key } = action.payload;
      let { restrictionsModalDismissals } = state;
      restrictionsModalDismissals = restrictionsModalDismissals.add(key);
      return state.merge({ restrictionsModalDismissals });
    }
    case SEARCH_MODIFY_REQUIRED_FIELD_LIST: {
      const { addedFields, removedFields } = action.payload;
      let requiredFields = state.requiredFields;
      let requiredFieldErrors = state.requiredFieldErrors;
      addedFields.forEach(field => (requiredFields = requiredFields.add(field)));
      removedFields.forEach(field => (requiredFields = requiredFields.remove(field)));
      removedFields.forEach(field => (requiredFieldErrors = requiredFieldErrors.remove(field)));

      return state.merge({
        requiredFields,
        requiredFieldErrors,
      });
    }
    case SEARCH_SET_REQUIRED_FIELD_ERRORS: {
      const { requiredFields } = state;
      const { requiredFieldErrors } = action.payload;
      if (requiredFieldErrors) {
        let errors = Map();
        requiredFields.forEach((f) => { errors = errors.set(f, requiredFieldErrors.get(f)); });

        return state.merge({
          requiredFieldErrors: errors,
        });
      }

      return state;
    }
    default: {
      return state;
    }
  }
}
