import { Record } from 'immutable';

const defaults = {
  type:     null,
  niceType: null,
  code:     null,
  lengths:  null,
}

export default class Card extends Record(defaults) {}
