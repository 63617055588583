import { Record } from 'immutable';
import HostedField from './hosted-field';
import Card from './card';

const defaults = {
  number:          new HostedField({ target: { fieldKey: 'number' }}),
  postalCode:      new HostedField({ target: { fieldKey: 'postalCode' }}),
  cvv:             new HostedField({ target: { fieldKey: 'cvv' }}),
  expirationMonth: new HostedField({ target: { fieldKey: 'expirationMonth' }}),
  expirationYear:  new HostedField({ target: { fieldKey: 'expirationYear' }}),
  card:            new Card()
}

export default class NewPaymentMethod extends Record(defaults) {
  constructor(props) {
    if (!props) {
      super();
      return;
    }

    const propsObj = props.toJS() ? props.toJS() : props;
    const init = Object.assign(propsObj, { card: new Card(propsObj.card) });
    if (props.target && props.target.fieldKey) {
      init[props.target.fieldKey] = new HostedField(props);
    }

    super(init);
  }

  get isSubmittable() {
    return (
      !this.number.isEmpty && this.number.isValid &&
      !this.postalCode.isEmpty && this.postalCode.isValid &&
      !this.cvv.isEmpty && this.cvv.isValid &&
      !this.expirationMonth.isEmpty && this.expirationMonth.isValid &&
      !this.expirationYear.isEmpty && this.expirationYear.isValid
    );
  }
}
