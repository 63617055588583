import React from 'react';
import createReactClass from 'create-react-class';
import Carousel from 'nuka-carousel';
import cx from 'classnames';

import { PREVIOUS_IMAGE_BUTTON_CLICK, NEXT_IMAGE_BUTTON_CLICK } from 'lib/analytics/events';
import { imageCdnAsset } from 'lib/common/url-helpers';

export default class SimpleCarousel extends React.Component {
  constructor(props) {
    super(props);

    this.trackNextArrow = this.trackNextArrow.bind(this);
    this.trackPreviousArrow = this.trackPreviousArrow.bind(this);
  }

  trackPreviousArrow() {
    const { trackEvent } = this.props;
    trackEvent(PREVIOUS_IMAGE_BUTTON_CLICK);
  }

  trackNextArrow() {
    const { trackEvent } = this.props;
    trackEvent(NEXT_IMAGE_BUTTON_CLICK);
  }

  previousSlideArrow() {
    const { trackPreviousArrow } = this;
    return (
      {
        component: createReactClass({
          render() {
            if (this.props.slideCount > 1) {
              return (
                <span
                  className="iconified-font iconified-thin-chevron-left text-size-42 text-color-white text-shadow-light"
                  onClick={() => {
                    this.props.previousSlide();
                    trackPreviousArrow();
                  }}
                />
              );
            }
            return null;
          },
        }),
        position: 'CenterLeft',
        style: {
          padding: 20,
        },
      }
    );
  }

  nextSlideArrow() {
    const { trackNextArrow } = this;
    return (
      {
        component: createReactClass({
          render() {
            if (this.props.slideCount > 1) {
              return (
                <span
                  className="iconified-font iconified-thin-chevron-right text-size-42 text-color-white text-shadow-light"
                  onClick={() => {
                    this.props.nextSlide();
                    trackNextArrow();
                  }}
                />
              );
            }
            return null;
          },
        }),
        position: 'CenterRight',
        style: {
          padding: 20,
        },
      }
    );
  }

  slideProgress() {
    return (
      {
        component: createReactClass({
          render() {
            if (this.props.slideCount > 1) {
              return (
                <div className="gallery-data">
                  <img src={imageCdnAsset('/images/svg/picture-icon.svg')} alt="" />
                  {this.props.currentSlide + 1}/{this.props.slideCount}
                </div>
              );
            }
            return null;
          },
        }),
        position: 'BottomCenter',
        style: {
          padding: 20,
        },
      }
    );
  }

  messageTag(message) {
    return (
      {
        component: createReactClass({
          render() {
            const classes = cx({
              'floating-tag': true,
              'wrap-ellipses': true,
              'hidden': !message,
            });
            return (
              <div className={classes}>
                {message}
              </div>
            );
          },
        }),
        position: 'TopRight',
        style: {
          maxWidth: '90%',
        },
      }
    );
  }

  renderCarouselStyles(height) {
    return (
      { __html: '\
        .slider-list { height: ' + height + 'px !important; }\
        .slider-slide { height: 100% !important; }\
        .slider-slide img { max-height: ' + height + 'px !important; object-fit: cover !important; height: 100% !important; }\
      ' }
    );
  }

  render() {
    const decorators = [
      this.previousSlideArrow(),
      this.nextSlideArrow(),
      this.slideProgress(),
      this.messageTag(this.props.message),
    ];

    const height = Math.min(this.props.height, this.props.maxHeight);

    if (this.props.urls) {
      return (
        <div>
          <style dangerouslySetInnerHTML={this.renderCarouselStyles(height)} />
          <Carousel
            className="image-gallery"
            decorators={decorators}
            dragging={this.props.urls.length > 1}
            slideIndex={this.props.slide}
            wrapAround
            initialSlideHeight={height}
            initialSlideWidth={this.props.width}
          >
            {this.props.urls.map((url, i) => (<img key={i} src={url} alt="" />))}
          </Carousel>
        </div>
      );
    }
    return null;
  }
}
