import React from 'react';
import PropTypes from 'prop-types';

import ValidationModel from 'models/validation';

import ValidationStep from 'containers/common/validation-step';

const propTypes = {
  validation: PropTypes.instanceOf(ValidationModel),
  className: PropTypes.string,
};

const defaultProps = {
  validation: null,
  className: '',
};

const ValidationSteps = ({ validation, className }) => {
  if (!(validation && validation.steps)) {
    return null;
  }

  const validationSteps = validation.steps.map((step, index) => <ValidationStep classNames={className} step={step} index={index} key={`validation-step-${index}`} />);

  return (
    <div className={`validation-steps ${className}`}>{validationSteps}</div>
  );
};

ValidationSteps.propTypes = propTypes;
ValidationSteps.defaultProps = defaultProps;

export default ValidationSteps;

