import { Record, OrderedMap, Map } from 'immutable';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';

import User from 'models/user';
import { Vehicles } from 'models/vehicles';

import { ACTIVATE_ACCOUNT } from 'action-creators/account/activate-account';
import { ADD_ERRORS } from 'action-creators/account/add-errors';
import { BOOKING_CANCELLED } from 'action-creators/bookings/booking-cancelled';
import { CHANGE_USER_INFO } from 'action-creators/account/change-user-info';
import { CHECK_SIGNIN_METHOD } from "action-creators/account/check-signin-method";
import { CLEANUP_ENTERPRISE_INVITE } from 'action-creators/account/cleanup-enterprise-invite';
import { CLEANUP_PASSWORD_RECOVERY_STATE } from 'action-creators/account/cleanup-password-recovery-state';
import { CLEANUP_SIGN_IN_STATE } from 'action-creators/account/cleanup-sign-in-state';
import { CLEANUP_SIGN_UP_STATE } from 'action-creators/account/cleanup-sign-up-state';
import { DELETED_VEHICLE } from 'action-creators/vehicles/deleted-vehicle';
import { DISCONNECT_GOOGLE } from 'action-creators/account/disconnect-google';
import { DISMISS_ERROR } from 'action-creators/account/dismiss-error';
import { GET_VEHICLES } from 'action-creators/account/get-vehicles';
import { GOT_BOOKING_COUNTS } from 'action-creators/bookings/got-booking-counts';
import { GOT_SESSION } from 'action-creators/account/got-session';
import { GOT_VEHICLE } from 'action-creators/bookings/got-vehicle';
import { GOT_VEHICLES } from 'action-creators/account/got-vehicles';
import { INITIALIZE_NEW_VEHICLE } from 'action-creators/vehicles/initialize-new-vehicle';
import { INITIALIZE_PASSWORD_RECOVERY } from 'action-creators/account/initialize-password-recovery';
import { INITIALIZE_SIGN_IN } from 'action-creators/account/initialize-sign-in';
import { INITIALIZE_SIGN_UP } from 'action-creators/account/initialize-sign-up';
import { SAVED_VEHICLE } from 'action-creators/vehicles/saved-vehicle';
import { SELECT_VEHICLE } from 'action-creators/vehicles/select-vehicle';
import { SET_DEFAULT_VEHICLE } from 'action-creators/vehicles/set-default-vehicle';
import { UNSET_SUBMIT } from 'action-creators/payment-methods/unset-submit';
import { USER_UPDATED } from 'action-creators/account/user-updated';

export class AccountState extends Record({
  accountActivated: false,
  defaultVehicleId: null,
  enterpriseInvite: null,
  errors: Map(),
  passwordRecoveryInitialized: false,
  selectedVehicleId: null,
  signInInitialized: false,
  signInMethod: "",
  signUpFields: Map(),
  signUpInitialized: false,
  submitting: false,
  user: new User(),
  vehicle: null,
  vehicles: OrderedMap(),
}) {
  constructor(props) {
    if (!props) {
      super();
      return;
    }

    const { user } = props;
    if (!user.token && props.token) {
      user.token = props.token;
    }

    const enterpriseInvite = props.enterpriseInvite && Map(props.enterpriseInvite);
    const {
      accountActivated = false,
    } = props || {};

    const defaultVehicle = props.vehicles ? props.vehicles.find(v => get(v, 'default', null)) : null;

    const signUpFields = props.account ? props.account.signUpFields : Map();

    super({
      accountActivated,
      defaultVehicleId: defaultVehicle && defaultVehicle.id,
      enterpriseInvite,
      signUpFields,
      user: new User(props.user),
      vehicles: Vehicles(props.vehicles),
    });
  }
}

export default function accountReducer(state = new AccountState(), action = null) {
  switch (action.type) {
    case ACTIVATE_ACCOUNT:
      return state.merge({
        accountActivated: true,
      });
    case ADD_ERRORS: {
      const { errors } = action.payload;
      return state.merge({
        errors: state.errors.merge(errors),
      });
    }
    case BOOKING_CANCELLED: {
      return state.merge({
        user: state.user.merge({
          bookingCount: state.user.bookingCount - 1,
        }),
      });
    }
    case CHANGE_USER_INFO:
      return state.merge({
        user: state.user.merge(action.payload),
      });
    case CHECK_SIGNIN_METHOD:
      return state.merge({
        signInMethod: action.payload.signInMethod,
      });
    case CLEANUP_ENTERPRISE_INVITE:
      return state.merge({
        enterpriseInvite: null,
      });
    case CLEANUP_PASSWORD_RECOVERY_STATE:
      return state.merge({
        passwordRecoveryInitialized: false,
        errors: Map(),
      });
    case CLEANUP_SIGN_IN_STATE:
      return state.merge({
        signInInitialized: false,
        errors: Map(),
      });
    case CLEANUP_SIGN_UP_STATE:
      return state.merge({
        signUpInitialized: false,
        errors: Map(),
      });
    case DELETED_VEHICLE: {
      const { vehicleId } = action.payload;
      const vehicles = state.vehicles.delete(vehicleId);
      return state.merge({
        vehicles,
      });
    }
    case DISMISS_ERROR: {
      const { error } = action.payload;
      const errors = state.errors.delete(error);
      return state.merge({
        errors,
      });
    }
    case DISCONNECT_GOOGLE: {
      localStorage.removeItem('signInMethod');
      return state.merge({
        signInMethod: "",
      });
    }
    case GOT_VEHICLE: {
      const { vehicles } = state;
      const { vehicle } = action.payload;

      return state.merge({
        vehicles: vehicles.set(vehicle.id.toString(), vehicle),
      });
    }
    case GET_VEHICLES: {
      return state;
    }
    case GOT_BOOKING_COUNTS: {
      const { type, query, count } = action.payload;
      if (isEqual(type, ['bookings']) && isEqual(query, ['upcoming', 'active'])) {
        let { user } = state;
        user = user.set('bookingCount', count);
        return state.merge({ user });
      }

      return state;
    }
    case GOT_SESSION: {
      const { user } = action.payload;
      return state.merge({ user });
    }
    case GOT_VEHICLES: {
      let { vehicles } = action.payload;
      vehicles = state.vehicles.merge(vehicles);
      return state.merge({ vehicles });
    }
    case INITIALIZE_NEW_VEHICLE: {
      const { vehicle } = action.payload;

      return state.merge({
        vehicle,
      });
    }
    case INITIALIZE_PASSWORD_RECOVERY:
      return state.merge({
        passwordRecoveryInitialized: true,
      });
    case INITIALIZE_SIGN_IN:
      return state.merge({
        signInInitialized: true,
      });
    case INITIALIZE_SIGN_UP:
      return state.merge({
        signUpInitialized: true,
      });
    case SAVED_VEHICLE: {
      const { vehicle } = action.payload;
      const vehicles = state.vehicles.set(`${vehicle.id}`, vehicle);
      return state.merge({ vehicles });
    }
    case SELECT_VEHICLE: {
      const { selectedVehicleId } = action.payload;
      const selectedVehicle = state.vehicles.get(selectedVehicleId);
      return state.merge({
        selectedVehicleId,
        vehicle: selectedVehicle,
      });
    }
    case SET_DEFAULT_VEHICLE: {
      const { defaultVehicleId } = action.payload;

      return state.merge({
        defaultVehicleId,
      });
    }
    case UNSET_SUBMIT: {
      return state.merge({
        submitting: false,
      });
    }
    case USER_UPDATED: {
      const user = state.user.merge(action.payload);

      return state.merge({ user });
    }
    default: {
      return state;
    }
  }
}
