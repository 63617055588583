import { List, Record } from 'immutable';
import moment from 'moment-timezone';
import compact from 'lodash/compact';
import isString from 'lodash/isString';

const DEFAULT_DISMISS_TIMEOUT = 20000;

function parseTime(time) {
  if (time) {
    if (parseInt(time, 10)) {
      return moment.unix(time);
    }

    return moment(time);
  }

  return null;
}

export class Message extends Record({
  id: null,
  message: null,
  details: null,
  type: null,
  persistent: false,
  dismissTimeout: DEFAULT_DISMISS_TIMEOUT,
  viewedAt: null,
  createdAt: null,
  intlMessageID: null,
  intlDetailsID: null,
  customMessage: false,
  messageValues: {},
}) {
  constructor(props) {
    if (!props) {
      super();
      return;
    }

    let { id } = props;
    const intlMessageID = `messages.${id}-message`;
    const intlDetailsID = `messages.${id}-details`;
    if (props.id && !props.id.match(/^pw-messages/)) {
      id = `pw-messages-${id}`;
    }

    let { messageValues = {} } = props;
    if (isString(messageValues)) {
      messageValues = JSON.parse(decodeURIComponent(messageValues));
    }

    super({
      id,
      message: decodeURIComponent(props.message),
      details: decodeURIComponent(props.details),
      type: props.type,
      persistent: props.persistent || false,
      dismissTimeout: props.dismissTimeout || DEFAULT_DISMISS_TIMEOUT,
      createdAt: parseTime(props.created_at) || moment(),
      viewedAt: parseTime(props.viewed_at),
      customMessage: !!props.customMessage,
      intlMessageID,
      intlDetailsID,
      messageValues,
    });
  }

  toCookie() {
    return {
      message: encodeURIComponent(this.message),
      details: encodeURIComponent(this.details),
      type: this.type,
      messageValues: encodeURIComponent(JSON.stringify(this.messageValues)),
      persistent: this.persistent,
      dismissTimeout: this.dismissTimeout,
      created_at: this.createdAt ? this.createdAt.unix() : null,
      viewed_at: this.viewedAt ? this.viewedAt.unix() : null,
    };
  }

  setViewedAt(time = null) {
    return this.merge({ viewedAt: time ? moment(time) : moment() });
  }
}

export function sortMessagesByTime(a, b) {
  if (a && b) {
    const aTime = a.createdAt;
    const bTime = b.createdAt;
    if (aTime && bTime) {
      return bTime.diff(aTime);
    }
  }
  return 0;
}

export function sortMessages(messages) {
  return List(messages).sort((a, b) => sortMessagesByTime(a, b));
}

export function Messages(response) {
  let messages = [];
  if (response && Object.keys(response).length) {
    messages = compact(Object.keys(response).map((messageID) => {
      let message;
      try {
        message = JSON.parse(response[messageID]);
      } catch (e) {
        console.warn(`Found message with invalid JSON.  Error: ${e}`);
        return null;
      }
      message.id = messageID;
      return new Message(message);
    }));
  }
  return sortMessages(messages);
}
