// External
import React, { Component } from 'react';
import cx from 'classnames';
import sanitizeHtml from 'sanitize-html';

import { imageCdnAsset, imageCdnAssetSet } from 'lib/common/url-helpers';

import Expand from 'components/svgs/icons/expand';

export default class About extends Component {
  constructor(props) {
    super(props);

    this.toggleDisplay = this.toggleDisplay.bind(this);
    this.showAboutModal = this.showAboutModal.bind(this);

    this.state = { show: true };
  }

  createDescription() {
    return { __html: sanitizeHtml(this.props.venue.description) };
  }

  componentWillMount() {
    const show = !!this.props.venue.description;
    this.setState({
      expanded: false,
      expandable: true,
      show,
    });
  }

  componentDidMount() {
    if (this.state.show) {
      this.setState({ expandable: this.aboutVenueInner.clientHeight > 160 });
    }
  }

  toggleDisplay(e) {
    e.preventDefault();
    this.setState({
      expanded: !this.state.expanded,
    });
  }

  renderExpandLink() {
    if (!this.state || !this.state.expandable) { return null; }

    let text;
    const expandClass = cx({ 'upside-down': this.state.expanded });
    if (this.state && !this.state.expanded) {
      text = `MORE INFO ABOUT ${this.props.venue.name}`;
    } else if (this.state) {
      text = `LESS INFO ABOUT ${this.props.venue.name}`;
    }
    return (
      <p className="toggle-about-venue">
        <a href="#" onClick={this.toggleDisplay}>
          {text}
        &nbsp;
          <Expand className={expandClass} />
        </a>
      </p>
    );
  }

  renderAboutInfo() {
    return <div className="about-venue-inner" ref={(aboutVenueInner) => { this.aboutVenueInner = aboutVenueInner; }} dangerouslySetInnerHTML={this.createDescription()} />;
  }

  renderModalContent() {
    return (
      <div className="container padding-2x">
        <h2 className="uppercase h2 strong capitalize">About {this.props.venue.name}</h2>
        {this.renderAboutInfo()}
      </div>
    );
  }

  showAboutModal(e) {
    e.preventDefault();
    e.stopPropagation();

    const { venue } = this.props;

    this.props.changeModalState({
      displayModal: true,
      body: 'aboutVenue',
      footer: 'generalFooter',
      bodyProps: {
        venue,
        modal: true,
      },
    });
  }

  render() {
    const { venue } = this.props;
    const aboutClasses = cx({
      'about-venue': true,
      'expandable': this.state.expandable,
      'expanded': this.state.expanded,
    });

    if (this.props.modal) { return this.renderModalContent(); }

    if (!this.state.show) { return null; }

    return (
      <div className="about-venue-wrapper">
        <div className="container hidden-lte-sm">
          <div className="row">
            <div className="col-md-7">
              <h2 className="uppercase h2 strong top">About {venue.name}</h2>
              <div className={aboutClasses}>
                {this.renderAboutInfo()}
              </div>
              {this.renderExpandLink()}
            </div>
            <div className="col-md-4 col-md-push-1 vertical-padding-top-2x">
              <picture>
                <source media="(max-width: 991px)" srcSet="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" />
                <img
                  src={imageCdnAsset('/images/three-cars.png')}
                  srcSet={imageCdnAssetSet('/images/three-cars.png 360w, /images/three-cars@2x.png 720w, /images/three-cars@3x.png 1080w')}
                  alt="Three cars"
                />
              </picture>
            </div>
          </div>
        </div>
        <div className="visible-lte-sm uppercase container padding-2x">
          <a className="deco-plus deco-blue deco-top" href="#" onClick={this.showAboutModal}>{`MORE ABOUT ${venue.name}`}</a>
        </div>
      </div>
    );
  }
}
