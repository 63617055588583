import User from 'models/user';
import { GOT_USER } from 'action-creators/payment-methods/got-user';

export default function gotSession(response) {
  const user = response.user;
  user.token = response.token;

  return {
    type: GOT_USER,
    payload: {
      user: new User(user),
    },
  };
}
