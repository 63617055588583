export const DELETED_VEHICLE = 'deleted-vehicle';

const deleteVehicle = ({ vehicleId }) => (
  {
    type: DELETED_VEHICLE,
    payload: { vehicleId },
  }
);

export default deleteVehicle;
