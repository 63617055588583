export const SELECT_PAYMENT_METHOD = 'select-payment-method';

export default function selectPaymentMethod({ paymentMethodId }) {
  return {
    type: SELECT_PAYMENT_METHOD,
    payload: {
      selectedPaymentMethodId: paymentMethodId,
    },
  };
}
