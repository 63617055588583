export const USER_UPDATED = 'user-updated';
const userUpdated = ({
  first_name: firstname,
  last_name: lastname,
  email,
  phone,
}) => ({
  type: USER_UPDATED,
  payload: {
    firstname,
    lastname,
    fullName: `${firstname} ${lastname}`,
    email,
    phone,
  },
});
export default userUpdated;
