import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { List, Map, Set } from 'immutable';
import get from 'lodash/get';
import url from 'url';
import Insights from '@parkwhiz-js/insights-sdk';

import DeepLink from 'models/deep-link';
import Search from 'models/search';
import Booking from 'models/booking';
import Quote from 'models/quote';
import TrackingProperties from 'models/tracking-properties';
import Partner from 'models/partner';
import FeatureFlags from 'models/feature-flags';
import Hub from 'models/hub';
import Venue from 'models/venue';
import Brand from 'models/brand';

import { ExperimentsConsumer } from 'providers/experiments-provider';

import BannerComponent from 'components/deep-links/banner.jsx';

import { SMART_BANNER_IMPRESSION, SMART_BANNER_DISMISS_IMPRESSION } from 'lib/analytics/events';
import * as AppContext from 'lib/app-context';
import { CHECKOUT_APP, PARKING_PASS_APP, VENUE_APP } from 'lib/app-names';
import env from 'lib/env';

import trackEventCreator from 'action-creators/analytics/track-event';
import dismissDeepLinkBanner from 'action-creators/deep-links/dismiss-banner';

export const HOME_BANNER = 'home';
const SEARCH_BANNER = 'search';
const PARKING_PASS_BANNER = 'parking-pass';
const CHECKOUT_BANNER = 'checkout';
const OFFER_COUPON = 'wdneaprg';
const HIDE_DEEP_LINK_BANNER_PARTNERS = Set(['Ford', 'Lincoln', 'Ticketmaster']);
const { FORD_AFFILIATE_ID } = env();

const propTypes = {
  insights: PropTypes.instanceOf(Insights).isRequired,
  display: PropTypes.bool,
  selectedParkingPassIds: PropTypes.instanceOf(List).isRequired,
  subject: PropTypes.oneOfType([
    PropTypes.instanceOf(Map),
    PropTypes.instanceOf(Search),
    PropTypes.object,
    PropTypes.string,
  ]),
  trackingProperties: PropTypes.instanceOf(TrackingProperties),
  couponCode: PropTypes.string,
  portalAffiliateId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  bookingCount: PropTypes.instanceOf(Map),
  app: PropTypes.string.isRequired,
  appContext: PropTypes.string.isRequired,
  hub: PropTypes.instanceOf(Hub),
  venue: PropTypes.instanceOf(Venue),
  currentSearch: PropTypes.instanceOf(Search),
  partner: PropTypes.instanceOf(Partner),
  affiliatePartner: PropTypes.instanceOf(Map),
  featureFlags: PropTypes.instanceOf(FeatureFlags).isRequired,
  bookingCounts: PropTypes.instanceOf(Map),
  trackEvent: PropTypes.func.isRequired,
  brand: PropTypes.instanceOf(Brand).isRequired,
  displayCustomerIntercept: PropTypes.bool,
  affiliateId: PropTypes.string,
};

const defaultProps = {
  display: false,
  displayMap: false,
  selectedLocationId: null,
  subject: null,
  trackingProperties: new TrackingProperties(),
  couponCode: null,
  portalAffiliateId: null,
  bookingCount: Map(),
  partner: null,
  affiliatePartner: null,
  hub: null,
  venue: null,
  currentSearch: null,
  bookingCounts: Map(),
  displayCustomerIntercept: false,
  affiliateId: null,
};

export class DeepLinkBanner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      renderDeepLinkURL: false,
    };
  }

  componentDidMount() {
    // Leave this as a pure state init. This avoids client/server mismatches
    // AND errors around calling setState from componentDidMount.
    // Do NOT move this into componentWillMount, as that runs on the server (you think it shouldn't, but it does)
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({ renderDeepLinkURL: true });
    if (this.displayable && this.props.appContext !== AppContext.DESKTOP) {
      const { bookingCounts } = this.props;
      this.props.trackEvent({
        ...SMART_BANNER_IMPRESSION,
        properties: {
          'Web Purchase Count': bookingCounts.getIn(['web', 'total']),
          'App Purchase Count': bookingCounts.getIn(['app', 'total']),
          'Offer Displayed': this.shouldDisplayOffer() ? '1' : '0',
        },
      });
      this.props.trackEvent({
        ...SMART_BANNER_DISMISS_IMPRESSION,
        properties: {
          'Web Purchase Count': bookingCounts.getIn(['web', 'total']),
          'App Purchase Count': bookingCounts.getIn(['app', 'total']),
          'Offer Displayed': this.shouldDisplayOffer() ? '1' : '0',
        },
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.displayable && this.props.appContext !== AppContext.DESKTOP && this.props.app !== prevProps.app) {
      const { bookingCounts } = this.props;
      this.props.trackEvent({
        ...SMART_BANNER_IMPRESSION,
        properties: {
          'Web Purchase Count': bookingCounts.getIn(['web', 'total']),
          'App Purchase Count': bookingCounts.getIn(['app', 'total']),
          'Offer Displayed': this.shouldDisplayOffer() ? '1' : '0',
        },
      });
    }
  }

  get hiddenForPartnerPass() {
    const { affiliateId, app, partner } = this.props;

    if (app !== PARKING_PASS_APP) {
      return false;
    }

    if (affiliateId === FORD_AFFILIATE_ID) {
      return true;
    }

    const partnerName = get(partner, 'name', null);
    if (HIDE_DEEP_LINK_BANNER_PARTNERS.has(partnerName)) {
      return true;
    }

    return false;
  }


  get displayable() {
    const { display, subject, affiliatePartner, partner, app, featureFlags } = this.props;
    const { hiddenForPartnerPass } = this;
    return (
      display &&
      !hiddenForPartnerPass &&
      subject &&
      this.bannerType &&
      // Hide the banner if there is no affiliate partner to provide an override
      // otherwise Ford and Dan will yell at you
      // Affiliate partner is a pure immutable map, do not use lodash get
      affiliatePartner &&
      affiliatePartner.get('marketingEligible') &&
      // Hide the banner if there is no partner to provide an override
      get(partner, 'marketingEligible', false) &&
      !(app === VENUE_APP && !featureFlags.mobileActivated('venue-deep-link-banner')) &&
      !(app === CHECKOUT_APP && !featureFlags.mobileActivated('checkout-deep-link-banner'))
    );
  }

  shouldDisplayOffer() {
    const { bookingCount, displayCustomerIntercept } = this.props;
    const appBookingCount = bookingCount.getIn(['app', 'total']) || 0;

    return displayCustomerIntercept && appBookingCount === 0;
  }

  coupon() {
    return this.shouldDisplayOffer() ? OFFER_COUPON : this.props.couponCode;
  }

  get bannerType() {
    const { selectedParkingPassIds, subject, featureFlags, app } = this.props;
    const { constructor, destination, quoteType } = subject || {};
    const { name: constructorName } = constructor || {};

    if (subject === HOME_BANNER) {
      return HOME_BANNER;
    } else if (selectedParkingPassIds.size) {
      return PARKING_PASS_BANNER;
    } else if (quoteType || constructorName === 'Quote') {
      if (!featureFlags.mobileActivated('checkout-deep-link-banner')) { return null; }
      return CHECKOUT_BANNER;
    } else if (destination) {
      if (app === VENUE_APP && !featureFlags.mobileActivated('venue-deep-link-banner')) { return null; }
      return SEARCH_BANNER;
    }

    return null;
  }

  deepLink() {
    const {
      app,
      portalAffiliateId,
      trackingProperties,
      brand,
      insights,
      router,
    } = this.props;
    let subject;
    switch (this.bannerType) {
      case PARKING_PASS_BANNER:
        subject = new Booking(this.props.subject);
        const { u: authorizationCode } = url.parse(router.location.search, true).query || {};

        return subject.getDeepLink(
          brand,
          {
            trackingParams: {
              pid: 'mobile_web_pass_banner',
              analyticsID: get(insights, 'analyticsID'),
            },
          },
          false,
          { trackingProperties },
          authorizationCode,
        );
      case SEARCH_BANNER:
        subject = new Search(this.props.subject);
        return subject.getDeepLink(
          brand,
          { pid: 'mobile_web_smart_app_banner', analyticsID: get(insights, 'analyticsID') },
          trackingProperties,
          this.coupon(),
          portalAffiliateId,
          false,
          app,
        );
      case HOME_BANNER:
        return new DeepLink({
          view: 'home',
          trackingParams: { pid: 'home_banner', analyticsID: get(insights, 'analyticsID') },
          trackingProperties,
          coupon: this.coupon(),
          portalAffiliateId,
          admin: false,
          brand,
        });
      case CHECKOUT_BANNER:
        subject = this.props.subject;
        if (subject.constructor.name !== 'Quote') {
          subject = new Quote(subject);
        }
        return subject.getDeepLink(
          brand,
          { pid: 'mobile_web_smart_app_banner', analyticsID: get(insights, 'analyticsID') },
          trackingProperties,
          this.coupon(),
          portalAffiliateId,
        );
      default:
        return null;
    }
  }

  get deepLinkURL() {
    const { insights } = this.props;
    return this.deepLink() && this.deepLink().set('analyticsID', get(insights, 'analyticsID')).url;
  }

  message() {
    switch (this.bannerType) {
      case PARKING_PASS_BANNER:
        return 'VIEW PASS IN APP';
      default:
        return 'OPEN APP';
    }
  }

  secondaryMessage() {
    switch (this.bannerType) {
      case PARKING_PASS_BANNER:
        return 'Now book as fast as you park.';
      default:
        return this.shouldDisplayOffer() ? 'Book on our app & get $5 OFF!' : 'Now book as fast as you park.';
    }
  }

  category() {
    switch (this.bannerType) {
      case PARKING_PASS_BANNER:
        return 'parking-pass';
      default:
        return 'general';
    }
  }

  render() {
    const { display } = this.props;
    if (!this.displayable) { return null; }

    return (
      <BannerComponent
        {...this.props}
        deepLinkURL={this.state.renderDeepLinkURL ? this.deepLinkURL : null}
        message={this.message()}
        secondaryMessage={this.secondaryMessage()}
        category={this.category()}
        display={display}
        offerDisplayed={this.shouldDisplayOffer()}
      />
    );
  }
}

DeepLinkBanner.propTypes = propTypes;
DeepLinkBanner.defaultProps = defaultProps;

const mapStateToProps = (state) => {
  const {
    display,
    message,
    deepLink,
  } = state.deepLinks;

  const { subject } = deepLink;

  const {
    displayMap,
    currentSearch,
    venue,
    hub,
  } = state.search;

  const {
    selectedLocation,
  } = currentSearch;

  const {
    selectedParkingPassIds,
  } = state.bookings;

  const {
    couponCode,
    portalAffiliateId,
    affiliateId,
  } = state.checkout;

  const {
    name: app,
    appContext,
    featureFlags,
    partner,
    affiliatePartner,
  } = state.app;

  const {
    insights,
    trackingProperties,
  } = state.analytics;

  const {
    user,
  } = state.account;

  const {
    brand,
  } = state.brand;

  const { bookingCounts } = user;

  return {
    app,
    appContext,
    display,
    message,
    deepLink,
    subject,
    venue,
    hub,
    bookingCount: bookingCounts,
    displayMap,
    currentSearch,
    selectedLocation,
    selectedParkingPassIds,
    trackingProperties,
    couponCode,
    portalAffiliateId,
    affiliateId,
    partner,
    affiliatePartner,
    featureFlags,
    insights,
    brand,
    router: state.router,
  };
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    dismissBanner: dismissDeepLinkBanner,
    trackEvent: trackEventCreator,
  }, dispatch)
);

class DeepLinkBannerWrapper extends Component {
  // Needed to pass through displayable from the wrapped component
  get displayable() {
    return get(this.banner, 'displayable', false);
  }

  render() {
    return (
      <ExperimentsConsumer>
        <DeepLinkBanner {...this.props} ref={(b) => { this.banner = b; }} />
      </ExperimentsConsumer>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(DeepLinkBannerWrapper);
