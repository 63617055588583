import { Record, Map } from 'immutable';
import Image from './image';

export default class Seller extends Record({
  name: null,
  id: null,
  logo: null,
}) {

  constructor(props) {
    if (!props) { 
      super();
      return;
    }
    const logo = props.logo && props.logo.sizes && new Image(props.logo.sizes);

    super({
      name: props.name,
      id: props.id,
      logo,
    });
  }
}

export function Sellers(response) {
  const sellers = {};
  if (response && response.filter) {
    const resp = response.filter(r => r);
    if (resp.length) {
      resp.forEach((seller) => {
        const newSeller = new Seller(seller);
        // Events can be returned with an undefined id...not sure how but it's happening
        if (newSeller.id) {
          sellers[newSeller.id] = newSeller;
        }
      });
    }
  }

  return new Map(sellers);
}
