export const UPDATE_VEHICLE = 'update-vehicle';

export default function updateVehicle(vehicle) {
  return {
    type: UPDATE_VEHICLE,
    payload: {
      vehicle,
    },
  };
}
