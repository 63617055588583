import { Record, OrderedMap, Set, Map } from 'immutable';

import NewPaymentMethod from 'models/braintree/new-payment-method';
import { sortPaymentMethods, PaymentMethods } from 'models/payment-method';

import { GOT_PAYMENT_METHODS } from 'action-creators/payment-methods/got-payment-methods';
import { SAVE_PAYMENT_METHOD } from 'action-creators/payment-methods/save-payment-method';
import { SAVED_PAYMENT_METHOD } from 'action-creators/payment-methods/saved-payment-method';
import { SAVE_BUSINESS_PROFILE } from 'action-creators/payment-methods/save-business-profile';
import { SET_DEFAULT_PAYMENT_METHOD } from 'action-creators/payment-methods/set-default-payment-method';
import { DELETE_PAYMENT_METHOD } from 'action-creators/payment-methods/delete-payment-method';
import { SELECT_PAYMENT_METHOD } from 'action-creators/payment-methods/select-payment-method';
import { INITIALIZE_NEW_PAYMENT_METHOD } from 'action-creators/payment-methods/initialize-new-payment-method';
import { BRAINTREE_FIELD_STATE_CHANGE } from 'action-creators/payment-methods/braintree-field-state-change';
import { MODIFY_REQUIRED_FIELD_LIST } from 'action-creators/payment-methods/modify-required-field-list';
import { SET_REQUIRED_FIELD_ERRORS } from 'action-creators/payment-methods/set-required-field-errors';
import { GOT_PAYMENT_TOKEN } from 'action-creators/payment-methods/got-payment-token';
import { UNSET_SUBMIT } from 'action-creators/payment-methods/unset-submit';
import { SET_SHOULD_SCROLL_TO_ERROR } from 'action-creators/payment-methods/set-should-scroll-to-error';
import { CLEANUP_CHECKOUT_STATE } from 'action-creators/checkout/cleanup-checkout-state';
import { CHANGE_PAYMENT_METHOD } from 'action-creators/payment-methods/change-payment-method';
import { HANDLE_PAYMENT_ERROR } from 'action-creators/payment-methods/handle-payment-error';

export class PaymentMethodsState extends Record({
  view: 'list',
  paymentMethods: OrderedMap(),
  paymentMethod: null,
  selectedPaymentMethod: null,
  selectedPaymentMethodId: null,
  defaultPaymentMethodId: null,
  requiredFields: Set(),
  requiredFieldErrors: OrderedMap(),
  braintreeToken: null,
  messages: OrderedMap(),
  submitting: false,
  shouldScrollToError: false,
  newPayment: false,
  newPaymentMethod: new NewPaymentMethod(),
}) {
  constructor(props) {
    if (!props || !props.payment_methods) {
      super();
      return;
    }

    const defaultPaymentMethod = props.payment_methods.find(p => p.is_default);

    super({
      paymentMethods: PaymentMethods(props.payment_methods),
      defaultPaymentMethodId: defaultPaymentMethod && defaultPaymentMethod.id,
    });
  }
}

export default function paymentMethodsReducer(state = new PaymentMethodsState(), action) {
  switch (action.type) {
    case GOT_PAYMENT_METHODS: {
      const { paymentMethods, defaultPaymentMethodId } = action.payload;
      const selectedPaymentMethod = paymentMethods.get(defaultPaymentMethodId);

      return state.merge({
        paymentMethods,
        selectedPaymentMethod,
      });
    }
    case SAVE_PAYMENT_METHOD: {
      return state.merge({
        submitting: true,
      });
    }
    case SAVE_BUSINESS_PROFILE: {
      return state.merge({
        submitting: true,
      });
    }
    case SAVED_PAYMENT_METHOD: {
      const { paymentMethod } = action.payload;
      const paymentMethods = sortPaymentMethods(state.paymentMethods.set(paymentMethod.id, paymentMethod));

      return state.merge({
        paymentMethods,
      });
    }
    case SET_DEFAULT_PAYMENT_METHOD: {
      const { defaultPaymentMethodId } = action.payload;

      return state.merge({
        defaultPaymentMethodId,
      });
    }
    case DELETE_PAYMENT_METHOD: {
      const { paymentMethodId } = action.payload;
      const paymentMethods = state.paymentMethods.delete(paymentMethodId);

      return state.merge({
        paymentMethods,
      });
    }
    case SELECT_PAYMENT_METHOD: {
      const { selectedPaymentMethodId } = action.payload;
      const { paymentMethods } = state;
      const selectedPaymentMethod = paymentMethods.get(selectedPaymentMethodId);

      return state.merge({
        selectedPaymentMethodId,
        selectedPaymentMethod,
      });
    }
    case INITIALIZE_NEW_PAYMENT_METHOD: {
      const { paymentMethod } = action.payload;

      return state.merge({
        paymentMethod,
      });
    }
    case BRAINTREE_FIELD_STATE_CHANGE: {
      const { newPaymentMethodUpdate } = action.payload;
      if (!newPaymentMethodUpdate) { return state; }
      const { requiredFields } = state;
      let requiredFieldErrors = OrderedMap();

      const existingPaymentMethod = state.newPaymentMethod || new NewPaymentMethod();
      const paymentMethod = new NewPaymentMethod(existingPaymentMethod.merge(newPaymentMethodUpdate));

      requiredFields.forEach((field) => {
        const paymentField = paymentMethod.get(field);
        if (paymentField && !paymentField.isPotentiallyValid) {
          requiredFieldErrors = requiredFieldErrors.set(field, paymentField);
        }
      });
      return state.merge({
        newPaymentMethod: paymentMethod,
        paymentMethod,
        requiredFieldErrors,
      });
    }
    case MODIFY_REQUIRED_FIELD_LIST: {
      const { addedFields, removedFields } = action.payload;
      let requiredFields = state.requiredFields;
      let requiredFieldErrors = state.requiredFieldErrors;
      addedFields.forEach(field => (requiredFields = requiredFields.add(field)));
      removedFields.forEach(field => (requiredFields = requiredFields.remove(field)));
      removedFields.forEach(field => (requiredFieldErrors = requiredFieldErrors.remove(field)));

      return state.merge({
        requiredFields,
        requiredFieldErrors,
      });
    }
    case SET_REQUIRED_FIELD_ERRORS: {
      const { requiredFields } = state;
      let { requiredFieldErrors } = action.payload;
      if (requiredFieldErrors) {
        let errors = Map();
        requiredFields.forEach((f) => { errors = errors.set(f, requiredFieldErrors.get(f)); });

        return state.merge({
          requiredFieldErrors: errors,
        });
      }

      requiredFieldErrors = OrderedMap();
      const { paymentMethod } = state;

      requiredFields.forEach((field) => {
        if (paymentMethod[field] && (!paymentMethod[field].isPotentiallyValid || paymentMethod[field].isEmpty)) {
          requiredFieldErrors = requiredFieldErrors.set(field, paymentMethod[field]);
        }
      });

      return state.merge({
        requiredFieldErrors,
      });
    }
    case HANDLE_PAYMENT_ERROR: {
      const { requiredFieldErrors } = action.payload;

      return state.merge({
        requiredFieldErrors,
      });
    }
    case GOT_PAYMENT_TOKEN: {
      const { braintreeToken } = action.payload;

      return state.merge({
        braintreeToken,
      });
    }
    case UNSET_SUBMIT:
      return state.merge({
        submitting: false,
      });
    case SET_SHOULD_SCROLL_TO_ERROR:
      return state.merge({
        shouldScrollToError: action.payload,
      });
    case CLEANUP_CHECKOUT_STATE: {
      return state.merge({
        newPayment: false,
        newPaymentMethod: new NewPaymentMethod(),
      });
    }
    case CHANGE_PAYMENT_METHOD: {
      const { selectedPaymentMethod, newPayment } = action.payload;
      return state.merge({
        selectedPaymentMethod,
        newPayment,
      });
    }
    default: {
      return state;
    }
  }
}
