import User from 'models/user';

export const GOT_USER = 'got-user';

export default function gotUser(response) {
  const user = new User(response);
  return {
    type: GOT_USER,
    payload: {
      user,
    },
  };
}
