import { Record } from 'immutable';


export default class Entrance extends Record({
  lat: null,
  lng: null,
}) {
  constructor(props) {
    let lat;
    let lng;
    if (props.lat && props.lng) {
      ({ lat, lng } = props);
    } else {
      lat = props[0];
      lng = props[1];
    }
    super({ lat, lng });
  }
}
