import React from 'react';
import BrandName from 'containers/common/brand-name';

const PrintOnly = () => (
  <p className="text-color-medium-grey text-size-20 text-weight-book" key="print-only">
    This location requires you to print your <BrandName /> pass and take it to the
    location, but no additional payment is necessary.
  </p>
);

export default PrintOnly;
