import isEmpty from 'lodash/isEmpty';
export const ADD_PREPURCHASE_LICENSE_PLATE = 'add-prepurchase-license-plate';
const addPrepurchaseLicensePlate = ({ plateNumber }) => (
  { type: ADD_PREPURCHASE_LICENSE_PLATE,
    payload: {
      plateNumber: isEmpty(plateNumber) ? null : plateNumber,
    },
  }
);

export default addPrepurchaseLicensePlate;
