export const UPDATE_SEARCH = 'update-search';
const updateSearch = ({
  currentSearch,
  monthlyAvailable,
  hub,
  event,
  venue,
  events,
  app,
  bookExtendedTimes,
  selectedLocation = null,
  selectedQuote = null,
  clearLocations,
  timezone,
  retainSelection = null,
  keepRoute = false,
}) => ({
  type: UPDATE_SEARCH,
  payload: {
    currentSearch,
    monthlyAvailable,
    hub,
    event,
    venue,
    events,
    app,
    bookExtendedTimes,
    selectedLocation,
    selectedQuote,
    clearLocations,
    timezone,
    retainSelection,
    keepRoute,
  },
});
export default updateSearch;
