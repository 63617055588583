import { Record } from 'immutable';
import mapKeys from 'lodash/mapKeys';
import camelCase from 'lodash/camelCase';

// http://developer.parkwhiz.com/v4/#cancellable-status
export default class CancellableStatus extends Record({
  cancellableNow: null,
  code: null,
  message: null,
}) {
  constructor(props) {
    super(mapKeys(props, (v, k) => camelCase(k)));
  }
}
