import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  TERMS_OF_USE_MOTORISTS_CLICK,
  TERMS_OF_USE_MOTORISTS_IMPRESSION,
  PRIVACY_POLICY_CLICK,
  PRIVACY_POLICY_IMPRESSION,
} from "lib/analytics/events";

import trackEventCreator from "action-creators/analytics/track-event";
import { LinkWithAnalytics } from "components/common/link-with-analytics";

const propTypes = {
  trackEvent: PropTypes.func.isRequired,
};

const TermsAndConditionsFooter = ({ trackEvent }) => {
  return (
    <>
      <LinkWithAnalytics
        href="https://www.flashparking.com/motorist-terms-of-use/"
        onClickEvent={() => trackEvent(TERMS_OF_USE_MOTORISTS_CLICK)}
        onVisibilityEvent={() => trackEvent(TERMS_OF_USE_MOTORISTS_IMPRESSION)}
      >
        Terms of Use for Motorists
      </LinkWithAnalytics>{" "}
      |{" "}
      <LinkWithAnalytics
        href="https://www.flashparking.com/privacy-policy/"
        onClickEvent={() => trackEvent(PRIVACY_POLICY_CLICK)}
        onVisibilityEvent={() => trackEvent(PRIVACY_POLICY_IMPRESSION)}
      >
        Privacy Policy
      </LinkWithAnalytics>
    </>
  );
};

TermsAndConditionsFooter.propTypes = propTypes;

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      trackEvent: trackEventCreator,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(TermsAndConditionsFooter);
