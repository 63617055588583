export const ADD_VEHICLE = 'add-vehicle';
const addVehicle = ({ vehicleId, plateNumber, isDefault, bookingId }) => ({
  type: ADD_VEHICLE,
  payload: {
    vehicleId,
    plateNumber,
    isDefault,
    bookingId,
  },
});
export default addVehicle;
