import { List, Record } from 'immutable';
import moment from 'moment-timezone';
import get from 'lodash/get';
import map from 'lodash/map';

export const MAX_EDIT_LICENSE_PLATE_IN_MINS = 10;

export default class Ticket extends Record({
  id: null,
  bookingId: null,
  type: '',
  updateBefore: null,
}) {
  constructor(props) {
    const { id, type } = props;

    const bookingProps = get(props, '_embedded.pw:booking', {});
    const locationProps = get(props, '_embedded.pw:location', {});

    const startTime = locationProps.timezone
      ? moment(bookingProps.start_time).tz(locationProps.timezone)
      : moment(bookingProps.start_time);
    const purchasedAt = locationProps.timezone
      ? moment(bookingProps.purchased_at).tz(locationProps.timezone)
      : moment(bookingProps.purchased_at);
    
    let updateBefore;
    if (startTime.isAfter(purchasedAt)) {
      updateBefore = startTime.add(MAX_EDIT_LICENSE_PLATE_IN_MINS, 'minutes');
    } else {
      updateBefore = purchasedAt.add(MAX_EDIT_LICENSE_PLATE_IN_MINS, 'minutes');
    }

    super({
      id,
      type,
      updateBefore,
      bookingId: bookingProps.id,
    });
  }

  get isSTP() {
    return this.type.toLowerCase() === 'stp';
  }

  get hasPassedSTPTreshold() {
    return moment().isAfter(this.updateBefore);
  }

  get formattedUpdateBefore() {
    return this.updateBefore.format('h:mmA');
  }

  get canUpdateLicensePlate() {
    switch (this.type.toLowerCase()) {
      case 'stp':
        return !this.hasPassedSTPTreshold;
      default:
        return true;
    }
  }
}

export function Tickets(tickets) {
  return List(map(tickets, (ticket) => new Ticket(ticket)));
}
