import { Record, Set } from 'immutable';
import get from 'lodash/get';

import { RESET_MODAL_STATE } from 'action-creators/modal/reset-modal-state';
import { SET_MODAL_STATE } from 'action-creators/modal/set-modal-state';
import { DISMISS_PARTNER_MODAL } from 'action-creators/brand/dismiss-partner-modal';

import Brand from 'models/brand';
import SearchSuggestions from 'models/search-suggestions';

import * as AppContext from 'lib/app-context';
import { countryByLocale } from 'lib/locale';

import { PARTNER_MODAL, SEARCH_RESTRICTIONS_MODAL } from 'containers/common/modal';

export class ModalState extends Record({
  displayModal: false,
  header: null,
  body: null,
  footer: null,
  headerProps: {},
  bodyProps: {},
  footerProps: {},
  appContext: AppContext.DESKTOP,
}) {
  constructor(props) {
    if (!props) {
      super();
      return;
    }

    const { partnerModalDismissed = false, locale } = props;

    const country = countryByLocale(locale);
    const { brand } = Brand.cascadedBrand(props, { country });

    let { displayModal = false, header, body, bodyProps, footer } = props;
    const { appContext, app, currentSearch, restrictionsModalDismissals } = props;
    if (appContext === AppContext.MOBILE) {
      const searchSuggestions = new SearchSuggestions(get(currentSearch, 'destination.searchSuggestions', null));
      if (!partnerModalDismissed && brand.showTransitionInterstitialOnVisit) {
        displayModal = true;
        header = 'emptyHeader';
        body = PARTNER_MODAL;
        footer = 'generalFooter';
        bodyProps = { brand, appContext };
      } else if (searchSuggestions && searchSuggestions.shouldDisplay({
        app,
        search: currentSearch,
        dismissals: Set(restrictionsModalDismissals),
      })) {
        displayModal = true;
        header = 'emptyHeader';
        body = SEARCH_RESTRICTIONS_MODAL;
        footer = 'generalFooter';
        bodyProps = { searchSuggestions };
      }
    }

    super({
      partnerModalDismissed,
      brand,
      displayModal,
      header,
      body,
      bodyProps,
      footer,
      appContext,
    });
  }
}

export default function modalReducer(state = new ModalState(), action = null) {
  switch (action.type) {
    case RESET_MODAL_STATE: {
      const { preventModalReset } = action.payload;
      if (preventModalReset) {
        return state;
      }

      return new ModalState();
    }
    case SET_MODAL_STATE: {
      const {
        displayModal,
        body,
        header,
        footer,
        bodyProps,
        headerProps,
        footerProps,
      } = action.payload;
      return state.merge({
        displayModal,
        body,
        header,
        footer,
        bodyProps,
        headerProps,
        footerProps,
      });
    }
    case DISMISS_PARTNER_MODAL: {
      return state.merge({
        displayModal: false,
      });
    }
    default:
      return state;
  }
}
