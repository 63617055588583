export const SAVE_BUSINESS_PROFILE = 'save-business-profile';

export default function saveBusinessProfile({ id, nonce, email, isUpdate }) {
  return {
    type: SAVE_BUSINESS_PROFILE,
    payload: {
      id,
      nonce,
      email,
      isUpdate,
    },
  };
}
