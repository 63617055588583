export const SAVE_NEW_VEHICLE = 'save-new-vehicle';

export default function saveNewVehicle(vehicle) {
  return {
    type: SAVE_NEW_VEHICLE,
    payload: {
      vehicle,
    },
  };
}
