import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { List } from 'immutable';

import { invalidTimeChangeWarning } from 'lib/common/messages';

import PickerRow from 'components/common/mobile-destination-time-picker/picker-row';

const propTypes = {
  startTime: PropTypes.instanceOf(moment).isRequired,
  endTime: PropTypes.instanceOf(moment).isRequired,
  timezone: PropTypes.string.isRequired,
  timesChange: PropTypes.func.isRequired,
  messages: PropTypes.instanceOf(List),
};

const defaultProps = {
  messages: List(),
};

class HourlyPicker extends Component {
  constructor(props) {
    super(props);

    this.updateStartTime = this.updateStartTime.bind(this);
    this.updateEndTime = this.updateEndTime.bind(this);
  }

  updateStartTime(startTime) {
    this.props.timesChange({
      startTime,
      disableSetRoute: true,
    });
  }

  updateEndTime(endTime) {
    this.props.timesChange({
      endTime,
      disableSetRoute: true,
    });
  }

  humanDuration(durationInMinutes) {
    if (durationInMinutes <= 0) { return ''; }

    const duration = moment.duration(durationInMinutes, 'minutes');
    let leftoverMinutes = 0;
    let prefix = '';
    let suffix = '';

    if (durationInMinutes < 60) {
      prefix = durationInMinutes;
      suffix = (prefix === 1 ? 'Min' : 'Mins');
    } else if (duration.asHours() < 24) {
      prefix = Math.floor(duration.asHours());
      suffix = (prefix === 1 ? 'Hr' : 'Hrs');
      leftoverMinutes = (durationInMinutes % 60);
    } else {
      prefix = Math.floor(duration.asDays());
      suffix = (prefix === 1 ? 'Day' : 'Days');
      leftoverMinutes = (durationInMinutes % (24 * 60));
    }

    return ` ${prefix.toString()} ${suffix}${this.humanDuration(leftoverMinutes)}`;
  }

  renderDuration() {
    const { startTime, endTime } = this.props;
    if (endTime.isBefore(startTime)) { return '-'; }
    const durationInMinutes = moment.duration(endTime.diff(startTime)).asMinutes();

    return this.humanDuration(durationInMinutes).trim();
  }

  renderTimePickerWarning() {
    const { messages } = this.props;
    const timeWarningMessage = messages.find(m => (m.id === invalidTimeChangeWarning.id));
    if (!timeWarningMessage) { return null; }

    return (
      <div className="row text-color-red">
        <div className="col-xs-12">
          <div className="text-weight-bold">
            <span className="iconified-font iconified-warning iconified-space-right-half" />
            {timeWarningMessage.message}
          </div>
          <div>{timeWarningMessage.details}</div>
        </div>
      </div>
    );
  }

  render() {
    const { startTime, endTime, timezone } = this.props;

    return (
      <div>
        <PickerRow
          label="ARRIVE AFTER"
          time={startTime}
          timezone={timezone}
          onUpdate={this.updateStartTime}
        />
        <PickerRow
          label="DEPART BEFORE"
          time={endTime}
          timezone={timezone}
          onUpdate={this.updateEndTime}
        />
        <div className="row text-right text-color-dark-slate text-weight-bold text-size-12 with-vertical-margin">
          <div className="col-xs-12">
            Duration: {this.renderDuration()}
          </div>
        </div>
        {this.renderTimePickerWarning()}
      </div>
    );
  }
}

HourlyPicker.propTypes = propTypes;
HourlyPicker.defaultProps = defaultProps;
export default HourlyPicker;
