import React, { Component } from 'react';

export default class Search extends Component {
  render() {
    return (
      <svg width="19px" height="19px" viewBox="0 0 19 19" version="1.1">
        <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round">
          <g id="FINDMYSPACE_BTN" transform="translate(-41.000000, -16.000000)" strokeWidth="2" stroke="#10D492">
            <g id="Imported-Layers">
              <path d="M58.6153846,33.6153846 L53.8153846,28.8153846 L58.6153846,33.6153846 Z M49.0153846,17.6153846 C52.5497846,17.6153846 55.4153846,20.4809846 55.4153846,24.0153846 C55.4153846,27.5497846 52.5497846,30.4153846 49.0153846,30.4153846 C45.4809846,30.4153846 42.6153846,27.5497846 42.6153846,24.0153846 C42.6153846,20.4809846 45.4809846,17.6153846 49.0153846,17.6153846 L49.0153846,17.6153846 Z"></path>
            </g>
          </g>
        </g>
      </svg>
    );
  }
}
