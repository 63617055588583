import { List, Map, Record, OrderedSet } from 'immutable';
import moment from 'moment-timezone';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import Entrance from 'models/entrance';
import Image from 'models/image';
import Quote from 'models/quote';
import NonBookableQuote from 'models/non-bookable-quote';
import { VALID_AMENITY_KEYS } from 'models/amenities';
import OperatingHoursCollection from 'models/operating-hours';

export class Location extends Record({
  id: null,
  name: null,
  address: null,
  address1: null,
  address2: null,
  distance: null,
  distanceUnits: null,
  city: null,
  state: null,
  country: null,
  currency: null,
  postalCode: null,
  timezone: moment.tz.guess(),
  description: null,
  msa: null,
  images: null,
  quotes: null,
  entrances: null,
  url: null,
  sellerId: null,
  sellerLogo: null,
  displaySellerLogo: false,
  directions: null,
  ratingSummary: {
    averageRating: null,
    ratingCount: null,
  },
  venueId: null,
  airport: false,
  hours: List(),
  meta: null,
  supportsBookingExtension: false,
}) {
  constructor(props, { locale, nonBookableRules } = {}) {
    if (!props) {
      super();
      return;
    }

    const location = get(props, ['_embedded', 'pw:location'], props);

    const { currency } = location;

    let entrances = [];
    let images = List();
    let quotes = List();

    const purchaseOptions = props.purchase_options;
    const nonBookableOptions = props.non_bookable_options;
    const startTime = props.start_time;
    const minStartTime = props.min_start;
    const endTime = props.end_time;
    const quoteType = props.quote_type;
    const distanceUnits = locale === 'en-us' ? 'feet' : 'meters';
    const distance = get(props, ['distance', 'straight_line', distanceUnits], props.distance);
    let maxEndTime = props.max_end;

    if (!location.sellerId) {
      location.sellerId = props.seller_id;
    }

    // Sorting images by their position for easy accessibility
    if (location.photos) {
      for (let i = 0; i < location.photos.length; i++) {
        const photo = location.photos[i];
        if (typeof photo.position !== 'undefined') {
          images = images.insert(photo.position, new Image({ ...photo.sizes, position: photo.position }));
        }
      }
    }

    if (location.images) {
      location.images.forEach((image) => {
        images = images.concat(new Image(image.imageSizes));
      });
    }

    images = images.filter(i => i).sortBy(i => parseInt(get(i, 'position', 0), 10));

    let sellerLogo;
    if (location.seller_logo && location.seller_logo.sizes) {
      sellerLogo = new Image(location.seller_logo.sizes);
    } else if (location.sellerLogo) {
      sellerLogo = new Image(location.sellerLogo.imageSizes);
    }

    // Quotes
    if (purchaseOptions) {
      purchaseOptions.forEach((purchaseOption) => {
        maxEndTime = purchaseOption.max_end || maxEndTime;
        quotes = quotes.push(new Quote(
          purchaseOption,
          startTime,
          minStartTime,
          endTime,
          maxEndTime,
          quoteType,
          null,
          { currency },
        ));
      });
    }

    // Non-bookable Quotes
    if (nonBookableOptions && get(nonBookableRules, 'display', false)) {
      nonBookableOptions.forEach((nonBookableOption) => {
        quotes = quotes.push(new NonBookableQuote(
          nonBookableOption,
          startTime,
          endTime,
          quoteType,
          { currency },
        ));
      });
    }

    // Sort based off of bookability and price
    if (quotes && quotes.length > 1) {
      quotes = quotes.sort(
        (a, b) => {
          const aPrice = a ? parseFloat(a.price) : 0;
          const bPrice = b ? parseFloat(b.price) : 0;

          if (a.bookable !== b.bookable) {
            if (a.bookable) {
              return -1;
            }
            return 1;
          }

          if (aPrice < bPrice) {
            return -1;
          } else if (aPrice > bPrice) {
            return 1;
          }
          return 0;
        },
      );
    }

    // Entrances
    if (location.entrances) {
      entrances = location.entrances.map(entrance => (
        new Entrance(entrance.coordinates ? entrance.coordinates : entrance)
      ));
    }

    entrances = List(entrances);
    const venueId = props._embedded && props._embedded['pw:venue'] ? props._embedded['pw:venue'].id : null;
    const airport = props._embedded && props._embedded['pw:venue'] ? props._embedded['pw:venue'].enhanced_airport : false;

    let { hours } = location;
    if (hours && typeof hours[0] === 'string') {
      hours = OperatingHoursCollection({ hours });
    }

    const address = get(location, 'address', null) || get(location, 'address1', null);
    const meta = {
      title: !isEmpty(address) ? `${address} Parking` : null,
      description: get(location, 'description', null),
      canonicalUrl: get(location, 'site_url', null),
    };

    super({
      id: location.id,
      name: location.name,
      address,
      address1: location.address1,
      address2: location.address2,
      distance,
      distanceUnits,
      city: location.city,
      country: location.country,
      currency,
      state: location.state,
      postalCode: location.postal_code,
      timezone: location.timezone || moment.tz.guess(),
      description: location.description,
      msa: location.msa,
      url: location.site_url,
      images: images.size > 0 ? images : null,
      quotes: quotes.size > 0 ? quotes : null,
      entrances,
      sellerId: location.sellerId,
      sellerLogo,
      displaySellerLogo: !!(location.display_seller_logo || location.displaySellerLogo),
      directions: location.directions,
      ratingSummary: {
        averageRating: location.rating_summary ? location.rating_summary.average_rating : null,
        ratingCount: location.rating_summary ? location.rating_summary.rating_count : null,
      },
      venueId,
      airport,
      hours,
      meta,
      supportsBookingExtension: location.supports_booking_extension,
    });
  }

  /**
   * Multiple Monthly Listings and Parking Packages
   * can have multiple quotes per location
   */
  get hasMultipleQuotes() {
    return Boolean(this.quotes && this.quotes.size > 1);
  }

  get hasMultipleBookableQuotes() {
    return Boolean(this.quotes && this.quotes.filter(q => q.bookable).size > 1);
  }

  get isAvailable() {
    return Boolean(this.quotes) && this.quotes.some(quote => !quote.isSoldOut);
  }

  get isUnavailable() {
    return this.quotes == null || this.quotes.every(quote => quote.isSoldOut);
  }

  get isBookable() {
    const quote = this.getQuote();
    return !!(quote && quote.bookable);
  }

  get fullAddress() {
    const { address, city, state, postalCode } = this;
    return `${address || ''} ${city || ''}, ${state || ''} ${postalCode || ''}`.trim();
  }

  getDefaultQuote() {
    // Returns the location default quote or the first quote if no default has
    // been defined.  Note: location default quotes are currently only defined
    // for Monthly pricings.
    let quote = null;

    if (this.quotes) {
      quote = this.quotes.find(q => q.locationDefault) || this.quotes.get(0);
    }

    return quote;
  }

  getAvailableQuotes() {
    return this.isAvailable ? this.quotes.filter(q => !q.isSoldOut) : this.quotes;
  }

  // For backwards compatability purposes in search
  getQuote() {
    return this.quotes ? this.getAvailableQuotes().get(0) : null;
  }

  getQuoteById(id) {
    let quote = null;

    if (this.quotes && id) {
      quote = this.quotes.find(q => q.id === id) || null;
    }

    return quote;
  }

  getQuoteId() {
    const quote = this.getQuote();
    return quote ? quote.id : null;
  }

  getImage() {
    return (this.images && this.images.size > 0) ? this.images.first() : null;
  }

  getLargestScaledImageHeight(width) {
    const images = this.images;
    if (!images || images.size === 0) { return null; }
    const heights = images.map((image) => {
      if (!image.imageSizes) { return 1; }
      const aspectRatio = (image.imageSizes.getIn(['gallery', 'width']) || 1) / (image.imageSizes.getIn(['gallery', 'height']) || 1);
      return width / aspectRatio;
    }).toArray();
    return Math.max(...heights);
  }

  get validAmenities() {
    const quote = this.getQuote();
    if (!quote) {
      return new Map();
    }

    const amenities = quote.amenities;

    if (amenities) {
      return VALID_AMENITY_KEYS.map(key => amenities.get(key)).filter(a => (a && a.visible));
    }

    return new Map();
  }

  get tierAmenities() {
    let tierAmenities = OrderedSet();
    if (!this.quotes) { return tierAmenities; }

    this.quotes.forEach((q) => {
      if (q) {
        const { tierAmenities: quoteAmenities } = q;
        if (quoteAmenities) {
          tierAmenities = tierAmenities.merge(quoteAmenities);
        }
      }
    });

    return tierAmenities;
  }

  get shuttleFrequencyBounds() {
    if (!this.quotes) { return []; }
    let shuttleFrequencies = List();

    this.quotes.forEach((q) => {
      q.shuttleTimesToVenue.concat(q.shuttleTimesFromVenue).forEach((st) => {
        shuttleFrequencies = shuttleFrequencies.concat(st.frequencies);
      });
    });

    const frequencies = [];
    const min = shuttleFrequencies.min();
    const max = shuttleFrequencies.max();
    if (min) { frequencies.push(min); }
    if (max && min !== max) { frequencies.push(max); }

    return frequencies;
  }

  getSearchThumbnail() {
    const image = this.getImage();
    return image && image.imageSizes ? image.imageSizes.getIn(['searchThumb', 'url']) : null;
  }

  getDisplayImage() {
    const image = this.getImage();
    return image && image.imageSizes ? image.imageSizes.getIn(['gallery', 'url']) : null;
  }

  get galleryImages() {
    const images = this.images;
    if (!images || images.size === 0) { return null; }
    return images.map(image => (
      image.imageSizes && image.imageSizes.getIn(['gallery', 'url'])
    )).toArray();
  }

  getRecentBooking(recentBookings) {
    if (recentBookings) {
      return recentBookings.get(this.id.toString());
    }
    return null;
  }

  hasRatings() {
    return (
      this.ratingSummary &&
      this.ratingSummary.ratingCount &&
      this.ratingSummary.ratingCount > 0 &&
      this.ratingSummary.averageRating &&
      this.ratingSummary.averageRating >= 0
    );
  }

  googleMapURL() {
    if (this.entrances.size > 0) {
      const entrance = this.entrances.first();
      return `http://www.google.com/maps/search/?api=1&query=${entrance.lat},${entrance.lng}`;
    }
    return null;
  }
}

export function getLocationsFromEmbedded({ body }) {
  return Locations(body);
}

export function Locations(response, { sort = 'closest', locale, nonBookableRules } = {}) {
  const locations = {};

  if (response && response.length) {
    for (let i = 0; i < response.length; i++) {
      const newLocation = new Location(response[i], { locale, nonBookableRules });
      if (newLocation.id) {
        locations[newLocation.id] = newLocation;
      }
    }
  }

  return sortLocations(locations, { sort });
}

export function sortLocations(locations, { sort = 'closest' } = {}) {
  switch (sort) {
    case 'cheapest':
      return Map(locations).sort(
        (a, b) => sortLocationsByPrice(a, b),
      );
    default:
      return Map(locations).sort(
        (a, b) => sortLocationsByDistance(a, b),
      );
  }
}

function sortLocationsByDistance(a, b) {
  // Sold out locations should always be on the bottom.
  if (a.quotes == null) {
    return 1;
  } else if (b.quotes == null) {
    return -1;
  }

  return a.get('distance') - b.get('distance');
}

export function sortLocationsByPrice(a, b) {
  const aQuote = a.getQuote();
  const bQuote = b.getQuote();
  const aPrice = aQuote ? aQuote.basePrice : null;
  const bPrice = bQuote ? bQuote.basePrice : null;

  // Sold out locations should always be on the bottom.
  if (aPrice === null) {
    return 1;
  } else if (bPrice === null) {
    return -1;
  }

  return aPrice - bPrice;
}
