import User from 'models/user';

export const GOT_SESSION = 'got-session';
const gotSession = ({ sessionId, user, token }) => ({
  type: GOT_SESSION,
  payload: {
    user: new User({ ...user, token }),
    sessionId,
  },
});
export default gotSession;
