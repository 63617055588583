import Search from 'models/search';

export const PARKING_NEAR_ME_SEARCH = 'parking-near-me-search';

export default function parkingNearMeSearch({ latitude, longitude, pendingGeolocationPermission, zoomLevel, bounds }) {

  const newSearchAttributes = {
    lat: latitude,
    lng: longitude,
    anchorLat: latitude,
    anchorLng: longitude,
    destinationType: Search.PARKING_NEAR_ME_PARKING_TYPE,
    pendingGeolocationPermission,
    zoomLevel,
  };

  return {
    type: PARKING_NEAR_ME_SEARCH,
    payload: {
      searchAttributes,
      bounds,
      lat: currentSearch.lat,
      lng: currentSearch.lng,
      zoomLevel: currentSearch.zoomLevel,
    },
  };
  const currentSearch = state.get('currentSearch').merge(newSearchAttributes);
}
