import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';

import Brand from 'models/brand';

import { SMART_BANNER_CLICK, SMART_BANNER_DISMISS_CLICK } from 'lib/analytics/events';

const propTypes = {
  deepLinkURL: PropTypes.string,
  dismissBanner: PropTypes.func,
  display: PropTypes.bool,
  message: PropTypes.string,
  secondaryMessage: PropTypes.string,
  bookingCounts: PropTypes.instanceOf(Map),
  category: PropTypes.string,
  offerDisplayed: PropTypes.bool,
  trackEvent: PropTypes.func.isRequired,
  brand: PropTypes.instanceOf(Brand).isRequired,
};

const defaultProps = {
  deepLinkURL: '',
  dismissBanner: (o => o),
  display: false,
  message: null,
  secondaryMessage: 'Now book as fast as your park.',
  bookingCounts: Map(),
  category: 'general',
  hub: null,
  venue: null,
  currentSearch: null,
  offerDisplayed: false,
};

class DeepLinkBanner extends Component {
  constructor(props) {
    super(props);

    this.handleDismissal = this.handleDismissal.bind(this);
    this.onClick = this.onClick.bind(this);
    this.trackEvent = this.trackEvent.bind(this);
  }

  trackEvent(event) {
    const { bookingCounts, trackEvent, offerDisplayed } = this.props;
    trackEvent({
      ...event,
      properties: {
        'Web Purchase Count': bookingCounts.getIn(['web', 'total']),
        'App Purchase Count': bookingCounts.getIn(['app', 'total']),
        'Offer Displayed': offerDisplayed ? '1' : '0',
      },
    });
  }

  onClick() {
    this.trackEvent(SMART_BANNER_CLICK);
  }

  handleDismissal(e) {
    e.stopPropagation();
    const {
      dismissBanner,
      category,
    } = this.props;
    dismissBanner({ category });
    this.trackEvent(SMART_BANNER_DISMISS_CLICK);
  }

  render() {
    if (!this.props.display) { return null; }
    const { deepLinkURL, message, secondaryMessage, brand } = this.props;

    return (
      <div className="deep-link-banner hidden-print visible-sm visible-xs">
        <div className="row">
          <div className="col-xs-2 padding-right-0">
            <a href={deepLinkURL}>
              <img src={brand.mobileAppIconURL} alt="" />
            </a>
          </div>
          <div className="col-xs-8 col-gutter-right-0 main-text">
            <a className="text-color-dark-slate" onClick={this.onClick} href={this.props.deepLinkURL}>
              <span className="text-size-12 text-weight-light">
                { secondaryMessage }
              </span>
              <br />
              <span className="text-size-16">
                { message }
                <span className="iconified-font iconified-chevron-right margin-left-10" />
              </span>
            </a>
          </div>
          <div className="col-xs-2">
            <a className="deep-link-dismiss text-color-dark-slate text-size-16" onClick={this.handleDismissal}>
              <span className="iconified-font iconified-x-border" />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

DeepLinkBanner.propTypes = propTypes;
DeepLinkBanner.defaultProps = defaultProps;

export default DeepLinkBanner;
