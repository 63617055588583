import React from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';
import * as arrowAnimationData from 'json-animations/sliding-right-arrow.json';
import Brand from 'models/brand';
import * as AppContext from 'lib/app-context';
import { imageCdnAsset } from 'lib/common/url-helpers';

const propTypes = {
  brand: PropTypes.instanceOf(Brand),
  closeModal: PropTypes.func.isRequired,
  appContext: PropTypes.string.isRequired,
};

const defaultProps = {
  brand: null,
};

const PartnerInterstitialBody = ({ brand, closeModal, appContext }) => {
  const options = {
    loop: true,
    autoplay: true,
    animationData: arrowAnimationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div className="background-color-white padding-top-xs-120 padding-top-sm-0">
      <div className="row margin-bottom-40">
        <div className="col-xs-4 col-xs-offset-1">
          <img src={brand.logoLightURL} alt={brand.displayName} />
        </div>
        <div className="col-xs-2">
          <Lottie options={options} />
        </div>
        <div className="col-xs-4">
          <img src={imageCdnAsset('/images/pw-logo-dark@2x.png')} alt={brand.displayName} />
        </div>
      </div>
      <div className="row text-align-center text-weight-bold text-color-dark-slate margin-horizontal-40 margin-bottom-40">
        <div className="col-xs-12">
          You have been taken to a new page to book your parking space.
        </div>
      </div>
      <div className="row margin-bottom-20">
        <div className="col-xs-6 col-xs-offset-3 col-sm-4 col-sm-offset-4">
          <div className="background-color-blue background-color-hover-blue-lighten text-color-white padding-vertical-10 border-radius-2 text-weight-book clickable text-center" onClick={closeModal}>
            {appContext === AppContext.MOBILE ? 'FIND PARKING' : 'OK'}
          </div>
        </div>
      </div>
    </div>
  );
};

PartnerInterstitialBody.propTypes = propTypes;
PartnerInterstitialBody.defaultProps = defaultProps;
export default PartnerInterstitialBody;
