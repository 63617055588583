export const SAVE_PAYMENT_METHOD = 'save-payment-method';

export default function savePaymentMethod({ id, nonce, captchaToken, label, isUpdate }) {
  return {
    type: SAVE_PAYMENT_METHOD,
    payload: {
      id,
      nonce,
      captchaToken,
      label,
      isUpdate,
    },
  };
}
