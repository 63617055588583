import { Record } from 'immutable';

const displayNames = {
  number:          'credit card number',
  cvv:             'CVV',
  expirationDate:  'expiration date',
  expirationMonth: 'expiration month',
  expirationYear:  'expiration year',
  postalCode:      'zip code',
}

const defaults = {
  isValid:            false,
  isPotentiallyValid: true,
  isEmpty:            true,
  displayName:        null
}

export default class HostedField extends Record(defaults) {
  constructor(props) {
    if (!props || !props.target || !props.target.fieldKey || !typeof props === 'object') {
       super(props);
       return;
    }

    // const { fieldKey, fieldValue } = props;
    // const init = Object.assign({ displayName: displayNames[fieldKey] }, fieldValue);
    const init = Object.assign({ displayName: displayNames[props.target.fieldKey] }, props);
    super(init);
  }
}
