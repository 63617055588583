import React from 'react';

import NoticeHeader from 'components/checkout/modal-notices/notice-headers/notice-header';

const SpecialRateHeader = () => (
  <div>
    <NoticeHeader headerImgSrc="/images/checkout/zag-deal.png">Deal Alert</NoticeHeader>
    <p>We found a special rate for this time and location.</p>
    <NoticeHeader>You Must:</NoticeHeader>
  </div>
);

export default SpecialRateHeader;
