import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import Expand from 'components/svgs/icons/expand';


const ShowAll = ({ text, onClick, className, expanded, count, limit }) => {
  if (count <= limit) { return null; }

  const message = expanded ? `Show Fewer ${text}` : `Show All ${text}`;
  const expandClass = cx({ 'upside-down': expanded });

  return (
    <div className={`row align-center clickable ${className}`} onClick={onClick}>
      <a className="pointer with-right-svg">
        { message }
        <Expand className={expandClass} />
      </a>
    </div>
  );
};

ShowAll.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  expanded: PropTypes.bool,
  count: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
};

ShowAll.defaultProps = {
  className: '',
  expanded: false,
};

export default ShowAll;
