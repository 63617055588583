import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { canUseDOM } from 'exenv';
import { Modal } from 'react-bootstrap';
import Brand from 'models/brand';

import * as AppContext from 'lib/app-context';
import PartnerInterstitialBody from 'components/modal/partner-interstitial-body';

const propTypes = {
  brand: PropTypes.instanceOf(Brand).isRequired,
  appContext: PropTypes.string.isRequired,
  dismissPartnerModal: PropTypes.func.isRequired,
  partnerModalDismissed: PropTypes.bool.isRequired,
};

const defaultProps = {
};

const TIME_TO_AUTO_CLOSE = 7000;

class PartnerTransitionInterstitial extends Component {
  constructor(props) {
    super(props);

    const { partnerModalDismissed, brand } = this.props;

    this.state = {
      show: !partnerModalDismissed && brand.showTransitionInterstitialOnVisit,
    };

    this.closeModal = this.closeModal.bind(this);

    if (canUseDOM && !partnerModalDismissed && brand.showTransitionInterstitialOnVisit) {
      this.partnerModalTimer = setTimeout(() => {
        this.closeModal();
      }, TIME_TO_AUTO_CLOSE);
    }
  }

  componentWillUnmount() {
    if (canUseDOM) {
      this.closeModal();
      if (this.partnerModalTimer) clearTimeout(this.partnerModalTimer);
    }
  }

  closeModal() {
    this.setState({ show: false });
    this.props.dismissPartnerModal();
  }

  render() {
    const { brand, appContext } = this.props;

    if (!this.state.show) return null;

    if (appContext === AppContext.MOBILE) return null;

    return (
      <Modal className="notices-modal text-size-16 hidden-xs" show={this.state.show}>
        <div className="row text-align-right margin-bottom-10">
          <div className="col-xs-12">
            <span className="iconified-font iconified-x clickable text-color-blue text-color-hover-blue padding-left-10 padding-bottom-10" onClick={this.closeModal} />
          </div>
        </div>
        <PartnerInterstitialBody brand={brand} closeModal={this.closeModal} appContext={appContext} />
      </Modal>
    );
  }
}

PartnerTransitionInterstitial.propTypes = propTypes;
PartnerTransitionInterstitial.defaultProps = defaultProps;

export default PartnerTransitionInterstitial;
