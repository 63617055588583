import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import get from 'lodash/get';

import { Location } from 'models/locations';
import Quote from 'models/quote';

const propTypes = {
  location: PropTypes.instanceOf(Location).isRequired,
  quote: PropTypes.instanceOf(Quote),
  expanded: PropTypes.bool,
  truncationLimit: PropTypes.number,
};

const defaultProps = {
  expanded: true,
  truncationLimit: 120,
  quote: null,
};

const About = ({ location, quote, expanded, truncationLimit }) => {
  const description = expanded ? location.description : `${location.description.slice(0, truncationLimit)}...`;
  let monthlyDetails;

  if (get(quote, 'isMonthly', false) && expanded) {
    monthlyDetails = (
      <div className="col-xs-12">
        <div className="text-weight-bold text-size-16 text-color-dark-slate margin-bottom-10 margin-top-30">
          Monthly Fee Details
        </div>
        <div className="text-weight-book text-color-dark-slate text-size-14">
          <ReactMarkdown source={quote.description} />
        </div>
      </div>
    );
  }

  return (
    <div className="row">
      <div className="col-xs-12">
        <div className="text-weight-bold text-size-16 text-color-dark-slate margin-bottom-10">
          About This Facility
        </div>
        <div className="text-weight-book text-color-dark-slate text-size-14">
          <ReactMarkdown source={description} />
        </div>
      </div>
      {monthlyDetails}
    </div>
  );
};

About.propTypes = propTypes;
About.defaultProps = defaultProps;
export default About;
