import { EventProperties, ObjectProperties } from '@parkwhiz-js/insights-sdk';

export const TIME_FORMAT = 'YYYY-MM-DDTHH:mmZ';

export const PAGEVIEW_EVENT = {
  ...EventProperties.PAGEVIEW,
  ...ObjectProperties.PAGE,
};

export const TIMEPICKER_START_IMPRESSION = {
  ...ObjectProperties.BUTTON,
  ...EventProperties.IMPRESSION,
  name: 'TimeIn',
  position: '0',
};

export const TIMEPICKER_START_CLICK = {
  ...ObjectProperties.BUTTON,
  ...EventProperties.CLICK,
  name: 'TimeIn',
  position: '0',
};

export const TIMEPICKER_END_IMPRESSION = {
  name: 'TimeOut',
  position: '0',
  ...EventProperties.IMPRESSION,
  ...ObjectProperties.BUTTON,
};

export const TIMEPICKER_END_CLICK = {
  ...ObjectProperties.BUTTON,
  eventName: 'Close',
  name: 'TimeOut',
  position: '0',
};

const HOME_HAMBURGER_PROPERTIES = {
  ...ObjectProperties.BUTTON,
  name: 'Hamburger',
  position: '0',
};
export const HOME_PAGE_NAV_CLICK = {
  ...EventProperties.CLICK,
  ...HOME_HAMBURGER_PROPERTIES,
};
export const HOME_PAGE_NAV_VISIBLE = {
  ...EventProperties.IMPRESSION,
  ...HOME_HAMBURGER_PROPERTIES,
};

const SEARCH_TEXTBOX_PROPERTIES = {
  ...ObjectProperties.TEXTBOX,
  name: 'Search',
  position: '0',
};

const HOME_PAGE_SEARCH_TEXTBOX_PROPERTIES = {
  ...SEARCH_TEXTBOX_PROPERTIES,
};

export const SEARCH_TEXTBOX_CLICK = {
  ...EventProperties.CLICK,
  ...SEARCH_TEXTBOX_PROPERTIES,
};

export const SEARCH_TEXTBOX_CHANGE = {
  ...EventProperties.CHANGE,
  ...SEARCH_TEXTBOX_PROPERTIES,
};

export const SEARCH_TEXTBOX_FOCUS = {
  ...EventProperties.FOCUS,
  ...SEARCH_TEXTBOX_PROPERTIES,
};

export const SEARCH_TEXTBOX_IMPRESSION = {
  ...EventProperties.IMPRESSION,
  ...SEARCH_TEXTBOX_PROPERTIES,
};

export const HOME_PAGE_SEARCH_TEXTBOX_IMPRESSION = {
  ...EventProperties.IMPRESSION,
  ...HOME_PAGE_SEARCH_TEXTBOX_PROPERTIES,
};

const SEARCH_BUTTON_PROPERTIES = {
  ...ObjectProperties.BUTTON,
  name: 'Search',
  position: '0',
};

export const SEARCH_BUTTON_CLICK = {
  ...EventProperties.CLICK,
  ...SEARCH_BUTTON_PROPERTIES,
};

export const HOME_PAGE_SEARCH_BUTTON_IMPRESSION = {
  ...EventProperties.IMPRESSION,
  ...SEARCH_BUTTON_PROPERTIES,
};

const FIND_PARKING_BUTTON_PROPERTIES = {
  ...ObjectProperties.BUTTON,
  name: 'Find Parking Nearby',
  position: '0',
};

export const FIND_PARKING_BUTTON_CLICK = {
  ...EventProperties.CLICK,
  ...FIND_PARKING_BUTTON_PROPERTIES,
};

export const FIND_PARKING_BUTTON_IMPRESSION = {
  ...EventProperties.IMPRESSION,
  ...FIND_PARKING_BUTTON_PROPERTIES,
};

const DOWNLOAD_APPLE_BUTTON_PROPERTIES = {
  ...ObjectProperties.BUTTON,
  name: 'Download (App Store)',
  position: '0',
};

export const DOWNLOAD_APPLE_BUTTON_CLICK = {
  ...EventProperties.CLICK,
  ...DOWNLOAD_APPLE_BUTTON_PROPERTIES,
};
export const DOWNLOAD_APPLE_BUTTON_VISIBLE = {
  ...EventProperties.IMPRESSION,
  ...DOWNLOAD_APPLE_BUTTON_PROPERTIES,
};

const TERMS_AND_CONDITIONS_PROPERTIES = {
  ...ObjectProperties.BUTTON,
  position: '0',
};

export const TERMS_OF_USE_MOTORISTS_CLICK = {
  ...EventProperties.CLICK,
  ...TERMS_AND_CONDITIONS_PROPERTIES,
  name: 'TermsOfUseMotorists'
};
export const TERMS_OF_USE_MOTORISTS_IMPRESSION = {
  ...EventProperties.IMPRESSION,
  ...TERMS_AND_CONDITIONS_PROPERTIES,
  name: 'TermsOfUseMotorists'
};
export const PRIVACY_POLICY_CLICK = {
  ...EventProperties.CLICK,
  ...TERMS_AND_CONDITIONS_PROPERTIES,
  name: 'PrivacyPolicy'
};
export const PRIVACY_POLICY_IMPRESSION = {
  ...EventProperties.IMPRESSION,
  ...TERMS_AND_CONDITIONS_PROPERTIES,
  name: 'PrivacyPolicy'
};
export const LEGAL_TERMS_CLICK = {
  ...EventProperties.CLICK,
  ...TERMS_AND_CONDITIONS_PROPERTIES,
  name: 'LegalTerms'
};
export const LEGAL_TERMS_IMPRESSION = {
  ...EventProperties.IMPRESSION,
  ...TERMS_AND_CONDITIONS_PROPERTIES,
  name: 'LegalTerms'
};

const DOWNLOAD_ANDROID_BUTTON_PROPERTIES = {
  ...ObjectProperties.BUTTON,
  name: 'Download (Google Play)',
  position: '0',
};

export const DOWNLOAD_ANDROID_BUTTON_CLICK = {
  ...EventProperties.CLICK,
  ...DOWNLOAD_ANDROID_BUTTON_PROPERTIES,
};

export const DOWNLOAD_ANDROID_BUTTON_VISIBLE = {
  ...EventProperties.IMPRESSION,
  ...DOWNLOAD_ANDROID_BUTTON_PROPERTIES,
};

const DOWNLOAD_ONELINK_BUTTON_PROPERTIES = {
  ...ObjectProperties.BUTTON,
  name: 'Download (OneLink)',
  position: '0',
};

export const DOWNLOAD_ONELINK_BUTTON_CLICK = {
  ...EventProperties.CLICK,
  ...DOWNLOAD_ONELINK_BUTTON_PROPERTIES,
};

export const DOWNLOAD_ONELINK_BUTTON_VISIBLE = {
  ...EventProperties.IMPRESSION,
  ...DOWNLOAD_ONELINK_BUTTON_PROPERTIES,
};

const SIGN_IN_FORM_PROPERTIES = {
  ...ObjectProperties.FORM,
  name: 'Sign In',
};

export const SIGN_IN_FORM_IMPRESSION = {
  ...EventProperties.IMPRESSION,
  ...SIGN_IN_FORM_PROPERTIES,
};

export const SIGN_IN_FORM_SUBMIT = {
  ...EventProperties.SUBMIT,
  ...SIGN_IN_FORM_PROPERTIES,
};

const SMART_BANNER_PROPERTIES = {
  ...ObjectProperties.BUTTON,
  name: 'Smart Banner',
};

const SMART_BANNER_DISMISS_PROPERTIES = {
  ...ObjectProperties.BUTTON,
  name: 'Smart Banner Dismiss',
};

export const SMART_BANNER_IMPRESSION = {
  ...SMART_BANNER_PROPERTIES,
  ...EventProperties.IMPRESSION,
};

export const SMART_BANNER_CLICK = {
  ...SMART_BANNER_PROPERTIES,
  ...EventProperties.CLICK,
};

export const SMART_BANNER_DISMISS_IMPRESSION = {
  ...SMART_BANNER_DISMISS_PROPERTIES,
  ...EventProperties.IMPRESSION,
};

export const SMART_BANNER_DISMISS_CLICK = {
  ...SMART_BANNER_DISMISS_PROPERTIES,
  ...EventProperties.CLICK,
};

export const SORT_BUTTON_IMPRESSION = {
  ...ObjectProperties.BUTTON,
  ...EventProperties.IMPRESSION,
  name: 'Sort',
};

export const SORT_BUTTON_CLICK = {
  ...ObjectProperties.BUTTON,
  ...EventProperties.CLICK,
  name: 'Sort',
};

const CHECKOUT_AUTO_EXTEND_LABEL = {
  ...ObjectProperties.BUTTON,
  name: 'Auto Extend Label',
  position: '0',
};

export const CHECKOUT_AUTO_EXTEND_LABEL_IMPRESSION = {
  ...CHECKOUT_AUTO_EXTEND_LABEL,
  ...EventProperties.IMPRESSION,
};

export const CHECKOUT_AUTO_EXTEND_LABEL_CLICK = {
  ...CHECKOUT_AUTO_EXTEND_LABEL,
  ...EventProperties.CLICK,
};

const CHECKOUT_AUTO_EXTEND_TOOLTIP = {
  ...ObjectProperties.BUTTON,
  name: 'Auto Extend Tooltip',
  position: '0',
};

export const CHECKOUT_AUTO_EXTEND_TOOLTIP_IMPRESSION = {
  ...CHECKOUT_AUTO_EXTEND_TOOLTIP,
  ...EventProperties.IMPRESSION,
};

export const CHECKOUT_AUTO_EXTEND_TOOLTIP_CLICK = {
  ...CHECKOUT_AUTO_EXTEND_TOOLTIP,
  ...EventProperties.CLICK,
};

const LOCATION_DETAILS_NAVIGATION = {
  ...ObjectProperties.BUTTON,
};

const LOCATION_DETAILS_ALL_LOCATIONS = {
  ...LOCATION_DETAILS_NAVIGATION,
  name: 'ViewAllLocations',
  position: '0',
};

const LOCATION_DETAILS_PREV_LOCATION = {
  ...LOCATION_DETAILS_NAVIGATION,
  name: 'PreviousLocation',
  position: '1',
};

const LOCATION_DETAILS_NEXT_LOCATION = {
  ...LOCATION_DETAILS_NAVIGATION,
  name: 'NextLocation',
  position: '2',
};

export const LOCATION_DETAILS_ALL_LOCATIONS_IMPRESSION = {
  ...LOCATION_DETAILS_ALL_LOCATIONS,
  ...EventProperties.IMPRESSION,
};

export const LOCATION_DETAILS_ALL_LOCATIONS_CLICK = {
  ...LOCATION_DETAILS_ALL_LOCATIONS,
  ...EventProperties.CLICK,
};

export const LOCATION_DETAILS_PREV_LOCATION_IMPRESSION = {
  ...LOCATION_DETAILS_PREV_LOCATION,
  ...EventProperties.IMPRESSION,
};

export const LOCATION_DETAILS_PREV_LOCATION_CLICK = {
  ...LOCATION_DETAILS_PREV_LOCATION,
  ...EventProperties.CLICK,
};
export const LOCATION_DETAILS_NEXT_LOCATION_IMPRESSION = {
  ...LOCATION_DETAILS_NEXT_LOCATION,
  ...EventProperties.IMPRESSION,
};

export const LOCATION_DETAILS_NEXT_LOCATION_CLICK = {
  ...LOCATION_DETAILS_NEXT_LOCATION,
  ...EventProperties.CLICK,
};

const MAP_LOCATION_LISTING = {
  ...ObjectProperties.CARD,
  name: 'LocationCard',
};

export const MAP_LOCATION_LISTING_IMPRESSION = {
  ...MAP_LOCATION_LISTING,
  ...EventProperties.IMPRESSION,
};

export const MAP_LOCATION_LISTING_CLICK = {
  ...MAP_LOCATION_LISTING,
  ...EventProperties.CLICK,
};

const BOOK_NOW = {
  ...ObjectProperties.BUTTON,
  name: 'BookNow',
};

export const BOOK_NOW_IMPRESSION = {
  ...BOOK_NOW,
  ...EventProperties.IMPRESSION,
};

export const BOOK_NOW_CLICK = {
  ...BOOK_NOW,
  ...EventProperties.CLICK,
};

export const CHECKOUT_PURCHASE = {
  ...EventProperties.PURCHASE,
  ...ObjectProperties.ANALYTICS_EVENT,
  name: 'Purchase',
};

export const BOUNDS_SEARCH = {
  name: 'Search',
  ...ObjectProperties.MAP_PAN_ZOOM,
  ...EventProperties.SEARCH,
};

export const DESTINATION_SEARCH = {
  name: 'Input',
  ...ObjectProperties.DESTINATION_SEARCH,
  ...EventProperties.SEARCH,
};

const AIRPORT_MAP_VENUE_INFO_DROPDOWN = {
  ...ObjectProperties.DROPDOWN,
  name: 'SEM',
  position: '0',
};

export const AIRPORT_MAP_VENUE_INFO_DROPDOWN_IMPRESSION = {
  ...AIRPORT_MAP_VENUE_INFO_DROPDOWN,
  ...EventProperties.IMPRESSION,
};

export const AIRPORT_MAP_VENUE_INFO_DROPDOWN_CLICK = {
  ...AIRPORT_MAP_VENUE_INFO_DROPDOWN,
  ...EventProperties.CLICK,
};

const AIRPORT_MAP_VENUE_INFO_CONTENT = {
  ...ObjectProperties.CONTENT,
  name: 'SEM',
  position: '0',
};

export const AIRPORT_MAP_VENUE_INFO_CONTENT_IMPRESSION = {
  ...AIRPORT_MAP_VENUE_INFO_CONTENT,
  ...EventProperties.IMPRESSION,
};


export const SEARCH_FILTER_IMPRESSION = {
  name: 'Filter',
  ...ObjectProperties.FILTER,
  ...EventProperties.IMPRESSION,
};

export const SEARCH_FILTER_CLICK = {
  name: 'Filter',
  ...ObjectProperties.FILTER,
  ...EventProperties.CLICK,
};

export const SEARCH_FILTER_DROPDOWN_IMPRESSION = {
  ...ObjectProperties.DROPDOWN,
  ...EventProperties.IMPRESSION,
};

export const SEARCH_FILTER_DROPDOWN_CLICK = {
  ...ObjectProperties.DROPDOWN,
  ...EventProperties.CLICK,
};

const SEARCH_MAP_TOGGLE = {
  ...ObjectProperties.BUTTON,
  name: 'ViewInMap',
};

export const SEARCH_MAP_TOGGLE_CLICK = {
  ...SEARCH_MAP_TOGGLE,
  ...EventProperties.CLICK,
};

export const SEARCH_MAP_TOGGLE_IMPRESSION = {
  ...SEARCH_MAP_TOGGLE,
  ...EventProperties.IMPRESSION,
};

const VIEW_ALL_BUTTON = {
  ...ObjectProperties.BUTTON,
  name: 'ViewAll',
  position: '0',
};

export const VIEW_ALL_BUTTON_IMPRESSION = {
  ...VIEW_ALL_BUTTON,
  ...EventProperties.IMPRESSION,
};

export const VIEW_ALL_BUTTON_CLICK = {
  ...VIEW_ALL_BUTTON,
  ...EventProperties.CLICK,
};

const LOCATION_DETAILS_CONTENT = {
  ...ObjectProperties.CONTENT,
  name: 'LocationDetails',
  position: '0',
};

export const LOCATION_DETAILS_CONTENT_IMPRESSION = {
  ...LOCATION_DETAILS_CONTENT,
  ...EventProperties.IMPRESSION,
};

export const LOCATION_DETAILS_CONTENT_CLICK = {
  ...LOCATION_DETAILS_CONTENT,
  ...EventProperties.CLICK,
};

const PARK_HERE_BUTTON = {
  ...ObjectProperties.BUTTON,
  name: 'ParkHere',
  position: '0',
};

export const PARK_HERE_BUTTON_IMPRESSION = {
  ...PARK_HERE_BUTTON,
  ...EventProperties.IMPRESSION,
};

export const PARK_HERE_BUTTON_CLICK = {
  ...PARK_HERE_BUTTON,
  ...EventProperties.CLICK,
};


const PRICING_TIER_OPTION = {
  ...ObjectProperties.OPTION,
};

export const PRICING_TIER_OPTION_IMPRESSION = {
  ...PRICING_TIER_OPTION,
  ...EventProperties.IMPRESSION,
};

export const PRICING_TIER_OPTION_CLICK = {
  ...PRICING_TIER_OPTION,
  ...EventProperties.CLICK,
};

const LOCATION_GALLERY_CONTENT = {
  ...ObjectProperties.CONTENT,
  name: 'Gallery',
  position: '0',
};

export const LOCATION_GALLERY_CONTENT_IMPRESSION = {
  ...LOCATION_GALLERY_CONTENT,
  ...EventProperties.IMPRESSION,
};

export const LOCATION_GALLERY_CONTENT_CLICK = {
  ...LOCATION_GALLERY_CONTENT,
  ...EventProperties.CLICK,
};

const LOCATION_GALLERY_BUTTON = {
  ...ObjectProperties.BUTTON,
  name: 'Gallery',
};

export const LOCATION_GALLERY_BUTTON_IMPRESSION = {
  ...LOCATION_GALLERY_BUTTON,
  ...EventProperties.IMPRESSION,
};

export const LOCATION_GALLERY_BUTTON_CLICK = {
  ...LOCATION_GALLERY_BUTTON,
  ...EventProperties.CLICK,
};

const LOCATION_SHUTTLE_CONTENT = {
  ...ObjectProperties.CONTENT,
  name: 'ShuttleInfo',
  position: '1',
};

export const LOCATION_SHUTTLE_CONTENT_IMPRESSION = {
  ...LOCATION_SHUTTLE_CONTENT,
  ...EventProperties.IMPRESSION,
};

export const LOCATION_SHUTTLE_CONTENT_CLICK = {
  ...LOCATION_SHUTTLE_CONTENT,
  ...EventProperties.CLICK,
};

const LOCATION_AMENITIES_CONTENT = {
  ...ObjectProperties.CONTENT,
  name: 'Amenities',
  position: '2',
};

export const LOCATION_AMENITIES_CONTENT_IMPRESSION = {
  ...LOCATION_AMENITIES_CONTENT,
  ...EventProperties.IMPRESSION,
};

export const LOCATION_AMENITIES_CONTENT_CLICK = {
  ...LOCATION_AMENITIES_CONTENT,
  ...EventProperties.CLICK,
};

const LOCATION_MAP_CONTENT = {
  ...ObjectProperties.CONTENT,
  name: 'Map',
  position: '3',
};

export const LOCATION_MAP_CONTENT_IMPRESSION = {
  ...LOCATION_MAP_CONTENT,
  ...EventProperties.IMPRESSION,
};

export const LOCATION_MAP_CONTENT_CLICK = {
  ...LOCATION_MAP_CONTENT,
  ...EventProperties.CLICK,
};

const LOCATION_NEED_TO_KNOW_CONTENT = {
  ...ObjectProperties.CONTENT,
  name: 'NeedToKnow',
  position: '4',
};

export const LOCATION_NEED_TO_KNOW_CONTENT_IMPRESSION = {
  ...LOCATION_NEED_TO_KNOW_CONTENT,
  ...EventProperties.IMPRESSION,
};

export const LOCATION_NEED_TO_KNOW_CONTENT_CLICK = {
  ...LOCATION_NEED_TO_KNOW_CONTENT,
  ...EventProperties.CLICK,
};

const LOCATION_HOW_TO_PARK_CONTENT = {
  ...ObjectProperties.CONTENT,
  name: 'HowToPark',
  position: '5',
};

export const LOCATION_HOW_TO_PARK_CONTENT_IMPRESSION = {
  ...LOCATION_HOW_TO_PARK_CONTENT,
  ...EventProperties.IMPRESSION,
};

export const LOCATION_HOW_TO_PARK_CONTENT_CLICK = {
  ...LOCATION_HOW_TO_PARK_CONTENT,
  ...EventProperties.CLICK,
};

const PREVIOUS_IMAGE_BUTTON = {
  ...ObjectProperties.BUTTON,
  position: '0',
  name: 'ChangePhotoLeft',
};

export const PREVIOUS_IMAGE_BUTTON_CLICK = {
  ...PREVIOUS_IMAGE_BUTTON,
  ...EventProperties.CLICK,
};

const NEXT_IMAGE_BUTTON = {
  ...ObjectProperties.BUTTON,
  position: '1',
  name: 'ChangePhotoRight',
};

export const NEXT_IMAGE_BUTTON_CLICK = {
  ...NEXT_IMAGE_BUTTON,
  ...EventProperties.CLICK,
};

export const LOCATION_DETAILS_IMPRESSION = {
  ...ObjectProperties.CONTENT,
  ...EventProperties.IMPRESSION,
  name: 'LocationDetails',
  position: '0',
};

export const TIME_IN_IMPRESSION = {
  ...ObjectProperties.BUTTON,
  ...EventProperties.IMPRESSION,
  name: 'TimeIn',
  position: '0',
};

export const TIME_OUT_IMPRESSION = {
  ...ObjectProperties.BUTTON,
  ...EventProperties.IMPRESSION,
  name: 'TimeOut',
  position: '1',
};

export const PRICING_OPTION_IMPRESSION = {
  ...ObjectProperties.BUTTON,
  ...EventProperties.IMPRESSION,
  name: 'PricingOption',
};

export const PRICE_IMPRESSION = {
  ...ObjectProperties.CONTENT,
  ...EventProperties.IMPRESSION,
  name: 'Price',
  position: '0',
};

export const ADDITIONS_IMPRESSION = {
  ...ObjectProperties.BUTTON,
  ...EventProperties.IMPRESSION,
  name: 'Additions',
};

export const ACCOUNT_IMPRESSION = {
  ...ObjectProperties.BUTTON,
  ...EventProperties.IMPRESSION,
  name: 'Account',
  position: '0',
};

export const LOGOUT_IMPRESSION = {
  ...ObjectProperties.BUTTON,
  ...EventProperties.IMPRESSION,
  name: 'Logout',
  position: '1',
};

export const FREE_EASY_CANCELLATION_IMPRESSION = {
  ...ObjectProperties.CONTENT,
  ...EventProperties.IMPRESSION,
  name: 'FreeEasyCancellations',
  position: '0',
};

export const PRIVACY_IMPRESSION = {
  ...ObjectProperties.CONTENT,
  ...EventProperties.IMPRESSION,
  name: 'Privacy',
  position: '1',
};

export const BRAIN_TREE_IMPRESSION = {
  ...ObjectProperties.BUTTON,
  ...EventProperties.IMPRESSION,
  name: 'Braintree',
  position: '0',
};

export const PAYMENT_DETAILS_IMPRESSION = {
  ...ObjectProperties.BUTTON,
  ...EventProperties.IMPRESSION,
  name: 'PaymentDetails',
  position: '0',
};

export const PROMO_CODE_IMPRESSION = {
  ...ObjectProperties.BUTTON,
  ...EventProperties.IMPRESSION,
  name: 'PromoCodeApply',
  position: '0',
};

export const COMPLETE_PURCHASE_IMPRESSION = {
  ...ObjectProperties.BUTTON,
  ...EventProperties.IMPRESSION,
  name: 'CompletePurchase',
  position: '0',
};

export const COMPLETE_PURCHASE_CLICK = {
  ...ObjectProperties.BUTTON,
  ...EventProperties.CLICK,
  name: 'CompletePurchase',
  position: '0',
};

export const APPLE_PAY_IMPRESSION = {
  ...ObjectProperties.BUTTON,
  ...EventProperties.IMPRESSION,
  name: 'ApplePay',
  position: '0',
};

export const APPLE_PAY_CLICK = {
  ...ObjectProperties.BUTTON,
  ...EventProperties.CLICK,
  name: 'ApplePay',
  position: '0',
};

export const PRICING_OPTION_CLICK = {
  ...ObjectProperties.BUTTON,
  ...EventProperties.CLICK,
  name: 'PricingOption',
};

export const PRICE_CLICK = {
  ...ObjectProperties.CONTENT,
  ...EventProperties.CLICK,
  name: 'Price',
  position: '0',
};

export const FREE_EASY_CANCELLATION_CLICK = {
  ...ObjectProperties.CONTENT,
  ...EventProperties.CLICK,
  name: 'FreeEasyCancellations',
  position: '0',
};

export const BRAIN_TREE_CLICK = {
  ...ObjectProperties.BUTTON,
  ...EventProperties.CLICK,
  name: 'Braintree',
  position: '0',
};

export const PRIVACY_CLICK = {
  ...ObjectProperties.CONTENT,
  ...EventProperties.CLICK,
  name: 'Privacy',
  position: '0',
};

export const PAYMENT_DETAILS_CLICK = {
  ...ObjectProperties.BUTTON,
  ...EventProperties.CLICK,
  name: 'PaymentDetails',
  position: '0',
};

export const PROMO_CODE_APPLY = {
  ...ObjectProperties.BUTTON,
  ...EventProperties.CLICK,
  name: 'PromoCodeApply',
  position: '0',
};

export const SEARCH_RETURNED = {
  ...EventProperties.SEARCH,
  ...ObjectProperties.ANALYTICS_EVENT,
  name: 'SearchReturned',
};

const ACTIVATE_SETUP_PASSWORD_BUTTON = {
  ...ObjectProperties.BUTTON,
  position: '0',
  name: 'SetUpPassword',
};

export const ACTIVATE_SETUP_PASSWORD_BUTTON_IMPRESSION = {
  ...ACTIVATE_SETUP_PASSWORD_BUTTON,
  ...EventProperties.IMPRESSION,
};

export const ACTIVATE_SETUP_PASSWORD_BUTTON_CLICK = {
  ...ACTIVATE_SETUP_PASSWORD_BUTTON,
  ...EventProperties.CLICK,
};

const ACTIVATE_NO_THANKS_BUTTON = {
  ...ObjectProperties.BUTTON,
  position: '1',
  name: 'NoThanks',
};

export const ACTIVATE_NO_THANKS_BUTTON_IMPRESSION = {
  ...ACTIVATE_NO_THANKS_BUTTON,
  ...EventProperties.IMPRESSION,
};

export const ACTIVATE_NO_THANKS_BUTTON_CLICK = {
  ...ACTIVATE_NO_THANKS_BUTTON,
  ...EventProperties.CLICK,
};

const ACTIVATE_CLOSE_BUTTON = {
  ...ObjectProperties.BUTTON,
  position: '2',
  name: 'Close',
};

export const ACTIVATE_CLOSE_BUTTON_IMPRESSION = {
  ...ACTIVATE_CLOSE_BUTTON,
  ...EventProperties.IMPRESSION,
};

export const ACTIVATE_CLOSE_BUTTON_CLICK = {
  ...ACTIVATE_CLOSE_BUTTON,
  ...EventProperties.CLICK,
};

export const BUTTON_IMPRESSION = {
  ...ObjectProperties.BUTTON,
  ...EventProperties.IMPRESSION,
};

export const BUTTON_CLICK = {
  ...ObjectProperties.BUTTON,
  ...EventProperties.CLICK,
};

export const INPUT_FOCUS = {
  ...ObjectProperties.INPUT,
  ...EventProperties.FOCUS,
  position: '0',
};

export const INPUT_CHANGE = {
  ...ObjectProperties.INPUT,
  ...EventProperties.CHANGE,
  position: '0',
};

export const INPUT_CLICK = {
  ...ObjectProperties.INPUT,
  ...EventProperties.CLICK,
  position: '0',
};

export const INPUT_IMPRESSION = {
  ...ObjectProperties.INPUT,
  ...EventProperties.IMPRESSION,
  position: '0',
};

export const OPTION_IMPRESSION = {
  ...ObjectProperties.OPTION,
  ...EventProperties.IMPRESSION,
  position: '0',
};

export const OPTION_CLICK = {
  ...ObjectProperties.OPTION,
  ...EventProperties.CLICK,
  position: '0',
};

export const TOP_PRICE_IMPRESSION = {
  ...ObjectProperties.CONTENT,
  ...EventProperties.IMPRESSION,
  position: '0',
  name: 'TopPrice',
};

export const NOTICE_IMPRESSION = {
  ...ObjectProperties.NOTICE,
  ...EventProperties.IMPRESSION,
};

export const NOTICE_CLICK = {
  ...ObjectProperties.NOTICE,
  ...EventProperties.CLICK,
};

const EXISTING_ACCOUNT_LOGIN_PROPERTIES = {
  ...ObjectProperties.BUTTON,
  name: 'ExistingAccountLogin',
  position: '0',
};

export const EXISTING_ACCOUNT_LOGIN_IMPRESSION = {
  ...EXISTING_ACCOUNT_LOGIN_PROPERTIES,
  ...EventProperties.IMPRESSION,
};

export const EXISTING_ACCOUNT_LOGIN_CLICK = {
  ...EXISTING_ACCOUNT_LOGIN_PROPERTIES,
  ...EventProperties.CLICK,
};

const NEW_PAYMENT_METHOD_PROPERTIES = {
  ...ObjectProperties.BUTTON,
  name: 'NewPaymentMethod',
  position: '0',
};

export const NEW_PAYMENT_METHOD_IMPRESSION = {
  ...NEW_PAYMENT_METHOD_PROPERTIES,
  ...EventProperties.IMPRESSION,
};

export const NEW_PAYMENT_METHOD_CLICK = {
  ...NEW_PAYMENT_METHOD_PROPERTIES,
  ...EventProperties.CLICK,
};

const PAYPAL_BUTTON_PROPERTIES = {
  ...ObjectProperties.BUTTON,
  name: 'PayPal',
  position: '0',
};

export const PAYPAL_BUTTON_IMPRESSION = {
  ...PAYPAL_BUTTON_PROPERTIES,
  ...EventProperties.IMPRESSION,
};

export const PAYPAL_BUTTON_CLICK = {
  ...PAYPAL_BUTTON_PROPERTIES,
  ...EventProperties.CLICK,
};

const MESSAGE_BANNER_PROPERTIES = {
  ...ObjectProperties.BANNER,
  name: 'MessageBanner',
  position: '0',
};

export const MESSAGE_BANNER_IMPRESSION = {
  ...MESSAGE_BANNER_PROPERTIES,
  ...EventProperties.IMPRESSION,
};

export const MESSAGE_BANNER_CLICK = {
  ...MESSAGE_BANNER_PROPERTIES,
  ...EventProperties.CLICK,
};

const ADD_TIME_BUTTON_PROPERTIES = {
  ...ObjectProperties.BUTTON,
  name: 'AddTime',
  position: '0',
};

export const ADD_TIME_BUTTON_IMPRESSION = {
  ...ADD_TIME_BUTTON_PROPERTIES,
  ...EventProperties.IMPRESSION,
};

export const ADD_TIME_BUTTON_CLICK = {
  ...ADD_TIME_BUTTON_PROPERTIES,
  ...EventProperties.CLICK,
};

const LOCALE_SELECTION_PROPERTIES = {
  ...ObjectProperties.DROPDOWN,
  name: 'Locale',
  position: '0',
};

export const LOCALE_SELECTION_IMPRESSION = {
  ...LOCALE_SELECTION_PROPERTIES,
  ...EventProperties.IMPRESSION,
};

export const LOCALE_SELECTION_CLICK = {
  ...LOCALE_SELECTION_PROPERTIES,
  ...EventProperties.CLICK,
};

const EMAIL_OPT_IN_PROPERTIES = {
  ...ObjectProperties.BUTTON,
  name: 'emailOptIn',
  position: '0',
};

export const EMAIL_OPT_IN_IMPRESSION = {
  ...EMAIL_OPT_IN_PROPERTIES,
  ...EventProperties.IMPRESSION,
};

export const EMAIL_OPT_IN_CLICK = {
  ...EMAIL_OPT_IN_PROPERTIES,
  ...EventProperties.CLICK,
};

export const HOUR_BUFFER_MODAL_IMPRESSION = {
  ...ObjectProperties.MODAL,
  ...EventProperties.IMPRESSION,
  name: 'HourBufferModal',
  position: '0',
};


const HOUR_BUFFER_MODAL_CONFIRM_PROPERTIES = {
  ...ObjectProperties.BUTTON,
  name: 'ConfirmHourBufferModal',
  position: '0',
};

export const HOUR_BUFFER_MODAL_CONFIRM_IMPRESSION = {
  ...HOUR_BUFFER_MODAL_CONFIRM_PROPERTIES,
  ...EventProperties.IMPRESSION,
};

export const HOUR_BUFFER_MODAL_CONFIRM_CLICK = {
  ...HOUR_BUFFER_MODAL_CONFIRM_PROPERTIES,
  ...EventProperties.CLICK,
};

const HOUR_BUFFER_MODAL_CLOSE_PROPERTIES = {
  ...ObjectProperties.BUTTON,
  name: 'CloseHourBufferModal',
  position: '0',
};

export const HOUR_BUFFER_MODAL_CLOSE_IMPRESSION = {
  ...HOUR_BUFFER_MODAL_CLOSE_PROPERTIES,
  ...EventProperties.IMPRESSION,
};

export const HOUR_BUFFER_MODAL_CLOSE_CLICK = {
  ...HOUR_BUFFER_MODAL_CLOSE_PROPERTIES,
  ...EventProperties.CLICK,
};

export const UPEXPRESS_PROPERTIES = {
  pageType: 'Venue',
  pageName: 'Venue',
  type: 'Banner',
  name: 'LocationHighlight',
};

export const UPEXPRESS_IMPRESSION = {
  ...UPEXPRESS_PROPERTIES,
  ...EventProperties.IMPRESSION,
};

export const UPEXPRESS_CLICK = {
  ...UPEXPRESS_PROPERTIES,
  ...EventProperties.CLICK,
};

const PARKING_TYPE_TOGGLE_NAME = 'ParkingTypeToggle';

export const PARKING_TYPE_TOGGLE_IMPRESSION = {
  ...EventProperties.IMPRESSION,
  ...ObjectProperties.BUTTON,
  name: PARKING_TYPE_TOGGLE_NAME,
};

export const PARKING_TYPE_TOGGLE_CLICK = {
  ...EventProperties.CLICK,
  ...ObjectProperties.BUTTON,
  name: PARKING_TYPE_TOGGLE_NAME,
};
