import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import cx from 'classnames';

import DatePicker from 'components/common/date-picker';

const propTypes = {
  label: PropTypes.string.isRequired,
  time: PropTypes.instanceOf(moment).isRequired,
  timezone: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
};

class MobileDestinationTimePickerRow extends Component {
  constructor(props) {
    super(props);

    this.state = {
      time: moment(props.time).tz(props.timezone),
      showCalendar: false,
    };

    this.toggleCalendar = this.toggleCalendar.bind(this);
    this.timeChanged = this.timeChanged.bind(this);
    this.dateChanged = this.dateChanged.bind(this);
    this.onUpdate = this.onUpdate.bind(this);
    this.formatDate = this.formatDate.bind(this);
  }

  componentDidMount() {
    this.timeInput.value = this.state.time.format('HH:mm');
  }

  componentWillUpdate(nextProps) {
    const { time } = nextProps;

    if (!time.isSame(this.props.time)) {
      this.setState({ time });
    }
  }

  componentDidUpdate(prevProps) {
    const { time } = this.props;
    if (!time.isSame(prevProps.time)) {
      this.timeInput.value = time.format('HH:mm');
    }
  }

  onUpdate() {
    const { time } = this.state;
    this.props.onUpdate(time);
  }

  dateChanged(value) {
    let { time } = this.state;
    const date = value.get('date');
    const month = value.get('month');
    const year = value.get('year');
    time = time.clone().set({ date, month, year });
    this.props.onUpdate(time);
    this.toggleCalendar();
  }

  timeChanged() {
    const t = this.timeInput.value;
    const hours = parseInt(t.split(':')[0], 10);
    const minutes = parseInt(t.split(':')[1], 10);
    const time = this.state.time.clone().hours(hours).minutes(minutes);
    this.setState({ time });
  }

  toggleCalendar() {
    this.setState({ showCalendar: !this.state.showCalendar });
  }

  formatDate() {
    const { time } = this.state;
    const now = moment();

    if (time.isSame(now, 'day')) {
      return 'TODAY';
    }

    return time.format('ddd MMM D');
  }

  render() {
    const calendarClasses = cx({
      'row': true,
      'clean-calendar': true,
      'hidden': !this.state.showCalendar,
    });

    return (
      <div>
        <div className="row mobile-time-row">
          <div className="col-xs-5 text-size-12 col-gutter-right-0 text-color-dark-slate text-weight-bold action-label">
            {this.props.label}
          </div>
          <div
            className="col-xs-3 text-size-12 text-size-10 col-gutter-8 col-gutter-left-0 date-label uppercase"
            onClick={this.toggleCalendar}
          >
            {this.formatDate()}
          </div>
          <div className="col-xs-4 time-label">
            <input
              type="time"
              ref={(i) => { this.timeInput = i; }}
              onChange={this.timeChanged}
              onBlur={this.onUpdate}
              step="300"
            />
          </div>
        </div>
        <div className={calendarClasses}>
          <div className="picker-date-wrapper">
            <DatePicker
              date={this.state.time.clone().startOf('day')}
              minDate={moment().startOf('day')}
              onDateChange={this.dateChanged}
            />
          </div>
        </div>
      </div>
    );
  }
}

MobileDestinationTimePickerRow.propTypes = propTypes;

export default MobileDestinationTimePickerRow;
