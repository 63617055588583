import { Sellers } from 'models/seller';

export const GOT_SELLER = 'got-seller';

export default function gotSeller(body) {
  const sellers = Sellers([body]);
  return {
    type: GOT_SELLER,
    payload: {
      sellers,
    },
  };
}
