import { PaymentMethods } from 'models/payment-method';

export const GOT_PAYMENT_METHODS = 'got-payment-methods';

export default function gotPaymentMethods(paymentMethodsBody) {
  const paymentMethods = PaymentMethods(paymentMethodsBody);
  const defaultPaymentMethod = paymentMethods.find(p => p.isDefault);


  return {
    type: GOT_PAYMENT_METHODS,
    payload: {
      paymentMethods,
      defaultPaymentMethodId: defaultPaymentMethod && defaultPaymentMethod.id,
    },
  };
}
