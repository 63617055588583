import { List } from 'immutable';
import React from 'react';
import Highlighter from 'react-highlight-words';

import Event from 'models/event';
import Venue from 'models/venue';

class EventPrediction {
  constructor(props) {
    const event = new Event(props);
    const venue = new Venue(props._embedded ? props._embedded['pw:venue'] : {});

    const mainText = (<Highlighter
      textToHighlight={event.name}
      searchWords={props.input && props.input.split(' ')}
      highlightClassName="text-weight-bold"
    />);
    const secondaryText = (<Highlighter
      textToHighlight={`${event.startTime.format('MMM D')} - ${venue.name} ${venue.city}, ${venue.state}`}
      searchWords={props.input && props.input.split(' ')}
      highlightClassName="text-weight-bold"
    />);
    const description = `${event.name}, ${event.startTime.format('MMM D')} - ${venue.name} ${venue.city}, ${venue.state}}`;

    Object.assign(this, {
      description,
      mainText,
      secondaryText,
      slug: event.url,
      id: event.id,
      input: props.input,
    });
  }
}

export default EventPrediction;

export function EventPredictions(eventResults, maxPredictions, input) {
  const events = eventResults.slice(0, maxPredictions).map(p => (new EventPrediction({ input, ...p })));
  return List(events);
}
