import React from 'react';

const LoadingArrow = () => (
  <svg width="23px" height="23px" viewBox="0 0 23 23" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-281.000000, -669.000000)" fill="#10D492">
        <path d="M288.982807,669.378948 L282.494991,670.29687 L284.879981,672.68186 C283.219473,674.414447 282.204327,676.581522 281.905952,678.848503 C281.596849,681.18991 282.039383,683.62854 283.283506,685.781534 C285.770413,690.091211 290.850166,692.195929 295.656352,690.908558 C300.461198,689.619845 303.811045,685.257534 303.811045,680.281374 C303.811045,675.307561 300.461198,670.943909 295.656352,669.655196 L295.250361,671.175569 C299.376989,672.280898 302.239714,676.013269 302.239714,680.285062 C302.239714,684.555849 299.376989,688.282857 295.250361,689.388186 C291.125408,690.49452 286.778854,688.695218 284.642957,684.995701 C282.547626,681.366923 283.117221,676.819887 285.994362,673.796241 L288.056838,675.858717 L288.280787,674.303143 L288.982807,669.378948 Z" />
      </g>
    </g>
  </svg>
);

export default LoadingArrow;
