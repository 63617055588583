import React from 'react';

export default class Expand extends React.Component {
  render() {
    return (
      <svg width="12px" height="11px" viewBox="0 0 12 11" version="1.1" className={this.props.className}>
          <defs></defs>
          <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="Bookings-&gt;-MyBookings-&gt;-Active" transform="translate(-832.000000, -2294.000000)" fill="#2AB7DF">
                  <g id="PAST" transform="translate(321.000000, 1242.000000)">
                      <g id="PastBookings" transform="translate(0.000000, 80.000000)">
                          <g id="showall" transform="translate(324.000000, 972.000000)">
                              <g id="expand_icon" transform="translate(187.000000, 0.000000)">
                                  <g>
                                      <path d="M11.1577773,5.22112424 C11.1577773,5.13168314 11.1130567,5.0310619 11.0459759,4.96398107 L10.486969,4.4049742 C10.4198882,4.33789337 10.319267,4.29317282 10.2298259,4.29317282 C10.1403848,4.29317282 10.0397635,4.33789337 9.9726827,4.4049742 L5.57888864,8.79876825 L1.18509458,4.4049742 C1.11801376,4.33789337 1.01739252,4.29317282 0.927951417,4.29317282 C0.827330179,4.29317282 0.737889078,4.33789337 0.670808253,4.4049742 L0.111801376,4.96398107 C0.0447205502,5.0310619 0,5.13168314 0,5.22112424 C0,5.31056534 0.0447205502,5.41118657 0.111801376,5.4782674 L5.32174547,10.6882115 C5.3888263,10.7552923 5.48944754,10.8000129 5.57888864,10.8000129 C5.66832974,10.8000129 5.76895098,10.7552923 5.8360318,10.6882115 L11.0459759,5.4782674 C11.1130567,5.41118657 11.1577773,5.31056534 11.1577773,5.22112424 L11.1577773,5.22112424 Z M11.1577773,0.927951417 C11.1577773,0.838510316 11.1130567,0.737889078 11.0459759,0.670808253 L10.486969,0.111801376 C10.4198882,0.0447205502 10.319267,0 10.2298259,0 C10.1403848,0 10.0397635,0.0447205502 9.9726827,0.111801376 L5.57888864,4.50559543 L1.18509458,0.111801376 C1.11801376,0.0447205502 1.01739252,0 0.927951417,0 C0.827330179,0 0.737889078,0.0447205502 0.670808253,0.111801376 L0.111801376,0.670808253 C0.0447205502,0.737889078 0,0.838510316 0,0.927951417 C0,1.01739252 0.0447205502,1.11801376 0.111801376,1.18509458 L5.32174547,6.39503868 C5.3888263,6.4621195 5.48944754,6.50684005 5.57888864,6.50684005 C5.66832974,6.50684005 5.76895098,6.4621195 5.8360318,6.39503868 L11.0459759,1.18509458 C11.1130567,1.11801376 11.1577773,1.01739252 11.1577773,0.927951417 L11.1577773,0.927951417 Z"></path>
                                  </g>
                              </g>
                          </g>
                      </g>
                  </g>
              </g>
          </g>
      </svg>
    );
  }
}
