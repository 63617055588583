import React, { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  currentFilter: PropTypes.string,
  displayTest: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
  className: PropTypes.string,
};

const defaultProps = {
  currentFilter: null,
  className: null,
};

const Filter = ({ children, displayTest, currentFilter, onFilter, className }) => {
  if (!displayTest() || !children) { return null; }

  const childArray = Array.isArray(children) ? children.filter(c => c) : [children];
  let filterIndex = childArray.indexOf(childArray.find(c => c.props.filter === currentFilter));
  filterIndex = Math.max(filterIndex, 0);

  const xsCols = children ? 12 / children.length : 6;

  return (
    <div className={`row filter ${className}`}>
      <div className={`filter-highlight col-md-2 col-xs-${xsCols} col-md-offset-${filterIndex * 2} col-xs-offset-${filterIndex * xsCols}`} />
      {Children.map(childArray, child => cloneElement(child, { onFilter, currentFilter, xsCols }))}
    </div>
  );
};

Filter.propTypes = propTypes;
Filter.defaultProps = defaultProps;

export default Filter;
