import { Record, List, Map } from 'immutable';
import { defineMessages } from 'react-intl';
import sortBy from 'lodash/sortBy';
import get from 'lodash/get';

import { SET_APP_NAME } from 'action-creators/app/set-app-name';
import { UPDATE_SEARCH } from 'action-creators/search/update-search';
import { CHANGE_LOCALE } from 'action-creators/app/change-locale';
import { GOT_TRANSLATIONS } from 'action-creators/app/got-translations';
import { INITIALIZE_RECEIPT } from 'action-creators/bookings/initialize-receipt';
import { INITIALIZE_PARKING_PASSES } from 'action-creators/bookings/initialize-parking-passes';
import { INITIALIZE_CHECKOUT } from 'action-creators/checkout/initialize-checkout';
import { INITIALIZE_SEARCH } from 'action-creators/search/initialize-search';
import { INITIALIZE_VENUE } from 'action-creators/search/initialize-venue';
import { INITIALIZE_HUB } from 'action-creators/search/initialize-hub';
import { INITIALIZE_SIGN_IN } from 'action-creators/account/initialize-sign-in';
import { INITIALIZE_SIGN_UP } from 'action-creators/account/initialize-sign-up';
import { INITIALIZE_HOME_PAGE } from 'action-creators/brand/initialize-home-page';
import { INITIALIZE_BUSINESS_PAGE } from 'action-creators/brand/initialize-business-page';
import { INITIALIZE_HOW_IT_WORKS } from 'action-creators/brand/initialize-how-it-works';
import { INITIALIZE_OUR_APPS_PAGE } from 'action-creators/brand/initialize-our-apps-page';
import { INITIALIZE_OAUTH_PAGE } from 'action-creators/brand/initialize-oauth-page';
import { INITIALIZE_CANCEL_BOOKING } from 'action-creators/bookings/initialize-cancel-booking';
import { INITIALIZE_BOOKINGS } from 'action-creators/bookings/initialize-bookings';
import { INITIALIZE_VEHICLES } from 'action-creators/account/initialize-vehicles';
import { INITIALIZE_PAYMENT_METHODS } from 'action-creators/account/initialize-payment-methods';
import { INITIALIZE_ACCOUNT_SETTINGS } from 'action-creators/account/initialize-account-settings';
import { INITIALIZE_PRESS_PAGE } from 'action-creators/app/initialize-press-page';

import Partner from 'models/partner';
import FeatureFlags from 'models/feature-flags';

import * as AppContext from 'lib/app-context';
import * as Apps from 'lib/app-names';
import { isValidLocale } from 'lib/locale';

export class AppState extends Record({
  name: Apps.HOME_APP,
  featureFlags: new FeatureFlags(),
  appContext: AppContext.DESKTOP,
  partner: new Partner(),
  partnerName: null,
  affiliatePartner: Map(),
  unsupportedBrowser: false,
  meta: null,
  parkingLocations: List(),
  footerQuestions: List(),
  pressPageInitialized: false,
  locale: 'en-us',
  translations: Map({
    'en-us': {},
    'fr-ca': {},
    'en-ca': {},
  }),
  directAffiliateTraffic: false,
  usejs: true,
}) {
  constructor(props) {
    if (!props) {
      super();
      return;
    }

    let parkingLocations = List();
    if (props.parkingLocations) {
      parkingLocations = List(sortBy(props.parkingLocations, ['country_override', 'city_state']));
    }

    let footerQuestions = List();
    if (props.footerQuestions) {
      footerQuestions = List(props.footerQuestions);
    }

    const translations = {};
    translations[props.locale] = props.translations;

    const meta = get(props, 'meta', null);

    super({
      name: props.app,
      featureFlags: new FeatureFlags(props.featureFlags),
      appContext: props.appContext,
      partner: new Partner(props.partner),
      partnerName: props.partnerName,
      affiliatePartner: Map(props.affiliatePartner),
      unsupportedBrowser: !!props.unsupportedBrowser,
      meta,
      parkingLocations,
      footerQuestions,
      translations: Map(translations),
      locale: props.locale,
      directAffiliateTraffic: !!props.directAffiliateTraffic,
      usejs: props.usejs,
    });
  }
}

export default function appReducer(state = new AppState(), action = null) {
  switch (action.type) {
    case SET_APP_NAME: {
      const { name } = action.payload;
      return state.merge({
        name,
      });
    }
    case UPDATE_SEARCH: {
      const { app, selectedLocation } = action.payload;
      const meta = get(selectedLocation, 'meta', null);
      return state.merge({
        name: app,
        meta,
      });
    }
    case INITIALIZE_RECEIPT:
      return state.merge({
        name: Apps.RECEIPT_APP,
      });
    case INITIALIZE_PARKING_PASSES:
      return state.merge({
        name: Apps.PARKING_PASS_APP,
      });
    case INITIALIZE_CHECKOUT:
      return state.merge({
        name: Apps.CHECKOUT_APP,
      });
    case INITIALIZE_SEARCH:
      return state.merge({
        name: Apps.SEARCH_APP,
      });
    case INITIALIZE_VENUE:
      return state.merge({
        name: Apps.VENUE_APP,
      });
    case INITIALIZE_HUB:
      return state.merge({
        name: Apps.HUB_APP,
      });
    case INITIALIZE_SIGN_IN:
      return state.merge({
        name: Apps.SIGN_IN_APP,
      });
    case INITIALIZE_SIGN_UP:
      return state.merge({
        name: Apps.SIGN_UP_APP,
      });
    case INITIALIZE_HOME_PAGE:
      return state.merge({
        name: Apps.HOME_APP,
      });
    case INITIALIZE_BUSINESS_PAGE:
      return state.merge({
        name: Apps.BUSINESS_APP,
      });
    case INITIALIZE_HOW_IT_WORKS:
      return state.merge({
        name: Apps.HOW_IT_WORKS_APP,
      });
    case INITIALIZE_OUR_APPS_PAGE:
      return state.merge({
        name: Apps.OUR_APPS_APP,
      });
    case INITIALIZE_OAUTH_PAGE:
      return state.merge({
        name: Apps.OAUTH_APP,
      });
    case INITIALIZE_CANCEL_BOOKING:
      return state.merge({
        name: Apps.CANCEL_APP,
      });
    case INITIALIZE_BOOKINGS:
      return state.merge({
        name: Apps.BOOKINGS_APP,
      });
    case INITIALIZE_VEHICLES:
      return state.merge({
        name: Apps.VEHICLES_APP,
      });
    case INITIALIZE_PAYMENT_METHODS:
      return state.merge({
        name: Apps.PAYMENT_METHODS_APP,
      });
    case INITIALIZE_ACCOUNT_SETTINGS:
      return state.merge({
        name: Apps.ACCOUNT_SETTINGS_APP,
      });
    case INITIALIZE_PRESS_PAGE:
      return state.merge({
        name: Apps.PRESS_APP,
        pressPageInitialized: true,
      });
    case GOT_TRANSLATIONS: {
      const { locale } = state;
      let { translations } = state;
      const { translations: newTranslations } = action.payload;

      translations = translations.set(
        locale,
        defineMessages({
          ...translations.get(locale),
          ...newTranslations,
        }),
      );

      return state.merge({ translations });
    }
    case CHANGE_LOCALE: {
      const { locale } = action.payload;
      if (isValidLocale(locale)) {
        return state.merge({ locale });
      }

      return state;
    }
    default:
      return state;
  }
}
