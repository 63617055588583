import React from 'react';
import PropTypes from 'prop-types';
import ProgressButton from 'components/common/buttons/progress-button';

class GateButtonModal extends React.Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);

    this.state = { loading: false };
  }

  onClick(e) {
    e.preventDefault();
    e.stopPropagation();

    const { openGate } = this.props;
    this.setState({ loading: true });
    openGate();
  }

  render() {
    const { dismissModal } = this.props;
    const { loading } = this.state;

    return (
      <div className="gate-open-wrapper">
        <div className="container">
          <div className="row">
            <h3 className="h3-sg text-color-red text-center text-weight-book with-vertical-margin-2x">ARE YOU SURE?</h3>
          </div>
          <div className="row">
            <div className="col-xs-8 col-xs-offset-2">
              <p className="text-color-slate text-center text-weight-light h6-sg line-height-extra">
                You can only open the gate once. It won&apos;t work twice. Still want to go forward?
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-10 col-xs-offset-1 modal-button-vertical-margin">
              <ProgressButton
                buttonClassName={'btn-block btn-primary text-center progress-btn-background-green'}
                loading={loading}
                onClick={this.onClick}
                textClassName={'btn-tall btn-text-size-3'}
              >
                YES, OPEN GATE
              </ProgressButton>
            </div>
          </div>
          <div className="row">
            <div className="text-center with-vertical-margin-2x text-weight-book">
              <a onClick={dismissModal}>NOT NOW</a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

GateButtonModal.propTypes = {
  openGate: PropTypes.func.isRequired,
  dismissModal: PropTypes.func.isRequired,
};

export default GateButtonModal;
