import React from "react";
import VisibilitySensor from "react-visibility-sensor";

export const LinkWithAnalytics = ({
    href,
    onClickEvent,
    onVisibilityEvent,
    children,
  }) => (
    <VisibilitySensor onChange={() => onVisibilityEvent()} partialVisibility>
      <a
        className="text-decoration-underline"
        target="_blank"
        href={href}
        onClick={() => onClickEvent()}
      >
        {children}
      </a>
    </VisibilitySensor>
  );