export const CHANGE_SEARCH_DESTINATION = 'change-search-destination';

export default function changeSearchDestination({ place, event, hub } = {}) {
  return {
    type: CHANGE_SEARCH_DESTINATION,
    payload: {
      searchDestination: place || event || hub,
    },
  };
}
