import { Map, Record } from 'immutable';
import get from 'lodash/get';
import compact from 'lodash/compact';

import { HubDestinations } from 'models/hub-destination';
import { Availabilities } from 'models/availability';
import Meta from 'models/meta';

export default class Hub extends Record({
  id: null,
  name: null,
  city: null,
  state: null,
  country: null,
  url: null,
  timezone: null,
  postalCode: null,
  lat: null,
  lng: null,
  msa: null,
  meta: null,
  slug: null,
  featuredDestinations: null,
  popularDestinations: null,
  availability: Map(),
  description: null,
}) {
  constructor(props) {
    if (!props) {
      super();
      return;
    }

    let { lat, lng } = props;
    if ((!lat || !lng) && props.coordinates) {
      lat = props.coordinates[0];
      lng = props.coordinates[1];
    }

    const featuredDestinations = HubDestinations(props.featuredDestinations);
    const popularDestinations = HubDestinations(props.popularDestinations);

    const availability = Availabilities(props.availability);

    const meta = Meta.getSeoMeta(props);

    super({
      id: props.id,
      name: props.name,
      city: props.city,
      state: props.state,
      country: props.country,
      timezone: props.timezone,
      postalCode: props.postalCode,
      lat,
      lng,
      msa: props.msa,
      slug: props.slug || props.map_site_url,
      featuredDestinations,
      popularDestinations,
      url: props.map_site_url || props.url,
      site_url: props.hub_site_url,
      meta,
      availability,
      description: props.description,
    });
  }

  static get HUB_URL_REGEX() { return /^(?!.*-monthly-parking)\/p\/.*-parking(-[0-9]*)?\/?([\?\&][\w\-]*(=[\w\-]*)?)*?$/; }

  get isValid() {
    return !!this.id;
  }

  get isNYC() {
    return (this.id === '1' || this.id === 1);
  }

  get hasDestinations() {
    return !(this.popularDestinations && this.popularDestinations.size === 0 &&
      this.featuredDestinations && this.featuredDestinations.size === 0);
  }

  get groupPopularDestinationsByCategory() {
    const { popularDestinations } = this;
    if (!popularDestinations || popularDestinations.size === 0) { return null; }
    const groupedCategories = this.popularDestinations.groupBy(destination => (destination.category));
    return (groupedCategories.map(category => category.toSet().toMap()));
  }

  get heroClass() {
    switch (this.id) {
      case '1':
        return 'ny-hub';
      case '2':
        return 'chicago-hub';
      case '3':
        return 'sf-hub';
      case '152':
        return 'dc-hub';
      case '22':
        return 'boston-hub';
      default:
        return 'minor-hub';
    }
  }

  get title() {
    return get(this, 'meta.title', null);
  }

  get monthlyAvailable() {
    return this.getIn(['availability', 'monthly', 'available'], false);
  }

  static validURL(url) {
    const parkWhizHubMatch = this.HUB_URL_REGEX.test(url);
    if (parkWhizHubMatch) { return parkWhizHubMatch; }

    const lastInPath = compact(url.split('/')).splice(-1)[0];
    return lastInPath === 'neighborhoods' || lastInPath === 'destinations';
  }

  static tryConstruct(props) {
    try {
      const hub = new Hub(props);
      return { hub: hub.isValid ? hub : null, error: null };
    } catch (err) {
      return { hub: null, error: err };
    }
  }
}
