// External libraries
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'react-bootstrap';
import { injectIntl, intlShape } from 'react-intl';
import sanitizeHtml from 'sanitize-html';
import VisibilitySensor from 'react-visibility-sensor';

import { processMessages } from 'lib/messaging';
import { MESSAGE_BANNER_IMPRESSION, MESSAGE_BANNER_CLICK } from 'lib/analytics/events';

import { Message as MessageModel } from 'models/messages';

export class Message extends Component {
  constructor(props) {
    super(props);

    this.onDismiss = this.onDismiss.bind(this);
    this.trackEvent = this.trackEvent.bind(this);
    this.createMessage = this.createMessage.bind(this);
    this.createDetails = this.createDetails.bind(this);
    this.onVisibilityChange = this.onVisibilityChange.bind(this);
  }

  componentDidMount() {
    processMessages(this.props.message, {
      onStateChange: (messages) => {
        this.props.onDismissTimeout({ messages });
      },
    });
  }

  trackEvent(event) {
    const { trackEvent, message } = this.props;

    trackEvent({
      ...event,
      properties: {
        message_type: message.type,
        message_id: message.id,
      },
    });
  }

  onDismiss() {
    this.props.onDismiss({ message: this.props.message });
    this.trackEvent(MESSAGE_BANNER_CLICK);
  }

  onVisibilityChange(visible) {
    if (visible) {
      this.trackEvent(MESSAGE_BANNER_IMPRESSION);
    }
  }

  createMessage() {
    const { message, intl } = this.props;

    return {
      __html: sanitizeHtml(intl.formatHTMLMessage(
        {
          id: message.intlMessageID,
          defaultMessage: message.message,
        },
        message.messageValues,
      )),
    };
  }

  createDetails() {
    const { message, intl } = this.props;

    // Needed for custom, API defined messages
    if (message.customMessage || !message.details) {
      return { __html: sanitizeHtml(message.details) };
    }

    return {
      __html: sanitizeHtml(intl.formatHTMLMessage(
        {
          id: message.intlDetailsID,
          defaultMessage: message.details,
        },
        message.messageValues,
      )),
    };
  }

  render() {
    return (
      <Alert
        bsStyle={this.props.message.type}
        onDismiss={this.onDismiss}
      >
        <VisibilitySensor onChange={this.onVisibilityChange} partialVisibility>
          <Fragment>
            <span className="message-title" dangerouslySetInnerHTML={this.createMessage()} />
            <span className="weak" dangerouslySetInnerHTML={this.createDetails()} />
          </Fragment>
        </VisibilitySensor>
      </Alert>
    );
  }
}

Message.propTypes = {
  message: PropTypes.instanceOf(MessageModel).isRequired,
  onDismissTimeout: PropTypes.func,
  onDismiss: PropTypes.func,
  trackEvent: PropTypes.func,
  intl: intlShape.isRequired,
};

Message.defaultProps = {
  onDismissTimeout: () => {},
  onDismiss: () => {},
  trackEvent: () => {},
};

export default injectIntl(Message);
