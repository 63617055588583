import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { List, OrderedMap } from 'immutable';
import get from 'lodash/get';
import result from 'lodash/result';

import Filter from 'components/common/filter';
import FilterOption from 'components/common/filter/option';

import Search, { HUB_TYPE, EVENT_TYPE, VENUE_TYPE } from 'models/search';
import Venue from 'models/venue';

import changeParkingType from 'action-creators/search/change-parking-type';
import resetCurrentSearch from 'action-creators/search/reset-current-search';

const propTypes = {
  currentSearch: PropTypes.instanceOf(Search).isRequired,
  changeParkingType: PropTypes.func.isRequired,
  displayModal: PropTypes.bool.isRequired,
  monthlyAvailable: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
  venue: PropTypes.instanceOf(Venue),
  events: PropTypes.instanceOf(OrderedMap),
  nearbyMetros: PropTypes.instanceOf(List),
  displayMonthly: PropTypes.bool,
  app: PropTypes.string.isRequired,
  appContext: PropTypes.string.isRequired,
  displayMap: PropTypes.bool.isRequired,
  routingStyle: PropTypes.string.isRequired,
};

const defaultProps = {
  venue: null,
  events: null,
  nearbyMetros: null,
  displayMonthly: true,
};

const BASE_STATE = {
  currentSearch: null,
  selectedLocation: null,
  selectedQuote: null,
  locationId: null,
  quoteId: null,
  nearbyMetros: null,
  monthlyAvailable: null,
  hub: null,
  venue: null,
  event: null,
  events: null,
};

class SearchPickerHeader extends Component {
  constructor(props) {
    super(props);

    this.onFilter = this.onFilter.bind(this);
    this.resetSearch = this.resetSearch.bind(this);
    this.monthlyAvailable = this.monthlyAvailable.bind(this);
    this.state = BASE_STATE;
  }

  componentWillMount() {
    this.cacheCurrentSearch();
  }

  componentWillUpdate(nextProps) {
    if (!this.state.currentSearch && !this.props.displayModal && nextProps.displayModal) {
      this.cacheCurrentSearch(nextProps);
    } else if (this.props.displayModal && !nextProps.displayModal) {
      this.setState(BASE_STATE);
    }
  }

  onFilter({ filter }) {
    this.props.changeParkingType({ parkingType: filter });
  }

  cacheCurrentSearch(props = this.props) {
    const {
      currentSearch,
      nearbyMetros,
      venue,
      hub,
      event,
      selectedLocation,
      selectedQuote,
      events,
    } = props;
    const { destinationType, destination } = currentSearch;
    let { monthlyAvailable } = this.props;
    if (!monthlyAvailable) {
      ({ monthlyAvailable } = destination);
    }

    const state = Object.create(BASE_STATE);
    Object.assign(state, {
      currentSearch,
      monthlyAvailable,
      nearbyMetros,
      hub,
      event,
      selectedLocation,
      selectedQuote,
      locationId: get(selectedLocation, 'id', null),
      quoteId: get(selectedQuote, 'id', null),
    });

    switch (destinationType) {
      case HUB_TYPE:
        Object.assign(state, { hub: destination });
        break;
      case EVENT_TYPE:
        Object.assign(state, { event: destination, venue, events });
        break;
      case VENUE_TYPE:
        Object.assign(state, { venue: destination, events });
        break;
      default:
        break;
    }
    this.setState(state);
  }

  resetSearch() {
    this.props.onHide();
    this.setState(BASE_STATE);
  }

  monthlyAvailable() {
    const { monthlyAvailable, currentSearch, displayMonthly } = this.props;
    if (!displayMonthly) { return false; }
    return currentSearch.destination.monthlyAvailable || monthlyAvailable;
  }

  get backLink() {
    const {
      app,
      appContext,
      displayMap,
      routingStyle,
    } = this.props;
    const {
      currentSearch,
      selectedLocation,
      selectedQuote,
      venue,
      event,
      hub,
    } = this.state;

    if (app === 'Checkout') {
      return (<Link
        to={{
          pathname: '/reserve/',
          search: `?quote_id=${selectedQuote.id}`,
          state: {
            app: { name: 'Checkout' },
            search: {
              currentSearch: currentSearch.toJSON(),
              locationId: selectedLocation.id,
              quoteId: selectedQuote.id,
              selectedLocation: selectedLocation.toJSON(),
              selectedQuote: selectedQuote.toJSON(),
              event: result(event, 'toJSON', null),
              venue: result(venue, 'toJSON', null),
              hub: result(hub, 'toJSON', null),
            },
          },
        }}
        className="col-xs-2"
        onClick={this.resetSearch}
      >
        <div className="iconified-font iconified-chevron-left text-color-watermelon text-size-20 modal-back" />
      </Link>);
    }
    return (<Link
      to={{
        pathname: currentSearch.route(selectedLocation, displayMap, appContext, { routingStyle }),
        state: {
          app: { name: 'Search' },
          search: {
            currentSearch: currentSearch.toJSON(),
            selectedLocation,
            selectedQuote,
            event: result(event, 'toJSON', null),
            venue: result(venue, 'toJSON', null),
            hub: result(hub, 'toJSON', null),
            locationId: get(selectedLocation, 'id', null),
            quoteId: get(selectedQuote, 'id', null),
            displayMap,
          },
        },
      }}
      className="col-xs-2"
      onClick={this.resetSearch}
    >
      <div className="iconified-font iconified-chevron-left text-color-watermelon text-size-20 modal-back" />
    </Link>);
  }

  render() {
    const { currentSearch } = this.props;
    const placeholder = this.monthlyAvailable() ? null : <div className="text-center margin-top-20">HOURLY</div>;

    return (
      <div className="search-picker-modal-header row">
        {this.backLink}
        <div className="col-xs-8">
          <Filter
            displayTest={this.monthlyAvailable}
            currentFilter={currentSearch.parkingType}
            onFilter={this.onFilter}
          >
            <FilterOption filter={Search.DAILY_PARKING_TYPE}>HOURLY</FilterOption>
            <FilterOption filter={Search.MONTHLY_PARKING_TYPE}>MONTHLY</FilterOption>
          </Filter>
          {placeholder}
        </div>
      </div>
    );
  }
}

SearchPickerHeader.propTypes = propTypes;
SearchPickerHeader.defaultProps = defaultProps;

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    changeParkingType,
    resetCurrentSearch,
  }, dispatch)
);

const mapStateToProps = (state) => {
  const {
    currentSearch,
    selectedLocation,
    selectedQuote,
    venue,
    hub,
    event,
    events,
    monthlyAvailable,
    nearbyMetros,
    displayMap,
  } = state.search;

  const {
    displayModal,
  } = state.modal;

  const {
    name: app,
    appContext,
  } = state.app;
  const { brand } = state.brand;
  const { routingStyle } = brand;

  return {
    app,
    currentSearch,
    displayModal,
    nearbyMetros,
    venue,
    hub,
    event,
    selectedQuote,
    selectedLocation,
    events,
    monthlyAvailable,
    displayMap,
    routingStyle,
    appContext,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchPickerHeader);

