import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { List } from 'immutable';

import Message from 'components/messages/message';

import setMesssagesCreator from 'action-creators/messaging/set-messages';
import dismissMessageCreator from 'action-creators/messaging/dismiss-message';
import trackEvent from 'action-creators/analytics/track-event';

const propTypes = {
  messages: PropTypes.instanceOf(List).isRequired,
  dismissMessage: PropTypes.func.isRequired,
  setMessages: PropTypes.func.isRequired,
  trackEvent: PropTypes.func.isRequired,
};

class Messages extends Component {
  constructor(props) {
    super(props);

    this.state = {
      displayMessages: false,
    };
  }

  componentDidMount() {
    this.state = {
      displayMessages: true,
    };
  }

  render() {
    const { messages, dismissMessage, setMessages } = this.props;

    if (!messages || Object.keys(messages).length === 0) {
      return null;
    }

    const messageItems = messages.map(message => (
      <Message
        key={message.id}
        message={message}
        onDismiss={dismissMessage}
        onDismissTimeout={setMessages}
        trackEvent={this.props.trackEvent}
      />
    ));

    return (
      <div className="pw-messages hidden-print">
        {messageItems}
      </div>
    );
  }
}

Messages.propTypes = propTypes;

const mapStateToProps = (state) => {
  const {
    messages,
  } = state.messaging;

  return { messages };
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    dismissMessage: dismissMessageCreator,
    setMessages: setMesssagesCreator,
    trackEvent,
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(Messages);
