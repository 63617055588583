import React from 'react';
import { connect } from 'react-redux';

import ValidationStepComponent, { propTypes, defaultProps } from 'components/common/validation-steps/validation-step';

const ValidationStep = props => <ValidationStepComponent {...props} />;

const mapStateToProps = (state, ownProps) => {
  const { brand } = state.brand;
  return {
    brand,
    ...ownProps,
  };
};

ValidationStep.propTypes = propTypes;
ValidationStep.defaultProps = defaultProps;
export default connect(mapStateToProps)(ValidationStep);
