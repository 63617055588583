export const RESET_MODAL_STATE = 'reset-modal-state';

export default function resetModalState({ preventModalReset = false } = {}) {
  return {
    type: RESET_MODAL_STATE,
    payload: {
      preventModalReset,
    },
  };
}
