import * as AppContext from 'lib/app-context';

export function getAddOnsFromReceipt(receipt) {
  if (receipt.addOns) {
    return {
      required: (receipt.addOns.find(addOn => addOn.isRequired) || {}).name,
      optional: (receipt.addOns.find(addOn => !addOn.isRequired) || {}).name,
    };
  }
  return {};
}

export const isVisibleOnMobile = ({ mobileMenu, showMobileMenu, appContext }) => {
  if (!mobileMenu) { return true; }
  return showMobileMenu && appContext === AppContext.MOBILE;
};
