import { Record, List } from 'immutable';
import moment from 'moment-timezone';

const SINGLE_DAY_TIMES = /^\w{3}\s-?\d{1,2}:\d{2}\s-\s\d{1,2}:\d{2}$/;
const MULTI_DAY_TIMES = /^\w{3}\s-?\d{1,2}:\d{2}\s-\s\w{3}\s-?\d{1,2}:\d{2}$/;
const SINGLE_DAY_ALL_DAY = /^\w{3}\s(24 hr)$/;
const MULTI_DAY_ALL_DAY = /^\w{3}\s-\s\w{3}\s(24 hr)$/;

const parseSingleDayTimes = (timeString) => {
  const dayString = timeString.substr(0, timeString.indexOf(' '));
  const timeRangeStrings = timeString.substr(timeString.indexOf(' ') + 1).split('-').map(s => s.trim()).filter(s => s);
  const startTime = moment().day(dayString).hour(timeRangeStrings[0].split(':')[0].replace(/-/, '')).minute(timeRangeStrings[0].split(':')[1]);
  const endTime = moment().day(dayString).hour(timeRangeStrings[1].split(':')[0].replace(/-/, '')).minute(timeRangeStrings[1].split(':')[1]);
  return { startTime, endTime };
};

const parseMultiDayTimes = (timeString) => {
  const days = timeString.split(' - ').map(s => s.trim());
  const dayStrings = [];
  const timeRangeStrings = [];
  [0, 1].forEach((i) => { [dayStrings[i], timeRangeStrings[i]] = days[i].split(' '); });
  const startTime = moment().day(dayStrings[0]).hour(timeRangeStrings[0].split(':')[0].replace(/-/, '')).minute(timeRangeStrings[0].split(':')[1]);
  const endTime = moment().day(dayStrings[1]).hour(timeRangeStrings[1].split(':')[0].replace(/-/, '')).minute(timeRangeStrings[1].split(':')[1]);

  return { startTime, endTime };
};

const parseSingleDayAllDay = (timeString) => {
  const dayString = timeString.substr(0, timeString.indexOf(' '));
  const startTime = moment().day(dayString).hour(0).minute(0);
  const displayTime = '24 hours';
  return { startTime, displayTime };
};

export class OperatingHours extends Record({
  startTime: null,
  endTime: null,
  displayTime: null,
  timeString: null,
}) {
  constructor(props) {
    switch (true) {
      case SINGLE_DAY_TIMES.test(props):
        super(parseSingleDayTimes(props));
        break;
      case MULTI_DAY_TIMES.test(props):
        super(parseMultiDayTimes(props));
        break;
      case SINGLE_DAY_ALL_DAY.test(props):
        super(parseSingleDayAllDay(props));
        break;
      case MULTI_DAY_ALL_DAY.test(props):
        super({ timeString: 'Mon - Sun 24 hours' });
        break;
      default:
        super({ timeString: props });
    }
  }

  toString() {
    if (this.timeString) {
      return this.timeString;
    }

    if (this.displayTime) {
      return `${this.startTime.format('ddd')} ${this.displayTime}`;
    }

    if (this.startTime && this.endTime) {
      if (this.startTime.day() === this.endTime.day()) {
        return `${this.startTime.format('ddd h:mm a')} - ${this.endTime.format('h:mm a')}`;
      }
      return `${this.startTime.format('ddd h:mm a')} - ${this.endTime.format('ddd h:mm a')}`;
    }

    return null;
  }
}

const OperatingHoursCollection = ({ hours }) => {
  const operatingHours = hours.map(h => new OperatingHours(h));
  return List(operatingHours);
};

export default OperatingHoursCollection;
