import React, { Component } from 'react';
import cx from 'classnames';

class LoginForm extends Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    const email = this.email.value;
    const password = this.password.value;
    const redirectUri = window.location;
    
    this.props.signIn({ email, password, redirectUri });
  }

  render() {
    const classNames = cx({
      'form-panel': true,
      'clearfix': true,
      'open': this.props.display,
      'display-block': this.props.display,
    });

    return (
      <div id="login" className={classNames}>
        <div className="row">
          <a className="close" onClick={this.props.toggleForm}>
            <img src="/static/images/business/buttons/button-close@2x.png" alt="Click to Cancel" />
          </a>
        </div>

        <div className="row">
          <div className="col-xs-10 col-xs-push-1 col-sm-7 col-sm-push-3">
            <form className="light" id="login-form" onSubmit={this.onSubmit}>
              <h3>Log In To Your ParkWhiz Account</h3>
              <div className="row">
                <div className="col-xs-12 col-sm-5 text-left">
                  <div className="form-group">
                    <input ref={(email) => { this.email = email; }} type="email" name="email" placeholder="Email Address" className="form-control" tabIndex="1" />
                  </div>
                </div>
                <div className="col-xs-8 col-sm-5 text-left">
                  <div className="form-group">
                    <input ref={(password) => { this.password = password; }} type="password" name="password" placeholder="Password" className="form-control" tabIndex="2" />
                  </div>
                </div>
                <div className="col-xs-4 col-sm-2">
                  <div className="form-group">
                    <input type="submit" className="button" name="submit" value="Log In" tabIndex="4" onClick={this.onSubmit} onSubmit={this.onSubmit} />
                  </div>
                </div>
                <div className="col-xs-12 col-sm-5 text-left" />
                <div className="col-xs-8 col-sm-5 text-left">
                  <p className="xhr-response error-response" />
                </div>
              </div>
              <h5><a href="/resetpw" target="_blank" rel="noopener noreferrer">Don't know your password?</a> No account? No problem, <a onClick={this.props.toggleEnroll} className="enroll in-form">enroll to create a new account</a>.</h5>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default LoginForm