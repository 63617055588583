import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const propTypes = {
  hours: PropTypes.number,
};

const defaultProps = {
  hours: null,
};

const MinimumStartTimeBuffer = ({ hours }) => {
  if (!hours) { return null; }

  return (
    <li>
      <FormattedMessage
        id="search.start-time-restriction"
        defaultMessage="fewer than {hours} hours in advance"
        values={{ hours }}
      />
    </li>
  );
};

MinimumStartTimeBuffer.propTypes = propTypes;
MinimumStartTimeBuffer.defaultProps = defaultProps;
export default MinimumStartTimeBuffer;
