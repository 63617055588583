import React from 'react';
import PropTypes from 'prop-types';
import Brand from 'models/brand';
import { FormattedMessage } from 'react-intl';

export const propTypes = {
  brand: PropTypes.instanceOf(Brand).isRequired,
  prefix: PropTypes.string,
  className: PropTypes.string,
  cobrand: PropTypes.string,
};

export const defaultProps = {
  prefix: null,
  className: '',
  cobrand: null,
};

const BrandName = ({ prefix, className, cobrand, brand }) => {
  const brandName = brand.displayName || 'ParkWhiz';
  let message;

  if (prefix) {
    message = `${prefix}${brandName}`;
  } else if (cobrand && cobrand !== brandName) {
    message = (<FormattedMessage
      id="common.cobranded"
      defaultMessage="{cobrand} and {brand}"
      values={{ cobrand, brand: brandName }}
    />);
  } else {
    message = brandName;
  }

  return <span className={className}>{message}</span>;
};

BrandName.propTypes = propTypes;
BrandName.defaultProps = defaultProps;
export default BrandName;
