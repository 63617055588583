import { takeEvery, put, call, select } from 'redux-saga/effects';

import { GET_SELLER } from 'action-creators/parking-pass/get-seller';
import { OPEN_GATE } from 'action-creators/parking-pass/open-gate';
import gotSeller from 'action-creators/parking-pass/got-seller';
import addMessage from 'action-creators/messaging/add-message';
import resetModalState from 'action-creators/modal/reset-modal-state';

import ClientApi from 'lib/api/client';
import { vendgateErrorMessage } from 'lib/common/messages';

const LOG_ERROR = 'log-error';

const getRequestQueue = state => state.requests.requestQueue;
const getUser = state => state.account.user;

function fetchSeller({ sellerId, requestQueue, accessToken }) {
  return ClientApi
    .seller(sellerId, requestQueue, accessToken)
    .then(({ body }) => ({ body }))
    .catch(error => ({ error }));
}

export function* getSeller(action) {
  const { sellerId } = action.payload;
  const requestQueue = yield select(getRequestQueue);
  const user = yield select(getUser);
  const { token } = user;
  const { body, error } = yield call(fetchSeller, { sellerId, requestQueue, accessToken: token });

  if (error) {
    yield put({ type: LOG_ERROR, error });
    return;
  }
  yield put(gotSeller(body));
}

const sendGateOpen = ({ parkingPass, requestQueue, accessToken }) => (
  ClientApi
    .openGate(parkingPass.id, { authorization_code: parkingPass.authorizationCode }, accessToken, 'fg', requestQueue)
    .then(({ body }) => ({ body }))
    .catch(error => ({ error }))
);

export function* openGate(action) {
  const { parkingPass } = action.payload;
  const requestQueue = yield select(getRequestQueue);
  const user = yield select(getUser);
  const { error } = yield call(sendGateOpen, { parkingPass, requestQueue, accessToken: user.token });

  if (error) {
    yield put(addMessage(vendgateErrorMessage));
    yield put(resetModalState());
  }
}

export default function* parkingPassesSaga() {
  yield takeEvery(GET_SELLER, getSeller);
  yield takeEvery(OPEN_GATE, openGate);
}
