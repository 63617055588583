import { Record } from 'immutable';
import get from 'lodash/get';

import { SEARCH_APP } from 'lib/app-names';

export const COOKIE_PREFIX = 'search-restrictions-dismissed-';

export default class SearchSuggestions extends Record({
  minimumStartTimeBufferSeconds: null,
  minimumDurationSeconds: null,
}) {
  static secondsToHours(duration) {
    if (!duration) { return null; }
    return duration / 3600.0;
  }

  static suggestionKey(search) {
    const id = get(search, 'destination.id', null);
    const type = get(search, 'destinationType', null);
    if (id && type) { return `${type}-${id}`; }
    return null;
  }

  static isDismissed(search, dismissals) {
    const key = SearchSuggestions.suggestionKey(search);
    return key && !!dismissals.get(key);
  }

  static dismissalCookie({ key, search } = {}) {
    const cookieKey = key || SearchSuggestions.suggestionKey(search);
    return cookieKey && `${COOKIE_PREFIX}${cookieKey}`;
  }

  static keysFromCookies(cookies) {
    const cookieKeys = Object.keys(cookies);
    const dismissalCookies = cookieKeys.filter(c => c.match(/^search-restrictions-dismissed-*/));
    const dismissalKeys = dismissalCookies.map(c => c.replace(COOKIE_PREFIX, ''));
    return dismissalKeys;
  }

  constructor(props) {
    if (!props) {
      super();
      return;
    }

    let {
      minimumStartTimeBufferSeconds,
      minimumDurationSeconds,
    } = props;

    if (!minimumStartTimeBufferSeconds) {
      minimumStartTimeBufferSeconds = get(props, 'minimum_start_buffer', null);
    }
    if (!minimumDurationSeconds) {
      minimumDurationSeconds = get(props, 'minimum_duration', null);
    }

    super({
      minimumStartTimeBufferSeconds,
      minimumDurationSeconds,
    });
  }

  get minimumStartTimeBuffer() {
    return SearchSuggestions.secondsToHours(this.minimumStartTimeBufferSeconds);
  }

  get minimumDuration() {
    return SearchSuggestions.secondsToHours(this.minimumDurationSeconds);
  }

  get hasSuggestions() {
    return this.minimumStartTimeBufferSeconds > 0 || this.minimumDurationSeconds > 0;
  }

  shouldDisplay({ app, search, dismissals }) {
    const { hasSuggestions } = this;
    const dismissed = SearchSuggestions.isDismissed(search, dismissals);
    return !dismissed && app === SEARCH_APP && hasSuggestions;
  }
}
