import React from 'react';

const Event = ({ endTime, eventDeparture, eventDepartureBuffer }) => (
  <div className="text-color-medium-grey text-size-20 text-weight-book" key="event-notice">
    <p>
      Stay parked until <span className="important">{endTime.format('h:mm' )}</span><span className="less-important">{endTime.format('A')}</span>
    </p>
    <p key="event-divider">&mdash;OR&mdash;</p>
    <p key="event-departure-time">
      <span className='capitalize'>{eventDeparture}</span> {eventDepartureBuffer > 1 ? 'hours' : 'hour'} after your event ends.
    </p>
  </div>
);

export default Event;
