export const SEARCH_MODIFY_REQUIRED_FIELD_LIST = 'search-modify-required-field-list';

export default function modifyRequiredFieldList({ add, remove }) {
  const addedFields = Array.isArray(add) ? add : [add];
  const removedFields = Array.isArray(remove) ? remove : [remove];

  return {
    type: SEARCH_MODIFY_REQUIRED_FIELD_LIST,
    payload: {
      addedFields,
      removedFields,
    },
  };
}
