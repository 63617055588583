import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Map, OrderedMap } from 'immutable';
import ClickOutside from 'react-click-outside';

import { isProduction } from 'lib/env';

import User from 'models/user';
import Booking from 'models/booking';
import Brand from 'models/brand';
import PastBookingGroup from 'models/past-booking-group';

import ReviewForm from 'components/bookings/review-form';

const propTypes = {
  pastBookings: PropTypes.instanceOf(PastBookingGroup).isRequired,
  locations: PropTypes.instanceOf(OrderedMap).isRequired,
  reviews: PropTypes.instanceOf(Map).isRequired,
  user: PropTypes.instanceOf(User).isRequired,
  brand: PropTypes.instanceOf(Brand).isRequired,
  app: PropTypes.string.isRequired,
  reviewRating: PropTypes.number,
  reviewModalDismissed: PropTypes.bool,
  reviewedBooking: PropTypes.instanceOf(Booking),
  createReview: PropTypes.func.isRequired,
  dismissReviewModal: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
};

const defaultProps = {
  reviewModalDismissed: false,
  reviewedBooking: null,
  reviewRating: null,
};

class BookingReviewModal extends Component {
  constructor(props) {
    super(props);

    this.dismiss = this.dismiss.bind(this);
  }
  get shouldRender() {
    const { reviews, user, app, reviewModalDismissed, reviewedBooking } = this.props;

    // Show if manually triggered
    if (reviewedBooking) { return true; }

    // Only show on account page (bookings page when differentiated)
    if (app !== 'Bookings') { return false; }

    // Don't render if the modal has been dismissed
    if (reviewModalDismissed) { return false; }

    // Don't render if the user is an admin and the app is running in production
    if (user.admin && isProduction()) { return false; }

    // Don't render if there are no unreviewed past bookings
    const { unreviewedBookings } = this;
    if (unreviewedBookings.isEmpty()) { return false; }

    // Don't render if a review has been submitted in the last week
    const mostRecentReview = reviews.sort(r => r.createdAt).first();
    if (mostRecentReview && mostRecentReview.createdAt.isAfter(moment().subtract(7, 'days'))) { return false; }

    return true;
  }

  get unreviewedBookings() {
    const { pastBookings, reviews } = this.props;
    return pastBookings.combinedBookings.filter(b => !reviews.get(b.id.toString()));
  }

  get reviewableBooking() {
    return this.props.reviewedBooking || this.unreviewedBookings.first();
  }

  get reviewableLocation() {
    const { locations } = this.props;
    return locations.get(this.reviewableBooking.locationId.toString());
  }

  dismiss(e) {
    e.preventDefault();
    e.stopPropagation();

    const { user, dismissReviewModal, push } = this.props;
    dismissReviewModal();
    if (!user.isLoggedIn()) {
      push('/');
    }
  }

  render() {
    const { shouldRender } = this;
    if (!shouldRender) { return null; }

    const { createReview, reviewRating, brand } = this.props;
    const { reviewableBooking, reviewableLocation } = this;

    return (
      <div className="fullscreen-modal background-color-xs-off-white background-color-sm-transparent-black-50">
        <ClickOutside className="review-modal" onClickOutside={this.dismiss}>
          <ReviewForm
            booking={reviewableBooking}
            location={reviewableLocation}
            createReview={createReview}
            reviewRating={reviewRating}
            dismiss={this.dismiss}
            brand={brand}
          />
        </ClickOutside>
      </div>
    );
  }
}

BookingReviewModal.propTypes = propTypes;
BookingReviewModal.defaultProps = defaultProps;
export default BookingReviewModal;
