import React, { Component } from 'react';
import cx from 'classnames';

import { businessEnrollIncompleteError, businessEnrollTermsError } from 'lib/common/messages';

class EnrollForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 1,
      termsAccepted: false,
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onSubmitAndLogin = this.onSubmitAndLogin.bind(this);
    this.nextStep = this.nextStep.bind(this);
    this.toggleForm = this.toggleForm.bind(this);
    this.toggleTerms = this.toggleTerms.bind(this);
  }

  onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    const companyName = this.companyName.value;
    const companyEmail = this.companyEmail.value;
    const companySize = this.companySize.value;
    const userEmail = this.userEmail.value;
    const password = this.password.value;
    const firstname = this.firstname.value;
    const lastname = this.lastname.value;
    const phone = this.phoneNumber.value;

    if (this.state.termsAccepted) {
      this.props.businessEnroll({
        companyName,
        companyEmail,
        companySize,
        userEmail,
        password,
        firstname,
        lastname,
        phone,
      });
    } else {
      this.props.addMessage(businessEnrollTermsError);
    }
  }

  onSubmitAndLogin(e) {
    e.preventDefault();
    e.stopPropagation();

    const companyName = this.companyName.value;
    const companyEmail = this.companyEmail.value;
    const companySize = this.companySize.value;
    const userEmail = this.loginUserEmail.value;
    const password = this.loginPassword.value;

    this.props.businessEnroll({
      companyName,
      companyEmail,
      companySize,
      userEmail,
      password,
      login: true,
    });
  }

  nextStep(e) {
    e.preventDefault();
    e.stopPropagation();

    if (this.companyName.value && this.companyEmail.value && this.companySize.value) {
      this.setState({ step: 2 });
    } else {
      this.props.addMessage(businessEnrollIncompleteError);
    }
  }

  toggleForm(e) {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ step: 1 });
    this.props.toggleForm(e);
  }

  toggleTerms(e) {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ termsAccepted: !this.state.termsAccepted });
  }

  render() {
    const classNames = cx({
      'form-panel': true,
      'clearfix': true,
      'open': this.props.display,
      'display-block': this.props.display,
    });

    const step1Classes = cx({
      'step': true,
      'row': true,
      'light': true,
      'display-block': this.state.step === 1,
      'hidden': this.state.step !== 1,
    });

    const step2Classes = cx({
      'step': true,
      'row': true,
      'display-block': this.state.step === 2,
      'hidden': this.state.step !== 2,
    });

    return (
      <div id="enroll" className={classNames}>
        <form className="light" id="enroll-form" onSubmit={this.onSubmit}>
          <div id="enroll-step-1" className={step1Classes}>
            <div className="row">
              <div className="col-xs-10 col-xs-push-1 col-sm-10 col-sm-push-1 col-md-8 col-md-push-2">
                <h3>Start by enrolling your company today</h3>
                <div className="row">
                  <div className="col-xs-12 col-sm-3 text-left">
                    <div className="form-group">
                      <input ref={(name) => { this.companyName = name; }} type="text" name="company_name" placeholder="Company Name" className="form-control" />
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-4 text-left">
                    <div className="form-group">
                      <input ref={(email) => { this.companyEmail = email; }} type="email" name="company_email" placeholder="Work Email" className="form-control" />
                    </div>
                  </div>
                  <div className="col-xs-8 col-sm-3 text-left">
                    <div className="form-group">
                      <select ref={(size) => { this.companySize = size; }} name="company_size" className="form-control">
                        <option value="" disabled selected>Company Size</option>
                        <option>1-50</option>
                        <option>51-100</option>
                        <option>101-1000</option>
                        <option>1000+</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-xs-4 col-sm-2">
                    <div className="form-group">
                      <a className="button button-next" onClick={this.nextStep}>Next</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="enroll-step-2" className={step2Classes}>
            <div className="col-xs-10 col-xs-push-1 col-sm-10 col-sm-push-1 col-md-8 col-md-push-2">
              <h3>Almost Done, Log In Or Create A New Account</h3>
              <div id="enroll-existing-account" className="light">
                <input type="hidden" name="login" value="1" />
                <div className="col-xs-12 col-sm-4">
                  <h5 className="text-center">Log In</h5>
                  <div className="row">
                    <div className="col-xs-12 text-left">
                      <div className="form-group">
                        <input ref={(email) => { this.loginUserEmail = email; }} type="email" name="user_email" placeholder="Email Address" className="form-control" />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-9 text-left">
                      <div className="form-group">
                        <input ref={(password) => { this.loginPassword = password; }} type="password" name="password" placeholder="Password" className="form-control" />
                      </div>
                    </div>
                    <div className="col-xs-3">
                      <div className="form-group">
                        <input type="submit" name="submit" value="GO" className="form-control button tiny" onClick={this.onSubmitAndLogin} />
                      </div>
                    </div>
                    <div className="col-xs-12 text-left">
                      <label><a href="/resetpw" target="_blank" rel="noopener noreferrer">Don't know your password?</a></label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xs-12 col-sm-1">
                <hr />
              </div>

              <div className="light">
                <div className="col-xs-12 col-sm-7">
                  <h5 className="text-center">Create a new account</h5>
                  <div className="row">
                    <div className="col-xs-12 text-left">
                      <div className="form-group">
                        <input ref={(email) => { this.userEmail = email; }} type="email" name="user_email" placeholder="Email Address" className="form-control" />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-6 text-left">
                      <div className="form-group">
                        <input ref={(firstname) => { this.firstname = firstname; }} type="text" name="firstname" placeholder="First Name" className="form-control" />
                      </div>
                    </div>
                    <div className="col-xs-6 text-left">
                      <div className="form-group">
                        <input ref={(lastname) => { this.lastname = lastname; }} type="text" name="lastname" placeholder="Last Name" className="form-control" />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-6 text-left">
                      <div className="form-group">
                        <input ref={(password) => { this.password = password; }} type="password" name="password" placeholder="Password" className="form-control" />
                      </div>
                    </div>
                    <div className="col-xs-4 text-left">
                      <div className="form-group">
                        <input ref={(phone) => { this.phoneNumber = phone; }} type="text" name="phone_number" placeholder="Phone Number" className="form-control" />
                      </div>
                    </div>
                    <div className="col-xs-2 text-left">
                      <div className="form-group">
                        <input type="submit" name="submit" value="GO" className="form-control button tiny" />
                      </div>
                    </div>
                    <div className="col-xs-12 text-left">
                      <div className={`checkbox ${this.state.termsAccepted ? 'checked' : null}`} onClick={this.toggleTerms}>
                        <input
                          type="checkbox"
                          name="agree_to_terms_of_use"
                          checked={this.state.termsAccepted}
                        />
                        <label>
                          I agree to the <a href="/support/terms" target="_blank">ParkWhiz Terms of Use</a>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <a className="close" onClick={this.toggleForm}>
            <img src="/static/images/business/buttons/button-close@2x.png" alt="Click to Cancel" />
          </a>
        </form>
      </div>
    );
  }
}

export default EnrollForm;
