export const SIGN_IN = 'sign-in';
const signIn = ({
  email,
  password,
  redirectURI,
  enterpriseInvite,
  credentialType,
  google_id_token,
  first_name,
  last_name,
  marketingAllowed,
}) => ({
  type: SIGN_IN,
  payload: {
    email,
    password,
    redirectURI,
    enterpriseInvite,
    credentialType,
    google_id_token,
    first_name,
    last_name,
    marketingAllowed,
  },
});
export default signIn;
