import { Record, List, Map } from 'immutable';

import { MapboxPredictions } from 'models/predictions/mapbox';
import { EventPredictions } from 'models/predictions/event';

export const MAX_PREDICTIONS = 6;

export default class Predictions extends Record({
  events: List(),
  places: List(),
}) {
  constructor(props) {
    if (!props) {
      super();
      return;
    }

    let { places, events } = props;
    if (places) {
      places = MapboxPredictions(places);
    }

    if (events) {
      events = EventPredictions(events);
    }

    super({
      events,
      places,
    });
  }

  get maxPredictions() {
    let placesLength = MAX_PREDICTIONS / 2;
    let eventsLength = MAX_PREDICTIONS / 2;

    if (this.places.size < placesLength) { eventsLength += placesLength - this.places.size; }
    if (this.events.size < eventsLength) { placesLength += eventsLength - this.events.size; }

    placesLength = Math.min(this.places.size, placesLength);
    eventsLength = Math.min(this.events.size, eventsLength);

    return new Map({ places: placesLength, events: eventsLength });
  }

  firstPrediction() {
    const { maxPredictions } = this;

    if (maxPredictions.get('places') === 0) {
      return { type: 'events', index: 0 };
    }

    return { type: 'places', index: 0 };
  }

  lastPrediction() {
    const { maxPredictions } = this;

    if (maxPredictions.get('events') === 0) {
      return { type: 'places', index: maxPredictions.get('places') - 1 };
    }

    return { type: 'events', index: maxPredictions.get('events') - 1 };
  }

  nextPrediction(selected) {
    const { maxPredictions } = this;

    if (selected.index + 1 < maxPredictions.get(selected.type)) {
      return Object.assign(selected, { index: selected.index + 1 });
    }

    if ((selected.type === 'places' || !maxPredictions.get('places')) && maxPredictions.get('events')) {
      return { type: 'events', index: 0 };
    }
    return { type: 'places', index: 0 };
  }

  previousPrediction(selected) {
    const { maxPredictions } = this;

    if (selected.index > 0) {
      return Object.assign(selected, { index: selected.index - 1 });
    }

    if ((selected.type === 'places' || !maxPredictions.get('places')) && maxPredictions.get('events')) {
      return { type: 'events', index: maxPredictions.get('events') - 1 };
    }
    return { type: 'places', index: maxPredictions.get('places') - 1 };
  }
}
