import React from 'react';
import PropTypes from 'prop-types';

const ONE_TENTH_OF_A_MILE = 528;
const METER_CUTOFF = 1000;

const propTypes = {
  location: PropTypes.shape({
    distance: PropTypes.number,
  }).isRequired,
};

export const FriendlyLocationDistance = ({ location }) => {
  const { distance, distanceUnits } = location;
  if (!location.distance || !location.distanceUnits) { return null; }

  let humanDistance;
  if (distanceUnits === 'feet') {
    if (distance < ONE_TENTH_OF_A_MILE) {
      humanDistance = `${distance} ft away`;
    } else {
      humanDistance = `${Math.round(distance / ONE_TENTH_OF_A_MILE, 10) / 10} mi away`;
    }
  } else if (distanceUnits === 'meters') {
    if (distance < METER_CUTOFF) {
      humanDistance = `${distance} m away`;
    } else {
      humanDistance = `${Math.round(distance / 1000 * 10) / 10} km away`;
    }
  }

  return (
    <span>{humanDistance}</span>
  );
};

FriendlyLocationDistance.propTypes = propTypes;

export default FriendlyLocationDistance;
