export const SET_DEFAULT_PAYMENT_METHOD = 'set-default-payment-method';

export default function setDefaultPaymentMethod(paymentMethodId) {
  return {
    type: SET_DEFAULT_PAYMENT_METHOD,
    payload: {
      defaultPaymentMethodId: paymentMethodId,
    },
  };
}
