import { Record } from 'immutable';
import { get, camelCase, fromPairs, mapKeys, pick, isEmpty } from 'lodash';

import Event from 'models/event';
import Brand from 'models/brand';

const SEO_META_ATTRIBUTES = ['title', 'description', 'monthly_description', 'monthly_title'];

export default class Meta extends Record({
  canonicalUrl: null,
  currentUrl: null,
  deepLinks: null,
  description: null,
  fbImage: null,
  fullUrl: null,
  noIndex: false,
  noTitleSlug: false,
  pageGroup: null,
  pageId: null,
  pwJsVar: null,
  queryString: null,
  scripts: null,
  title: null,
  brand: new Brand(),
}) {
  constructor({ search = null, venue = null, event = null, hub = null, point = null, props = {}, brand = null } = {}) {
    const metaProps = {
      canonicalUrl: props.canonicalUrl,
      currentUrl: props.currentUrl,
      deepLinks: props.deepLinks ? props.deepLinks : [],
      description: props.description,
      fbImage: `http://${props.host}/images/fb-og.png`,
      fullUrl: props.fullUrl,
      queryString: props.queryString,
      noIndex: props.noIndex ? props.noIndex : false,
      noTitleSlug: props.noTitleSlug ? props.noTitleSlug : false,
      pageGroup: props.pageGroup,
      title: props.title,
      brand,
      // In support of PHP
      pwJsVar: props.pwJsVar,
      scripts: props.scripts,
      pageId: props.pageId,
    };

    // Event
    if (event && event.isValid) {
      metaProps.deepLinks.push(Meta.deepLink('event', search));

      Object.assign(metaProps, {
        description: Meta.description({ destinationType: 'event', search, venue, brand }),
        title: Meta.title({ destinationType: 'event', search, brand }),
        canonicalUrl: `${event.url}`,
      });
    } else if (venue && venue.isValid) {
      metaProps.deepLinks.push(Meta.deepLink('venue', search));

      Object.assign(metaProps, {
        description: Meta.description({ destinationType: 'venue', search, brand }),
        noTitleSlug: true,
        title: Meta.title({ destinationType: 'venue', search, brand }),
        canonicalUrl: `${venue.url}`,
      });

      // No Daily or Monthly Available on Searches
      if (((search.parkingType === 'Daily' && !venue.transientAvailable)
       || (search.parkingType === 'Monthly' && !venue.monthlyAvailable))) {
        Object.assign(metaProps, { noIndex: true });
      }

      // Packages
      if (search.parkingType === 'Package') {
        const packageDescription = `Got a season pass at ${venue.name}? Purchase a reserved parking package to go with it. You have seats. Why not parking?`;
        Object.assign(metaProps, { description: packageDescription });
      }
    } else if (hub && hub.isValid) {
      metaProps.deepLinks.push(Meta.deepLink('hub', search));

      Object.assign(metaProps, {
        description: Meta.description({ destinationType: 'hub', search, brand }),
        title: Meta.title({ destinationType: 'hub', search, brand }),
        canonicalUrl: `${hub.url}`,
      });
    } else if (point && point.isValid) {
      metaProps.deepLinks.push(Meta.deepLink('point', search));

      Object.assign(metaProps, {
        description: Meta.description({ destinationType: 'point', search, brand }),
        title: Meta.title({ destinationType: 'point', search, brand }),
        canonicalUrl: `${point.url}`,
      });
    }

    if (!metaProps.canonicalUrl) {
      Object.assign(metaProps, { canonicalUrl: props.site_url });
    }

    super(metaProps);
  }

  static getSeoMeta(props) {
    const seoMeta = get(props, 'seo_meta', fromPairs(SEO_META_ATTRIBUTES));
    return mapKeys(pick(seoMeta, SEO_META_ATTRIBUTES), (_, k) => camelCase(k));
  }

  static title({ destinationType, search, brand, override }) {
    if (!isEmpty(override)) { return override; }

    const { destination, isMonthlySearch, timezone } = search;

    const parkingNoun = isMonthlySearch ? 'Monthly Parking' : 'Parking';
    const findParkingMessage = `${destination.name} ${parkingNoun} - Find Parking`;

    const metaTitle = get(destination, ['meta', 'title'], '');
    const metaTitleFindParkingMessage = `${metaTitle}${isMonthlySearch ? ' - Monthly Parking' : ''}`;

    switch (destinationType) {
      case 'event': {
        return `${destination.name} Parking - ${destination.startTime.tz(timezone).format('MMM DD')}`;
      }
      case 'point': {
        // If it's a current location search, we want to display a different title;
        return destination.geolocation
          ? 'Parking Near Me'
          : findParkingMessage;
      }
      case 'hub': {
        // Return a generic meta for hubs if something isn't returned by the API
        return metaTitle
          ? metaTitleFindParkingMessage
          : findParkingMessage;
      }
      case 'venue': {
        return metaTitle
          ? metaTitleFindParkingMessage
          : `${destination.name} ${parkingNoun} - Find ${parkingNoun} near ${destination.name}`;
      }
      // TODO: See if we actually need to check for and use metaTitle for default case
      default:
        return metaTitle
          ? metaTitleFindParkingMessage
          : `Save money on parking. Find the best parking deals with the ${brand.displayName} mobile app.`;
    }
  }

  static description({ destinationType, search, searchVenue, brand, override }) {
    if (!isEmpty(override)) return override;
    if (destinationType === 'event') {
      const event = search.destination;
      if (event.approximateLowPrice > 0) {
        return `Find parking for ${event.name} on ${event.startTime.format('MMM DD')} and compare prices on a parking map. Parking available for ${Event.formatPrice(event.approximateLowPrice)}`;
      }
      return `Find ${event.name} parking for ${event.startTime.format('MMM DD')} at ${searchVenue.name} at ${brand.displayName} and take the hassle out of parking.`;
    } else if (destinationType === 'venue') {
      const venue = search.destination;
      if (venue.meta && venue.meta.description) {
        return venue.meta.description;
      } else if (venue.isAirportVenue) {
        return `Got a flight? Book a parking space for ${venue.name} and avoid travel hassles.  It's quick, safe and convenient!`;
      } else if (venue.isSportsVenue) {
        return `Get a prepaid parking space near ${venue.name} and you won't have to worry about parking on game day.`;
      }
      return `Find out where to park near ${venue.name} and book a space. See parking lots and garages and compare prices on the ${venue.name} parking map at ${brand.displayName}.`;
    } else if (destinationType === 'hub') {
      const hub = search.destination;
      if (hub.meta && hub.meta.description) {
        return hub.meta.description;
      }
      // Return a generic meta for hubs if something isn't returned by the API
      return `Find out where to park in ${hub.name} and compare prices on the ${hub.name} parking map at ${brand.displayName}. Make your trip hassle-free by reserving a space ahead of time.`;
    } else if (destinationType === 'point') {
      const point = search.destination;
      return `Save money on parking near ${point.name}. Find the best parking deals with the ${brand.displayName} mobile app.`;
    }
    // Catch all here
    return `Save money on parking. Find the best parking deals with the ${brand.displayName} mobile app.`;
  }

  static deepLink(type, search) {
    switch (type) {
      case 'location':
        return `android-app://com.parkwhiz.driverApp/http/www.parkwhiz.com/location?id=${search.destination.id}`;
      case 'point':
        return `android-app://com.parkwhiz.driverApp/http/www.parkwhiz.com/search?args=${search.destination.name}&start=${search.startTime.format('YYYY-MM-DDTHH:mm')}&end=${search.endTime.format('YYYY-MM-DDTHH:mm')}`;
      case 'hub':
        return `android-app://com.parkwhix.driverApp/http/www.parkwhiz.com/city?lat=${search.destination.lat}&lng=${search.destination.lng}`;
      case 'venue':
        return `android-app://com.parkwhiz.driverApp/http/www.parkwhiz.com/venue?id=${search.destination.id}&start=&end=`;
      case 'event':
        return `android-app://com.parkwhiz.driverApp/http/www.parkwhiz.com/event?id=${search.destination.id}&start=${search.startTime.format('YYYY-MM-DDTHH:mm')}&end=${search.endTime.format('YYYY-MM-DDTHH:mm')}`;
      default:
        return null;
    }
  }
}
