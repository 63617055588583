import { get, post, put, del } from 'lib/api/base';
import env from 'lib/env';


const { NODE_BASE_V3_1_API_URL, NODE_BASE_INTERNAL_API_URL } = env();
const URL = `${NODE_BASE_V3_1_API_URL}/accounts`;

export const AccountApi = {
  getPaymentMethods({ id, accessToken, fields, zoom }, requestQueue = null) {
    const url = `${URL}/me/payment_methods/`;

    const [request, promise] = get({
      url,
      accessToken,
      data: {
        fields: fields ? fields.join() : '',
        zoom: zoom ? zoom.join() : '',
      },
    });

    if (requestQueue) { requestQueue.addRequest(request, promise, 'bg', 'payment_methods', { type: 'bg', scope: 'same' }); }
    return promise;
  },
  createPaymentMethod(accessToken, params, requestQueue = null) {
    const url = `${URL}/me/payment_methods/`;

    const [request, promise] = post({
      url,
      data: params,
      accessToken,
    });

    if (requestQueue) { requestQueue.addRequest(request, promise, 'bg', 'create_payment_method', { type: 'fg', scope: 'same' }); }
    return promise;
  },
  updatePaymentMethod(id, accessToken, params, requestQueue = null) {
    const url = `${URL}/me/payment_methods/${id}/`;

    const [request, promise] = put({
      url,
      data: params,
      accessToken,
    });

    if (requestQueue) { requestQueue.addRequest(request, promise, 'bg', 'update_payment_method', { type: 'fg', scope: 'same' }); }
    return promise;
  },
  createBusinessProfile(accessToken, params, requestQueue = null) {
    const url = `${NODE_BASE_V3_1_API_URL}/business_profiles/`;

    const [request, promise] = post({
      url,
      data: params,
      accessToken,
    });

    if (requestQueue) { requestQueue.addRequest(request, promise, 'bg', 'create_business_profile', { type: 'fg', scope: 'same' }); }
    return promise;
  },
  updateBusinessProfile(id, accessToken, params, requestQueue = null) {
    const url = `${NODE_BASE_V3_1_API_URL}/business_profiles/${id}/`;

    const [request, promise] = put({
      url,
      data: params,
      accessToken,
    });

    if (requestQueue) { requestQueue.addRequest(request, promise, 'bg', 'update_business_profile', { type: 'fg', scope: 'same' }); }
    return promise;
  },
  deletePaymentMethod(id, accessToken, requestQueue = null) {
    const url = `${URL}/me/payment_methods/${id}/`;

    const [request, promise] = del({
      url,
      accessToken,
    });

    if (requestQueue) { requestQueue.addRequest(request, promise, 'bg', 'delete_payment_method', { type: 'fg', scope: 'same' }); }
    return promise;
  },
  deleteConcurToken(id, accessToken, requestQueue = null) {
    const url = `${NODE_BASE_INTERNAL_API_URL}/concur/users/${id}/`;

    const [request, promise] = del({
      url,
      accessToken,
    });

    if (requestQueue) { requestQueue.addRequest(request, promise, 'bg', 'delete_concur_token', { type: 'fg', scope: 'same' }); }
    return promise;
  },
  createExpensifyToken(id, accessToken, requestQueue = null) {
    const url = `${NODE_BASE_INTERNAL_API_URL}/expensify/users/`;

    const [request, promise] = post({
      url,
      accessToken,
    });

    if (requestQueue) { requestQueue.addRequest(request, promise, 'bg', 'create_expensify_token', { type: 'fg', scope: 'same' }); }
    return promise;
  },
  deleteExpensifyToken(id, accessToken, requestQueue = null) {
    const url = `${NODE_BASE_INTERNAL_API_URL}/expensify/users/`;

    const [request, promise] = del({
      url,
      accessToken,
    });

    if (requestQueue) { requestQueue.addRequest(request, promise, 'bg', 'delete_expensify_token', { type: 'fg', scope: 'same' }); }
    return promise;
  },
  getAccountInfo({ accessToken, fields, zoom }, requestQueue = null) {
    const url = `${URL}/me/`;

    const [request, promise] = get({
      url,
      accessToken,
      data: {
        fields: fields ? fields.join() : '',
        zoom: zoom ? zoom.join() : ':default,pw:vehicles',
      },
    });

    if (requestQueue) { requestQueue.addRequest(request, promise, 'fg', 'accountInfo', { type: 'all', scope: 'same' }); }
    return promise;
  },

  getVehicles(accessToken, requestQueue = null) {
    const url = `${URL}/me/vehicles/`;

    const [request, promise] = get({
      url,
      accessToken,
    });

    if (requestQueue) { requestQueue.addRequest(request, promise, 'bg', 'vehicles', { type: 'all', scope: 'same' }); }
    return promise;
  },

  // TODO: Look into removing this?
  signin(email, password, requestQueue = null) {
    const url = '/reserve/signin/';

    const [request, promise] = post({
      url,
      data: { email, password },
      type: 'form',
    });

    if (requestQueue) { requestQueue.addRequest(request, promise, 'fg', 'signin', { type: 'fg', scope: 'same' }); }
    return promise;
  },

  setDefaultVehicle({ defaultVehicleId, requestQueue, accessToken }) {
    const url = `${URL}/me/vehicles/${defaultVehicleId}`;
    const [request, promise] = put({
      url,
      data: { default: true },
      type: 'form',
      accessToken,
    });
    if (requestQueue) { requestQueue.addRequest(request, promise, 'bg', 'setDefaultVehicle', { type: 'bg', scope: 'same' }); }
    return promise;
  },

  deleteVehicle({ vehicleId, requestQueue, accessToken }) {
    const url = `${URL}/me/vehicles/${vehicleId}`;
    const [request, promise] = del({
      url,
      accessToken,
    });
    if (requestQueue) { requestQueue.addRequest(request, promise, 'bg', 'deleteVehicle', { type: 'bg', scope: 'same' }); }
    return promise;
  },

  saveVehicle({ vehicle, requestQueue, accessToken }) {
    const url = `${URL}/me/vehicles/`;
    const [request, promise] = post({
      url,
      data: {
        label: vehicle.label,
        plate_number: vehicle.plateNumber,
      },
      type: 'form',
      accessToken,
    });
    if (requestQueue) { requestQueue.addRequest(request, promise, 'bg', 'saveVehicle', { type: 'bg', scope: 'same' }); }
    return promise;
  },

  updateVehicle({ vehicle, requestQueue, accessToken }) {
    const url = `${URL}/me/vehicles/${vehicle.id}`;
    const [request, promise] = put({
      url,
      data: {
        label: vehicle.label,
        plate_number: vehicle.plateNumber,
        default: vehicle.default,
        plate_state: vehicle.plateState,
      },
      type: 'form',
      accessToken,
    });
    if (requestQueue) { requestQueue.addRequest(request, promise, 'bg', 'saveVehicle', { type: 'bg', scope: 'same' }); }
    return promise;
  },

};

export default AccountApi;
