import React, { Component } from 'react';
import PropTypes from 'prop-types';

import env from 'lib/env';

const { ENVIRONMENT } = env();

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  // We'd love to add a message here, but the JS on the page is crashing
  // Because of that, message dispatching is unreliable.
  componentDidCatch(error, info) {
    this.setState({ hasError: true });
    console.warn(error, info);
    if (ENVIRONMENT !== 'local') {
      window.location = '/';
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="text-center padding-top-40">
          <h1 className="text-color-dark-slate text-weight-black text-size-72">Aw Shucks</h1>
          <div>Something went wrong. Please refresh the page to try again .</div>
        </div>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = propTypes;
export default ErrorBoundary;
