import React, { Component } from 'react';
import PropTypes from 'prop-types';
import range from 'lodash/range';

import StarRating from 'components/bookings/review-form/star-ratings/star-rating';

const propTypes = {
  reviewRating: PropTypes.number,
};

const defaultProps = {
  reviewRating: null,
};

class StarRatings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hoveredRating: null,
      selectedRating: props.reviewRating,
    };

    this.onHover = this.onHover.bind(this);
    this.onSelect = this.onSelect.bind(this);
  }

  onSelect(selectedRating) {
    this.setState({ selectedRating });
  }

  onHover(hoveredRating) {
    this.setState({ hoveredRating });
  }

  render() {
    const { selectedRating, hoveredRating } = this.state;

    const starRatings = range(1, 6).map(i => (
      <StarRating
        key={`star-rating-${i}`}
        rating={i}
        onHover={this.onHover}
        onSelect={this.onSelect}
        selectedRating={selectedRating}
        hoveredRating={hoveredRating}
      />
    ));

    return (
      <div className="margin-top-15 margin-bottom-10">
        {starRatings}
      </div>
    );
  }
}

StarRatings.propTypes = propTypes;
StarRatings.defaultProps = defaultProps;
export default StarRatings;
