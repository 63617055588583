import { get, post } from 'lib/api/base';
import env from 'lib/env';


const { NODE_BASE_V3_1_API_URL } = env();

const BraintreeTokenApi = {
  get(accessToken, requestQueue = null) {
    const url = `${NODE_BASE_V3_1_API_URL}/payment_tokens/`;
    const [request, promise] = post({ url, accessToken });
    if (requestQueue) { requestQueue.addRequest(request, promise, 'bg', 'braintree_token', {type: 'bg', scope: 'same'}); }
    return promise;
  }
};

export default BraintreeTokenApi;
