const apiParsingMiddleware = store => next => (action) => {
  const parsedAction = action;
  const { models } = parsedAction;

  if (models) {
    const { payload: { body, headers } } = parsedAction;

    parsedAction.payload.parsedModels = {};

    Object.keys(models).forEach((model) => {
      let parsedModel;
      // Try if it's a function
      try {
        parsedModel = models[model]({ body, headers });
      // Otherwise it's an immutable record / class
      } catch (e) {
        parsedModel = new models[model]({ body, headers });
      }

      parsedAction.payload.parsedModels[model] = parsedModel;
    });
  }

  next(parsedAction);
};

export default apiParsingMiddleware;
