import React from 'react';
import PropTypes from 'prop-types';
import VisibilitySensor from 'react-visibility-sensor';

import { analyticsEventShape } from 'lib/common/prop-types';

import Close from 'components/svgs/icons/close';

const propTypes = {
  impressionEvent: analyticsEventShape,
  onHide: PropTypes.func.isRequired,
  trackEvent: PropTypes.func,
};

const defaultProps = {
  impressionEvent: null,
  clickEvent: null,
  trackEvent: null,
};

const GeneralFooter = ({ onHide, trackEvent, impressionEvent }) => {
  if (trackEvent) {
    return (
      <VisibilitySensor onChange={() => trackEvent(impressionEvent)}>
        <a onClick={onHide}><Close /></a>
      </VisibilitySensor>
    );
  }
  return <a onClick={onHide}><Close /></a>;
};

GeneralFooter.propTypes = propTypes;
GeneralFooter.defaultProps = defaultProps;
export default GeneralFooter;
