import { takeEvery, call, put } from 'redux-saga/effects';

import addMessage from 'action-creators/messaging/add-message';
import { ADD_MESSAGE_AND_SCROLL_TO_TOP } from 'action-creators/messaging/add-message-and-scroll-to-top';

const scrollToTop = () => {
  window.scrollTo(0, 0);
};

export function* addMessageAndScrollToTop(action) {
  yield put(addMessage(action.payload));
  yield call(scrollToTop);
}

export default function* root() {
  yield takeEvery(ADD_MESSAGE_AND_SCROLL_TO_TOP, addMessageAndScrollToTop);
}
