import phpdate from './phpdate';
import moment from 'moment-timezone';
import pluralize from 'pluralize';


function pad(number, minimumDigits) {
  let i;
  let padding = '';
  for (i = 0; i < minimumDigits; i++) {
    padding += '0';
  }
  return (padding + number).slice(0 - minimumDigits);
}

// return a valid pair of start and end times based on an incoming start time and duration
export function getValid(start, duration = 10800000, timezone = null) {
  // minimum duration is 15 minutes
  if (duration < 1800000) { duration = 1800000; }

  let d1 = start;
  let d2 = d1.clone().add(duration);
  const now = moment();
  let today = moment();
  // set TODAY var as 12:01am
  today = today.hours(0);
  today = today.minutes(1);

  // if start date is invalid, make it now.
  if (isNaN(d1) || d1.diff(today) < 0) {
    d1 = now;
    d2 = d1.clone().add(duration);
  }

  if (isNaN(d2) || d2.diff(d1) <= 900000) { d2 = d1.clone().add(10800000); }

  d1 = d1.minutes(Math.round(d1.minutes() / 30) * 30);
  d2 = d2.minutes(Math.round(d2.minutes() / 30) * 30);
  return [d1, d2];
}

// return a user-friendly date
export function friendlyDate(date, format) {
  const today = new Date();
  const tomorrow = new Date(today.valueOf() + 86400000);
  date = new Date(date);
  if (date.getFullYear() === today.getFullYear() &&
            date.getMonth() === today.getMonth() &&
            date.getDate() === today.getDate()) {
    return 'Today';
  }
  if (date.getFullYear() === tomorrow.getFullYear() &&
            date.getMonth() === tomorrow.getMonth() &&
            date.getDate() === tomorrow.getDate()) {
    return 'Tomorrow';
  }
  return phpdate(format || 'm d', date);
}

// return a user-friendly date range
export function friendlyDateRange(startDate, endDate) {
  const sd = friendlyDate(startDate, 'm d');
  const ed = friendlyDate(endDate, 'm d');
  const st = phpdate('g:ia', new Date(startDate));
  const et = phpdate('g:ia', new Date(endDate));
  if (sd === ed) {
    return `${sd} ${st} - ${et}`;
  }
  return `${sd} ${st} - ${ed} ${et}`;
}

// returns a user-friendly time
export function friendlyTime(time) {
  const now = new Date();
  if ((time - now) / 60000 < 10) {
    return 'now';
  }
  return phpdate('g:ia', time);
}

// return a user-friendly duration
export function friendlyDuration(startDate, endDate) {
  const d = endDate - startDate;
  const date = new Date(endDate);
  let hrs = d / (1000 * 60 * 60);
  if (hrs < 24) {
    return `${hrs} hrs`;
  }
  if (hrs === 24) {
    return '1 day';
  }
  if (hrs < 48) {
    return `1 day, ${hrs % 24} hrs`;
  }
  date.setHours(23);
  date.setMinutes(59);
  hrs = (date - startDate) / (1000 * 60 * 60);
  return `${Math.floor(hrs / 24)} days`;
}

// return a date with padded zeroes for ISO compatibility
export function isoPad(date) {
  // if (typeof date === 'string') {
  //     date = new Date(date);
  // }
  return [date.getFullYear(), pad(date.getMonth() + 1, 2), pad(date.getDate(), 2)].join('-');
}

export function offsetTime(time, timezone) {
  const now = moment.utc();
  const my_offset = (new Date()).getTimezoneOffset();
  const target_offset = moment.tz.zone(timezone).offset(now);
  const tz_diff = (target_offset - my_offset) * 60;
  // tz_diff = (moment.tz.zone(timezone || 'America/New_York').offset(now) - moment.tz.zone("America/New_York").offset(now)) * 60,
  // quoteStart = parseInt(moment(startTime).format("X")) + tz_diff,
  // quoteEnd = parseInt(moment(endTime).format("X")) + tz_diff;;
  return new Date((parseInt(moment(time).format('X'), 10) + tz_diff) * 1000);
}

export function sameTimes(a, b) {
  return moment(a).format('YYYY-MM-DD HH:mm') === moment(b).format('YYYY-MM-DD HH:mm');
}

export function preciseHumanize(duration, pluralizeLabels = true, hourLabel = 'hour', minuteLabel = 'minute') {
  if (!duration) { return null; }

  const breakdown = [];

  const hours = duration.hours();
  if (hours > 0) {
    const finalHourLabel = pluralizeLabels ? pluralize(hourLabel, hours) : hourLabel;
    breakdown.push(`${hours} ${finalHourLabel}`);
  }

  const minutes = duration.minutes();
  if (minutes > 0) {
    const finalMinuteLabel = pluralizeLabels ? pluralize(minuteLabel, minutes) : minuteLabel;
    breakdown.push(`${minutes} ${finalMinuteLabel}`);
  }

  return breakdown.join(' and ');
}

export function preciseHumanizeRange(startDuration, endDuration, pluralizeLabels = true, hourLabel = 'hour', minuteLabel = 'minute') {
  if (!startDuration) { return null; }

  const durationRange = [preciseHumanize(startDuration, pluralizeLabels, hourLabel, minuteLabel)];

  if (endDuration && startDuration.asMinutes() !== endDuration.asMinutes()) {
    durationRange.push(preciseHumanize(endDuration, pluralizeLabels, hourLabel, minuteLabel));
  }

  return durationRange.join(' to ');
}
