import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const propTypes = {
  // Required
  className: PropTypes.string.isRequired,
  focus: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,

  // Optional
  text: PropTypes.string,
};

const defaultProps = {
  text: '',
};

class MapboxAutocompleteButton extends Component {
  constructor(props) {
    super(props);

    this.buttonClick = this.buttonClick.bind(this);
  }

  buttonClick(e) {
    e.preventDefault();
    e.stopPropagation();

    this.props.onClick();
  }

  render() {
    const buttonClassName = cx(this.props.focus);

    return (
      <button
        className={`${this.props.className} ${buttonClassName}`}
        onClick={this.buttonClick}
        type="submit"
      >
        <span className="hidden-xs fout-enabled">{ this.props.text }</span>
      </button>
    );
  }
}

MapboxAutocompleteButton.propTypes = propTypes;
MapboxAutocompleteButton.defaultProps = defaultProps;

export default MapboxAutocompleteButton;
