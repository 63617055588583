import { Message } from 'models/messages';
import { dismissMessages } from 'lib/messaging';

export const DISMISS_MESSAGE = 'dismiss-message';

export default function dismissMessage({ message }) {
  const newMessage = new Message(message);
  const messages = dismissMessages(newMessage);

  return {
    type: DISMISS_MESSAGE,
    messages,
  };
}
