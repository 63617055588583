import React, { Component } from 'react';
import PropTypes from 'prop-types';
import url from 'url';
import VisibilitySensor from 'react-visibility-sensor';
import get from 'lodash/get';

import Brand from 'models/brand';
import TrackingProperties from 'models/tracking-properties';

import * as AppContext from 'lib/app-context';

import {
  DOWNLOAD_APPLE_BUTTON_CLICK,
  DOWNLOAD_APPLE_BUTTON_VISIBLE,
  DOWNLOAD_ANDROID_BUTTON_CLICK,
  DOWNLOAD_ANDROID_BUTTON_VISIBLE,
  DOWNLOAD_ONELINK_BUTTON_CLICK,
  DOWNLOAD_ONELINK_BUTTON_VISIBLE,
} from 'lib/analytics/events';

export const propTypes = {
  brand: PropTypes.instanceOf(Brand).isRequired,
  appContext: PropTypes.string.isRequired,
  trackEvent: PropTypes.func.isRequired,
  trackingProperties: PropTypes.instanceOf(TrackingProperties).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
  appStore: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
  linkProps: PropTypes.shape({
    c: PropTypes.string,
  }),
  analyticsPosition: PropTypes.number,
  mobileOnly: PropTypes.bool,
  desktopOnly: PropTypes.bool,
};

export const defaultProps = {
  appStore: null,
  linkProps: {},
  title: null,
  className: null,
  analyticsPosition: 0,
  mobileOnly: false,
  desktopOnly: false,
};

class AppLink extends Component {
  constructor(props) {
    super(props);

    this.state = { wasVisible: false };
    this.onVisible = this.onVisible.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  get content() {
    const { children } = this.props;
    if (typeof children === 'string') { return <span>{children}</span>; }
    return children;
  }

  get downloadURL() {
    const { brand, appStore, trackingProperties } = this.props;
    let { linkProps } = this.props;
    linkProps.pid = brand.pid;
    const { afProps } = trackingProperties;
    linkProps = TrackingProperties.addAFProps({ linkProps, afProps });

    let downloadURL;
    switch (appStore) {
      case 'apple':
        downloadURL = brand.appDownloadURLs.ios;
        break;
      case 'android':
        downloadURL = brand.appDownloadURLs.android;
        break;
      default:
        downloadURL = brand.appDownloadURLs.onelink;
        break;
    }
    return url.format({ pathname: downloadURL, query: linkProps });
  }

  trackEvent(event) {
    const { trackEvent, analyticsPosition } = this.props;
    trackEvent({
      ...event,
      position: analyticsPosition.toString(),
    });
  }

  onClick() {
    const { appStore } = this.props;
    switch (appStore) {
      case 'apple':
        this.trackEvent(DOWNLOAD_APPLE_BUTTON_CLICK);
        break;
      case 'android':
        this.trackEvent(DOWNLOAD_ANDROID_BUTTON_CLICK);
        break;
      default:
        this.trackEvent(DOWNLOAD_ONELINK_BUTTON_CLICK);
        break;
    }
  }

  onVisible(isVisible) {
    const { appStore, appContext, mobileOnly, desktopOnly } = this.props;
    if (
      (mobileOnly && appContext !== AppContext.MOBILE) ||
      (desktopOnly && appContext !== AppContext.DESKTOP)
    ) { return; }

    if (isVisible && !this.state.wasVisible) {
      this.setState({ wasVisible: true });
      switch (appStore) {
        case 'apple':
          this.trackEvent(DOWNLOAD_APPLE_BUTTON_VISIBLE);
          break;
        case 'android':
          this.trackEvent(DOWNLOAD_ANDROID_BUTTON_VISIBLE);
          break;
        default:
          this.trackEvent(DOWNLOAD_ONELINK_BUTTON_VISIBLE);
          break;
      }
    }
  }

  render() {
    const { title, className, brand } = this.props;
    const { content, downloadURL } = this;

    if (!brand.showAppDownloadInformation) { return null; }

    return (
      <a href={downloadURL} title={title} className={className} onClick={this.onClick} target="_blank" rel="noopener noreferrer">
        <VisibilitySensor onChange={this.onVisible} partialVisibility>{content}</VisibilitySensor>
      </a>
    );
  }
}

AppLink.propTypes = propTypes;
AppLink.defaultProps = defaultProps;
export default AppLink;
