import { Record } from 'immutable';
import { Availabilities } from 'models/availability';
import url from 'url';
import isEmpty from 'lodash/isEmpty';
import { isNumber } from 'lib/common/url-helpers';
export default class Point extends Record({
  id: null,
  name: null,
  city: null,
  state: null,
  country: null,
  slug: null,
  lat: null,
  lng: null,
  timezone: null,
  url: null,
  meta: null,
  postalCode: null,
  msa: null,
}) {
  constructor(props) {
    if (!props) {
      super();
      return;
    }

    const availability = Availabilities(props.availability);
    const url = props.site_url || props.url || props.slug;

    super({
      id: props.id,
      name: props.name,
      city: props.city,
      state: props.state,
      country: props.country,
      lat: props.lat,
      lng: props.lng,
      timezone: props.timezone,
      slug: props.slug,
      postalCode: props.postalCode,
      msa: props.msa,
      url,
      meta: {
        title: props.name ? props.name : null,
        canonicalUrl: url,
      },
      availability,
    });
  }

  /**
   * Indiciates whether this point search was a geolocation search or not
   * @return {Boolean} - true/false whether geolocation search
   */
  get geolocation() {
    return this.name === 'current location';
  }

  get isValid() {
    return !!this.name;
  }

  get monthlyAvailable() {
    return this.getIn(['availability', 'monthly', 'available'], false);
  }

  get fullSlugFromPoint() {
    if (isEmpty(this.slug) || !isNumber(this.lat) || !isNumber(this.lng)) {
      return null;
    }
    return `${url.parse(this.slug).pathname}${this.lat}/${this.lng}/`;
  }

  static tryConstruct(props) {
    try {
      const point = new Point(props);
      return { point: point.isValid ? point : null, error: null };
    } catch (err) {
      return { point: null, error: err };
    }
  }
}
