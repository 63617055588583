// External libraries
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Location } from 'models/locations';

const propTypes = {
  location: PropTypes.instanceOf(Location),
  type: PropTypes.string,
};

const defaultProps = {
  location: null,
  type: null,
};

class LocationImage extends Component {
  renderTextOverlay() {
    return (
      <div className="text-overlay">
        {/* eslint-disable-next-line react/prop-types */}
        { this.props.children }
      </div>
    );
  }

  render() {
    let imgURL;
    if (!this.props.location || !this.props.location.getDisplayImage) { return null; }

    switch (this.props.type) {
      case 'thumbnail':
        imgURL = this.props.location.getSearchThumbnail();
        break;
      case 'gallery':
        imgURL = this.props.location.getDisplayImage();
        break;
      default:
        imgURL = this.props.location.getDisplayImage();
        break;
    }

    const backgroundImageStyle = imgURL ? { backgroundImage: `url('${imgURL}')` } : null;
    return (
      <div>
        <div className='location-image' style={backgroundImageStyle} >
          { this.props.children ? this.renderTextOverlay() : null }
        </div>
        <img src={imgURL} className="visible-print-block" alt="" />
      </div>
    );
  }
}

LocationImage.propTypes = propTypes;
LocationImage.defaultProps = defaultProps;

export default LocationImage;
