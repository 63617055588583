import React from 'react';

import { imageCdnAsset } from 'lib/common/url-helpers';

// IMPORTANT: headerImgSrc should NOT be a /static or fully-formed URL. It must be safe for imageCdnAsset to consume.
const NoticeHeader = ({ headerImgSrc, children }) => {

  const headerImage = (
    <div className="modal-image">
      <img src={imageCdnAsset(headerImgSrc)} alt="" />
    </div>
  );

  return (
    <div>
      { headerImgSrc ? headerImage : null }
      <h2 className="h2-bright">
        { children }
      </h2>
    </div>
  )
};

export default NoticeHeader;
