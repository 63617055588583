import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { List } from 'immutable';
import chunk from 'lodash/chunk';

import Brand from 'models/brand';

import * as AppContext from 'lib/app-context';

import CityToggle from 'components/common/footer/city-toggle';

const propTypes = {
  appContext: PropTypes.string.isRequired,
  brand: PropTypes.instanceOf(Brand).isRequired,
  cities: PropTypes.instanceOf(List).isRequired,
  onHide: PropTypes.func.isRequired,
};

class ParkingCities extends Component {
  renderHeader() {
    const { brand } = this.props;
    return (
      <div className="text-align-center">
        <div className="text-weight-black text-size-sm-24 text-size-xs-18 text-color-dark-slate">
          ALL <span className="text-color-blue">PARKING</span> LOCATIONS
        </div>
        <div className="padding-top-10 font-family-georgia text-size-sm-18 text-size-xs-14 text-color-medium-grey">
          Need a spot? {brand.displayName} has you covered.
        </div>
        <div className="padding-top-5 text-weight-book text-size-sm-16 text-size-xs-14 text-color-medium-grey">
          Select a city or airport below.
        </div>
      </div>
    );
  }

  renderMobileCities() {
    const countries = this.props.cities.groupBy(city => city.country_override);
    const rows = [];
    countries.forEach((cities, country) => {
      const { column1, column2 } = this.columize(cities);
      rows.push(
        <Fragment>
          <div className="row">
            <div className="padding-left-20 col-xs-6 padding-top-xs-10 text-color-blue uppercase text-weight-bold text-decoration-underline text-size-14" key={country}>
              {country || 'United States'}
            </div>
          </div>
          <div className="row">
            <div className="padding-left-30 col-xs-6">
              {Array.from(column1).map((city, x) => <CityToggle city={city} key={`col1-${x}`} />)}
            </div>
            <div className="padding-right-30 col-xs-6">
              {Array.from(column2).map((city, y) => <CityToggle city={city} key={`col2-${y}`} />)}</div>
          </div>
        </Fragment>,
      );
    });
    return <Fragment>{rows}</Fragment>;
  }

  columize(parkingCities) {
    const cityStateMap = new Map();
    const column1 = new Map();
    const column2 = new Map();
    let cities = [];
    parkingCities.forEach((city, i) => {
      if (i === parkingCities.findIndex(item => item.city_state === city.city_state)) {
        cities = [];
        cities.push({ name: city.city_name, url: city.city_url });
      } else {
        cities.push({ name: city.city_name, url: city.city_url });
      }
      cityStateMap.set(city.city_state, cities);
    });
    const numStates = cityStateMap.size;
    let count = 0;
    cityStateMap.forEach((cityStates, state) => {
      if (count < numStates / 2) {
        column1.set(state, cityStates);
        count++;
      } else {
        column2.set(state, cityStates);
      }
    });
    return { column1, column2 };
  }

  renderDesktopCities() {
    const { cities: parkingCities } = this.props;
    const cities = [];
    parkingCities.forEach((city, i) => {
      if (city.country_override && (i === parkingCities.findIndex(item => item.country_override === city.country_override))) {
        cities.push(<div className="text-color-blue uppercase text-weight-bold text-decoration-underline text-size-14" key={`country-${city.country_override}`}>{`${city.country_override}`}</div>);
      }
      if (i === parkingCities.findIndex(item => !item.country_override)) {
        cities.push(<div className="text-color-blue uppercase text-weight-bold text-decoration-underline text-size-14" key="country-usa">United States</div>);
      }
      if (i === parkingCities.findIndex(item => item.city_state === city.city_state)) {
        cities.push(<div className="text-color-dark-slate text-size-14" key={`state-${city.city_state}`}>{`${city.city_state}`}</div>);
      }
      cities.push(<a className="text-color-medium-grey text-size-14 text-size-xs-12 display-block" href={`${city.city_url}`} key={`link-${city.city_name}${city.city_state}`}>{`${city.city_name}`}</a>);
    });
    const numColumns = 4;
    const columnContainers = this.mapCitiesToColumns(cities, numColumns);
    const columns = columnContainers.map((column, i) => (
      <div key={`col-${i}`} className={`col-xs-${12 / numColumns}`}>{column}</div>
    ));
    return columns;
  }

  mapCitiesToColumns(allCities, numColumns) {
    const cities = [];
    for (let col = 0; col < numColumns; col++) {
      cities[col] = [];
    }
    const chunkedCities = chunk(allCities, Math.ceil(allCities.length / numColumns));
    for (let col = 0; col < numColumns; col++) {
      cities[col].push(chunkedCities[col]);
    }
    return cities;
  }

  render() {
    if (this.props.appContext === AppContext.MOBILE) {
      return (
        <div className="background-color-white padding-bottom-80 container">
          <div className="row background-color-white">
            <div className="modal-header background-color-white border-color-white">{ this.renderHeader() }</div>
          </div>
          <div className="row background-color-white">
            <div className="modal-body padding-top-10 background-color-white">
              <div className="content-mobile">{ this.renderMobileCities() }</div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div id="citiesModal" className="modal" tabIndex="-1" role="dialog">
        <div className="container background-color-white border-radius-4 margin-top-40 padding-horizontal-xs-20 padding-bottom-sm-40">
          <div className="row text-align-right">
            <div
              className="padding-top-20 padding-right-20 iconified-font iconified-x text-size-18 text-color-blue text-color-hover-blue clickable"
              onClick={this.props.onHide}
              type="button"
            />
          </div>
          <div className="row text-align-center">
            { this.renderHeader() }
          </div>
          <div className="row">
            <div className="col-xs-12 padding-horizontal-sm-80 padding-horizontal-xs-0 padding-bottom-xs-20 padding-bottom-sm-0">
              <hr className="padding-top-sm-5 padding-top-xs-20 padding-bottom-sm-10 hidden-xs" />
              <div className="content">
                { this.renderDesktopCities() }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ParkingCities.propTypes = propTypes;
export default ParkingCities;
