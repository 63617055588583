export const CHANGE_SELECTED_LOCATION = 'change-selected-location';

export default function changeSelectedLocation({ locationId, quoteId, displayMap, keepRoute } = {}) {
  return {
    type: CHANGE_SELECTED_LOCATION,
    payload: {
      locationId: locationId && locationId.toString(),
      quoteId,
      displayMap,
      keepRoute,
    },
  };
}
