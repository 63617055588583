import { List } from 'immutable';
import cookie from 'react-cookie';
import { canUseDOM } from 'exenv';

import { get } from 'lib/api/base';
import _get from 'lodash/get';
import { MAX_PREDICTIONS } from 'models/predictions/predictions';
import MapboxPrediction, { MapboxPredictions } from 'models/predictions/mapbox';
import { EventPredictions } from 'models/predictions/event';
import env from 'lib/env';
import * as RoutingStyle from 'lib/routing-style';

const DEFAULT_COORDINATES = { lat: 41.8781136, lng: -87.6297982 };
const LOCATION_BIAS_COOKIE = 'LOCATION_BIAS';

const { NODE_BASE_INTERNAL_API_URL } = env();

export class MapboxAutoCompleteService {
  static getCurrentLocationURL({ latitude, longitude }) {
    return `/destination/current-location/?lat=${latitude}&lng=${longitude}`;
  }

  constructor(
    geoIPLocation,
    insights = null,
    accessToken = null,
    requestQueue = null,
    brand = null,
    { daily = false } = {},
  ) {
    Object.assign(this, {
      disable: true,
      input: null,
      // eslint-disable-next-line no-undef
      timeout: null,
      insights,
      accessToken,
      requestQueue,
      brand,
      daily,
    });

    // Cookie Bias. If set, will bias all the searches to near this loceation
    const bias = cookie.load(LOCATION_BIAS_COOKIE);
    // If the IP Lookup fails, it'll just return a ',' in the cookie, which is bad
    if (bias && bias !== ',') {
      const [lat, lng] = bias.split(',').map(Number);
      // eslint-disable-next-line no-undef
      this.bias = { lat, lng };
    } else if (geoIPLocation.latitude && geoIPLocation.longitude) {
      this.bias = { lat: geoIPLocation.latitude, lng: geoIPLocation.longitude };
    } else {
      // eslint-disable-next-line no-undef
      this.bias = DEFAULT_COORDINATES;
    }

    // Default coordinates
    if (geoIPLocation.latitude && geoIPLocation.longitude) {
      this.defaultCoordinates = geoIPLocation;
    } else {
      this.defaultCoordinates = { latitude: DEFAULT_COORDINATES.lat, longitude: DEFAULT_COORDINATES.lng };
    }
  }

  /**
   * Gets the user's geolocation and calls either a success or error callback
   * @param {function} success - callback success function that accepts latitude and longitude
   * @return {undefined}
   */
  getCurrentLocation(success) {
    window.navigator.geolocation.getCurrentPosition(
      ({ coords }) => {
        const { latitude, longitude } = coords;
        success({ latitude, longitude, successfulRetrieval: true });
        if (this.insights && canUseDOM) {
          this.insights.associate({ location: [coords] });
        }
      },
      (error) => {
        const { latitude, longitude } = this.defaultCoordinates;
        // error code 1 is user denying geolocation, others are browser failure
        const successfulRetrieval = error.code !== 1;
        success({ latitude, longitude, successfulRetrieval });
      },
      {
        timeout: 5000,
        enableHighAccuracy: true,
      },
    );
  }

  getPredictions(input, callback, repeat = false, { routingStyle = RoutingStyle.PARKWHIZ } = {}) {
    if (!repeat) { this.input = input; }
    if (this.searchTimeout) { clearTimeout(this.searchTimeout); }
    const { brand, daily } = this;

    if (!input) {
      this.disable = true;
      callback({ places: List(), events: List() });
      return;
    }

    this.disable = false;
    this.searchTimeout = setTimeout(() => {
      this.search(input, routingStyle, 'bg', this.requestQueue)
        .then(({ body }) => {
          if (this.disable) { return; }
          const { events = [], autocomplete: places = [] } = body;
          callback({
            events: EventPredictions(events, MAX_PREDICTIONS, input),
            places: MapboxPredictions(places, MAX_PREDICTIONS, input, brand.routingStyle, { daily }),
          });
        })
        .catch(({ error }) => {
          console.warn({ error });
        });
    }, 200);
  }

  search(term, routingStyle, requestType = 'bg', requestQueue = null) {
    const url = `${NODE_BASE_INTERNAL_API_URL}/autocomplete/`;
    const { accessToken, proximity, insights, brand } = this;

    const [request, promise] = get({
      url,
      accessToken,
      data: {
        term,
        proximity,
        routing_style: routingStyle,
        results: MAX_PREDICTIONS,
        analytics_id: insights && insights.analyticsId,
        country: 'us,ca',
        cohort: _get(insights, ['experiments', 'autocomplete_geocoder_web'], 'control'),
      },
    });
    if (requestQueue) { requestQueue.addRequest(request, promise, requestType, 'autocomplete'); }
    return promise;
  }

  resolvePrediction(id, callback, { routingStyle = RoutingStyle.PARKWHIZ } = {}) {
    this.resolve(id, routingStyle, 'bg', this.requestQueue)
      .then(({ body }) => { callback(new MapboxPrediction(body)); })
      .catch((resp) => { console.warn({ error: resp.error, resp }); });
  }

  resolve(id, routingStyle, requestType = 'bg', requestQueue = null) {
    const url = `${NODE_BASE_INTERNAL_API_URL}/autocomplete/${id}/`;
    const { accessToken } = this;
    const [request, promise] = get({
      url,
      accessToken,
      data: {
        routing_style: routingStyle,
      },
    });

    if (requestQueue) { requestQueue.addRequest(request, promise, requestType, 'autocomplete-resolve'); }
    return promise;
  }

  setBias({ lat, lng }) {
    if (lat && lng) {
      this.bias = { lat: parseFloat(lat), lng: parseFloat(lng) };
      cookie.save(LOCATION_BIAS_COOKIE, `${lat},${lng}`, { path: '/' });
    }
  }

  get proximity() {
    return `${this.bias.lat},${this.bias.lng}`;
  }
}
