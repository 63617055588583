import { fetchMessages } from 'lib/messaging';

export const RELOAD_MESSAGES = 'reload-messages';

export default function reloadMessages() {
  return {
    type: RELOAD_MESSAGES,
    messages: fetchMessages({ newPageLoad: true }),
  };
}
