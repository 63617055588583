import Search from 'models/search';
import Event from 'models/event';
import Venue from 'models/venue';

export const UPDATE_CURRENT_SEARCH = 'update-current-search';
const updateCurrentSearch = ({ newSearch, event, venue, displayMap }) => ({
  type: UPDATE_CURRENT_SEARCH,
  payload: {
    newSearch: new Search(newSearch),
    event: event && new Event(event),
    venue: venue && new Venue(venue),
    displayMap,
  },
});
export default updateCurrentSearch;
