import upperFirst from 'lodash/upperFirst';
import camelCase from 'lodash/camelCase';

import * as Apps from 'lib/app-names';

const ACCOUNT_SETTINGS = {
  pageName: 'AccountSettings',
  pageType: 'Account',
};

const AIRPORT_CHECKOUT = {
  pageName: 'AirportCheckout',
  pageType: 'Checkout',
};

const AIRPORT_LOCATION_DETAILS = {
  pageName: 'AirportLocationDetails',
  pageType: 'LocationDetails',
};

const AIRPORT_SEARCH_RESULTS = {
  pageName: 'AirportSearchResults',
  pageType: 'SearchResults',
};

const BOOKINGS = {
  pageName: 'MyPasses',
  pageType: 'Account',
};

const CHECKOUT = {
  pageName: 'Checkout',
  pageType: 'Checkout',
};

const ERROR = {
  pageName: '404',
  pageType: 'Error',
};

const HOME = {
  pageName: 'HomePage',
  pageType: 'Landing',
};

const HOW_IT_WORKS = {
  pageName: 'HowItWorks',
  pageType: 'HowItWorks',
};

const HUB = {
  pageName: 'Hub',
  pageType: 'Hub',
};

const LOCATION_DETAILS = {
  pageName: 'LocationDetails',
  pageType: 'LocationDetails',
};

const MAP = {
  pageName: 'SearchResults',
  pageType: 'SearchResults',
};

const MONTHLY_CHECKOUT = {
  pageName: 'MonthlyCheckout',
  pageType: 'Checkout',
};

const MONTHLY_LOCATION_DETAILS = {
  pageName: 'MonthlyLocationDetails',
  pageType: 'LocationDetails',
};

const MONTHLY_SEARCH_RESULTS = {
  pageName: 'MonthlySearchResults',
  pageType: 'SearchResults',
};

const RECEIPT = {
  pageName: 'Receipt',
  pageType: 'Receipt',
};

const PARKING_PASS = {
  pageName: 'Pass',
  pageType: 'Pass',
};

const PASSWORD_RECOVERY = {
  pageName: 'PasswordRecovery',
  pageType: 'SignInSignUp',
};

const PAYMENT_METHODS = {
  pageName: 'PaymentMethods',
  pageType: 'Account',
};

const SIGN_IN = {
  pageName: 'SignIn',
  pageType: 'SignInSignUp',
};

const SIGN_UP = {
  pageName: 'SignUp',
  pageType: 'SignInSignUp',
};

const VEHICLES = {
  pageName: 'Vehicles',
  pageType: 'Account',
};

const VENUE = {
  pageName: 'Venue',
  pageType: 'Venue',
};

const isEnhancedAirportSearch = (currentSearch, venue) => {
  return (currentSearch && currentSearch.destination && currentSearch.destination.enhancedAirport) ||
    (venue && venue.enhancedAirport);
};

const isMonthlySearch = (currentSearch) => {
  return (currentSearch && currentSearch.isMonthlySearch);
};

/**
 * Retrieve page property info for analytics
 * @param  {object} args
 *         args object
 * @param  {string } [app]
 *         name of current app (app state) for page
 * @param  {object} [currentSearch]
 *         if app state at page has currentSearch, Search model instance representing it
 * @param  {object} [venue]
 *         if app state at page has a selected venue, Venue model instance representing it
 * @return {{pageType: string, pageName: string}}
 *         page properties for analytics
 */
export const pageProps = ({ app, currentSearch, venue }) => {
  if (isEnhancedAirportSearch(currentSearch, venue)) {
    switch (app) {
      case Apps.SEARCH_APP:
        if (currentSearch.selectedLocationId) {
          return AIRPORT_LOCATION_DETAILS;
        }
        return AIRPORT_SEARCH_RESULTS;
      case Apps.LOCATION_DETAILS_APP:
        return AIRPORT_LOCATION_DETAILS;
      case Apps.CHECKOUT_APP:
        return AIRPORT_CHECKOUT;
    }
  }

  if (isMonthlySearch(currentSearch)) {
    switch (app) {
      case Apps.SEARCH_APP:
        if (currentSearch.selectedLocationId) {
          return MONTHLY_LOCATION_DETAILS;
        }
        return MONTHLY_SEARCH_RESULTS;
      case Apps.LOCATION_DETAILS_APP:
        return MONTHLY_LOCATION_DETAILS;
      case Apps.CHECKOUT_APP:
        return MONTHLY_CHECKOUT;
    }
  }

  switch (app) {
    case Apps.SEARCH_APP: {
      if (currentSearch.selectedLocationId) {
        return LOCATION_DETAILS;
      }
      let { pageName } = MAP;
      if (currentSearch.destinationType !== 'point') {
        pageName = `${upperFirst(camelCase(currentSearch.destinationType))}${pageName}`;
      } else if (currentSearch.isParkingNearMeSearch) {
        pageName = `ParkingNearMe${pageName}`;
      }
      return {
        ...MAP,
        pageName,
      };
    }
    case Apps.ACCOUNT_SETTINGS_APP:
      return ACCOUNT_SETTINGS;
    case Apps.BOOKINGS_APP:
      return BOOKINGS;
    case Apps.CHECKOUT_APP:
      return CHECKOUT;
    case Apps.ERROR_APP:
      return ERROR;
    case Apps.HOW_IT_WORKS_APP:
      return HOW_IT_WORKS;
    case Apps.HUB_APP:
      return HUB;
    case Apps.LOCATION_DETAILS_APP:
      return LOCATION_DETAILS;
    case Apps.PARKING_PASS_APP:
      return PARKING_PASS;
    case Apps.PASSWORD_RECOVERY_APP:
      return PASSWORD_RECOVERY;
    case Apps.PAYMENT_METHODS_APP:
      return PAYMENT_METHODS;
    case Apps.RECEIPT_APP:
      return RECEIPT;
    case Apps.SIGN_IN_APP:
      return SIGN_IN;
    case Apps.SIGN_UP_APP:
      return SIGN_UP;
    case Apps.VEHICLES_APP:
      return VEHICLES;
    case Apps.VENUE_APP:
      return VENUE;
    default:
      return HOME;
  }
};

export const urlProps = () => ({
  URL: `${window.location.origin}${window.location.pathname}`,
  FullURL: window.location.href,
});
