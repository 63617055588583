import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Brand from 'models/brand';

const propTypes = {
  brand: PropTypes.instanceOf(Brand).isRequired,
  pastDelay: PropTypes.bool.isRequired,
};

const Loading = ({ brand, pastDelay }) => {
  if (!pastDelay) { return null; }

  return (
    <div className="force-full-height margin-vertical-80">
      <img
        className="centered pulse"
        src={brand.logoURL}
      />
    </div>
  );
};

const mapDispatchToProps = () => ({});
const mapStateToProps = (state) => {
  const { brand } = state.brand;
  return { brand };
};

Loading.propTypes = propTypes;
export default connect(mapStateToProps, mapDispatchToProps)(Loading);
