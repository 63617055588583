export const CREATE_REVIEW = 'create-review';
const createReview = ({
  rating,
  comment,
  bookingId,
  locationId,
  authorizationCode,
}) => ({
  type: CREATE_REVIEW,
  payload: {
    rating,
    comment,
    bookingId,
    locationId,
    authorizationCode,
  },
});

export default createReview;
