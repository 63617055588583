import React from 'react';
import { connect } from 'react-redux';

import BrandNameComponent, { propTypes, defaultProps } from 'components/common/brand-name';

const BrandName = props => <BrandNameComponent {...props} />;

const mapStateToProps = (state, ownProps) => {
  const { brand } = state.brand;
  return {
    brand,
    ...ownProps,
  };
};

BrandName.propTypes = propTypes;
BrandName.defaultProps = defaultProps;
export default connect(mapStateToProps)(BrandName);
