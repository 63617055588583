import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const propTypes = {
  city: PropTypes.array.isRequired,
};

class CityToggle extends Component {
  constructor(props) {
    super(props);
    this.toggleCitiesForState = this.toggleCitiesForState.bind(this);
    this.state = {
      active: false,
    };
  }

  toggleCitiesForState(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ active: !this.state.active });
  }

  render() {
    const { city } = this.props;
    const state = city[0];
    const cityData = city[1];
    const toggleClasses = cx({
      'background-color-transparent-light-grey': true,
      'text-size-12': true,
      'text-weight-book': true,
      'padding-top-5': true,
      'padding-left-5': true,
      'padding-bottom-5': true,
      'margin-top-5': true,
      'hidden-xs': !this.state.active,
      'visible-xs': this.state.active,
    });
    const arrowClasses = cx({
      'float-right': true,
      'iconified-font': true,
      'iconified-chevron-up': true,
      'text-color-dark-slate': true,
      'text-size-10': true,
      'clickable': true,
      'hidden-xs': !this.state.active,
      'visible-xs': this.state.active,
    });
    return (
      <div>
        <div onClick={this.toggleCitiesForState} className="text-color-dark-slate text-weight-black text-size-14 padding-top-5">
          <span className={arrowClasses} />
          {`${state}`}
        </div>
        <div className={toggleClasses}>
          {cityData.map(c=> (
            <div key={`${c.name}-${state}`}><a className="text-color-medium-grey" href={`${c.url}`}>{`${c.name}`}</a></div>
          ))}
        </div>
      </div>
    );
  }
}

CityToggle.propTypes = propTypes;
export default CityToggle;
