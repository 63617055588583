export const RESET_CURRENT_SEARCH = 'reset-current-search';
const resetCurrentSearch = ({
  currentSearch,
  nearbyMetros,
  monthlyAvailable,
  hub,
  venue,
  events,
}) => ({
  type: RESET_CURRENT_SEARCH,
  payload: {
    currentSearch,
    nearbyMetros,
    monthlyAvailable,
    hub,
    venue,
    events,
  },
});
export default resetCurrentSearch;
