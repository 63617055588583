const REQUEST_TYPE_FG = 'fg',
      REQUEST_TYPE_BG = 'bg';

export default class RequestQueue {
  constructor(callback = null) {
    this.fg = new TypeQueue(REQUEST_TYPE_FG);
    this.bg = new TypeQueue(REQUEST_TYPE_BG);
    this.callback = callback;
  }

  length(type = null, scope = null) {
    switch(type) {
      case REQUEST_TYPE_FG:
      return this.fg.length(scope);
      break;
      case REQUEST_TYPE_BG:
      return this.bg.length(scope);
      break;
      default:
      return this.fg.length(scope) + this.bg.length(scope);
      break;
    }
  }

  addRequest(request, promise, type, scope, cancel = { type: 'none', scope: 'same' }) {
    const requestNode = new Request(request, promise);
    promise
      .then(res => requestNode.delete())
      .catch((res) => {
        requestNode.delete();
      })
      .then(res => this.callback())
      .catch((res) => {
        console.log("Invalid Request");
        this.callback();
      });
    switch (cancel.type) {
      case 'all':
        this.bg.cancelRequests(cancel.scope, scope);
        this.fg.cancelRequests(cancel.scope, scope);
        break;
      case REQUEST_TYPE_FG:
        this.fg.cancelRequests(cancel.scope, scope);
        break;
      case REQUEST_TYPE_BG:
        this.bg.cancelRequests(cancel.scope, scope);
        break;
      case 'none':
      default:
        break;
    }

    switch (type) {
      case REQUEST_TYPE_BG:
        this.bg.addRequest(requestNode, scope);
        break;
      case REQUEST_TYPE_FG:
      default:
        this.fg.addRequest(requestNode, scope);
        break;
    }

    this.callback();
  }

  cancelRequests(type = null, scope = null) {
    switch (type) {
      case REQUEST_TYPE_FG:
        if (scope) {
          this.fg.cancelRequests('same', scope);
        } else {
          this.fg.cancelRequests('all');
        }
        break;
      case REQUEST_TYPE_BG:
        if (scope) {
          this.bg.cancelRequests('same', scope);
        } else {
          this.bg.cancelRequests('all');
        }
        break;
      default:
        if (scope) {
          this.fg.cancelRequests('same', scope);
          this.bg.cancelRequests('same', scope);
        } else {
          this.fg.cancelRequests('all');
          this.bg.cancelRequests('all');
        }
        break;
    }

    this.callback();
  }

  empty(type = null, scope = null) {
    return (this.length(type,scope) === 0);
  }

  addCallback(callback) {
    this.callback = callback;
  }
}

class TypeQueue {
  constructor(name) {
    this.name = name;
    this.scopes = [];
  }

  addRequest(request, scope) {
    let added = false;
    for (let i = 0; i < this.scopes.length; i++) {
      if (this.scopes[i].name == scope) {
        added = true;
        this.scopes[i].addRequest(request);
      }
    }

    if (!added) {
      const newScope = new Scope(scope);
      this.scopes.push(newScope);
      newScope.addRequest(request);
    }
  }

  length(scope = null) {
    let size = 0;
    for (let i = 0; i < this.scopes.length; i++) {
      if (this.scopes[i].name == scope || !scope) {
        size += this.scopes[i].length();
      }
    }
    return size;
  }

  cancelRequests(cancelScope, requestScope) {
    for (let i = 0; i < this.scopes.length; i++) {
      if (this.scopes[i].name == requestScope || cancelScope == 'all') {
        this.scopes[i].cancelRequests();
      }
    }
  }
}

class Scope {
  constructor(name) {
    this.name = name;
    this.requests = new RequestList();
  }

  addRequest(request) {
    this.requests.add(request);
  }

  cancelRequest(request) {
    let trav = this.root;
    while (trav) {
      if (trav.request == request) {
        trav.delete();
        trav = null;
      } else {
        trav = trav.next;
      }
    }
  }

  cancelRequests() {
    this.requests.cancelAll();
  }

  length() {
    return this.requests.length()
  }
}

class RequestList {
  constructor() {
    this.root = null;
    this.end = null;
  }

  add(node) {
    node.list = this;
    if (!this.root) {
      this.root = node;
      this.end = node;
    } else {
      node.prev = this.end;
      this.end.next = node;
    }
  }

  length() {
    let length = 0,
        trav = this.root;
    while (trav) {
      length += 1;
      trav = trav.next;
    }
    return length;
  }

  cancelAll() {
    let trav = this.root,
        next;
    while (trav) {
      next = trav.next;
      trav.delete();
      trav = next;
    }
  }
}

class Request {
  constructor(request, promise) {
    this.request = request;
    this.promise = promise;
    this.next = null;
    this.prev = null;
  }

  delete() {
    if (this.prev) {this.prev.next = this.next;}
    this.next = null;
    this.prev = null;
    if (this.request.abort) { this.request.abort(); }
    if (this.list) {
      if (this.list.root == this) { this.list.root = this.next; }
      if (this.list.end == this) { this.list.end = this.prev; }
    }
  }

  active() {
    return (this.promise._state != null && this.promis._state != undefined);
  }
}
