import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cx from 'classnames';

import BrandName from 'containers/common/brand-name';
import AppLink from 'containers/common/app-link';
import LocaleSelection from 'containers/common/locale-selection';

import TermsAndConditionsFooter from 'components/common/terms-and-conditions/terms-and-conditions-footer';

import Brand from 'models/brand';

import * as AppContext from 'lib/app-context';
import * as RoutingStyle from 'lib/routing-style';
import { imageCdnAsset } from 'lib/common/url-helpers';
import env from 'lib/env';

const {
  ENTERPRISE_HOST,
} = env();

// Localization data and hrefs for hubs
const MONTHLY_DEFAULT_MESSAGE = '{hub} Monthly Parking';
const MONTHLY_ID = 'common.hub-monthly-parking';
const TRANSIENT_DEFAULT_MESSAGE = '{hub} Parking';
const TRANSIENT_ID = 'common.hub-parking';

const BP_LINK_KEY = 'bestParkingLink';
const PW_LINK_KEY = 'parkWhizLink';

/**
 * @typedef hubInfo
 * @type {Object}
 * @property {string} bestParkingLink
 *           Href for the hub on bestparking.com
 * @property {string} parkWhizLink
 *           Href for the hub on parkwhiz.com
 * @property {string} defaultMessage
 *           FALLBACK message template string used by react-intl if `id` does not exist.
 * @property {string} id
 *           ID of message template string used by react-intl. In www/locales.
 *           Ex: `common.hub-parking` looks in locales/common/ for 'common.hub-parking' JSON keys.
 * @property {object} values
 *           Values used to fill template message string.
 */
const HUBS_INFO = [
  {
    [BP_LINK_KEY]: '/boston-ma-parking/destinations/',
    [PW_LINK_KEY]: '/p/boston-parking/',
    defaultMessage: TRANSIENT_DEFAULT_MESSAGE,
    id: TRANSIENT_ID,
    values: {
      hub: 'Boston',
    },
  },
  {
    [BP_LINK_KEY]: '/chicago-il-parking/destinations/',
    [PW_LINK_KEY]: '/p/chicago-parking/',
    defaultMessage: TRANSIENT_DEFAULT_MESSAGE,
    id: TRANSIENT_ID,
    values: {
      hub: 'Chicago',
    },
  },
  {
    [BP_LINK_KEY]: '/chicago-il-parking/monthly-parking/',
    [PW_LINK_KEY]: '/p/chicago-monthly-parking/',
    defaultMessage: MONTHLY_DEFAULT_MESSAGE,
    id: MONTHLY_ID,
    values: {
      hub: 'Chicago',
    },
  },
  {
    [BP_LINK_KEY]: '/new-york-ny-parking/destinations/',
    [PW_LINK_KEY]: '/p/new-york-parking/',
    defaultMessage: TRANSIENT_DEFAULT_MESSAGE,
    id: TRANSIENT_ID,
    values: {
      hub: 'New York',
    },
  },
  {
    [BP_LINK_KEY]: '/new-york-ny-parking/monthly-parking/',
    [PW_LINK_KEY]: '/p/new-york-monthly-parking/',
    defaultMessage: MONTHLY_DEFAULT_MESSAGE,
    id: MONTHLY_ID,
    values: {
      hub: 'New York',
    },
  },
  {
    [BP_LINK_KEY]: '/san-francisco-ca-parking/destinations/',
    [PW_LINK_KEY]: '/p/san-francisco-parking/',
    defaultMessage: TRANSIENT_DEFAULT_MESSAGE,
    id: TRANSIENT_ID,
    values: {
      hub: 'San Francisco',
    },
  },
  {
    [BP_LINK_KEY]: '/toronto-on-parking/destinations/',
    [PW_LINK_KEY]: '/p/toronto-parking/',
    defaultMessage: TRANSIENT_DEFAULT_MESSAGE,
    id: TRANSIENT_ID,
    values: {
      hub: 'Toronto',
    },
  },
  {
    [BP_LINK_KEY]: '/washington-dc-parking/destinations/',
    [PW_LINK_KEY]: '/p/washington-dc-parking/',
    defaultMessage: TRANSIENT_DEFAULT_MESSAGE,
    id: TRANSIENT_ID,
    values: {
      hub: 'Washington DC',
    },
  },
];

const propTypes = {
  brand: PropTypes.instanceOf(Brand).isRequired,
  showParkingCitiesModal: PropTypes.func.isRequired,
  showSummaryOfServices: PropTypes.func.isRequired,
  showFAQ: PropTypes.func.isRequired,
};

class FullFooter extends React.Component {
  constructor(props) {
    super(props);

    this.handleTopButtonClick = this.handleTopButtonClick.bind(this);
  }

  handleTopButtonClick() {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

  renderAppDownloadLinks() {
    if (!this.props.brand.showAppDownloadInformation) { return null; }

    return (
      <Fragment>
        <h4 className="text-color-white text-weight-medium text-size-16 margin-top-40 line-height-extra">
          <FormattedMessage
            id="common.get-the-app"
            defaultMessage="GET THE APP"
          />
        </h4>
        <AppLink
          appStore="apple"
          linkProps={{ c: 'desktop_footer' }}
        >
          <img
            className="display-inline padding-vertical-5 padding-horizontal-10 background-color-transparent-white background-color-hover-transparent-white border-radius-8"
            height="38"
            src={imageCdnAsset('/images/app-badges/app-badge-apple-bare@2x.png')}
            width="134"
          />
        </AppLink>
        <AppLink
          appStore="android"
          linkProps={{ c: 'desktop_footer' }}
          className="margin-left-20"
        >
          <img
            className="display-inline padding-vertical-5 padding-horizontal-10 background-color-transparent-white background-color-hover-transparent-white border-radius-8"
            height="38"
            src={imageCdnAsset('/images/app-badges/app-badge-android-bare@2x.png')}
            width="134"
          />
        </AppLink>
      </Fragment>
    );
  }

  /**
   * Renders Hub link for Parking
   * E.G. Boston Parking
   * @param {hubinfo} hub
   *        localization info and links for the hub.
   * @param {string} hubLinkKey
   *        Object property key to get either correct link (bestparking or parkwhiz) for hub.
   * @return {ReactDOM}
   *         DOM Element for Hub link
   */
  renderHubLink(hub, hubLinkKey) {
    const { defaultMessage, id, values } = hub;
    const href = hub[hubLinkKey];
    return (
      <li key={href} className="margin-bottom-5">
        <a
          className="padding-vertical-5 text-color-light-grey text-color-hover-white display-block"
          href={href}
        >
          <FormattedMessage
            id={id}
            defaultMessage={defaultMessage}
            values={values}
          />
        </a>
      </li>
    );
  }

  /**
   * Renders Hub Links for Parking
   * @return {ReactDOM}
   *         DOM Elements for Hubs
   */
  renderHubLinks() {
    const hubLinkKey = this.props.brand.routingStyle === RoutingStyle.BESTPARKING
      ? BP_LINK_KEY
      : PW_LINK_KEY;
    return HUBS_INFO.map(hub => this.renderHubLink(hub, hubLinkKey));
  }

  /**
   * Renders Links that open modals
   * Renders both a desktop and mobile version
   * @param  {String} id
   *         ID of the react-intl element
   * @param  {String} defaultMessage
   *         Default message if translation can't be found
   * @param  {String} href
   *         Href of the desktop link
   * @param  {Function} onClick
   *         Function to open the modal on click
   * @return {ReactDOM}
   *         Dom Element for mobile and desktop link
   */
  renderModalLinks(id, defaultMessage, href, onClick) {
    return (
      <li className="margin-bottom-5">
        <a
          className="padding-vertical-5 text-color-light-grey text-color-hover-white display-block hidden-xs"
          href={href}
          onClick={(e) => { onClick(e, AppContext.DESKTOP); }}
          role="button"
        >
          <FormattedMessage id={id} defaultMessage={defaultMessage} />
        </a>
        <a
          className="padding-vertical-5 text-color-light-grey text-color-hover-white display-block visible-xs"
          onClick={(e) => { onClick(e, AppContext.MOBILE); }}
        >
          <FormattedMessage id={id} defaultMessage={defaultMessage} />
        </a>
      </li>
    );
  }

  render() {
    const { brand } = this.props;
    const footerClassNames = cx({
      'footer-wrapper': true,
    });
    const isBestParking = brand.isBestParking;
    const backgroundClassName = cx({
      'background-color-dark-slate footer-background-image-slate': !isBestParking,
      'background-color-bp-dark-blue footer-background-image-blue': isBestParking,
    });
    return (
      <div className={footerClassNames}>
        <div className={`full-footer text-color-white padding-top-80 full-footer hidden-print ${backgroundClassName}`}>
          <div className="container">
            <div className="row">

              <div className="col-sm-4 hidden-xs">
                <a href="/">
                  <img
                    alt="Parking App"
                    height="59"
                    src={brand.logoDarkURL}
                    width="293"
                  />
                </a>
                <a href="https://www.flashparking.com/">
                  <img 
                    alt="Flash Parking"
                    height="59"
                    width="293"
                    src={imageCdnAsset("/images/flash-parking.png")}
                  />
                </a>
                { this.renderAppDownloadLinks() }
              </div>

              <div className="col-sm-8">
                <div className="col-sm-4 col-xs-6">
                  <h4 className="text-color-white text-weight-black text-size-xs-14 text-size-md-20 margin-top-0 margin-bottom-xs-5 margin-bottom-md-20 line-height-extra">
                    <FormattedMessage
                      id="common.get-to-know-us"
                      defaultMessage="GET TO KNOW US"
                    />
                  </h4>
                  <ul className="list-unstyled text-size-xs-12 text-size-md-14">
                    <li className="margin-bottom-5">
                      <a className="padding-vertical-5 text-color-light-grey text-color-hover-white display-block" href="https://www.arrive.com/about/">
                        <FormattedMessage id="common.about-us" defaultMessage="About Us" />
                      </a>
                    </li>
                    <li className="margin-bottom-5">
                      <a className="padding-vertical-5 text-color-light-grey text-color-hover-white display-block" href="/how-it-works/" target="_blank">
                        <FormattedMessage id="common.how-it-works" defaultMessage="How it Works" />
                      </a>
                    </li>
                    <li className="margin-bottom-5">
                      <a className="padding-vertical-5 text-color-light-grey text-color-hover-white display-block" href="/about/press/" target="_blank">
                        <FormattedMessage id="common.press" defaultMessage="Press" />
                      </a>
                    </li>
                    <li className="margin-bottom-5">
                      <a className="padding-vertical-5 text-color-light-grey text-color-hover-white display-block" href="/about/jobs/">
                        <FormattedMessage id="common.careers" defaultMessage="Careers" />
                      </a>
                    </li>
                  </ul>

                  <h4 className="text-color-white text-weight-black text-size-xs-14 text-size-md-20 margin-top-40 margin-bottom-xs-5 margin-bottom-md-20 line-height-extra">
                    <FormattedMessage id="common.better-business" defaultMessage="BETTER FOR BUSINESS" />
                  </h4>
                  <ul className="list-unstyled">
                    <li className="margin-bottom-5">
                      <a className="padding-vertical-5 text-color-light-grey text-color-hover-white display-block" href={ENTERPRISE_HOST} target="_blank">
                        <FormattedMessage id="common.for-business" defaultMessage="ParkWhiz for Business" />
                      </a>
                    </li>
                    <li className="margin-bottom-5">
                      <a className="padding-vertical-5 text-color-light-grey text-color-hover-white display-block" href="https://www.arrive.com/platform/" target="_blank">
                        <FormattedMessage id="common.our-platform" defaultMessage="Our Platform" />
                      </a>
                    </li>
                    <li className="margin-bottom-5">
                      <a className="padding-vertical-5 text-color-light-grey text-color-hover-white display-block" href="/affiliate-program-info/">
                        <FormattedMessage id="common.affiliate" defaultMessage="Affiliate Program" />
                      </a>
                    </li>
                    <li className="margin-bottom-5">
                      <a className="padding-vertical-5 text-color-light-grey text-color-hover-white display-block" href="https://www.arrive.com/solutions/parking-operators">
                        <FormattedMessage id="common.list-parking" defaultMessage="List Your Parking" />
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="col-sm-4 col-xs-6">
                  <h4 className="text-color-white text-weight-black text-size-xs-14 text-size-md-20 margin-top-0 margin-bottom-xs-5 margin-bottom-md-20 line-height-extra">
                    <FormattedMessage id="common.find-us" defaultMessage="FIND US HERE" />
                  </h4>
                  <ul className="list-unstyled">
                    { this.renderHubLinks() }
                    <li className="margin-bottom-5">
                      <a className="padding-vertical-5 text-color-light-grey text-color-hover-white display-block" href="/parking-near-me/">
                        <FormattedMessage
                          id="common.parking-near-me"
                          defaultMessage="Parking Near Me"
                        />
                      </a>
                    </li>
                    { this.renderModalLinks('common.browse-all', 'Browse All Cities', '#citiesModal', this.props.showParkingCitiesModal) }
                  </ul>
                </div>

                <div className="col-sm-4 col-xs-6 col-xs-offset-6 col-sm-offset-0">
                  <h4 className="text-color-white text-weight-black text-size-xs-14 text-size-md-20 margin-top-0 margin-bottom-xs-5 margin-bottom-md-20 line-height-extra">
                    <FormattedMessage id="common.let-us-help" defaultMessage="LET US HELP YOU" />
                  </h4>
                  <ul className="list-unstyled">
                    <li className="margin-bottom-5">
                      <a className="padding-vertical-5 text-color-light-grey text-color-hover-white display-block" href={brand.helpWebsiteURL} target="_blank" rel="noopener noreferrer">
                        <FormattedMessage id="common.visit-help" defaultMessage="Visit Our Help Center" />
                      </a>
                    </li>
                    { this.renderModalLinks('common.summary-of-services', 'Summary of Services', '#summaryOfServiceModal', this.props.showSummaryOfServices) }
                    { this.renderModalLinks('common.faqs', 'FAQs', '#faqModal', this.props.showFAQ) }
                  </ul>
                  <h4 className="text-color-white text-weight-black text-size-xs-14 text-size-md-20 margin-top-40 margin-bottom-xs-5 margin-bottom-md-20 line-height-extra">
                    <FormattedMessage id="common.were-social" defaultMessage="WE'RE SOCIAL" />
                  </h4>
                  <ul className="list-unstyled">
                    <li className="margin-bottom-5">
                      <a className="padding-vertical-5 text-color-light-grey text-color-hover-white display-block" href="http://blog.parkwhiz.com/" target="_blank" rel="noopener noreferrer">
                        <FormattedMessage id="common.our-blog" defaultMessage="Read Our Blog" />
                      </a>
                    </li>
                    <li className="margin-bottom-5 text-color-light-grey">
                      <FormattedMessage id="common.follow-us" defaultMessage="Follow Us" />:
                    </li>
                    <li className="text-size-18">
                      <a href="https://twitter.com/parkwhiz" target="_blank" rel="noopener noreferrer">
                        <span className="iconified-font iconified-twitter text-color-white text-color-hover-blue" />
                      </a>
                      <a href="https://www.facebook.com/parkwhiz/" target="_blank" rel="noopener noreferrer">
                        <span className="iconified-font iconified-facebook text-color-white text-color-hover-blue margin-left-10" />
                      </a>
                      <a href="https://www.linkedin.com/company/parkwhiz" target="_blank" rel="noopener noreferrer">
                        <span className="iconified-font iconified-linkedin text-color-white text-color-hover-blue margin-left-10" />
                      </a>
                    </li>
                  </ul>
                  <LocaleSelection />
                </div>
              </div>
            </div>
          </div>

          <div className="background-color-transparent-black margin-top-20 padding-vertical-xs-20 padding-top-sm-40 padding-bottom-sm-20">
            <div className="container">
              <div className="row hidden-xs">
                <div className="col-sm-8 general-text">
                  <BrandName cobrand="ParkWhiz" /> &copy;{`${new Date().getFullYear()}`}. <FormattedMessage id="common.all-rights" defaultMessage="All Rights Reserved" />
                  <br />
                  <TermsAndConditionsFooter />
                </div>
                <div className="col-sm-4 text-right">
                  <a className="btn btn-primary btn-blue without-padding" onClick={() => this.handleTopButtonClick()}>
                    <FormattedMessage id="common.top" defaultMessage="Top" />
                    <span className="iconified iconified-chevron-up iconified-space-left" />
                  </a>
                </div>
              </div>

              <div className="row visible-xs">
                <div className="col-xs-8">
                  <a href="/"><img src={brand.logoDarkURL} alt={brand.displayName} /></a>
                  <a href="https://www.flashparking.com/"><img alt="Flash Parking" src={imageCdnAsset("/images/flash-parking.png")} /></a>
                </div>
                <div className="col-xs-3 col-xs-offset-1 text-right">
                  <a className="btn btn-primary btn-blue without-padding" onClick={() => this.handleTopButtonClick()}>
                    <FormattedMessage id="common.top" defaultMessage="Top" />
                  </a>
                </div>
              </div>
              <div className="row visible-xs">
                <div className="col-xs-12 margin-vertical-20 text-align-center">
                  <AppLink
                    appStore="apple"
                    linkProps={{ c: 'mobile_footer' }}
                  >
                    <img
                      className="display-inline padding-vertical-5 padding-horizontal-10 background-color-transparent-white background-color-hover-transparent-white border-radius-8"
                      height="38"
                      src={imageCdnAsset('/images/app-badges/app-badge-apple-bare@2x.png')}
                      width="134"
                    />
                  </AppLink>
                  <AppLink
                    appStore="android"
                    linkProps={{ c: 'mobile_footer' }}
                    className="margin-left-5"
                  >
                    <img
                      className="display-inline padding-vertical-5 padding-horizontal-10 background-color-transparent-white background-color-hover-transparent-white border-radius-8"
                      height="38"
                      src={imageCdnAsset('/images/app-badges/app-badge-android-bare@2x.png')}
                      width="134"
                    />
                  </AppLink>
                </div>
              </div>
              <div className="row visible-xs">
                <div className="col-xs-12 text-size-xs-10">
                  <BrandName cobrand="ParkWhiz" /> &copy;{`${new Date().getFullYear()}`}. <FormattedMessage id="common.all-rights" defaultMessage="All Rights Reserved" />
                  <br />
                  <TermsAndConditionsFooter />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

FullFooter.propTypes = propTypes;

export default FullFooter;
