import React from 'react';

const NonRefundableParkingPackages= () => (
  <p className="text-color-medium-grey text-size-20 text-weight-book" key="non-refundable-parking-packages">
    All parking packages are non-refundable.
    Please book with that in mind.
  </p>
);

export default NonRefundableParkingPackages;
