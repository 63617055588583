import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push } from 'connected-react-router';
import { Map, OrderedMap } from 'immutable';

import User from 'models/user';
import Booking from 'models/booking';
import Brand from 'models/brand';
import PastBookingGroup from 'models/past-booking-group';

import createReviewCreator from 'action-creators/bookings/create-review';
import dismissReviewModal from 'action-creators/bookings/dismiss-review-modal';

import ReviewModalComponent from 'components/bookings/review-modal';

const propTypes = {
  pastBookings: PropTypes.instanceOf(PastBookingGroup).isRequired,
  locations: PropTypes.instanceOf(OrderedMap).isRequired,
  reviews: PropTypes.instanceOf(Map).isRequired,
  user: PropTypes.instanceOf(User).isRequired,
  brand: PropTypes.instanceOf(Brand).isRequired,
  app: PropTypes.string.isRequired,
  reviewModalDismissed: PropTypes.bool.isRequired,
  createReview: PropTypes.func.isRequired,
  reviewedBooking: PropTypes.instanceOf(Booking),
  dismissReviewModal: PropTypes.func.isRequired,
};

const defaultProps = {
  reviewedBooking: null,
};

class BookingReviewModal extends Component {
  render() {
    return (<ReviewModalComponent
      {...this.props}
      ref={(m) => { this.modal = m; }}
    />);
  }
}

const mapStateToProps = state => ({
  pastBookings: state.bookings.pastBookings.allBookings,
  locations: state.bookings.locations,
  reviews: state.bookings.reviews,
  user: state.account.user,
  app: state.app.name,
  brand: state.brand.brand,
  reviewModalDismissed: state.bookings.reviewModalDismissed,
  reviewedBooking: state.bookings.reviewedBooking,
  reviewRating: state.bookings.reviewRating,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  createReview: createReviewCreator,
  dismissReviewModal,
  push,
}, dispatch);

BookingReviewModal.propTypes = propTypes;
BookingReviewModal.defaultProps = defaultProps;
export default connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(BookingReviewModal);
