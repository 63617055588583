import React from 'react';
import PropTypes from 'prop-types';

import Announcement from 'models/announcement';

const propTypes = {
  announcement: PropTypes.instanceOf(Announcement).isRequired,
  closeBanner: PropTypes.func.isRequired,
};

const AnnouncementBannerLink = ({ announcement, closeBanner }) => {
  if (!announcement.bannerLinkURL) { return null; }

  return (
    <div className="col-sm-3 col-sm-push-1 col-xs-10 col-xs-push-1 col-gutter-right-sm-30">
      <div className="background-color-white border-radius-4 text-size-14 text-weight-medium text-center padding-vertical-sm-15 padding-vertical-xs-10 margin-top-sm-20 margin-top-xs-0">
        <a
          href={announcement.bannerLinkURL}
          onClick={closeBanner}
          target="_blank"
          rel="noopener noreferrer"
        >
          {announcement.bannerLinkText}
        </a>
      </div>
    </div>
  );
};

AnnouncementBannerLink.propTypes = propTypes;
export default AnnouncementBannerLink;
