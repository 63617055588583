import React, { createContext, Component } from 'react';
import PropTypes from 'prop-types';

const AppContext = createContext({
  mounted: false,
});

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]).isRequired,
};

class AppProvider extends Component {
  static getDerivedStateFromProps(nextProps, prevState) {
    const { mounted } = nextProps;
    if (mounted !== prevState.mounted) {
      return { ...prevState, mounted };
    }

    return null;
  }
  constructor(props) {
    super(props);
    const { mounted, isWebView } = props;
    this.state = { mounted, isWebView };
  }

  render() {
    return (
      <AppContext.Provider value={this.state}>
        {this.props.children}
      </AppContext.Provider>
    );
  }
}
AppProvider.propTypes = {
  ...propTypes,
  mounted: PropTypes.bool,
  isWebView: PropTypes.bool,
};
AppProvider.defaultProps = {
  mounted: false,
  isWebView: false,
};

const AppConsumer = (props) => {
  const { children } = props;

  return (
    <AppContext.Consumer>
      {({ mounted, isWebView } = { mounted: false, isWebView: false }) => (
        React.Children.map(children, child => React.cloneElement(child, { mounted, isWebView, ...props }))
      )}
    </AppContext.Consumer>
  );
};
AppConsumer.propTypes = propTypes;

export { AppProvider, AppConsumer };
export default AppContext;
