import { Map, Record, List } from 'immutable';

export const VALET_AMENITY_KEY = 'valet';

// We need restroom AND restrooms, because someone keeps changing the key.
// Please just leave both. Somebody will change this again for no reason.
export const VALID_AMENITY_KEYS = List([
  'printed_pass',
  'valet',
  'unobstructed',
  'tailgate',
  'attended',
  'shuttle',
  'indoor',
  'restroom',
  'restrooms',
  'reentry',
  'reentry_allowed',
  'security',
  'handicap',
  'vehicle_charging',
]);

export class Amenity extends Record({
  name: null,
  description: null,
  key: null,
  enabled: null,
  visible: null,
}) {
  strikethrough() {
    return (this.visible && !this.enabled);
  }

  display() {
    // TODO: Move this somewhere else, but still inside the class
    const noDisplay = ['eticket', 'has_gallery', 'lowest_price_guarantee', 'mobile_pass', 'perk', 'price_premium'];

    if (noDisplay.includes(this.key)) {
      return false;
    }

    return ((this.enabled && this.visible) || this.key === 'unobstructed');
  }

  message() {
    switch (this.key) {
      case 'unobstructed':
        if (!this.enabled) { return 'Obstructed'; }
        return this.name;
      case 'reentry':
      case 'reentry_allowed':
        if (!this.enabled) { return 'No Reentry Allowed'; }
        return this.name;
      case 'covered':
      case 'attended':
      case 'attended_arrival':
      case 'attended_alltimes':
      case 'handicap':
      case 'indoor':
        if (!this.enabled) {
          return `Not ${this.name}`;
        }
        return this.name;
      default:
        if (this.enabled) {
          return this.name;
        }
        return `No ${this.name}`;
    }
  }

  icon() {
    let icon;
    switch (this.key) {
      case 'accessible':
        icon = 'valet-glyph-amenity-accessibility';
        break;
      case 'attended':
        icon = 'amenity-icon-attended';
        break;
      case 'attended_alltimes':
        icon = 'valet-glyph-user';
        break;
      case 'attended_arrival':
        icon = 'valet-glyph-user';
        break;
      case 'covered':
        icon = 'valet-glyph-amenity-covered';
        break;
      case 'vehicle_charging':
        icon = 'amenity-icon-vehicle-charging';
        break;
      case 'eticket':
        icon = 'valet-glyph-amenity-mobilepass';
        break;
      case 'handicap':
        icon = 'amenity-icon-handicap';
        break;
      case 'has_gallery':
        icon = 'valet-glyph-image';
        break;
      case 'indoor':
        icon = 'amenity-icon-covered';
        break;
      case 'mobile_pass':
        icon = 'valet-glyph-amenity-mobilepass';
        break;
      case 'obstructed':
        icon = 'amenity-icon-obstructed';
        break;
      case 'perk':
        icon = 'valet-glyph-star-filled';
        break;
      case 'price_premium':
        icon = 'valet-glyph-tag';
        break;
      case 'printed_pass':
        icon = 'amenity-icon-printed';
        break;
      case 'restrooms':
      case 'restroom':
        icon = 'amenity-icon-restroom';
        break;
      case 'rv':
        icon = 'valet-glyph-amenity-rv';
        break;
      case 'security':
        icon = 'amenity-icon-security';
        break;
      case 'shuttle':
        icon = 'amenity-icon-shuttle';
        break;
      case 'shuttle_to_venue':
        icon = 'valet-glyph-amenity-shuttle';
        break;
      case 'shuttle_from_venue':
        icon = 'valet-glyph-amenity-shuttle';
        break;
      case 'tailgate':
        icon = 'amenity-icon-tailgate';
        break;
      case 'unobstructed':
        if (this.enabled) {
          icon = 'amenity-icon-unobstructed';
          break;
        }
        icon = 'amenity-icon-obstructed';
        break;
      case VALET_AMENITY_KEY:
        icon = 'amenity-icon-valet';
        break;
      // it looks like reentry is coming back with both of these keys in some places
      case 'reentry':
      case 'reentry_allowed':
        if (this.enabled) {
          icon = 'amenity-icon-reentry';
          break;
        }
        icon = 'amenity-icon-no-reentry';
        break;
      default:
        icon = null;
    }

    if (!this.enabled && icon.key !== 'unobstructed' && icon.key !== 'reentry' && icon.key !== 'reentry_allowed') {
      icon = `${icon} disabled`;
    }

    return icon;
  }
}

export function Amenities(response) {
  const amenities = {};

  for (let i = 0; i < response.length; i++) {
    const amenityData = new Amenity(response[i]);
    amenities[response[i].key] = amenityData;
  }

  return Map(amenities);
}
