import { List } from 'immutable';
import React from 'react';
import Highlighter from 'react-highlight-words';
import get from 'lodash/get';

import Hub from 'models/hub';
import Venue from 'models/venue';
import Point from 'models/point';

import * as RoutingStyle from 'lib/routing-style';

class MapboxPrediction {
  static secondaryTextString(destination) {
    let text = `${destination.state}`;

    if (destination.name !== destination.city && destination.city) {
      text = `${destination.city}, ${text}`;

      if (destination.address) {
        text = `${destination.address}, ${text}`;
      }
    }

    if (destination.country) {
      text = `${text}, ${destination.country}`;
    }

    return text;
  }

  constructor(props) {
    const { result_type: resultType = 'address', routing_style: routingStyle, daily = false, must_resolve: mustResolve } = props || {};
    let destination;
    const description = props.full_name;
    let slug;

    switch (resultType) {
      case 'hub':
        destination = new Hub({
          ...props,
          name: props.short_name,
          postalCode: props.postal_code,
        });

        ({ slug } = destination);
        if (routingStyle === RoutingStyle.PARKWHIZ) {
          slug = `${slug}map/`;
        }
        break;
      case 'venue':
        destination = new Venue({
          ...props,
          name: props.short_name,
          postalCode: props.postal_code,
          daily,
        });

        slug = destination.url;
        break;
      case 'place':
        destination = new Point({
          id: props.id,
          name: props.short_name,
          address: props.address,
          city: props.city,
          state: props.state,
          country: props.country,
          timezone: props.timezone,
          lat: get(props, ['coordinates', 0], null),
          lng: get(props, ['coordinates', 1], null),
          slug: props.site_url || props.slug,
        });

        ({ slug } = destination);
        break;
      case 'address':
        destination = new Point({
          ...props,
          name: props.short_name,
          postalCode: props.postal_code,
          slug: props.site_url || props.slug,
          site_url: props.site_url || props.slug,
          lat: get(props, ['coordinates', 0], null),
          lng: get(props, ['coordinates', 1], null),
        });

        ({ slug } = destination);
        break;
      default:
        destination = new Point({
          ...props,
          name: props.short_name,
          postalCode: props.postal_code,
          slug: props.site_url || props.slug,
          site_url: props.site_url || props.slug,
          lat: get(props, ['coordinates', 0], null),
          lng: get(props, ['coordinates', 1], null),
        });

        ({ slug } = destination);
        break;
    }

    const mainText = (<Highlighter
      textToHighlight={destination.name}
      searchWords={props.input && props.input.split(' ')}
      highlightClassName="text-weight-bold"
    />);

    const secondaryText = (<Highlighter
      textToHighlight={MapboxPrediction.secondaryTextString(destination)}
      searchWords={props.input && props.input.split(' ')}
      highlightClassName="text-weight-bold"
    />);

    Object.assign(this, {
      description,
      destination,
      mainText,
      secondaryText,
      slug,
      id: destination.id,
      input: props.input,
      mustResolve,
      resultType,
    });
  }
}

export default MapboxPrediction;

export function MapboxPredictions(eventResults, maxPredictions, input, routingStyle, { daily = false } = {}) {
  const events = eventResults.slice(0, maxPredictions).map(p => (
    new MapboxPrediction({ input, ...p, routingStyle, daily })
  ));
  return List(events);
}
