import Quote from 'models/quote';

export default class NonBookableQuote extends Quote {
  static buildAmenities(attributes) {
    if (!attributes) { return null; }
    const amenities = [];
    if (attributes.Covered) {
      let enabled = false;
      if (attributes.Covered.toLowerCase().includes('indoor')) {
        enabled = true;
      }
      amenities.push({ name: 'Covered', key: 'indoor', description: 'covered', visible: true, enabled });
    }

    if (attributes.Type) {
      let enabled = false;
      if (attributes.Type.toLowerCase().includes('valet')) {
        enabled = true;
      }
      amenities.push({ name: 'Valet', key: 'valet', description: 'Valet', visible: enabled, enabled });
    }

    return amenities;
  }

  constructor(props, startTime, endTime, quoteTypeArg = null, { currency } = {}) {
    const quoteType = quoteTypeArg || 'transient';

    const quoteProps = { bookable: false, ...props };
    quoteProps.amenities = NonBookableQuote.buildAmenities(props.attributes);

    super(
      quoteProps,
      startTime,
      startTime,
      endTime,
      endTime,
      quoteType,
      null,
      { currency },
    );
  }
}
