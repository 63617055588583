import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  schema: PropTypes.object,
};

const defaultProps = {
  schema: {},
};

const JsonLD = ({ schema }) => (
  <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }} />
);

JsonLD.propTypes = propTypes;
JsonLD.defaultProps = defaultProps;

export default JsonLD;
