import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import SearchModel from 'models/search';

const propTypes = {
  currentSearch: PropTypes.instanceOf(SearchModel).isRequired,
  monthlyStart: PropTypes.instanceOf(moment),
};

const defaultProps = {
  monthlyStart: moment(),
};


const TimeRange = ({ currentSearch, monthlyStart }) => {
  const today = moment.tz(currentSearch.timezone);
  if (currentSearch.parkingType === SearchModel.MONTHLY_PARKING_TYPE) {
    let sd = monthlyStart.format('MMM D ');
    if (monthlyStart.isSame(today, 'day')) { sd = 'Today'; }
    return <div className="time-range">{sd}</div>;
  }
  const { startTime, endTime } = currentSearch;

  let sd = startTime.format('MMM D ');
  let ed = endTime.format('MMM D ');

  const st = startTime.format('h:mm A');
  const et = endTime.format('h:mm A');

  if (startTime.isSame(today, 'day')) { sd = 'Today '; }
  if (endTime.isSame(today, 'day')) { ed = 'Today '; }

  if (startTime.isSame(endTime, 'day')) {
    return (
      <div className="time-range">{sd}<span className="time">{st}</span> - <span className="time">{et}</span></div>
    );
  }

  return (
    <div className="time-range">{sd}<span className="time">{st}</span> - {ed}<span className="time">{et}</span></div>
  );
};

TimeRange.propTypes = propTypes;
TimeRange.defaultProps = defaultProps;

export default TimeRange;
