export const UPDATE_USER = 'update-user';
const updateUser = ({
  firstname,
  lastname,
  email,
  phone,
  password,
  passwordChanged,
  feedbackReminder,
}) => ({
  type: UPDATE_USER,
  payload: {
    firstname,
    lastname,
    email,
    phone,
    password,
    passwordChanged,
    feedbackReminder,
  },
});
export default updateUser;
