import React from 'react';
import PropTypes from 'prop-types';

import SimplePartnerHeader from './simple-partner-header';
import BusinessHeader from './business-header';
import FullHeader from './full-header';

import env from 'lib/env';
import get from 'lodash/get';

const { FORD_AFFILIATE_ID } = env();

const propTypes = {
  headerStyle: PropTypes.string,
  affiliateId: PropTypes.string,
  brandingImageUrl: PropTypes.string,
  trackEvent: PropTypes.func.isRequired,
  signIn: PropTypes.func.isRequired,
};

const defaultProps = {
  affiliateId: null,
  brandingImageUrl: null,
  headerStyle: '',
  navLogo: null,
};

function Header(props) {
  const { headerStyle, brandingImageUrl, affiliateId, brand } = props;
  if (!brand.showHeader && !brand.showSimplifiedHeader) { return null; }
  const isFord = (affiliateId === FORD_AFFILIATE_ID);

  if (get(brand, 'showSimplifiedHeader', false)) {
    return (
      <SimplePartnerHeader
        partnerImagePath={brand.logoLightURL}
        hasLightBackground
        shouldLinkToHome={false}
      />
    );
  }

  switch (headerStyle) {
    case 'Groupon':
      return (
        <SimplePartnerHeader
          partnerImagePath="/images/passes/logo-groupon.png"
          navBarStyles="groupon-green-background-nav"
        />
      );
    case 'Ford':
      return (
        <SimplePartnerHeader
          partnerImagePath="/images/passes/logo-ford@2x.png"
          navBarStyles="blue-background-nav"
        />
      );
    case 'Lincoln':
      return (
        <SimplePartnerHeader
          partnerImagePath="/images/passes/logo-lincoln@2x.png"
          navBarStyles="black-background-nav"
        />
      );
    case 'BestParking' && !brand.isBestParking:
      return (
        <SimplePartnerHeader
          partnerImagePath="/images/passes/logo-best-parking@2x.png"
          navBarStyles="blue-background-nav"
        />
      );
    case 'Ticketmaster':
      return (
        <SimplePartnerHeader
          partnerImagePath="/images/passes/logo-ticketmaster@2x.png"
          navBarStyles="blue-background-nav"
        />
      );
    case 'PartnerAffiliate':
      return (
        <FullHeader
          {...props}
          navHref={isFord ? 'https://www.fordpass.com/' : null}
          navLogo={brandingImageUrl}
        />
      );
    case 'Business':
      return <BusinessHeader {...props} />;
    default:
      return <FullHeader {...props} />;
  }
}

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
