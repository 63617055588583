import { Record } from 'immutable';
import url from 'url';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

const trackingPropertiesDefaults = {
  campaignId: null,
  mailchimpEmailId: null,
  mailchimpCampaignId: null,
  medium: null,
  source: null,
  term: null,
  content: null,
  userId: null,
};

export default class TrackingProperties extends Record(trackingPropertiesDefaults) {
  static addAFProps({ linkProps, afProps }) {
    const props = linkProps;
    if (get(afProps, 'af_sub1')) { props.pid = afProps.af_sub1; }
    if (get(afProps, 'af_sub3')) { props.c = afProps.af_sub3; }
    return { ...props, ...afProps };
  }

  constructor(props) {
    if (!props) {
      super();
      return;
    }

    const campaignId = props.utm_campaign || props.campaignId || null;
    const medium = props.utm_medium || props.medium || null;
    const source = props.utm_source || props.source || null;
    const mailchimpEmailId = props.mc_eid || props.mailchimpEmailId || null;
    const mailchimpCampaignId = props.mc_cid || props.mailchimpCampaignId || null;
    const term = props.utm_term || props.term || null;
    const content = props.utm_content || props.content || null;

    super({ campaignId, medium, source, mailchimpEmailId, mailchimpCampaignId, term, content });
  }

  static fromUrl(trackingUrl) {
    if (!trackingUrl || typeof trackingUrl !== 'string') {
      return null;
    }

    const trackingProperties = new TrackingProperties(url.parse(trackingUrl, true).query);
    return trackingProperties.equals(new TrackingProperties()) ? null : trackingProperties;
  }

  get isOrganic() {
    return !(this.campaignId || this.medium || this.source || this.term) || this.medium === 'organic';
  }

  get forAssociation() {
    const data = {};
    if (this.campaignId) {
      if (isArray(this.campaignId)) {
        data.campaign = this.campaignId[0];
      } else {
        data.campaign = this.campaignId;
      }
    }
    if (this.medium) {
      if (isArray(this.medium)) {
        data.medium = this.medium[0];
      } else {
        data.medium = this.medium;
      }
    }
    if (this.source) {
      if (isArray(this.source)) {
        data.source = this.source[0];
      } else {
        data.source = this.source;
      }
    }
    if (this.term) {
      if (isArray(this.term)) {
        data.term = this.term[0];
      } else {
        data.term = this.term;
      }
    }
    if (this.content) {
      if (isArray(this.content)) {
        data.content = this.content[0];
      } else {
        data.content = this.content;
      }
    }

    Object.keys(data).forEach((k) => {
      if (isEmpty(data[k])) {
        delete data[k];
      }
    });
    if (Object.keys(data).length === 0) { return null; }
    return data;
  }

  get afProps() {
    return {
      af_sub1: this.source,
      af_sub2: this.medium,
      af_sub3: this.campaignId,
      af_sub4: this.content,
      af_sub5: this.term,
    };
  }
}
