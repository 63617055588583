import { canUseDOM } from 'exenv';

const isWebView = () => (
  // Can only check webview status browser-side
  canUseDOM &&
  (
    // iOS  provides a standalone helper in safari/webviews
    window.navigator.standalone ||
    // Android and apps allow you to check via matchMedia
    window.matchMedia('(display-mode: standalone)').matches
  )
);
export default isWebView;
