import { takeEvery, takeLatest, put, call, select } from 'redux-saga/effects';
import { LOCATION_CHANGE } from 'connected-react-router';
import cookie from 'react-cookie';
import Immutable from 'immutable';
import moment from 'moment-timezone';
import url from 'url';
import uuid from 'uuid/v4';
import get from 'lodash/get';
import result from 'lodash/result';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';

import setSelectedLocation from 'action-creators/search/set-selected-location';
import { CHANGE_SELECTED_LOCATION } from 'action-creators/search/change-selected-location';
import searchBoundsChange, { SEARCH_BOUNDS_CHANGE } from 'action-creators/search/bounds-change';
import { SEARCH_CHANGE_TIMES } from 'action-creators/search/change-times';
import { SEARCH_CHANGE_PARKING_TYPE } from 'action-creators/search/change-parking-type';
import { PARKING_NEAR_ME_SEARCH } from 'action-creators/search/parking-near-me-search';
import updateCurrentSearch, { UPDATE_CURRENT_SEARCH } from 'action-creators/search/update-current-search';
import { CHANGE_SEARCH_DESTINATION } from 'action-creators/search/change-search-destination';
import updateSearch, { UPDATE_SEARCH } from 'action-creators/search/update-search';
import { DISMISS_TIME_PICKER_PROMPT, DISMISS_TIME_PICKER_PROMPT_COOKIE_NAME } from 'action-creators/search/dismiss-time-picker-prompt';
import { DISMISS_CURATION, DISMISS_CURATION_COOKIE_NAME } from 'action-creators/search/dismiss-curation';
import { SHOW_CURATION } from 'action-creators/search/show-curation';
import { SET_CURRENT_SEARCH_AND_CHANGE_BOUNDS } from 'action-creators/search/set-current-search-and-change-bounds';
import { INITIALIZE_SEARCH } from 'action-creators/search/initialize-search';
import { INITIALIZE_VENUE } from 'action-creators/search/initialize-venue';
import { ON_POP_STATE } from 'action-creators/router/on-pop-state';
import getVenueCreator, { GET_VENUE } from 'action-creators/search/get-venue';
import gotVenue, { GOT_VENUE } from 'action-creators/search/got-venue';
import gotVenueEvents from 'action-creators/search/got-venue-events';
import gotEvent from 'action-creators/search/got-event';
import gotHub from 'action-creators/search/got-hub';
import gotNearbyMetros from 'action-creators/search/got-nearby-metros';
import { RESET_CURRENT_SEARCH } from 'action-creators/search/reset-current-search';
import { REFRESH_SEARCH_FROM_HISTORY } from 'action-creators/search/refresh-search-from-history';
import setCurrentSearch, { SET_CURRENT_SEARCH } from 'action-creators/search/set-current-search';
import { REVERT_SEARCH } from 'action-creators/search/revert-search';
import highlightLocation from 'action-creators/search/highlight-location';
import clearLocations from 'action-creators/search/clear-locations';
import { GET_LOCATION_SELLER } from 'action-creators/search/get-location-seller';
import gotLocationSeller from 'action-creators/search/got-location-seller';
import trackEvent from 'action-creators/analytics/track-event';
import gotStreetParking from 'action-creators/search/got-street-parking';
import gotQuotes, { GOT_QUOTES } from 'action-creators/search/got-quotes';
import addMessage from 'action-creators/messaging/add-message';
import toggleStreetParking, { TOGGLE_STREET_PARKING } from 'action-creators/search/toggle-street-parking';
import getQuote from 'action-creators/checkout/get-quote';
import { DISMISS_RESTRICTIONS_MODAL } from 'action-creators/search/dismiss-restrictions-modal';
import setModalState from 'action-creators/modal/set-modal-state';

import { isMonthlyAvailable } from 'lib/common/search-helpers';
import { locationUnavailableMessage } from 'lib/common/messages';
import ClientApi from 'lib/api/client';
import calculateStartAndEndTimes from 'lib/common/calculate-start-and-end-times';
import history from 'lib/history';
import { SEARCH_RETURNED, DESTINATION_SEARCH } from 'lib/analytics/events';
import { pageProps } from 'lib/analytics/page-properties';
import * as RoutingStyle from 'lib/routing-style';
import { SEARCH_APP } from 'lib/app-names';
import env from 'lib/env';

import Hub from 'models/hub';
import Point from 'models/point';
import Venue from 'models/venue';
import Event, { Events } from 'models/event';
import { Location, Locations, sortLocations } from 'models/locations';
import Quote from 'models/quote';
import Search, { HUB_TYPE, POINT_TYPE, VENUE_TYPE, EVENT_TYPE } from 'models/search';
import SearchSuggestions from 'models/search-suggestions';

import { SEARCH_RESTRICTIONS_MODAL } from 'containers/common/modal';

const LOG_ERROR = 'log-error';
const HUB_MAXIMUM_MILES_AWAY = 600;

const COOKIE_DOMAIN = env();

export const getUser = state => state.account.user;
const getApp = state => state.app.name;
const getLocations = state => state.search.locations;
const getCurrentSearch = state => state.search.currentSearch;
const getPreviousSearch = state => state.search.previousSearch;
const getMonthlyAvailable = state => state.search.monthlyAvailable;
const getHub = state => state.search.hub;
const getEvent = state => state.search.event;
const getVenue = state => state.search.venue;
const getEvents = state => state.search.events;
const getEventPackageIds = state => state.search.eventPackageIds;
const getEventPackageId = state => state.search.eventPackageId;
export const getRequestQueue = state => state.requests.requestQueue;
const getBookExtendedTimes = state => state.checkout.bookExtendedTimes;
export const getRouterLocation = state => state.router.location;
const getSearch = state => state.search;
const getSelectedSearchLocation = state => state.search.selectedLocation;
const getSelectedSearchQuote = state => state.search.selectedQuote;
const getRoutingStyle = state => state.brand.brand.routingStyle;
const getBrand = state => state.brand.brand;
const getLocale = state => state.app.locale;
const getRestrictionsModalDismissals = state => state.search.restrictionsModalDismissals;

export function* changeSelectedLocation(action) {
  const { locationId, quoteId, displayMap, keepRoute = false } = action.payload;

  if (locationId) {
    const locations = yield select(getLocations);
    let selectedLocation = yield select(getSelectedSearchLocation);
    if (locationId !== get(selectedLocation, 'id')) {
      selectedLocation = locations && locations.get(locationId);
    }
    const selectedQuote = selectedLocation ? selectedLocation.getQuoteById(quoteId) : null;
    yield put(highlightLocation({ locationId }));
    if (selectedLocation) {
      yield put(setSelectedLocation({ selectedLocation, selectedQuote, displayMap, keepRoute }));
    } else {
      yield call(getQuotes, {});
    }
  } else {
    yield put(setSelectedLocation({ keepRoute }));
    yield call(getQuotes, {});
  }
}

const fetchSlug = ({ slug, accessToken, requestQueue, routingStyle }) => (
  ClientApi.resolveSlug(slug, accessToken, 'fg', requestQueue, routingStyle)
    .then(({ body }) => ({ body }))
    .catch(error => ({ error }))
);

const fetchHubs = ({ q, queryParams = {}, requestQueue, accessToken }) => (
  ClientApi.hubs({ q, queryParams, requestQueue, accessToken })
    .then(({ body }) => ({ body }))
    .catch(error => ({ error }))
);

export function* getNearbyMetros({ lat, lng }) {
  const q = {
    only_major_metros: true,
    nearby_coordinates: [lat, lng],
    distance: HUB_MAXIMUM_MILES_AWAY,
  };
  const queryParams = {
    per_page: 2,
    fields: 'hub::default,hub:site_url,hub:state,hub:coordinates,hub:availability',
  };

  const user = yield select(getUser);
  const { token: accessToken } = user;
  const requestQueue = yield select(getRequestQueue);
  const routingStyle = yield select(getRoutingStyle);

  const { error, body } = yield call(fetchHubs, { q, queryParams, requestQueue, accessToken });
  if (error) {
    yield put({ type: LOG_ERROR, error });
  } else {
    yield put(gotNearbyMetros({ hubs: body, routingStyle }));
  }
}

export function* changeSearchDestination(action) {
  const search = yield select(getSearch);
  if (search.showStreetParking) {
    yield put(toggleStreetParking({ showStreetParking: false }));
  }
  const { searchDestination } = action.payload;
  const user = yield select(getUser);
  const routingStyle = yield select(getRoutingStyle);
  const { token: accessToken } = user;
  const requestQueue = yield select(getRequestQueue);
  let { destination } = searchDestination;
  const { resultType } = searchDestination;
  let { slug } = searchDestination;
  let lat;
  let lng;
  let timezone;
  if (destination) {
    ({ timezone, lat, lng, slug } = destination);
  }
  if (!isEmpty(slug)) {
    if ((resultType === 'place') || (resultType === 'address')) {
      slug = get(destination, 'fullSlugFromPoint', null);
    }
    const { body, error } = yield call(fetchSlug, {
      slug,
      accessToken,
      requestQueue,
      routingStyle,
    });
    if (body) {
      slug = body;
    } else {
      yield put({ type: LOG_ERROR, error });
      return;
    }
  } else {
    yield put({ type: LOG_ERROR, error: { error: 'destination is missing a slug' } });
    return;
  }

  const currentSearch = yield select(getCurrentSearch);
  let { startTime, endTime } = currentSearch;

  let { venue, event, point, hub } = slug;

  let destinationType;
  let events;
  let { parkingType } = currentSearch;
  let bookExtendedTimes = true;
  if (hub) {
    hub = new Hub(hub);
    if (!timezone) { ({ timezone } = hub); }
    // The API takes care of separating "landing page" vs "search page" with
    // "url" vs "slug". So we'll just use the slug here.
    destination = hub.set('url', hub.slug);
    destinationType = HUB_TYPE;
    if (!(lat && lng)) {
      ({ lat, lng } = hub);
    }
    if (parkingType === Search.EVENT_PARKING_TYPE || parkingType === Search.PACKAGE_PARKING_TYPE) {
      parkingType = Search.DAILY_PARKING_TYPE;
    }
  }

  if (point) {
    point = new Point(point);
    if (!timezone) { ({ timezone } = point); }
    destination = point;
    destinationType = POINT_TYPE;
    if (!(lat && lng)) {
      ({ lat, lng } = point);
    }
    if (parkingType === Search.EVENT_PARKING_TYPE || parkingType === Search.PACKAGE_PARKING_TYPE) {
      parkingType = Search.DAILY_PARKING_TYPE;
    }
  }


  if (venue) {
    if (!timezone) { ({ timezone } = venue); }
    events = Events(get(venue, '_embedded.pw:upcoming_events', null));
    venue = new Venue(venue);
    if (event) {
      event = new Event(event);
      ({ startTime, endTime } = Event.getDefaultTimes(event.startTime, event.endTime, timezone));
      destination = event;
      destinationType = EVENT_TYPE;
      parkingType = Search.EVENT_PARKING_TYPE;
    } else {
      // destination = venue.set('url', `${venue.url}?${currentSearch.parkingType.toLowerCase()}=1`);
      destination = venue;
      destinationType = VENUE_TYPE;
      if (parkingType === Search.EVENT_PARKING_TYPE) {
        parkingType = Search.DAILY_PARKING_TYPE;
      }
    }
    ({ lat, lng } = venue);
    if (venue.enhancedAirport) {
      bookExtendedTimes = false;
    }
  }

  if (!(lat && lng)) {
    ({ lat, lng } = currentSearch);
  }

  const bounds = Search.boundsWithBackup({ lat, lng });

  startTime = moment(startTime).tz(timezone);
  endTime = moment(endTime).tz(timezone);
  const newSearch = currentSearch.merge({
    destination,
    destinationType,
    parkingType,
    anchorLat: lat,
    anchorLng: lng,
    lat,
    lng,
    bounds,
    startTime,
    endTime,
    defaultStartTime: startTime,
    defaultEndTime: endTime,
    locationId: null,
    selectedLocationId: null,
    selectedQuoteId: null,
    isParkingNearMeSearch: false,
  });

  yield put(updateSearch({
    app: 'Search',
    currentSearch: newSearch,
    previousSearch: currentSearch,
    monthlyAvailable: isMonthlyAvailable(slug),
    hub,
    event,
    venue,
    events,
    bookExtendedTimes,
    timezone,
    selectedLocation: null,
    selectedQuote: null,
    clearLocations: true,
  }));
  const searchSuggestions = new SearchSuggestions(get(newSearch, 'destination.searchSuggestions', null));
  const restrictionsModalDismissals = yield select(getRestrictionsModalDismissals);
  if (searchSuggestions && searchSuggestions.shouldDisplay({
    app: SEARCH_APP,
    search: currentSearch,
    dismissals: restrictionsModalDismissals,
  })) {
    yield put(setModalState({
      displayModal: true,
      header: 'emptyHeader',
      body: SEARCH_RESTRICTIONS_MODAL,
      footer: 'generalFooter',
      bodyProps: searchSuggestions,
    }));
  }

  yield call(getNearbyMetros, { lat, lng });
}

function fetchQuotes({
  search,
  searchType,
  options,
  eventPackageId,
  eventPackageIds,
  user,
  brand,
  requestQueue,
  accessToken,
}) {
  const { nonBookableRules, routingStyle } = brand;
  const { query, params } = search.params({
    searchType,
    user,
    options,
    eventPackageId,
    eventPackageIds,
    nonBookableRules,
  });

  return ClientApi.search(query, params, requestQueue, { routingStyle, accessToken })
    .then(resp => ({ resp }))
    .catch(error => ({ error }));
}

function* trackDestinationSearch(requestId) {
  const currentSearch = yield select(getCurrentSearch);
  const previousSearch = yield select(getPreviousSearch);
  const app = yield select(getApp);
  const hub = yield select(getHub);
  const venue = yield select(getVenue);
  const event = yield select(getEvent);

  let searchType = null;
  if (
    previousSearch.searchTerm !== currentSearch.searchTerm ||
    previousSearch.startTime.format() !== currentSearch.startTime.format() ||
    previousSearch.endTime.format() !== currentSearch.endTime.format()
  ) {
    currentSearch.searchId = uuid();
    searchType = 'SearchTerm';
  } else if (!Immutable.is(previousSearch.bounds, currentSearch.bounds)) {
    searchType = 'MapPanZoom';
  } else if (document.referrer === '') {
    searchType = 'LandingPage';
  }

  const venueId = venue ? venue.id : null;
  const eventId = event ? event.id : null;

  const userCoord = currentSearch.isParkingNearMeSearch ? `{"Long": ${currentSearch.lng.toFixed(8)}, "Lat": ${currentSearch.lat.toFixed(8)}}` : null;

  const properties = {
    RequestId: requestId,
    SearchId: currentSearch.searchId,
    SearchType: searchType,
    UserInput: currentSearch.userInput,
    SearchedTerm: currentSearch.searchTerm,
    InTime: currentSearch.startTime.format(),
    OutTime: currentSearch.endTime.format(),
    DestCoord: `{"Long": ${currentSearch.lng.toFixed(8)}, "Lat": ${currentSearch.lat.toFixed(8)}}`,
    UserCoord: userCoord,
    ParkingType: currentSearch.insightsParkingType,
    SearchCategory: currentSearch.insightsSearchCategory,
    VenueId: venueId,
    EventId: eventId,
  };

  yield put(trackEvent({
    ...pageProps({ app, currentSearch, hub, venue }),
    ...DESTINATION_SEARCH,
    properties,
  }));
}

export function* getQuotes(action) {
  const { searchType = 'bounds', options = {}, shouldGetQuotes } = action.payload || {};
  let { retainSelection } = action.payload || {};

  // This does not get set in all action creators, so we're fine with
  // gettings quotes if this is null or undefined.
  if (shouldGetQuotes === false) { return; }

  let currentSearch = yield select(getCurrentSearch);
  const user = yield select(getUser);
  const eventPackageId = yield select(getEventPackageId);
  const eventPackageIds = yield select(getEventPackageIds);
  const requestQueue = yield select(getRequestQueue);
  const routingStyle = yield select(getRoutingStyle);
  const brand = yield select(getBrand);
  const locale = yield select(getLocale);
  const app = yield select(getApp);
  const { token: accessToken } = user;
  const { error, resp } = yield call(fetchQuotes, {
    search: currentSearch,
    searchType,
    options,
    eventPackageId,
    eventPackageIds,
    user,
    brand,
    requestQueue,
    routingStyle,
    accessToken,
  });

  if (error) {
    yield put({ type: LOG_ERROR, error });
  } else {
    if (isNil(retainSelection)) {
      retainSelection = action.type === REVERT_SEARCH;
    }
    const { body } = resp;
    const searchRequestId = get(resp, ['response', 'headers', 'x-request-id'], null);
    const previousSearch = yield select(getPreviousSearch);
    let locations = yield select(getLocations);

    yield call(trackDestinationSearch, searchRequestId);

    yield put(trackEvent({
      ...pageProps({ app, currentSearch }),
      ...SEARCH_RETURNED,
      properties: {
        Search_Request_Id: searchRequestId,
      },
    }));
    let selectedLocation = yield select(getSelectedSearchLocation);
    let selectedQuote = yield select(getSelectedSearchQuote);
    let { selectedLocationId, selectedQuoteId } = currentSearch;
    const { sort } = currentSearch;
    const { nonBookableRules } = brand;
    const newLocations = Locations(body.data, { sort, locale, nonBookableRules });
    if (currentSearch.isDirty(previousSearch)) {
      locations = sortLocations(newLocations, { sort });
    } else {
      locations = sortLocations(locations.merge(newLocations), { sort });
    }

    if (retainSelection) {
      selectedLocation = newLocations.get(result(selectedLocationId, 'toString', null)) || selectedLocation;
    } else {
      selectedLocation = newLocations.get(result(selectedLocationId, 'toString', null));
    }

    if (currentSearch.selectedLocationId && !selectedLocation) {
      yield put(addMessage(locationUnavailableMessage));
    }

    selectedQuote = result(selectedLocation, 'getQuote', null);
    selectedQuoteId = result(selectedLocation, 'getQuoteId', null);
    selectedLocationId = get(selectedLocation, 'id', null);
    currentSearch = currentSearch.merge({
      selectedLocationId: result(selectedLocationId, 'toString', null),
      selectedQuoteId: result(selectedQuoteId, 'toString', null),
    });

    yield put(gotQuotes({
      locations,
      selectedLocation,
      selectedQuote,
      currentSearch,
      searchRequestId,
    }));
  }
}

const fetchStreetParking = ({ accessToken, query, params, requestQueue, routingStyle }) => (
  ClientApi.streetParking(query, params, requestQueue, { routingStyle, accessToken })
    .then(resp => ({ resp }))
    .catch(error => ({ error }))
);

export function* getStreetParking() {
  const brand = yield select(getBrand);
  const locations = yield select(getLocations);
  const search = yield select(getSearch);
  if (!brand.streetParkingEligible(locations)) {
    if (search.showStreetParking) {
      yield put(toggleStreetParking({ showStreetParking: false }));
    }
    return;
  }

  if (!search.showStreetParking) {
    return;
  }

  const currentSearch = yield select(getCurrentSearch);
  const requestQueue = yield select(getRequestQueue);
  const user = yield select(getUser);

  const { routingStyle } = brand;
  const { token: accessToken } = user;
  const { query, params } = currentSearch.params({
    searchType: 'bounds',
    user,
  });

  const { resp, error } = yield call(fetchStreetParking, {
    accessToken, query, params, requestQueue, routingStyle,
  });

  if (error) {
    yield put({ type: LOG_ERROR, error });
  } else {
    const { body } = resp;
    yield put(gotStreetParking({ body }));
  }
}

const fetchVenue = ({ slug, requestQueue, routingStyle, accessToken }) => (
  ClientApi.venue(slug, requestQueue, { routingStyle, accessToken })
    .then(({ body }) => ({ body }))
    .catch(error => ({ error }))
);

export function* getVenueFromAPI(action) {
  const { slug, routingStyle } = action.payload;
  const requestQueue = yield select(getRequestQueue);
  const user = yield select(getUser);
  const { token: accessToken } = user;
  const { body, error } = yield call(fetchVenue, {
    slug: (routingStyle === RoutingStyle.BESTPARKING ? slug : slug.replace(/\//g, '')),
    requestQueue,
    routingStyle,
    accessToken,
  });

  if (error) {
    yield put({ type: LOG_ERROR, error });
    return;
  }

  yield put(gotVenue({ venue: body[0] }));
}

const fetchVenueEvents = ({ venue, requestQueue, routingStyle, accessToken }) => (
  ClientApi.venueEvents(venue.id, { requestQueue, routingStyle, accessToken })
    .then(({ body }) => ({ body }))
    .catch(error => ({ error }))
);

export function* getVenueEvents() {
  const venue = yield select(getVenue);
  const requestQueue = yield select(getRequestQueue);
  const routingStyle = yield select(getRoutingStyle);
  const user = yield select(getUser);
  const { token: accessToken } = user;

  const { body, error } = yield call(fetchVenueEvents, { venue, requestQueue, routingStyle, accessToken });

  if (error) {
    yield put({ type: LOG_ERROR, error });
    return;
  }

  yield put(gotVenueEvents({ events: body }));
}

export function* changeBounds(action) {
  yield put(searchBoundsChange(action.payload));
}

export function* changeSearchTimes(action) {
  const { startTime: requestedStartTime, endTime: requestedEndTime } = action.payload;
  let currentSearch = yield select(getCurrentSearch);
  const { startTime, endTime, endTimeChanged, error } = calculateStartAndEndTimes(
    {
      startTime: requestedStartTime,
      endTime: requestedEndTime,
    },
    {
      startTime: currentSearch.startTime,
      endTime: currentSearch.endTime,
    },
    { endChanged: currentSearch.endTimeChanged },
  );

  if (error) {
    yield put(addMessage(error));
    return;
  }

  const monthlyAvailable = yield select(getMonthlyAvailable);
  const app = yield select(getApp);
  const hub = yield select(getHub);
  const event = yield select(getEvent);
  const venue = yield select(getVenue);
  const events = yield select(getEvents);
  const bookExtendedTimes = yield select(getBookExtendedTimes);
  const selectedLocation = yield select(getSelectedSearchLocation);
  const selectedQuote = yield select(getSelectedSearchQuote);
  currentSearch = currentSearch.merge({ startTime, endTime, endTimeChanged });

  yield put(updateSearch({
    app,
    currentSearch,
    selectedLocation,
    selectedQuote,
    monthlyAvailable,
    hub,
    event,
    venue,
    events,
    bookExtendedTimes,
    retainSelection: app === 'Checkout',
  }));
}

export function* setCurrentSearchAndChangeBounds(action) {
  const { lat, lng, bounds, zoomLevel } = action.payload;
  yield put(searchBoundsChange({ lat, lng, bounds, zoomLevel }));
}

const cookieTimePickerPromptDismissal = () => {
  cookie.save(DISMISS_TIME_PICKER_PROMPT_COOKIE_NAME, true, { path: '/', expires: moment().add(1, 'week').toDate() });
};

export function* dismissTimePickerPrompt() {
  yield call(cookieTimePickerPromptDismissal);
}

const cookieCurationDismissal = () => {
  cookie.save(DISMISS_CURATION_COOKIE_NAME, true, { path: '/' });
};

export function* dismissCuration() {
  yield call(cookieCurationDismissal);
}

const unCookieCurationDismissal = () => {
  cookie.remove(DISMISS_CURATION_COOKIE_NAME, { path: '/' });
};

export function* showCuration() {
  yield call(unCookieCurationDismissal);
}

export function* initializeSearch() {
  const routerLocation = yield select(getRouterLocation);
  if (routerLocation.state && routerLocation.state.search) {
    let newSearch;
    try {
      newSearch = new Search(JSON.parse(routerLocation.state.search.currentSearch));
    } catch (e) {
      console.warn(e);
      newSearch = new Search();
    }
    const event = routerLocation.state.search.event && JSON.parse(routerLocation.state.search.event);
    const venue = routerLocation.state.search.venue && JSON.parse(routerLocation.state.search.venue);
    const { displayMap } = routerLocation.state.search;
    yield put(updateCurrentSearch({ newSearch, event, venue, displayMap }));
    yield call(getQuotes, {});
  }
}

export function* initializeVenue() {
  const routingStyle = yield select(getRoutingStyle);
  const routerLocation = yield select(getRouterLocation);
  const venueSlug = routerLocation.pathname;

  yield put(getVenueCreator({ slug: venueSlug, routingStyle }));
  yield put(clearLocations());
}

export function* onPopState(action) {
  const app = yield select(getApp);
  if (!['Search', 'Checkout', 'Receipt', 'Parking Pass'].includes(app)) { return; }

  const { historyState } = action.payload;
  let selectedLocation = yield select(getSelectedSearchLocation);
  let selectedQuote = yield select(getSelectedSearchQuote);
  if (historyState && historyState.search && !historyState.search.locationId && selectedLocation) {
    yield put(setSelectedLocation({}));
  }

  if (get(historyState, ['search', 'locationId'], null) !== get(selectedLocation, 'id', null) || get(historyState, ['search', 'quoteId'], null) !== get(selectedQuote, 'id', null)) {
    selectedLocation = get(historyState, ['search', 'selectedLocation'], null) || selectedLocation;
    selectedQuote = get(historyState, ['search', 'selectedQuote'], null) || selectedQuote;

    if (app === 'Checkout' && (!selectedQuote || selectedQuote === 'null')) {
      const routerLocation = yield select(getRouterLocation);
      const { quote_id: quoteId } = url.parse(routerLocation.search, true).query;
      yield put.resolve(getQuote({ quoteId }));
      selectedLocation = yield select(getSelectedSearchLocation);
      selectedQuote = yield select(getSelectedSearchQuote);
    }
    yield put(setSelectedLocation({
      selectedLocation,
      selectedQuote,
      keepRoute: true,
    }));
  }

  const currentSearch = yield select(getCurrentSearch);
  const previousSearch = yield select(getPreviousSearch);
  if (currentSearch.isDirty(previousSearch)) {
    yield put(clearLocations());
  }

  if (historyState && historyState.app && historyState.app === 'Search') {
    yield call(getQuotes, {});
  }
}

export function* refreshSearchFromHistory() {
  const currentSearch = yield select(getCurrentSearch);
  const app = yield select(getApp);

  if (
    currentSearch &&
    currentSearch.destinationType &&
    currentSearch.lat &&
    currentSearch.lng
  ) { return; }

  const historySearch = get(history, ['location', 'state', 'search', 'currentSearch']);
  if (isEmpty(historySearch)) { return; }
  const topHistory = isString(historySearch) ? JSON.parse(historySearch) : historySearch;
  if (!(topHistory && Object.keys(topHistory).includes('search'))) { return; }

  const searchContent = JSON.parse(topHistory.search);
  const newSearch = currentSearch.merge({
    destination: searchContent.destination,
    destinationType: searchContent.destinationType,
    lat: searchContent.lat,
    lng: searchContent.lng,
    anchorLat: searchContent.lat,
    anchorLng: searchContent.lng,
    bounds: searchContent.bounds,
  });

  if (
    !newSearch.destination ||
    !newSearch.lat ||
    !newSearch.lng ||
    !newSearch.anchorLat ||
    !newSearch.anchorLng ||
    newSearch.bounds.size === 0
  ) { return; }

  const shouldGetQuotes = ['Checkout', 'Venue'].includes(app);

  yield put(setCurrentSearch({ newSearch, shouldGetQuotes }));
}

const fetchSeller = ({ sellerId, requestQueue, accessToken }) => (
  ClientApi.seller(sellerId, requestQueue, accessToken)
    .then(({ body }) => ({ body }))
    .catch(error => ({ error }))
);

export function* getLocationSeller(action) {
  const locations = yield select(getLocations);
  const requestQueue = yield select(getRequestQueue);
  const { locationId } = action.payload;
  const location = locations.get(locationId);
  const user = yield select(getUser);
  const { token: accessToken } = user;
  if (!location) { return; }
  const { sellerId } = location;
  const { body, error } = yield call(fetchSeller, { sellerId, requestQueue, accessToken });

  if (error) {
    yield put({ type: LOG_ERROR, error });
    return;
  }
  yield put(gotLocationSeller({ body }));
}

export function* locationChanged() {
  const app = yield select(getApp);
  const routerLocation = yield select(getRouterLocation);
  const selectedLocation = yield select(getSelectedSearchLocation);
  const selectedQuote = yield select(getSelectedSearchQuote);
  const currentSearch = yield select(getCurrentSearch);
  if (routerLocation.state && routerLocation.state.search) {
    let { venue, event, hub } = routerLocation.state.search;
    if (venue) {
      if (typeof venue === 'string') {
        venue = new Venue(JSON.parse(venue));
      }
      yield put(gotVenue({ venue }));
    }
    if (event) {
      if (typeof event === 'string') {
        event = new Event(JSON.parse(event));
      }
      yield put(gotEvent({ event }));
    }
    if (hub) {
      if (typeof hub === 'string') {
        hub = new Hub(JSON.parse(hub));
      }
      yield put(gotHub({ hub }));
    }

    if (app === 'Search' && ['Search', 'Venue'].includes(get(routerLocation, ['state', 'app', 'name'], null))) {
      if (routerLocation.state.search.currentSearch) {
        let routerSearch = routerLocation.state.search.currentSearch;
        if (typeof routerSearch === 'string') {
          routerSearch = JSON.parse(routerSearch);
        }

        let {
          selectedLocation: routerSelectedLocation,
          selectedQuote: routerSelectedQuote,
        } = routerLocation.state.search;
        if (routerSelectedLocation && typeof routerSelectedLocation === 'string') { routerSelectedLocation = new Location(JSON.parse(routerSelectedLocation)); }
        if (routerSelectedQuote && typeof routerSelectedQuote === 'string') {
          routerSelectedQuote = new Quote(
            JSON.parse(routerSelectedQuote),
            null,
            null,
            null,
            null,
            null,
            null,
            { currency: get(selectedLocation, 'currency') },
          );
        }
        if (routerSearch && typeof routerSearch === 'string') { routerSearch = JSON.parse(routerSearch); }

        let newSearch = new Search(routerSearch);

        if (
          !(currentSearch.lat && currentSearch.lng) ||
          currentSearch.anchorLat !== routerSearch.anchorLat ||
          currentSearch.anchorLng !== routerSearch.anchorLng ||
          get(selectedLocation, 'id', null) !== get(routerSelectedLocation, 'id', null) ||
          get(selectedQuote, 'id', null) !== get(routerSelectedQuote, 'id', null) ||
          currentSearch.isDirty(newSearch)
        ) {
          // eslint-disable-next-line max-depth
          if (newSearch.parkingType === Search.EVENT_PARKING_TYPE && newSearch.destinationType !== EVENT_TYPE) {
            newSearch = newSearch.merge({ parkingType: Search.DAILY_PARKING_TYPE });
          }

          const { monthlyAvailable } = newSearch.destination || {};
          yield put(updateSearch({
            app,
            currentSearch: newSearch,
            previousSearch: currentSearch,
            selectedLocation: routerSelectedLocation,
            selectedQuote: routerSelectedQuote,
            monthlyAvailable,
            hub,
            event,
            venue,
            bookExtendedTimes: !(venue && venue.enhancedAirport),
            clearLocations: true,
            keepRoute: true,
          }));
        }
      }
    }
  }
}

const cookieRestrictionsDismissal = (cookieName) => { cookie.save(cookieName, true, { path: COOKIE_DOMAIN }); };

export function* dismissRestrictionsModal(action) {
  const { key } = action.payload;
  const cookieName = yield call(SearchSuggestions.dismissalCookie, { key });
  yield call(cookieRestrictionsDismissal, cookieName);
}

export default function* root() {
  yield takeLatest(CHANGE_SELECTED_LOCATION, changeSelectedLocation);
  yield takeLatest(SEARCH_CHANGE_TIMES, changeSearchTimes);
  yield takeLatest(SEARCH_BOUNDS_CHANGE, getQuotes);
  yield takeLatest(SEARCH_CHANGE_PARKING_TYPE, getQuotes);
  yield takeLatest(RESET_CURRENT_SEARCH, getQuotes);
  yield takeLatest(PARKING_NEAR_ME_SEARCH, changeBounds);
  yield takeLatest(UPDATE_CURRENT_SEARCH, getQuotes);
  yield takeLatest(UPDATE_SEARCH, getQuotes);
  yield takeLatest(SET_CURRENT_SEARCH, getQuotes);
  yield takeLatest(CHANGE_SEARCH_DESTINATION, changeSearchDestination);
  yield takeEvery(DISMISS_TIME_PICKER_PROMPT, dismissTimePickerPrompt);
  yield takeEvery(DISMISS_CURATION, dismissCuration);
  yield takeEvery(SHOW_CURATION, showCuration);
  yield takeLatest(SET_CURRENT_SEARCH_AND_CHANGE_BOUNDS, setCurrentSearchAndChangeBounds);
  yield takeLatest(ON_POP_STATE, onPopState);
  yield takeLatest(INITIALIZE_SEARCH, initializeSearch);
  yield takeLatest(INITIALIZE_VENUE, initializeVenue);
  yield takeLatest(GET_VENUE, getVenueFromAPI);
  yield takeLatest(GOT_VENUE, getVenueEvents);
  yield takeLatest(REFRESH_SEARCH_FROM_HISTORY, refreshSearchFromHistory);
  yield takeEvery(GET_LOCATION_SELLER, getLocationSeller);
  yield takeLatest(LOCATION_CHANGE, locationChanged);
  yield takeLatest(GOT_QUOTES, getStreetParking);
  yield takeEvery(DISMISS_RESTRICTIONS_MODAL, dismissRestrictionsModal);
  yield takeEvery(TOGGLE_STREET_PARKING, getStreetParking);
}
