import { takeEvery, takeLatest, call, put, select } from 'redux-saga/effects';
import kebabCase from 'lodash/kebabCase';

import getTranslationsCreator, { GET_TRANSLATIONS } from 'action-creators/app/get-translations';
import { CHANGE_LOCALE } from 'action-creators/app/change-locale';
import gotTranslations from 'action-creators/app/got-translations';
import { INITIALIZE_TRANSLATIONS } from 'action-creators/app/initialize-translations';
import { INITIALIZE_HOME_PAGE } from 'action-creators/brand/initialize-home-page';
import { INITIALIZE_CHECKOUT } from 'action-creators/checkout/initialize-checkout';
import { INITIALIZE_PARKING_PASSES } from 'action-creators/bookings/initialize-parking-passes';
import { INITIALIZE_CANCEL_BOOKING } from 'action-creators/bookings/initialize-cancel-booking';

import ClientApi from 'lib/api/client';

const getLocale = state => state.app.locale;
const getApp = state => state.app.name;
const getRequestQueue = state => state.requests.requestQueue;

const fetchTranslations = ({ locale, app, requestQueue }) => (
  ClientApi.getTranslations({ locale, app, requestQueue })
    .then(({ body }) => ({ body }))
    .catch(({ error }) => ({ error }))
);

function* getTranslations(action) {
  let { app, locale } = action.payload;
  if (!locale) {
    locale = yield select(getLocale);
  }
  const requestQueue = yield select(getRequestQueue);
  app = kebabCase(app);

  const { body, error } = yield call(fetchTranslations, { locale, app, requestQueue });
  yield put(gotTranslations({ translations: body }));
}

function* initializeTranslations() {
  yield put.resolve(getTranslationsCreator({ app: 'common' }));
  yield put.resolve(getTranslationsCreator({ app: 'messages' }));
}

function* changeLocale(action) {
  const { locale } = action.payload;
  const app = yield select(getApp);
  yield call(getTranslationsCreator({ app, locale }));
  yield call(getTranslationsCreator({ app: 'common' }));
}

function* getHomePageTranslations() {
  yield put.resolve(getTranslationsCreator({ app: 'Home' }));
}

function* getCheckoutTranslations() {
  yield put.resolve(getTranslationsCreator({ app: 'Checkout' }));
}

function* getParkingPassTranslations() {
  yield put.resolve(getTranslationsCreator({ app: 'Parking Pass' }));
}

function* getCancelTranslations() {
  yield put.resolve(getTranslationsCreator({ app: 'Cancel' }));
}

export default function* root() {
  yield takeEvery(GET_TRANSLATIONS, getTranslations);
  yield takeLatest(INITIALIZE_HOME_PAGE, getHomePageTranslations);
  yield takeLatest(INITIALIZE_CHECKOUT, getCheckoutTranslations);
  yield takeLatest(INITIALIZE_PARKING_PASSES, getParkingPassTranslations);
  yield takeLatest(INITIALIZE_CANCEL_BOOKING, getCancelTranslations);
  yield takeLatest(CHANGE_LOCALE, changeLocale);
  yield takeLatest(INITIALIZE_TRANSLATIONS, initializeTranslations);
}
