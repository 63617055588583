import React from 'react';
import PropTypes from 'prop-types';

import Predictions from 'models/predictions/predictions';

import MapboxAutocompletePrediction from 'components/mapbox/autocomplete/prediction';

const propTypes = {
  // Models/Variables/etc.
  className: PropTypes.string,
  predictions: PropTypes.instanceOf(Predictions).isRequired,
  selected: PropTypes.object,

  // Functions
  setSelection: PropTypes.func.isRequired,
};

const defaultProps = {
  className: '',
  selected: {},
};

const MapboxAutocompleteDropdown = ({ className, predictions, selected, setSelection }) => {
  const { places, events, maxPredictions } = predictions;
  if (!places.size && !events.size) { return null; }

  let eventsHeader;
  let placesHeader;

  if (maxPredictions.get('places')) {
    placesHeader = (
      <div className="location-suggestion-subheader text-color-dark-slate">
        Results
      </div>
    );
  }

  if (maxPredictions.get('events')) {
    eventsHeader = (
      <div className="location-suggestion-subheader text-color-dark-slate">
        Related Events
      </div>
    );
  }

  return (
    <div className={`location-suggestion-bar text-weight-light ${className}`}>
      {placesHeader}
      {
        places.slice(0, maxPredictions.get('places')).map(
          (prediction, index) => (
            <MapboxAutocompletePrediction
              key={`p-${prediction.id}`}
              prediction={prediction}
              predictionKey={{ type: 'places', index }}
              selected={selected.type === 'places' && selected.index === index}
              setSelection={setSelection}
            />
          ),
        )
      }
      {eventsHeader}
      {
        events.slice(0, maxPredictions.get('events')).map(
          (prediction, index) => (
            <MapboxAutocompletePrediction
              key={`e-${prediction.id}`}
              prediction={prediction}
              predictionKey={{ type: 'events', index }}
              selected={selected.type === 'events' && selected.index === index}
              setSelection={setSelection}
            />
          ),
        )
      }
    </div>
  );
};

MapboxAutocompleteDropdown.propTypes = propTypes;
MapboxAutocompleteDropdown.defaultProps = defaultProps;
export default MapboxAutocompleteDropdown;
