import { Location } from 'models/locations';

export const GOT_SELECTED_QUOTE = 'got-selected-quote';
const gotSelectedQuote = ({ body, quoteId, nonBookableRules }) => {
  const selectedLocation = new Location(body, { nonBookableRules });
  const selectedQuote = selectedLocation.getQuoteById(quoteId);

  return {
    type: GOT_SELECTED_QUOTE,
    payload: {
      selectedLocation,
      selectedQuote,
    },
  };
};
export default gotSelectedQuote;
