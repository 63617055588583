import { Record } from 'immutable';

export default class Partner extends Record({
  name: null,
  id: null,
  marketingEligible: true,
}) {
  constructor(props) {
    if (!props) {
      super();
      return;
    }
    const { name, id, marketing_eligible: marketingEligible } = props;
    super({ id, name, marketingEligible });
  }

  get isStubhub() {
    return this.name === 'Stubhub';
  }

  get isBestParking() {
    return this.name === 'BestParking';
  }

  get isParkWhiz() {
    return this.name === 'ParkWhiz';
  }

  get isGoogle() {
    return this.name === 'Google';
  }
}
