import { Record } from 'immutable';

import BookingsGroup from 'models/bookings-group';

export default class PastBookingGroup extends Record({
  monthly: new BookingsGroup(),
  transient: new BookingsGroup(),
}) {
  get hasMonthlyAndTransient() {
    return this.monthly.totalCount > 0 && this.transient.totalCount > 0;
  }

  get hasBookings() {
    return this.monthly.totalCount > 0 || this.transient.totalCount > 0;
  }

  get combinedBookings() {
    return this.transient.bookings.merge(this.monthly.bookings);
  }
}
