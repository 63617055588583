import { decamelizeKeys } from 'humps';
import moment from 'moment-timezone';
import uuid from 'uuid/v4';
import { BOOKING_CANCELLABLE_FIELDS, get, post } from 'lib/api/base';
import env from 'lib/env';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';

import { PAGE_BRAND_FIELDS } from 'models/brand';

const { NODE_BASE_V3_1_API_URL } = env();
const URL = `${NODE_BASE_V3_1_API_URL}`;

const CheckoutApi = {
  getPreview(rawQueryParams = {}, accessToken, requestQueue = null) {
    const url = `${URL}/bookings/previews/`;
    const queryParams = decamelizeKeys(rawQueryParams);
    queryParams.purchase_vector = 'Site';

    const [request, promise] = post({
      url,
      data: queryParams,
      accessToken,
    });

    if (requestQueue) { requestQueue.addRequest(request, promise, 'fg', 'preview', { type: 'all', scope: 'same' }); }
    return promise;
  },

  getQuote({ quoteId, locationId, eventId, startTime, endTime, coordinates, includeAll, accessToken = null }, requestQueue = null) {
    let url;
    let params;
    // Need to chop off timezone for API v3 search
    [startTime, endTime] = [startTime, endTime].map(t => moment(t).format('YYYY-MM-DDTHH:mm'));

    if (quoteId) {
      url = `${URL}/quotes/${quoteId}/`;
    } else {
      url = `${URL}/quotes/`;
      const formattedKeys = decamelizeKeys({ locationId, coordinates, eventId });
      const q = Object.keys(formattedKeys).map((key) => {
        if (formattedKeys[key]) {
          if (Array.isArray(formattedKeys[key])) {
            formattedKeys[key] = formattedKeys[key].join(',');
          }
          return `${key}:${formattedKeys[key]}`;
        }
      }).join(' ');
      params = decamelizeKeys({ q, startTime, endTime, includeAll });
    }
    const [request, promise] = get({
      url,
      accessToken,
      data: params,
    });
    if (requestQueue) { requestQueue.addRequest(request, promise, 'fg', 'get_quote', { type: 'fg', scope: 'same' }); }

    return promise;
  },

  submitCheckout(rawParams = {}, accessToken, requestQueue = null) {
    const url = `${URL}/bookings/`;
    let deviceID = null;
    const params = omitBy(decamelizeKeys(rawParams), isNil);
    params.purchase_vector = 'Site';
    params.send_email_confirmation = 'true';
    params.zoom = 'pw:location,pw:vehicle,pw:receipt,pw:parking_pass,pw:customer,pw:event,pw:venue,pw:session,pw:tracking,pw:monthly_pricing,pw:partner,pw:brand';
    params.fields = `${BOOKING_CANCELLABLE_FIELDS},location::default,location:timezone,location:monthly_iframe_uri,booking:order_id,parking_pass::default,parking_pass:it_id,parking_pass:display_cancel_link,add_on:name,add_on:type,add_on:items,add_on:booking_id,venue:name,event:name,event:id,booking:event_id,venue:id,event:venue_id,seller::default,seller:logo,location:seller_logo,location:display_seller_logo,location:directions,booking:package,partner::default,partner:marketing_eligible,venue:enhanced_airport,parking_pass:shuttle_times,parking_pass:pickup_instructions,parking_pass:dropoff_instructions,customer:account_activated,${PAGE_BRAND_FIELDS}`;

    try {
      // Test webstorage existence.
      deviceID = localStorage.getItem('deviceID');
      if (!deviceID) {
        deviceID = uuid();
        localStorage.setItem('deviceID', deviceID);
      }
    } catch (e) {
      // Private browsing mode in Safari (both Mobile/Desktop) will throw a
      // QuotaExceededError: DOM Exception 22 if you try to use localStorage
      // so we need to catch exceptions and continue booking
      deviceID = uuid();
    }

    params.device_id = deviceID;
    const [request, promise] = post({
      url,
      data: params,
      accessToken,
    });

    if (requestQueue) { requestQueue.addRequest(request, promise, 'fg', 'checkout', { type: 'fg', scope: 'same' }); }
    return promise;
  },

  getPricing({ pricingId, accessToken }, requestQueue = null) {
    const url = `${URL}/pricings/${pricingId}/`;
    const [request, promise] = get({
      url,
      accessToken,
    });

    if (requestQueue) { requestQueue.addRequest(request, promise, 'bg', 'pricing', { type: 'fg', scope: 'same' }); }
    return promise;
  },
};

export default CheckoutApi;
