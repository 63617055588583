import { Record, Map } from 'immutable';
import get from 'lodash/get';

import { StreetParkingGeometries } from 'models/street-parking-geometry';

const RIGHT_ANGLE_IN_RADIANS = 1.5708;
const STREET_OFFSET_IN_DEGREES = 0.00004;

export default class StreetParkingSegment extends Record({
  id: null,
  geometries: null,
  availability: 'NoParking',
}) {
  constructor(props) {
    if (!props) {
      super();
      return;
    }

    const { id, state: availability } = props;
    const geometries = new StreetParkingGeometries(props.geometry);

    super({
      id,
      availability,
      geometries,
    });
  }

  get coordinates() {
    return this.geometries.map(g => [g.lng, g.lat]);
  }

  get color() {
    switch (this.availability) {
      case 'Available':
        return '#35f3f9';
      case 'Metered':
        return '#28357c';
      default:
        return '#e90404';
    }
  }
}

/**
 * Calculates the street parking geometry for both the left
 * and right side of the street
 * @param  {Array} geometry
 *         Array of geometric coordinates of segment
 * @return {Array}
 *         Array of left and right side segments of the street
 */
const StreetParkingGeometry = (geometry) => {
  const [initialLng, initialLat] = geometry[0];
  const [nextLng, nextLat] = geometry[1];

  const latDiff = nextLat - initialLat; // x
  const lngDiff = nextLng - initialLng; // y

  const radians = Math.atan2(lngDiff, latDiff);

  const latLeftIncrement = STREET_OFFSET_IN_DEGREES * Math.cos(radians - RIGHT_ANGLE_IN_RADIANS);
  const lngLeftIncrement = STREET_OFFSET_IN_DEGREES * Math.sin(radians - RIGHT_ANGLE_IN_RADIANS);

  const latRightIncrement = STREET_OFFSET_IN_DEGREES * Math.cos(radians + RIGHT_ANGLE_IN_RADIANS);
  const lngRightIncrement = STREET_OFFSET_IN_DEGREES * Math.sin(radians + RIGHT_ANGLE_IN_RADIANS);

  const leftGeometry = [];
  const rightGeometry = [];

  geometry.forEach(([lng, lat]) => {
    leftGeometry.push([lng + lngLeftIncrement, lat + latLeftIncrement]);
    rightGeometry.push([lng + lngRightIncrement, lat + latRightIncrement]);
  });

  return [leftGeometry, rightGeometry];
};

/**
 * Calculates the street parking segment state
 * @param  {String} restriction
 *         Restriction flag on the street segment
 * @param  {Object/null} price
 *         Price of the segment
 * @return {String}
 *         Translated restriction flag
 */
const StreetParkingSegmentState = (restriction, price) => {
  switch (restriction) {
    case 'allowed':
      if (price) {
        return Number(price[Object.keys(price)[0]]) > 0
          ? 'Metered'
          : 'Available';
      }
      return 'Available';
    case 'prohibited':
      return 'Prohibited';
    case 'unknown':
    default:
      return null;
  }
};

export const StreetParkingSegments = ((segments) => {
  const streetParkingSegments = {};

  if (segments && segments.length) {
    segments.forEach((segment) => {
      const streets = get(segment, '_embedded[pw:streets]', []);
      streets.forEach((street) => {
        const { partitions } = street;
        if (partitions && partitions.length) {
          partitions.forEach((partition, index) => {
            const { id } = street;
            const { geometry, price, restrictions } = partition;

            // Split out each partition into right and left
            const {
              left_allowed: leftAllowed,
              right_allowed: rightAllowed,
            } = restrictions;

            const leftState = StreetParkingSegmentState(leftAllowed, price);
            const rightState = StreetParkingSegmentState(rightAllowed, price);
            const streetParkingSegmentLeftId = `${id}-${index}-left`;
            const streetParkingSegmentRightId = `${id}-${index}-right`;
            const [leftGeometry, rightGeometry] = StreetParkingGeometry(geometry.coordinates);

            if (leftState) {
              const streetParkingSegment = new StreetParkingSegment({
                geometry: leftGeometry,
                id: streetParkingSegmentLeftId,
                state: leftState,
              });
              streetParkingSegments[streetParkingSegmentLeftId] = streetParkingSegment;
            }
            if (rightState) {
              const streetParkingSegment = new StreetParkingSegment({
                geometry: rightGeometry,
                id: streetParkingSegmentRightId,
                state: rightState,
              });
              streetParkingSegments[streetParkingSegmentRightId] = streetParkingSegment;
            }
          });
        }
      });
    });
  }

  return Map(streetParkingSegments);
});
