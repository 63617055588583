import Search from 'models/search';
export const SET_CURRENT_SEARCH_AND_CHANGE_BOUNDS = 'search-set-current-search-and-change-bounds';
const setCurrentSearchAndChangeBounds = ({ latitude, longitude, pendingGeolocationPermission, zoomLevel, bounds }) => ({
  type: SET_CURRENT_SEARCH_AND_CHANGE_BOUNDS,
  payload: {
    lat: latitude,
    lng: longitude,
    anchorLat: latitude,
    anchorLng: longitude,
    destinationType: Search.PARKING_NEAR_ME_PARKING_TYPE,
    pendingGeolocationPermission,
    zoomLevel,
    bounds,
  },
});
export default setCurrentSearchAndChangeBounds;
