export const ADD_CALLBACK_TO_REQUEST_QUEUE = 'add-callback-to-request-queue';

export default function addCallbackToRequestQueue(callbackFunction) {
  return {
    type: ADD_CALLBACK_TO_REQUEST_QUEUE,
    payload: {
      callback: callbackFunction,
    },
  };
}
