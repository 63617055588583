import React from 'react';

import NoticeHeader from 'components/checkout/modal-notices/notice-headers/notice-header';

const EarlyBirdHeader = () => (
  <NoticeHeader headerImgSrc="/images/checkout/zag-early-bird.png">
    The early bird gets the lowest rate but must:
  </NoticeHeader> 
);

export default EarlyBirdHeader;