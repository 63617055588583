import { Record, List } from 'immutable';

export default class StreetPakingGeometry extends Record({
  lat: null,
  lng: null,
}) {
  constructor(props) {
    if (!props) {
      super();
      return;
    }

    super({
      lat: props[1],
      lng: props[0],
    });
  }
}

export const StreetParkingGeometries = (geometries => (List(geometries.map(g => new StreetPakingGeometry(g)))));
