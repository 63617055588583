export const CHECK_SIGNIN_METHOD = 'check-signin-method';

const checkSignInMethod = () => ({
  type: CHECK_SIGNIN_METHOD,
  payload: {
    signInMethod: localStorage.getItem('signInMethod'),
  },
});

export default checkSignInMethod;
