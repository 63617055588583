import { Record, Map } from 'immutable';
import cookie from 'react-cookie';
import url from 'url';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import snakeCase from 'lodash/snakeCase';
import get from 'lodash/get';
import moment from 'moment-timezone';

import TrackingProperties from 'models/tracking-properties';
import env from 'lib/env';
import Brand from 'models/brand';

const { ONELINK_ID } = env();
const ONELINK_URL = 'parkwhiz.onelink.me';
const PROPS_DICT = {
  campaignId: 'utm_campaign',
  medium: 'utm_medium',
  source: 'utm_source',
  term: 'utm_term',
  mailchimpEmailId: 'mc_eid',
  mailchimpCampaignId: 'mc_cid',
};

export const COOKIE_NAME = 'deep-link-banner-dismissal-history';

export default class DeepLink extends Record({
  view: null,
  params: {},
  trackingParams: {},
  trackingProperties: new TrackingProperties(),
  coupon: null,
  affiliateId: null,
  portalAffiliateId: null,
  admin: false,
  display: false,
  subject: null,
  analyticsID: null,
  brand: new Brand(),
}) {
  constructor(props) {
    if (!props) {
      super({ trackingParams: new Map({ is_retargeting: true }) });
      return;
    }

    let trackingParams;
    if (!props.trackingParams) {
      trackingParams = { is_retargeting: true };
    } else {
      trackingParams = props.trackingParams;
      trackingParams.is_retargeting = true;
    }

    const afProps = get(props.trackingProperties, 'afProps', {});
    trackingParams = TrackingProperties.addAFProps({ linkProps: trackingParams, afProps });

    super({
      view: props.view,
      params: new Map(props.params),
      trackingParams: new Map(trackingParams),
      trackingProperties: props.trackingProperties,
      coupon: props.coupon,
      affiliateId: props.affiliateId,
      portalAffiliateId: props.portalAffiliateId,
      admin: props.admin,
      display: !!(props.display),
      subject: props.subject,
      analyticsID: props.analyticsID,
      brand: props.brand,
    });
  }

  static url(query, brand) {
    const { hostname, pathname } = url.parse(brand.appDownloadURLs.onelink);
    return url.format({
      protocol: 'https',
      hostname: hostname || ONELINK_URL,
      pathname: pathname || ONELINK_ID,
      query,
    });
  }

  get url() {
    const queryParams = this.trackingParams.toJS();
    queryParams.af_dp = this._afDP();
    queryParams.af_web_dp = DeepLink._afWebDP(this.brand);
    return this.constructor.url(queryParams, this.brand);
  }

  _afDP() {
    let queryParams = this.params.toJS();
    if (!this.admin) {
      queryParams.sid = cookie.load('SID', { path: '/' });
      queryParams.mid = this.analyticsID;
      queryParams.analytics_id = this.analyticsID;
    }
    queryParams.coupon = this.coupon;
    queryParams.portal_affiliate_id = this.portalAffiliateId;
    if (this.trackingProperties) {
      this.trackingProperties._keys.forEach((k) => {
        const key = this.constructor.translateProp(k);
        if (this.trackingProperties.get(k)) {
          queryParams[key] = this.trackingProperties.get(k);
        }
      });
    }
    queryParams = omitBy(queryParams, isNil);
    const displayName = this.brand.displayName.toLowerCase() || 'parkwhiz';
    return url.format({
      protocol: displayName,
      hostname: '//',
      pathname: this.view,
      query: queryParams,
    });
  }

  withTrackingProperties(trackingProperties) {
    return this.set('trackingProperties', trackingProperties);
  }

  static _afWebDP(brand) {
    if (typeof window !== 'undefined') {
      return window.location.href;
    }
    const hostname = brand.displayName.toLowerCase() || 'parkwhiz';
    return `https://www.${hostname}.com`;
  }

  // This is for the big interstitial.
  static showDeepLink(cookieName) {
    return !this._windowsPhone() && !this._dismissCookiePresent(cookieName);
  }

  // This is for the little banners.
  static showDeepLinkBanner(dismissalHistory, category, platform) {
    if (platform === 'Windows Phone') { return false; }

    const history = dismissalHistory[category] || [];
    const dismissedDaysAgo = moment().diff(moment(history[history.length - 1]), 'days');

    if (category === 'parking-pass') {
      switch (history.length) {
        case 0:
          return true;
        case 1:
          return dismissedDaysAgo > 1;
        default:
          return dismissedDaysAgo > 7;
      }
    } else { // Covers 'general'
      switch (history.length) {
        case 0:
          return true;
        default:
          return dismissedDaysAgo > 7;
      }
    }
  }

  static _selfReffered(internalReferrer) {
    if (typeof window === 'undefined') { return false; }

    const currentHost = window.location.hostname;
    const documentRefHost = document.referrer && document.referrer.match(/:\/\/(.[^/]+)/)[1];
    const internalRefHost = internalReferrer && internalReferrer.host;

    return currentHost === documentRefHost || currentHost === internalRefHost;
  }

  static _windowsPhone() {
    return navigator && navigator.userAgent.match(/Windows Phone/i);
  }

  static _dismissCookiePresent(cookieName) {
    return cookie.load(cookieName, { path: '/' });
  }

  static translateProp(propKey) {
    if (Object.keys(PROPS_DICT).includes(propKey)) {
      return PROPS_DICT[propKey];
    }

    return snakeCase(propKey);
  }
}
