import { Record, Map } from 'immutable';

export default class BusinessProfile extends Record({
  id: null,
  userId: null,
  email: null,
}) {
  constructor(props) {
    super({
      id: props.id,
      userId: props.user_id,
      email: props.email,
    });
  }
}

export function BusinessProfiles(response) {
  const businessProfiles = {};

  for (let i = 0; i < response.length; i++) {
    const businessProfileData = new BusinessProfile(response[i]);
    businessProfiles[response[i].id] = businessProfileData;
  }

  return Map(businessProfiles);
}
