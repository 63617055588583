import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import Brand from 'models/brand';

const propTypes = {
  brand: PropTypes.instanceOf(Brand).isRequired,
};

const OurAppsLink = ({ brand }) => {
  if (!brand.showAppDownloadInformation) { return null; }

  return (
    <li className="with-vertical-bar">
      <Link
        to={{ pathname: '/parking-app/' }}
        title="Available for iOS & Android"
      >
        <FormattedMessage
          id="common.our-apps"
          defaultMessage="OUR APPS"
        />
      </Link>
    </li>
  );
};

OurAppsLink.propTypes = propTypes;

export default OurAppsLink;
