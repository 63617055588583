import get from 'lodash/get';

import Bookings from 'models/bookings';
import { ParkingPasses } from 'models/parking-passes';
import Receipt from 'models/receipt';
import { Events } from 'models/event';
import Venue from 'models/venue';
import { Locations } from 'models/locations';
import { Reviews } from 'models/review';

export const GOT_BOOKINGS = 'got-bookings';

export default function gotBookings({ body, queryType, bookingType, nextRequest, user }) {
  let locations;
  let bookings;
  let parkingPasses;
  let receipt;
  let events;
  let venue;
  let session;
  let reviews;
  if (body && !!body.length) {
    const embedded = body[0]._embedded;
    locations = Locations(body);
    bookings = Bookings(body, { sort: { prop: 'startTime', direction: 'desc' } });
    parkingPasses = ParkingPasses(body);
    const { currency } = locations.first();
    receipt = new Receipt(embedded['pw:receipt'], { currency }).merge({ user });
    events = Events(body.map(booking => booking._embedded['pw:event']));
    venue = embedded['pw:event'] ? new Venue(get(embedded, ['pw:event', '_embedded', 'pw:venue'], null)) : null;
    reviews = Reviews(body.map(b => get(b, ['_embedded', 'pw:review'], null)));
    session = embedded['pw:session'];
  } else {
    locations = Locations();
    bookings = Bookings();
    parkingPasses = ParkingPasses();
    events = Events();
    reviews = Reviews();
  }

  return {
    type: GOT_BOOKINGS,
    payload: {
      bookings,
      parkingPasses,
      receipt,
      locations,
      events,
      venue,
      queryType,
      bookingType,
      nextRequest,
      session,
      reviews,
    },
  };
}
