import React from 'react';

export default class ThinNextArrow extends React.Component {
  render() {
    return(
      <svg width="28px" height="13px" viewBox="0 0 28 13" version="1.1">
          <defs></defs>
          <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="Bookings-&gt;-MyBookings-&gt;-Active" transform="translate(-648.000000, -1356.000000)" fill="#FFFFFF">
                  <g id="PAST" transform="translate(321.000000, 1242.000000)">
                      <g id="PastBookings" transform="translate(0.000000, 80.000000)">
                          <g id="ListingExpanded">
                              <g id="time" transform="translate(246.000000, 28.001802)">
                                  <path d="M88.7119127,6.00917479 L89.5796417,6.81040534 L94.6706733,1.33562472 L94.6706733,25.7522098 L95.8536712,25.7522098 L95.8536712,1.42465033 L100.861893,6.81040534 L101.729031,6.00917479 L95.6549276,-0.523890475 C95.5431343,-0.644163493 95.3857955,-0.712554033 95.2207673,-0.712554033 C95.0563306,-0.712554033 94.8989919,-0.644163493 94.7871986,-0.523890475 L88.7119127,6.00917479 Z" id="Fill-1" transform="translate(95.220472, 12.519828) rotate(90.000000) translate(-95.220472, -12.519828) "></path>
                              </g>
                          </g>
                      </g>
                  </g>
              </g>
          </g>
      </svg>
    );
  }
}