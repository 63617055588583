import React from 'react';
import PropTypes from 'prop-types';
import { ValidationStep as ValidationStepModel } from 'models/validation';

import Brand from 'models/brand';

export const propTypes = {
  step: PropTypes.instanceOf(ValidationStepModel).isRequired,
  index: PropTypes.number.isRequired,
  classNames: PropTypes.string,
  brand: PropTypes.instanceOf(Brand).isRequired,
};

export const defaultProps = {
  classNames: '',
};

const ValidationStep = ({ step, index, classNames, brand }) => (
  <div className="col-xs-12 col-md-4 col-centered validation-block col-print-12 print-avoid-break">
    <div className="print-row">
      <div className="col-print-2">
        <div className="validation-number">{index + 1}.</div>
        <div className="validation-icon" style={{ backgroundImage: `url(${step.icon})` }}>
          <img src={step.icon} className="visible-print-block" alt="" />
        </div>
      </div>
      <div className={`col-print-10 ${classNames}`}>
        {brand.displayName
          ? step.instructions.replace('ParkWhiz', brand.displayName)
          : step.instructions
        }
      </div>
    </div>
  </div>
);

ValidationStep.propTypes = propTypes;
ValidationStep.defaultProps = defaultProps;

export default ValidationStep;
