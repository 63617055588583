import { Vehicle } from 'models/vehicles';

export const INITIALIZE_NEW_VEHICLE = 'initialize-new-vehicle';

export default function initializeNewVehicle() {
  const vehicle = new Vehicle({});

  return {
    type: INITIALIZE_NEW_VEHICLE,
    payload: {
      vehicle,
    },
  };
}
