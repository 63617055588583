import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import { localizeImageURL } from 'lib/locale';

const propTypes = {
  hasLightBackground: PropTypes.bool,
  intl: intlShape.isRequired,
  navBarStyles: PropTypes.string,
  partnerImagePath: PropTypes.string.isRequired,
  shouldLinkToHome: PropTypes.bool,
};

const defaultProps = {
  hasLightBackground: false,
  navBarStyles: '',
  shouldLinkToHome: true,
};

export const SimplePartnerHeader = ({
  hasLightBackground,
  intl,
  navBarStyles,
  partnerImagePath,
  shouldLinkToHome,
}) => {
  const poweredByLink = shouldLinkToHome ? '/' : '#';

  let poweredByDesktopSrc;
  let poweredByMobileSrc;
  if (hasLightBackground) {
    poweredByDesktopSrc = localizeImageURL('/images/passes/logo-parkwhiz-dark.png', intl.locale);
    poweredByMobileSrc = localizeImageURL('/images/passes/logo-parkwhiz-dark-mobile.png', intl.locale);
  } else {
    poweredByDesktopSrc = localizeImageURL('/images/passes/logo-parkwhiz@2x.png', intl.locale);
    poweredByMobileSrc = localizeImageURL('/images/passes/logo-parkwhiz-mobile@2x.png', intl.locale);
  }

  return (
    <div id="desktop-nav" className={`hidden-print floating-nav ${navBarStyles}`}>
      <div id="partner-top-nav" className="container-fluid">
        <div className="row">
          <div className="partner-header-logo col-xs-6 text-align-left">
            <img src={partnerImagePath} alt="" />
          </div>
          <div className="partner-header-logo col-xs-6 text-align-right">
            <a href={poweredByLink}>
              <img
                src={poweredByDesktopSrc}
                className="pw-partner-header-logo hidden-xs hidden-sm"
              />
              <img
                src={poweredByMobileSrc}
                className="pw-partner-header-logo hidden-md hidden-lg"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

SimplePartnerHeader.propTypes = propTypes;
SimplePartnerHeader.defaultProps = defaultProps;

export default injectIntl(SimplePartnerHeader);
