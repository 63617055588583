export const PARKING_PASS_PAGE_TITLE = 'Parking Pass';
export const CHECKOUT_PAGE_TITLE = 'Purchase Parking';
export const BUSINESS_PAGE_TITLE = 'ParkWhiz for Business';
export const HOME_PAGE_TITLE = 'ParkWhiz: Find and Book Parking Anywhere';
export const HOW_IT_WORKS_PAGE_TITLE = 'How It Works';
export const OAUTH_PAGE_TITLE = 'ParkWhiz OAuth';
export const OUR_APPS_PAGE_TITLE = 'Save money with the free ParkWhiz App for iPhone or Android';
export const RECEIPT_PAGE_TITLE = 'Your ParkWhiz Purchase';
export const BOOKING_CANCEL_PAGE_TITLE = 'Cancel Parking';
export const SIGN_IN_PAGE_TITLE = 'Sign In to ParkWhiz';
export const SIGN_UP_PAGE_TITLE = 'Sign Up for ParkWhiz';
export const PRIVACY_TERMS_TITLE = 'Terms and Conditions/Privacy Policy';
export const ERROR_PAGE_TITLE = 'Error 404 (Not Found)';
export const PASSWORD_RECOVERY_PAGE_TITLE = 'Choose a New Password';
export const ACCOUNT_PAGE_TITLE = 'Your Account';
export const PRESS_PAGE_TITLE = 'Press Center';
export const SHIPPING_ADDRESS_PAGE_TITLE = 'ParkWhiz Shipping Address Form';
export const AFFILIATE_TERMS_PAGE_TITLE = 'Affiliate Terms';
