export const SET_MODAL_STATE = 'set-modal-state';
const setModalState = ({
  displayModal,
  body,
  header,
  footer,
  bodyProps,
  headerProps,
  footerProps,
}) => ({
  type: SET_MODAL_STATE,
  payload: {
    displayModal,
    body,
    header,
    footer,
    bodyProps,
    headerProps,
    footerProps,
  },
});
export default setModalState;

// export default [resetModalState, changeModalState, scrollToTop]; 