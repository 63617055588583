import { Record } from 'immutable';
import moment from 'moment-timezone';
import result from 'lodash/result';
import Place from 'models/schema.org/place';
import AggregateOffer from 'models/schema.org/aggregate-offer';
import env from 'lib/env';
export default class Event extends Record({
  '@context': 'http://schema.org',
  '@type': 'Event',
  'name': null,
  'description': null,
  'startDate': moment(),
  'endDate': moment(),
  'url': null,
  'location': new Place(),
  'offers': new AggregateOffer(),
}) {
  constructor(props) {
    if (!props) {
      super();
      return;
    }

    const { event, venue } = props || {};
    const { name, startTime, endTime, approximateLowPrice, approximateHighPrice, available } = event || {};
    const { currency } = venue || {};
    const { NODE_PW_URL } = env();
    const url = `https:${NODE_PW_URL}${event.url}`;
    // It may bit a bit safer to default to empty string so no webcrawler JSON parser ever turns null into "null".
    // Neither https://schema.org  nor Google's docs on Event Structured Data indicate a best practice for when data is
    // unavailable. https://developers.google.com/search/docs/data-types/event
    const [startDate, endDate] = [startTime, endTime].map(m => result(m, 'format', ''));

    super({
      name,
      description: name,
      startDate,
      endDate,
      url,
      location: new Place(venue),
      offers: new AggregateOffer({
        availability: available ? 'http://schema.org/InStock' : 'http://schema.org/SoldOut',
        priceCurrency: currency,
        price: approximateLowPrice,
        lowPrice: approximateLowPrice,
        highPrice: approximateHighPrice,
        url,
      }),
    });
  }
}
