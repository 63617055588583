import { Record } from 'immutable';

export class PressRelease extends Record({
  slug: '',
  body: '',
  datePosted: '',
  leadLine: '',
  title: '',
}) {
  constructor(props) {
    if (!props) {
      super();
      return;
    }
    super({
      slug: props.url_slug_slug,
      body: props.body,
      datePosted: props.date_posted,
      leadLine: props.lead_line,
      title: props.title,
    });
  }
}
