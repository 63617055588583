export const GOT_PAYMENT_TOKEN = 'got-payment-token';

export default function gotPaymentToken(braintreeToken) {
  return {
    type: GOT_PAYMENT_TOKEN,
    payload: {
      braintreeToken,
    },
  };
}
