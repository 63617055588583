import { Record, Map } from 'immutable';
import moment from 'moment-timezone';
import result from 'lodash/result';

import Booking from 'models/booking';

const BookingsSort = Record({ prop: 'startTime', direction: 'desc' });

function comparator(a, b, prop) {
  if (a && a.get && b && b.get) {
    const [aVal, bVal] = [a, b].map(x => x.get(prop));

    if ([aVal, bVal].every(x => moment.isMoment(x))) {
      return bVal.diff(aVal);
    }

    return bVal - aVal;
  }

  return 0;
}

function SortBookings({ prop, direction } = new BookingsSort()) {
  let sortedBookings = this;
  switch (direction) {
    case 'asc':
      sortedBookings = this.sort((a, b) => comparator(b, a, prop));
      break;
    case 'desc':
    default:
      sortedBookings = this.sort((a, b) => comparator(a, b, prop));
      break;
  }

  return AddBookingsHelpers(sortedBookings);
}

function Upcoming() {
  return AddBookingsHelpers(this.filter(b => b.isUpcoming));
}

function Past() {
  return AddBookingsHelpers(this.filterNot(b => b.isUpcoming));
}

function Active() {
  return AddBookingsHelpers(this.filter(b => b.isActive && !b.onDemand));
}

function Cancelled() {
  return AddBookingsHelpers(this.filterNot(b => b.isActive));
}

function Transient() {
  return AddBookingsHelpers(this.filter(b => b.isTransient));
}

function Monthly() {
  return AddBookingsHelpers(this.filter(b => b.isMonthly));
}

function MergeNewBookings(newBookings) {
  return AddBookingsHelpers(this.toMap().merge(result(newBookings, 'toMap', Map())).toKeyedSeq());
}

function FilterBookings(field) {
  return AddBookingsHelpers(field ? this.filter(b => b[field]).toMap().toKeyedSeq() : this);
}

function DeleteBooking(bookingId) {
  return AddBookingsHelpers(bookingId ? this.filter(b => b.id !== bookingId).toMap().toKeyedSeq() : this);
}

function AddBookingsHelpers(bookingsSeq) {
  Object.defineProperties(bookingsSeq, {
    upcoming: { get: Upcoming },
    past: { get: Past },
    active: { get: Active },
    cancelled: { get: Cancelled },
    transient: { get: Transient },
    monthly: { get: Monthly },
    sortBookings: { value: SortBookings },
    mergeNewBookings: { value: MergeNewBookings },
    filterBookings: { value: FilterBookings },
    deleteBooking: { value: DeleteBooking },
  });

  return bookingsSeq;
}

export default function Bookings(bookings) {
  if (!bookings || !bookings.map) {
    bookings = [];
  }
  const bookingsSeq = Map(bookings.map((b) => {
    let booking = b;
    if (!(booking instanceof Booking)) {
      booking = new Booking(booking);
    }
    return [booking.id, booking];
  })).toKeyedSeq();

  return AddBookingsHelpers(bookingsSeq);
}
