import Seller from 'models/seller';

export const GOT_LOCATION_SELLER = 'got-location-seller';
const gotLocationSeller = ({ body }) => ({
  type: GOT_LOCATION_SELLER,
  payload: {
    seller: new Seller(body),
  },
});
export default gotLocationSeller;
