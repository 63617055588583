import React from 'react';

export default class Close extends React.Component {
  render() {
    return (
      <svg width="23px" height="23px" viewBox="0 0 23 23" version="1.1">
          <defs></defs>
          <g  stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="Mobile_listview_OVERLAY" transform="translate(-148.000000, -524.000000)" stroke="#2AB7DF">
                  <g id="close" transform="translate(0.000000, 504.000000)">
                      <g id="close-blue" transform="translate(148.000000, 20.000000)">
                          <g id="Group-3" transform="translate(1.000000, 1.000000)">
                              <path d="M0,21.2650121 L21.2650121,0" id="Path-252" strokeWidth="2"></path>
                              <path d="M0,0 L21.2650121,21.2650121" id="Path-252-Copy" strokeWidth="2"></path>
                          </g>
                      </g>
                  </g>
              </g>
          </g>
      </svg>
    );
  }
}