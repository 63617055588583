import React from 'react';
import PropTypes from 'prop-types';

import Checkmark from 'components/svgs/icons/checkmark';
import ThoughtCar from 'components/svgs/thought-car';

const propTypes = {
  dismiss: PropTypes.func.isRequired,
};

const NegativeReviewResponse = ({ dismiss }) => (
  <div className="review-modal background-color-white text-center border-radius-4 position-relative padding-vertical-30 padding-horizontal-50">
    <div className="row">
      <div className="col-xs-12">
        <div className="text-size-28 text-color-blue margin-vertical-30 text-weight-book">
          Thank you for sharing your thoughts.
        </div>
      </div>
      <div className="col-xs-12">
        <div className="text-size-20 text-color-grey margin-bottom-15">
          They'll be brought to the attention of the parking owner.
        </div>
      </div>
      <div className="col-xs-12 margin-vertical-40">
        <ThoughtCar />
      </div>
      <div className="col-xs-12 dismiss-modal text-align-left text-color-green text-size-24 clickable" onClick={dismiss}>
        GOT IT
        <span className="iconified-font iconified-checkmark text-color-green padding-left-10" />
      </div>
    </div>
  </div>
);

NegativeReviewResponse.propTypes = propTypes;
export default NegativeReviewResponse;
