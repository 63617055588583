import { post } from 'lib/api/base';
import env from 'lib/env';

const { NODE_BASE_INTERNAL_API_URL, V3_API_CLIENT_ID } = env();
const SESSION_URL = `${NODE_BASE_INTERNAL_API_URL}/sessions`;

export const UserApi = {
  createSession({ credentialType, credentials, guestSession = false, requestQueue = null, accessToken }) {
    const url = `${SESSION_URL}/`;
    let data = {
      grant_type: 'password',
      scope: 'public',
      client_id: V3_API_CLIENT_ID,
    };

    if (guestSession) {
      data = {
        guest_session: true,
        client_id: V3_API_CLIENT_ID,
      };
    } else {
      switch (credentialType) {
        case 'password':
          data.customer_email = credentials.email;
          data.customer_password = credentials.password;
          data.rememberme = credentials.rememberme;
          data.remembercs = credentials.remembercs;
          break;
        case 'google':
          data.customer_email = credentials.email;
          data.google_id_token = credentials.google_id_token;
          data.firstname = credentials.first_name;
          data.last_name = credentials.last_name;
          break;
        default:
          break;
      }
    }

    const [request, promise] = post({ url, data, accessToken });

    if (requestQueue) { requestQueue.addRequest(request, promise, 'fg', 'logIn', { type: 'all', scope: 'same' }); }
    return promise;
  },

  refreshSession({ sessionId, token, requestQueue = null }) {
    const url = `${SESSION_URL}/`;
    const data = {
      current_session_id: sessionId,
      client_id: V3_API_CLIENT_ID,
    };

    const [request, promise] = post({ url, data, accessToken: token });

    if (requestQueue) { requestQueue.addRequest(request, promise, 'fg', 'logIn', { type: 'all', scope: 'same' }); }
    return promise;
  },

  setSession({ requestQueue }) {
    const url = '/sessions/';
    const [request, promise] = post({ url });
    if (requestQueue) { requestQueue.addRequest(request, promise, 'bg', 'setSession', { type: 'all', scope: 'same' }); }
    return promise;
  },
};

export default UserApi;
