import PaymentMethod from 'models/payment-method';

export const SAVED_PAYMENT_METHOD = 'saved-payment-method';

export default function savedPaymentMethod(paymentMethod) {
  return {
    type: SAVED_PAYMENT_METHOD,
    payload: {
      paymentMethod: new PaymentMethod(paymentMethod),
    },
  };
}
