export const SET_LOCATION = 'set-location';

export default function setLocation({ selectedLocation, selectedQuote }) {
  return {
    type: SET_LOCATION,
    payload: {
      selectedLocation,
      selectedQuote,
      selectedLocationId: selectedLocation && selectedLocation.id,
      selectedQuoteId: selectedQuote && selectedQuote.id,
    },
  };
}

