import { List, Record, Map } from 'immutable';
import get from 'lodash/get';

import Image from './image';

const requiredAddOnTypes = ['vehicleSize'];

function sortByPrice(a, b) {
  const [aPrice, bPrice] = [Number(a.price), Number(b.price)];
  if (isNaN(aPrice)) { return 1; }
  if (isNaN(bPrice)) { return -1; }
  if (aPrice > bPrice) { return 1; }
  if (aPrice < bPrice) { return -1; }
  return 0;
}

export class AddOn extends Record({
  id: null,
  name: null,
  price: null,
  availability: null,
  items: null,
  image: null,
}) {
  constructor(props, { currency } = {}) {
    const imageSizes = get(props, ['icon', 'sizes'], {});
    const image = new Image({ ...imageSizes, position: 0 });

    super({
      id: props.id,
      name: props.name || '',
      price: Number(get(props, ['price', currency])),
      availability: props.availability ? props.availability.status : 'unavailable',
      items: props.items,
      image,
    });
  }

  static get STANDARD() { return 'Standard'; }
  static get OVERSIZE() { return 'Oversize'; }
  static get SUPERSIZE() { return 'Supersize'; }

  getImageURL() {
    const { imageSizes } = this.image;
    if (!imageSizes) { return null; }
    const imageSize = imageSizes.first();
    if (!imageSize) { return null; }
    return imageSize.url;
  }

  isAvailable() {
    return this.availability !== 'unavailable' && this.isValid();
  }

  getNormalizedName() {
    if (this.name) {
      try {
        // Try normalizing the name to Title case (first upper, rest lower)
        return this.name.charAt(0).toUpperCase() + this.name.slice(1).toLowerCase();
      } catch (err) {
        return '';
      }
    } else {
      return '';
    }
  }

  get formattedPrice() {
    if (isNaN(Number(this.price))) {
      return Number(0).toFixed(2);
    }
    return Number(this.price).toFixed(2);
  }

  isValid() {
    if (isNaN(Number(this.price))) {
      return false;
    }

    if (!this.name) {
      return false;
    }

    return true;
  }

  get isRequired() {
    return [AddOn.STANDARD, AddOn.OVERSIZE, AddOn.SUPERSIZE].includes(this.name);
  }
}

export class AddOns extends Record({
  vehicleSize: List(),
  general: List(),
}) {
  constructor(props, { currency } = {}) {
    if (!props) {
      super();
      return;
    }

    const addOns = props;

    let vehicleSize;
    let general;

    // Vehicle Size Add Ons
    if (addOns.vehicle_size && addOns.vehicle_size.options) {
      vehicleSize = List();
      for (let i = 0; i < addOns.vehicle_size.options.length; i++) {
        vehicleSize = vehicleSize.push(new AddOn(addOns.vehicle_size.options[i], { currency }));
      }
    } else if (props.requiredList) {
      vehicleSize = props.requiredList;
    }

    if (vehicleSize && vehicleSize.size > 0) {
      vehicleSize = vehicleSize.sort((a, b) => sortByPrice(a, b));
    }

    // General Add Ons
    if (addOns.general && addOns.general.options) {
      general = List();
      for (let i = 0; i < addOns.general.options.length; i++) {
        general = general.push(new AddOn(addOns.general.options[i], { currency }));
      }
    } else if (props.generalList) {
      general = props.generalList;
    }

    if (general && general.size > 0) {
      general = general.sort((a, b) => sortByPrice(a, b));
    }

    super({
      vehicleSize,
      general,
    });
  }

  getRequiredAddOns() {
    // TODO currently only vehicle_size is the only required Addon, so the whole
    // schema for this model needs to be reconsidered once there is more than one
    const { vehicleSize } = this;
    if (!vehicleSize || vehicleSize.size < 1) { return {}; }
    return { vehicleSize };
  }

  getOptionalAddOns() {
    const { general } = this;
    if (!general) { return {}; }
    return { general };
  }

  // Returns an array of all addOnIds
  getAddOnIds() {
    const { vehicleSize, general } = this;
    const addOns = List([vehicleSize, general]);
    return addOns.map(addOnType => addOnType.map(addOn => addOn.id)).flatten().toArray();
  }

  get totalCount() {
    const { vehicleSize, general } = this;
    return vehicleSize.size + general.size;
  }

  getApiFormattedAddOnIds() {
    return this.getAddOnIds().join(',');
  }

  static getDisplayName(addOnType) {
    const displayNames = {
      vehicleSize: 'Vehicle Size',
      general: 'Add Ons',
    };

    return displayNames[addOnType];
  }

  getValidationDisplayName(addOnType) {
    const displayNames = {
      vehicleSize: 'vehicle size',
    };
    return displayNames[addOnType];
  }

  validateRequiredAddOns(selectedAddOns) {
    const addOnValidations = {};
    if (!requiredAddOnTypes) { return addOnValidations; }
    requiredAddOnTypes.forEach((addOnType) => {
      const validation = {
        isValid: false,
        isPotentiallyValid: false,
        isEmpty: true,
        displayName: this.getValidationDisplayName(addOnType),
      };
      if (selectedAddOns[addOnType] && selectedAddOns[addOnType].size) {
        const isValid = selectedAddOns[addOnType].size === 1;
        Object.assign(validation, { isValid, isPotentiallyValid: isValid, isEmpty: false });
      }
      addOnValidations[addOnType] = validation;
    });
    return Map(addOnValidations);
  }
}
