export const RECOVER_PASSWORD = 'recover-password';

const recoverPassword = ({ email, password, passwordResetToken }) => ({
  type: RECOVER_PASSWORD,
  payload: {
    email,
    password,
    passwordResetToken,
  },
});

export default recoverPassword;
