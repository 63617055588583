import React from 'react';
import PropTypes from 'prop-types';

import { Location } from 'models/locations';

const propTypes = {
  location: PropTypes.instanceOf(Location).isRequired,
};

const OperatingHours = ({ location }) => {
  if (!location.hours || location.hours.size === 0) { return null; }
  return (
    <div className="row margin-top-20">
      <div className="col-xs-12 col-sm-4 col-gutter-right-0 text-weight-book text-color-dark-slate text-size-14 margin-bottom-10">
        Hours of Operation:
      </div>
      <div className="col-xs-12 col-sm-8 text-weight-bold text-color-dark-slate text-size-14">
        {location.hours.map((hours, i) => <div key={`hours-${i}`}>{hours.toString()}</div>)}
      </div>
    </div>
  );
};

OperatingHours.propTypes = propTypes;
export default OperatingHours;
