import { List, Record, Map, OrderedSet } from 'immutable';
import moment from 'moment-timezone';
import get from 'lodash/get';
import includes from 'lodash/includes';
import result from 'lodash/result';

import { AddOns } from './addons';
import { Amenities, VALID_AMENITY_KEYS } from './amenities';
import CancellableStatus from 'models/cancellable-status';
import { Fee } from 'models/fees';
import Pricing from './pricing';
import Validation from './validation';
import DeepLink from 'models/deep-link';
import TrackingProperties from 'models/tracking-properties';
import ShuttleTime from 'models/shuttle-times';

import { isCALawPricingEnabled } from 'lib/env';

export default class Quote extends Record({
  addOns: null,
  amenities: null,
  availability: null,
  basePrice: null,
  bookable: true,
  cancellableStatus: null,
  curationScore: null,
  dailyPrice: null,
  description: null,
  disclaimers: null,
  dropoffInstructions: null,
  endTime: null,
  eventPackageId: null,
  fees: [],
  id: null,
  locationDefault: false,
  maxEndTime: null,
  minStartTime: null,
  name: null,
  packagePricings: null,
  pickupInstructions: null,
  price: null,
  priceAdvisory: null,
  pricings: null,
  quoteType: 'transient',
  restrictions: null,
  shuttle: false,
  shuttleTimesFromVenue: [],
  shuttleTimesToVenue: [],
  startTime: null,
  validation: null,
}) {
  constructor(props, start, minStart, end, maxEnd, quoteTypeArg = null, timezoneArg = null, { currency } = {}) {
    const quoteType = quoteTypeArg || 'transient';
    const timezone = timezoneArg || moment.tz.guess();

    if (!props) {
      super({ startTime: start, minStartTime: minStart, endTime: end, maxEndTime: maxEnd, quoteType, bookable: true });
      return;
    }

    const quote = props;
    const { bookable = true } = quote;

    if (quote.shuttle) {
      if (quote.amenities && quote.amenities.push) {
        quote.amenities.push({ name: 'Shuttle', description: 'Free Shuttle', key: 'shuttle', enabled: true, visible: true });
      } else {
        quote.amenities = [{ name: 'Shuttle', description: 'Free Shuttle', key: 'shuttle', enabled: true, visible: true }];
      }
    }

    const addOns = quote.add_ons ? new AddOns(quote.add_ons, { currency }) : null;
    const amenities = quote.amenities ? new Amenities(quote.amenities) : null;
    const cancellableStatus = new CancellableStatus(quote.cancellable_status);
    let pricings = List();
    let packagePricings = List();
    const validation = quote.validation ? new Validation(quote.validation) : null;
    const shuttle = !!quote.shuttle;

    if (quote.pricing_segments) {
      for (let i = 0; i < quote.pricing_segments.length; i++) {
        pricings = pricings.push(new Pricing(quote.pricing_segments[i]));
      }
    }

    if (quote.package_pricings) {
      const pricingStartTime = pricing => moment(pricing.start_time);

      packagePricings = List(
        quote.package_pricings
          .sort((pricingA, pricingB) => pricingStartTime(pricingA).diff(pricingStartTime(pricingB)))
          .map(pricing => new Pricing(pricing)),
      );
    }

    const formattedPrice = Quote.formatPrice(quote, 'price', currency);

    const formattedBasePrice = bookable && quote.base_price ? Quote.formatPrice(quote, 'base_price', currency) : formattedPrice;

    const formattedDailyPrice = Quote.formatPrice(quote, 'daily_price', currency);

    const fees = List(get(props, 'fees', []).map(fee => new Fee(fee, { currency })));

    const startTime = moment.tz(start, timezone);
    const endTime = moment.tz(end, timezone);
    const minStartTime = moment.tz(minStart, timezone);
    const maxEndTime = moment.tz(maxEnd, timezone);

    const shuttleTimesToVenue = [];
    try {
      if (quote.shuttle_times && quote.shuttle_times.to_venue) {
        quote.shuttle_times.to_venue.forEach((st) => {
          shuttleTimesToVenue.push(new ShuttleTime(
            st,
            moment.parseZone(startTime.format()),
            moment.parseZone(startTime.format()),
          ));
        });
      }
    } catch (error) {
      console.warn('Shuttle Times To Venue Crashed');
      console.warn({ error });
      console.warn({ quoteProps: props });
      console.warn({ startTime });
    }

    const shuttleTimesFromVenue = [];
    try {
      if (quote.shuttle_times && quote.shuttle_times.from_venue) {
        quote.shuttle_times.from_venue.forEach((st) => {
          shuttleTimesFromVenue.push(new ShuttleTime(
            st,
            moment.parseZone(endTime.format()),
            moment.parseZone(endTime.format()),
          ));
        });
      }
    } catch (error) {
      console.warn('Shuttle Times From Venue Crashed');
      console.warn({ error });
      console.warn({ quoteProps: props });
      console.warn({ endTime });
    }

    super({
      addOns,
      amenities,
      availability: quote.space_availability,
      cancellableStatus,
      description: quote.description,
      disclaimers: List(quote.disclaimers),
      endTime,
      fees,
      maxEndTime,
      id: quote.id,
      name: quote.name ? quote.name : '',
      price: formattedPrice,
      dailyPrice: formattedDailyPrice,
      pricings: pricings.size > 0 ? pricings : null,
      packagePricings: packagePricings.size > 0 ? packagePricings : null,
      startTime,
      minStartTime,
      validation,
      shuttle,
      priceAdvisory: quote.price_advisory,
      quoteType,
      locationDefault: quote.location_default || false,
      curationScore: quote.curation_score,
      shuttleTimesToVenue,
      shuttleTimesFromVenue,
      pickupInstructions: props.pickup_instructions,
      dropoffInstructions: props.dropoff_instructions,
      eventPackageId: props.event_package_id,
      bookable,
      restrictions: props.restrictions,
      basePrice: formattedBasePrice,
    });
  }

  static formatPrice(quote, priceKey, currency) {
    let formattedPrice = get(quote, [priceKey, currency], null);

    if (formattedPrice) {
      formattedPrice = Number(formattedPrice);

      // If float, display two decimal places
      if (formattedPrice % 1 !== 0) {
        formattedPrice = formattedPrice.toFixed(Quote.DECIMAL_DISPLAY);
      } else {
        formattedPrice = formattedPrice.toString();
      }
    }

    return formattedPrice;
  }

  static get PRICE_ADVISORY_DESCRIPTIONS() {
    return Map({
      Premium: 'Lock a spot now, but note that onsite prices may be cheaper',
    });
  }

  static get DECIMAL_DISPLAY() {
    return 2;
  }

  get bookingDuration() {
    return moment.duration(moment(this.maxEndTime).diff(moment(this.startTime))).asHours() || 0;
  }

  get reentryAllowed() {
    return !!(this.amenities.get('reentry') && this.amenities.get('reentry').enabled);
  }

  get pricePremium() {
    return this.priceAdvisory === this.constructor.PRICE_ADVISORY_DESCRIPTIONS.get('Premium');
  }

  get moreThan24HourNoReentry() {
    const { bookingDuration, reentryAllowed } = this;
    return !!(bookingDuration && bookingDuration > 24 && !reentryAllowed);
  }

  get isEventPackage() {
    return (this.quoteType && this.quoteType.toLowerCase() === 'event_package') || false;
  }

  get isMonthly() {
    return (this.quoteType && this.quoteType.toLowerCase() === 'monthly') || false;
  }

  get isTransient() {
    // NOTE: search_api returns 'event' pricings with a quoteType of 'transient' also
    return (this.quoteType && this.quoteType.toLowerCase() === 'transient') || false;
  }

  get isAirport() {
    return get(this, 'quoteType', '').toLowerCase() === 'airport';
  }

  get isEventRelated() {
    return !!this.shuttle || !!this.packagePricings || includes(['event', 'EventPricing'], result(this, 'pricings.first', {}).pricingType);
  }

  get isSoldOut() {
    return get(this, 'availability.status') === 'unavailable';
  }

  getDeepLink(
    brand,
    trackingParams = {},
    trackingProperties = new TrackingProperties(),
    coupon = null,
    affiliateId = null,
    portalAffiliateId = null,
    admin = false,
  ) {
    const view = 'checkout';
    const params = { id: this.id };
    const subject = this;

    return new DeepLink({
      view,
      params,
      trackingParams,
      trackingProperties,
      coupon,
      affiliateId,
      portalAffiliateId,
      admin,
      subject,
      brand,
    });
  }

  get validAmenities() {
    if (this.amenities) {
      return VALID_AMENITY_KEYS.map(key => this.amenities.get(key)).filter(a => (a && a.visible));
    }

    return new Map();
  }

  get tierAmenities() {
    let tierAmenities = OrderedSet();
    const { validAmenities } = this;
    let amenity = validAmenities.find(a => (a.key === 'valet'));
    if (amenity && amenity.enabled) {
      tierAmenities = tierAmenities.add('Valet');
    } else {
      tierAmenities = tierAmenities.add('Self Park');
    }

    amenity = validAmenities.find(a => (a.key === 'indoor'));
    if (amenity && amenity.enabled) {
      tierAmenities = tierAmenities.add('Covered');
    } else {
      tierAmenities = tierAmenities.add('Uncovered');
    }
    return tierAmenities;
  }

  getListingPrice(isCheckoutSurprise = false, locationState = '') {    
    if (isCALawPricingEnabled() && locationState === 'CA') {
      return this.price;
    }
    if (isCheckoutSurprise) {
      return this.basePrice;
    }
  
    return this.price;
  }
}
