import env from 'lib/env';

const {
  ASSET_CDN_HOST,
  GIT_HASH,
} = env();

export const shouldCDNServe = () => !!(ASSET_CDN_HOST && GIT_HASH);
export const cdnServeWebpack = () => {
  if (shouldCDNServe()) {
    __webpack_public_path__ = `${ASSET_CDN_HOST.replace(/['"]+/g, '')}/${GIT_HASH}/dist/`;
  } else {
    __webpack_public_path__ = '/dist/';
  }
};
