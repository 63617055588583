export const HIGHLIGHT_LOCATION = 'highlight-location';

export default function highlightLocation({ locationId }) {
  return {
    type: HIGHLIGHT_LOCATION,
    payload: {
      locationId: locationId && locationId.toString(),
    },
  };
}
