import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import cx from 'classnames';

import { SEARCH_APP } from 'lib/app-names';

import Link from 'components/announcement-banner/link';

import dismissAnnouncementBanner from 'action-creators/brand/dismiss-announcement-banner';

import Announcement from 'models/announcement';

const propTypes = {
  announcement: PropTypes.instanceOf(Announcement).isRequired,
  app: PropTypes.string.isRequired,
  announcementBannerDismissed: PropTypes.bool,
  dismissAnnouncementBanner: PropTypes.func,
};

const defaultProps = {
  announcementBannerDismissed: false,
  dismissAnnouncementBanner: () => {},
};

export class AnnouncementBanner extends Component {
  constructor(props) {
    super(props);

    this.closeBanner = this.closeBanner.bind(this);
  }

  closeBanner() {
    this.props.dismissAnnouncementBanner();
  }

  get shouldDisplay() {
    return (
      this.props.announcement.showBanner &&
      !this.props.announcementBannerDismissed &&
      this.props.app !== SEARCH_APP
    );
  }

  render() {
    if (!this.shouldDisplay) { return null; }

    const { announcement } = this.props;

    const bodyClasses = cx({
      'col-sm-8': !!announcement.bannerLinkURL,
      'col-sm-12': !announcement.bannerLinkURL,
      'col-xs-10': true,
      'col-sm-push-0': true,
      'col-xs-push-1': true,
      'col-gutter-left-sm-30': true,
      'col-gutter-right-sm-30': !announcement.bannerLinkURL,
      'text-left-sm': true,
      'text-center-xs': true,
      'margin-bottom-xs-20': !!announcement.bannerLinkURL,
      'margin-bottom-sm-0': true,
    });

    return (
      <div className="container position-fixed fixed-banner text-color-white padding-top-10 padding-bottom-sm-40 padding-bottom-xs-20 background-color-medium-grey">
        <div className="row text-align-right">
          <div
            type="button"
            className="col-xs-12 float-right iconified-font iconified-x text-size-16 text-color-white text-color-hover-grey clickable margin-bottom-sm-10 margin-bottom-xs-0"
            onClick={this.closeBanner}
          />
        </div>
        <div className="row">
          <div className={bodyClasses}>
            <div className="row">
              <div className="col-sm-12 col-xs-10 col-xs-push-1 col-sm-push-0 text-size-sm-24 text-size-xs-16 text-weight-bold margin-bottom-sm-20 margin-bottom-xs-10">
                {announcement.bannerTitle}
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 text-size-sm-16 text-size-xs-12 text-weight-medium">
                {announcement.bannerBody}
              </div>
            </div>
          </div>
          <Link announcement={announcement} closeBanner={this.closeBanner} />
        </div>
      </div>
    );
  }
}

AnnouncementBanner.propTypes = propTypes;
AnnouncementBanner.defaultProps = defaultProps;

const mapStateToProps = (state) => {
  const {
    announcementBannerDismissed,
    brand,
  } = state.brand;
  const { announcement } = brand;

  const {
    name: app,
  } = state.app;

  return {
    announcementBannerDismissed,
    announcement,
    app,
  };
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    dismissAnnouncementBanner,
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(AnnouncementBanner);
