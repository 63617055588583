// import env from 'lib/env';

// TODO: Refactor this at some point to make this nicer
// const { RS_TENANT_ALIAS } = env();

export default function externals() {
  /* Referral SaasQuatch */
  // if (controller && controller.get('user')) {
  //   const user = controller.get('user');
  //   const name = user.name ? user.name.split(' ') : null;
  //   const firstName = name ? name[0] : 'No';
  //   const lastName = name && name.length > 1 ? name[1] : 'Name';

  //   var _sqh = _sqh || [];

  //   _sqh.push(['init', {
  //     tenant_alias: RS_TENANT_ALIAS,
  //     account_id: user.id,
  //     payment_provider_id: null,
  //     user_id: user.id,
  //     email: user.email,
  //     first_name: firstName,
  //     last_name: lastName,
  //     userReferralCode: user.referralCode,
  //     jwt: user.jwt,
  //   }]);

  //   window._sqh = _sqh;

  //   (function () { function l() { const s = document.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = `${location.protocol}//d2rcp9ak152ke1.cloudfront.net/assets/javascripts/squatch.min.js`; const t = document.getElementsByTagName('script')[0]; t.parentNode.insertBefore(s, t); } if (window.attachEvent) { window.attachEvent('onload', l); } else { window.addEventListener('load', l, false); } }());
  // }

  /* MailChimp Goal Tracking */
  const $mcGoal = { settings: { uuid: '5c14da375c886bb95d53572ed', dc: 'us2' } };
  (function () {
    const sp = document.createElement('script'); sp.type = 'text/javascript'; sp.async = true; sp.defer = true;
    sp.src = `${document.location.protocol == 'https:' ? 'https://s3.amazonaws.com/downloads.mailchimp.com' : 'http://downloads.mailchimp.com' }/js/goal.min.js`;
    const s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(sp, s);
  }());
}
