import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { get, compact, zip, times, constant } from 'lodash';
import cx from 'classnames';
import { Map } from 'immutable';
import url from 'url';

import * as RoutingStyle from 'lib/routing-style';
import Search from 'models/search';
import Pricing from 'models/pricing';
import Quote from 'models/quote';

import Checkmark from 'components/svgs/icons/checkmark';
import EarlyBirdHeader from 'components/checkout/modal-notices/notice-headers/early-bird-header';
import HeadsUpHeader from 'components/checkout/modal-notices/notice-headers/heads-up-header';
import PricePremiumHeader from 'components/checkout/modal-notices/notice-headers/price-premium-header';
import ScoreHeader from 'components/checkout/modal-notices/notice-headers/score-header';
import SpecialRateHeader from 'components/checkout/modal-notices/notice-headers/special-rate-header';
import PrintOnlyHeader from 'components/checkout/modal-notices/notice-headers/print-only-header';
import NonRefundableParkingPackagesHeader from 'components/checkout/modal-notices/notice-headers/non-refundable-parking-packages-header';
import GeneralErrorHeader from 'components/checkout/modal-notices/notice-headers/general-error-header';
import MinParkingHours from 'components/checkout/modal-notices/notice-details/min-parking-hours';
import DepartAfter from 'components/checkout/modal-notices/notice-details/depart-after';
import ArriveBy from 'components/checkout/modal-notices/notice-details/arrive-by';
import Event from 'components/checkout/modal-notices/notice-details/event';
import MoreThan24HourNoReentry from 'components/checkout/modal-notices/notice-details/more-than-24-hour-no-reentry';
import PrintOnly from 'components/checkout/modal-notices/notice-details/print-only';
import NonRefundableParkingPackages from 'components/checkout/modal-notices/notice-details/non-refundable-parking-packages';
import ParkingNotAvailable from 'components/checkout/modal-notices/notice-details/parking-not-available';
import AndSeparator from 'components/checkout/modal-notices/and-separator';

const propTypes = {
  clearError: PropTypes.func.isRequired,
  revertSearch: PropTypes.func.isRequired,
  error: PropTypes.instanceOf(Map),
  requestQueueLength: PropTypes.number.isRequired,
  routingStyle: PropTypes.string,
  currentSearch: PropTypes.instanceOf(Search),
  savedSearch: PropTypes.instanceOf(Search),
  deepLinkDisplay: PropTypes.bool,
  pricing: PropTypes.instanceOf(Pricing),
  quote: PropTypes.instanceOf(Quote),
};

const defaultProps = {
  error: Map(),
  routingStyle: RoutingStyle.PARKWHIZ,
  currentSearch: null,
  savedSearch: null,
  deepLinkDisplay: false,
  pricing: new Pricing(),
  quote: null,
};

export const PARKING_UNAVAILABLE_MESSAGE = 'Quote not found';

class ModalNotices extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: true,
      savedSearch: props.currentSearch,
    };

    this.revertSearch = this.revertSearch.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { error, currentSearch } = this.props;

    if (!error || !nextProps.error) { return; }

    const currentError = error.get('message');
    const nextError = nextProps.error.get('message');

    if (!currentError && nextError === PARKING_UNAVAILABLE_MESSAGE) {
      this.setState({ show: true });
    }

    if (
      currentSearch !== nextProps.currentSearch &&
      nextError !== PARKING_UNAVAILABLE_MESSAGE &&
      currentError !== PARKING_UNAVAILABLE_MESSAGE
    ) {
      this.setState({ savedSearch: nextProps.currentSearch });
    }
  }

  handleModalClose() {
    this.setState({
      show: false,
    });
    this.props.clearError();
  }

  revertSearch() {
    this.props.revertSearch({
      currentSearch: this.props.savedSearch,
    });
    this.props.clearError();
  }

  returnToSearch() {
    window.history.back();
  }

  get isParkingNotAvailable() {
    return this.props.error.get('message') === PARKING_UNAVAILABLE_MESSAGE && this.props.requestQueueLength === 0;
  }

  renderOptions() {
    if (this.isParkingNotAvailable) {
      const commonClasses = 'display-block padding-vertical-20 text-size-16 border-radius-2 text-weight-book clickable';
      const { routingStyle } = this.props;
      let { currentSearch } = this.props;
      currentSearch = currentSearch.merge({ selectedLocationId: null, selectedQuoteId: null });
      const searchURL = url.parse(currentSearch.route(null, null, null, { routingStyle }));

      return (
        <div className="row margin-top-20 margin-bottom-20">
          <div className="col-xs-12 col-md-6 margin-bottom-xs-20 margin-bottom-md-0">
            <div className={`background-color-blue background-color-hover-blue-lighten text-color-white ${commonClasses}`} onClick={this.revertSearch}>
              Change Time <span className="iconified-font iconified-clock margin-left-5" />
            </div>
          </div>
          <div className="col-xs-12 col-md-6">
            <Link
              to={{
                pathname: searchURL.pathname,
                search: searchURL.search,
                state: {
                  search: {
                    currentSearch: currentSearch.toJSON(),
                    locationId: null,
                    quoteId: null,
                    displayMap: true,
                  },
                },
              }}
              className={`background-color-xs-white background-color-md-medium-grey background-color-hover-medium-grey text-color-xs-blue text-color-md-white ${commonClasses}`}
            >
              Find Parking
            </Link>
          </div>
        </div>
      );
    }

    return (
      <div className="row">
        <div className="col-xs-5 no-thanks" onClick={this.returnToSearch}>
          No, Thanks
          <div className="subtext">
            Take me back to search
          </div>
        </div>
        <div className="col-xs-offset-2 col-xs-5 dismiss-modal" onClick={this.handleModalClose}>
          GOT IT
          <Checkmark />
          <div className="subtext">
            I want this space
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { pricing, quote, currentSearch } = this.props;
    const {
      earlyBird,
      minParkingHours,
      eventDepartureBuffer,
      eventDeparture,
      instructionsRequired,
      arriveBy,
      departAfter,
      mobileEnabled,
    } = pricing || {};
    const { pricePremium, endTime, moreThan24HourNoReentry } = quote || {};
    const { isEventSearch, isPackageSearch } = currentSearch || {};

    const noticeHeaderOptions = [
      {
        component: <ScoreHeader />,
        conditional: (isEventSearch && eventDepartureBuffer),
      },
      {
        component: <NonRefundableParkingPackagesHeader />,
        conditional: (isPackageSearch && !this.isParkingNotAvailable),
      },
      {
        component: <EarlyBirdHeader />,
        conditional: earlyBird,
      },
      {
        component: <PricePremiumHeader instructionsRequired={instructionsRequired} />,
        conditional: pricePremium,
      },
      {
        component: <HeadsUpHeader />,
        conditional: (moreThan24HourNoReentry && !this.isParkingNotAvailable && !currentSearch.isEnhancedAirportSearch),
      },
      {
        component: <SpecialRateHeader />,
        conditional: instructionsRequired,
      },
      {
        component: <PrintOnlyHeader />,
        conditional: !mobileEnabled,
      },
      {
        component: <GeneralErrorHeader handleClose={this.handleModalClose} />,
        conditional: this.isParkingNotAvailable,
      },
    ];

    const noticeDetailOptions = [
      {
        component: (
          <Event
            endTime={endTime}
            eventDeparture={eventDeparture}
            eventDepartureBuffer={eventDepartureBuffer}
            key="event-departure"
          />
        ),
        conditional: isEventSearch && eventDepartureBuffer,
      },
      {
        component: (
          <NonRefundableParkingPackages
            key="nonrefundable-packages"
          />
        ),
        conditional: isPackageSearch,
      },
      {
        component: (
          <ArriveBy
            arriveBy={arriveBy}
            key="arrive-by"
          />
        ),
        conditional: arriveBy,
      },
      {
        component: (
          <DepartAfter
            departAfter={departAfter}
            key="depart-after"
          />
        ),
        conditional: departAfter,
      },
      {
        component: (
          <MinParkingHours
            minParkingHours={minParkingHours}
            key="min-parking-hours"
          />
        ),
        conditional: minParkingHours,
      },
      {
        component: (
          <MoreThan24HourNoReentry
            key="no-reentry"
          />
        ),
        conditional: moreThan24HourNoReentry && !this.isParkingNotAvailable,
      },
      {
        component: (
          <PrintOnly
            key="print-only"
          />
        ),
        conditional: !mobileEnabled,
      },
      {
        component: (
          <ParkingNotAvailable
            key="parking-not-available"
          />
        ),
        conditional: this.isParkingNotAvailable,
      },
    ];

    const noticeHeader = (noticeHeaderOptions.find(({ conditional }) => conditional) || {}).component;

    let noticeDetails = compact(noticeDetailOptions.map(({ component, conditional }) =>
      (conditional ? component : null)),
    );
    noticeDetails = zip(noticeDetails, times(noticeDetails.length - 1, constant()).map((_, i) =>
      <AndSeparator key={`and-${i}`} />,
    ));

    /**
      * The pricePremium check is a bit hacky, but it's the only modal that
      * can show up if instructions aren't required.  If another modal needs
      * to be rendered (Header-only, no notice-details), then please reconsider
      * the following checks
    **/
    if ((!noticeHeader || !noticeDetails.length) && !pricePremium) { return null; }

    const modalClasses = cx({
      'notices-modal': true,
      'margin-top-sm-0': true,
      'margin-top-xs-navbar': !this.props.deepLinkDisplay,
      'margin-top-xs-navbar-and-banner': this.props.deepLinkDisplay,
    });

    return (
      <div className="panel-body">
        <Modal className={modalClasses} show={this.state.show} onHide={this.handleModalClose}>
          { noticeHeader }
          { noticeDetails }
          { this.renderOptions() }
        </Modal>
      </div>
    );
  }
}

ModalNotices.propTypes = propTypes;
ModalNotices.defaultProps = defaultProps;

export default ModalNotices;
