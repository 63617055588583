import { Record } from 'immutable';

export default Record({
  '@context': 'http://schema.org',
  '@type': 'AggregateOffer',
  'availability': null,
  'priceCurrency': null,
  'price': null,
  'lowPrice': null,
  'highPrice': null,
  'url': null,
});
