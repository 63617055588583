import { takeEvery, call } from 'redux-saga/effects';
import cookie from 'react-cookie';
import moment from 'moment-timezone';

import { COOKIE_NAME } from 'models/deep-link';

import { DISMISS_DEEP_LINK_BANNER } from 'action-creators/deep-links/dismiss-banner';

function saveDismissal(category) {
  const history = cookie.load(COOKIE_NAME) || {};
  if (!history[category]) {
    history[category] = [];
  }
  history[category].push(moment());
  cookie.save(COOKIE_NAME, history, { path: '/' });
}

export function* saveDeepLinkDismissal(action) {
  const { category } = action.payload;
  yield call(saveDismissal, category);
}


export default function* root() {
  yield takeEvery(DISMISS_DEEP_LINK_BANNER, saveDeepLinkDismissal);
}
