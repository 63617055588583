import { Record } from 'immutable';
import PostalAddress from 'models/schema.org/postal-address';

export default class Place extends Record({
  '@context': 'http://schema.org',
  '@type': 'Place',
  'name': null,
  'address': new PostalAddress(),
}) {

  constructor(props) {
    if (!props) {
      super();
      return;
    }

    const { name } = props || {};

    super({
      name,
      address: new PostalAddress(props),
    });
  }
}
