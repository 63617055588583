import React from 'react';
import PropTypes from 'prop-types';
import LoadingArrow from 'components/svgs/icons/loading-arrow';
import cx from 'classnames';

import { AppConsumer } from 'providers/app-provider';

const propTypes = {
  requestQueueLength: PropTypes.number,
  mounted: PropTypes.bool,
};

const defaultProps = {
  requestQueueLength: 0,
  mounted: false,
};

export class LoadingIndicator extends React.Component {
  shouldComponentUpdate(nextProps) {
    return (
      (this.props.requestQueueLength > 0 && nextProps.requestQueueLength === 0) ||
      (this.props.requestQueueLength === 0 && nextProps.requestQueueLength > 0)
    );
  }

  render() {
    const loadingClasses = cx({
      'loading-indicator': true,
      'loading': this.props.mounted && this.props.requestQueueLength > 0,
    });

    return (
      <div className={loadingClasses}>
        <div className="loading-label">LOADING...</div>
        <div className="loading-icon"><LoadingArrow /></div>
      </div>
    );
  }
}

LoadingIndicator.propTypes = propTypes;
LoadingIndicator.defaultProps = defaultProps;

const LoadingIndicatorWrapper = props => (
  <AppConsumer><LoadingIndicator {...props} /></AppConsumer>
);

export default LoadingIndicatorWrapper;
