import { Record } from 'immutable';

import DeepLink from 'models/deep-link';
import { DISMISS_DEEP_LINK_BANNER } from '../action-creators/deep-links/dismiss-banner';

// import { SET_DEEP_LINK } from 'action-reducers/deep-links/set-deep-link';

export class DeepLinksState extends Record({
  display: false,
  message: '',
  deepLink: new DeepLink(),
}) {
  constructor(props) {
    if (!props) {
      super();
      return;
    }

    const { deepLink } = props;
    if (!deepLink) {
      super();
      return;
    }

    const { display } = deepLink;
    let analyticsID;
    if (props.insights) {
      ({ analyticsID } = props.insights);
    }

    super({
      display,
      message: deepLink.message,
      deepLink: new DeepLink({ ...deepLink, display, analyticsID }),
    });
  }
}

export default function deepLinksReducer(state = new DeepLinksState(), action = null) {
  switch (action.type) {
    case DISMISS_DEEP_LINK_BANNER:
      return state.merge({
        display: false,
        deepLink: new DeepLink(),
      });
    default:
      return state;
  }
}
