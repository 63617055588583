export const GOT_BOOKING_COUNTS = 'got-booking-counts';

export default function gotBookingCounts({ type, query, body }) {
  const { count } = body;

  return {
    type: GOT_BOOKING_COUNTS,
    payload: {
      type,
      query,
      count,
    },
  };
}
