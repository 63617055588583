import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { List } from 'immutable';

import FullFooter from 'components/common/footer/full-footer';
import MinimalFooter from 'components/common/footer/minimal-footer';

import Brand from 'models/brand';

import setModalState from 'action-creators/modal/set-modal-state';

import * as AppContext from 'lib/app-context';

export const NONE = 'none';
export const MINIMAL = 'minimal';
export const FULL = 'full';

const propTypes = {
  footerStyle: PropTypes.string,
  parkingLocations: PropTypes.instanceOf(List),
  brand: PropTypes.instanceOf(Brand).isRequired,
  footerQuestions: PropTypes.instanceOf(List),
  setModalState: PropTypes.func.isRequired,
};

const defaultProps = {
  footerStyle: null,
  parkingLocations: List(),
  footerQuestions: List(),
};

class Footer extends Component {
  constructor(props) {
    super(props);

    this.showParkingCitiesModal = this.showParkingCitiesModal.bind(this);
    this.showSummaryOfServices = this.showSummaryOfServices.bind(this);
    this.showFAQ = this.showFAQ.bind(this);
    this.showModal = this.showModal.bind(this);
  }

  renderFooter() {
    const { footerStyle, brand } = this.props;

    if (footerStyle === NONE || !brand.showFooter) { return null; }

    if (footerStyle === MINIMAL) {
      return (<MinimalFooter
        brand={brand}
        showParkingCitiesModal={this.showParkingCitiesModal}
        showSummaryOfServices={this.showSummaryOfServices}
        showFAQ={this.showFAQ}
      />);
    }

    return (<FullFooter
      brand={brand}
      showParkingCitiesModal={this.showParkingCitiesModal}
      showSummaryOfServices={this.showSummaryOfServices}
      showFAQ={this.showFAQ}
    />);
  }

  showFAQ(e, context) { this.showModal(e, 'faq', 'faq', context); }
  showParkingCitiesModal(e, context) { this.showModal(e, 'parking-cities', 'parkingCities', context); }
  showSummaryOfServices(e, context) { this.showModal(e, 'summary-of-services', 'summaryOfServices', context); }

  /**
   * Shows a specific modal for the footer
   * @param  {Event} e
   *         Javascript Click Event
   * @param  {String} key
   *         Key for modal logic for body lookup
   * @param  {String} body
   *         Key for what component to show in the body
   * @param  {String} appContext
   *         Context of the application running (e.g. Desktop or Mobile)
   * @return {Null}
   *         null
   */
  showModal(e, key, body, appContext) {
    e.preventDefault();
    e.stopPropagation();

    const {
      brand,
      footerQuestions,
      parkingLocations: cities,
    } = this.props;

    this.props.setModalState({
      body,
      bodyProps: {
        appContext,
        brand,
        cities,
        footerQuestions,
        key,
      },
      displayModal: true,
      footer: appContext === AppContext.DESKTOP ? 'noFooter' : 'generalFooter',
      header: 'emptyHeader',
    });
  }

  render() {
    return (
      <div id="footerContainer">
        { this.renderFooter() }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    parkingLocations,
    appContext,
    footerQuestions,
  } = state.app;

  const { brand } = state.brand;

  return {
    parkingLocations,
    appContext,
    footerQuestions,
    brand,
  };
};

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    setModalState,
  }, dispatch)
);

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
