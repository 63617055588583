import React from 'react';

const SoldoutCar = () => (
  <svg width="32px" height="21px" viewBox="0 0 32 21" version="1.1">
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="soldout_btn" transform="translate(-25.000000, -9.000000)">
        <g id="BookNow_Button">
          <g id="Group" transform="translate(25.000000, 5.000000)">
            <g id="zag-soldout">
              <path d="M6.04620789,9.53081027 C6.50362338,8.55942684 7.21333531,7.37888423 8.22252107,6.52012497 C9.00197348,6.40347851 10.072695,6.3109658 11.3362283,6.24459799 C12.591557,6.17621903 14.0314927,6.1400184 15.5555273,6.1400184 C18.6015453,6.1400184 21.3378335,6.2888432 22.8967383,6.52012497 C24.9192122,8.23362121 25.7273813,11.2563734 25.7273813,11.2563734 L5.3918781,11.2563734 C5.3918781,11.2563734 5.59084358,10.5001826 6.04620789,9.53081027 M29.3520865,11.2188732 C28.3193849,10.4293592 27.5983136,9.28077254 27.5983136,9.28077254 C27.5983136,9.28077254 25.4737689,4.99643266 24.1476344,4.79347851 C22.4393549,4.53030719 19.3480741,4.35188596 15.8860216,4.35188596 C12.4307931,4.35188596 9.33723769,4.53030719 7.63350752,4.79347851 C6.30282374,4.99643266 4.18055371,9.28077254 4.18055371,9.28077254 C4.18055371,9.28077254 3.461757,10.4293592 2.4290554,11.2188732 C0.177129005,12.5458811 -0.114029156,15.5255157 0.0861420796,18.0702486 C0.0451979633,18.5653675 0.0292752514,19.1184733 0.0292752514,19.7273358 C0.0292752514,22.5909966 0.427343049,24.9149331 0.914123098,24.9149331 L4.5536001,24.9149331 C4.79016611,24.9149331 5.00171071,24.3752089 5.1632125,23.4987146 C7.37646945,23.6548332 10.7156896,23.7284319 15.8860216,23.7284319 C18.4768743,23.7284319 20.608243,23.7061293 22.36884,23.670445 C24.1203383,23.6325305 25.5101636,23.5767739 26.6133801,23.4987146 C26.7748819,24.3752089 26.9887012,24.9149331 27.2252672,24.9149331 L30.8647442,24.9149331 C31.3537989,24.9149331 31.7518667,22.5909966 31.7518667,19.7273358 C31.7518667,19.1184733 31.7313946,18.5653675 31.6949999,18.0702486 C31.8951711,15.5255157 31.6040129,12.5458811 29.3520865,11.2188732 Z" id="Fill-8" fillOpacity="0.501811594" fill="#A5A5A5" />
              <g id="Group-2" transform="translate(16.186035, 15.718782) rotate(45.000000) translate(-16.186035, -15.718782) translate(5.186035, 5.218782)" fill="#FFFFFF">
                <path d="M1.89731586,19.1847103 L1.89731586,20.1643022 C1.89731586,20.5700981 2.22608226,20.898996 2.63171586,20.898996 C3.03734946,20.898996 3.36611586,20.5700981 3.36611586,20.1643022 L3.36611586,19.1847103 L4.34531586,19.1847103 C4.75094946,19.1847103 5.07971586,18.8558124 5.07971586,18.4500165 C5.07971586,18.0442205 4.75094946,17.7153226 4.34531586,17.7153226 L3.36611586,17.7153226 L3.36611586,16.7357307 C3.36611586,16.3299348 3.03734946,16.0010369 2.63171586,16.0010369 C2.22608226,16.0010369 1.89731586,16.3299348 1.89731586,16.7357307 L1.89731586,17.7153226 L0.918115859,17.7153226 C0.512482259,17.7153226 0.183715859,18.0442205 0.183715859,18.4500165 C0.183715859,18.8558124 0.512482259,19.1847103 0.918115859,19.1847103 L1.89731586,19.1847103 L1.89731586,19.1847103 Z" id="Combined-Shape-Copy" />
                <path d="M18.1414597,3.25490282 L18.1414597,4.23449466 C18.1414597,4.64029058 18.4702261,4.96918854 18.8758597,4.96918854 C19.2814933,4.96918854 19.6102597,4.64029058 19.6102597,4.23449466 L19.6102597,3.25490282 L20.5894597,3.25490282 C20.9950933,3.25490282 21.3238597,2.92600486 21.3238597,2.52020895 C21.3238597,2.11441303 20.9950933,1.78551507 20.5894597,1.78551507 L19.6102597,1.78551507 L19.6102597,0.805923232 C19.6102597,0.400127314 19.2814933,0.0712293547 18.8758597,0.0712293547 C18.4702261,0.0712293547 18.1414597,0.400127314 18.1414597,0.805923232 L18.1414597,1.78551507 L17.1622597,1.78551507 C16.7566261,1.78551507 16.4278597,2.11441303 16.4278597,2.52020895 C16.4278597,2.92600486 16.7566261,3.25490282 17.1622597,3.25490282 L18.1414597,3.25490282 L18.1414597,3.25490282 Z" id="Combined-Shape-Copy-2" />
              </g>
              <path d="M20.9435828,21.8779287 L10.9483708,21.8779287 C10.9483708,21.8779287 7.92764482,22.0887064 7.92764482,19.2290826 L24.2014457,19.2290826 C24.2014457,21.8236045 20.9435828,21.8779287 20.9435828,21.8779287" id="Path" fill="#FFFFFF" transform="translate(16.064545, 20.555245) scale(1, -1) translate(-16.064545, -20.555245) " />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SoldoutCar;
