export const SET_SELECTED_LOCATION = 'set-selected-location';

const setSelectedLocation = ({ selectedLocation, selectedQuote, keepRoute, displayMap }) => ({
  type: SET_SELECTED_LOCATION,
  payload: {
    selectedLocation,
    selectedQuote,
    selectedLocationId: selectedLocation && selectedLocation.id,
    selectedQuoteId: selectedQuote && selectedQuote.id,
    keepRoute,
    displayMap,
  },
});
export default setSelectedLocation;
