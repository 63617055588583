export const BUSINESS_ENROLL = 'business-enroll';
const businessEnroll = ({
  companyName,
  companyEmail,
  companySize,
  userEmail,
  password,
  firstname,
  lastname,
  phone,
}) => ({
  type: BUSINESS_ENROLL,
  payload: {
    companyName,
    companyEmail,
    companySize,
    userEmail,
    password,
    firstname,
    lastname,
    phone,
  },
});
export default businessEnroll;
