import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';

import ClickOutside from 'react-click-outside';
import { Set } from 'immutable';
import MobileDetect from 'mobile-detect';
import get from 'lodash/get';
import result from 'lodash/result';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import pick from 'lodash/pick';
import VisibilitySensor from 'react-visibility-sensor';
import cx from 'classnames';
import Insights from '@parkwhiz-js/insights-sdk';

import Coordinates from 'models/coordinates';
import Predictions from 'models/predictions/predictions';
import { Message } from 'models/messages';
import Search from 'models/search';
import Brand from 'models/brand';
import Hub from 'models/hub';
import Venue from 'models/venue';

import { pageProps } from 'lib/analytics/page-properties';
import RequestQueue from 'lib/api/request-queue';
import { MapboxAutoCompleteService } from 'lib/mapbox/autocomplete';
import * as AppContext from 'lib/app-context';
import { denyGeolocationPermission } from 'lib/common/messages';
import { addMessages } from 'lib/messaging';
import {
  SEARCH_TEXTBOX_IMPRESSION,
  SEARCH_TEXTBOX_CLICK,
  SEARCH_BUTTON_CLICK,
  SEARCH_TEXTBOX_FOCUS,
  SEARCH_TEXTBOX_CHANGE,
} from 'lib/analytics/events';

import {
  addQuery,
  redirectToParkingNearMe,
  renderMessageAndScrollToTop,
} from 'components/mapbox/base';
import MapboxAutocompleteButton from 'components/mapbox/autocomplete/button';
import MapboxAutocompleteDropdown from 'components/mapbox/autocomplete/dropdown';

export const propTypes = {
  // Required
  buttonClassName: PropTypes.string.isRequired,
  dropdownClassName: PropTypes.string.isRequired,
  geoIPLocation: PropTypes.instanceOf(Coordinates).isRequired,
  inputClassName: PropTypes.string.isRequired,
  routingStyle: PropTypes.string.isRequired,
  changeUserInput: PropTypes.func.isRequired,

  // Optional
  buttonText: PropTypes.string,
  id: PropTypes.string.isRequired,
  mapPin: PropTypes.bool,
  placeholder: PropTypes.string,
  placeholderId: PropTypes.string,
  fullscreen: PropTypes.bool,
  requestQueue: PropTypes.instanceOf(RequestQueue),
  className: PropTypes.string,
  pinClassName: PropTypes.string,
  onSearch: PropTypes.func,
  currentDestination: PropTypes.shape({
    lat: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    lng: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  showCancel: PropTypes.bool,
  insights: PropTypes.instanceOf(Insights),
  currentSearch: PropTypes.instanceOf(Search),
  addMessageAndScrollToTop: PropTypes.func,
  trackEvent: PropTypes.func,
  appContext: PropTypes.string,
  app: PropTypes.string,
  brand: PropTypes.instanceOf(Brand),
  token: PropTypes.string.isRequired,
  daily: PropTypes.bool,
  hub: PropTypes.instanceOf(Hub),
  venue: PropTypes.instanceOf(Venue),
  showMobileMenu: PropTypes.bool,
  intl: intlShape,
};

export const defaultProps = {
  addMessageAndScrollToTop: null,
  buttonText: '',
  mapPin: false,
  placeholder: 'Search',
  placeholderId: null,
  fullscreen: true,
  requestQueue: null,
  className: '',
  showAutocompleteButton: true,
  pinClassName: '',
  onSearch: null,
  currentDestination: { lat: null, lng: null },
  showCancel: true,
  app: 'Home',
  appContext: AppContext.MOBILE,
  trackEvent: () => {},
  insights: null,
  currentSearch: null,
  brand: null,
  daily: false,
  hub: null,
  venue: null,
  showMobileMenu: false,
};

const VALID_KEY_EVENTS = Set(['ArrowDown', 'ArrowUp', 'Enter', 'Backspace']);

export class MapboxAutocomplete extends Component {
  constructor(props) {
    super(props);

    this.onBlur = this.onBlur.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onTouchEnd = this.onTouchEnd.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.unFocus = this.unFocus.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.setPredictions = this.setPredictions.bind(this);
    this.setSelection = this.setSelection.bind(this);
    this.setTypedValue = this.setTypedValue.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.setOrientation = this.setOrientation.bind(this);
    this.setFullscreenStyles = this.setFullscreenStyles.bind(this);
    this.clearInput = this.clearInput.bind(this);
    this.setInput = this.setInput.bind(this);
    this.trackEvent = this.trackEvent.bind(this);
    this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
    this.onInputClick = this.onInputClick.bind(this);

    // We need to patch this for mobile Firefox, which doesn't have the forEach iterator on nodelist
    // Chrome and Safari use NodeArray
    if (typeof NodeList !== 'undefined') {
      NodeList.prototype.forEach = Array.prototype.forEach;
    }


    this.autocomplete = null;
    this.fullscreenStyles = null;
    this.state = {
      disabled: false,
      focus: false,
      loading: false,
      predictions: new Predictions(),
      selected: {},
      gotEvents: false,
      touched: false,
      fullscreen: false,
      appContext: null,
      orientation: 0,
      isVisible: true,
    };
  }

  get additionalQueryParams() {
    return omitBy({
      monthly: this.props.currentSearch.isMonthlySearch ? 1 : null,
    }, isNil);
  }

  componentDidMount() {
    const { daily } = this.props;
    this.autocomplete = new MapboxAutoCompleteService(
      this.props.geoIPLocation,
      this.props.insights,
      this.props.token,
      this.props.requestQueue,
      this.props.brand,
      { daily },
    );
    window.addEventListener('orientationchange', this.setOrientation);

    if (this.props.currentDestination) {
      this.autocomplete.setBias(this.props.currentDestination);
    }

    const state = this.state;

    let userAgent;
    if (window && window.navigator) {
      userAgent = window.navigator.userAgent;
    }

    const md = new MobileDetect(userAgent);
    if (md.mobile() && md.phone()) {
      state.appContext = AppContext.MOBILE;
    } else if (md.mobile() && md.tablet()) {
      state.appContext = AppContext.TABLET;
    } else {
      state.appContext = AppContext.DESKTOP;
    }

    // Need to reset state with mobile detect once the component mounts
    this.state = state;
  }

  componentDidUpdate(prevProps, prevState) {
    const { placeholderId, intl } = this.props;
    let { placeholder } = this.props;
    if (placeholderId) {
      placeholder = intl.formatMessage({ id: placeholderId, defaultMessage: placeholder });
    }

    if (this.input) {
      this.input.placeholder = placeholder;
    }

    if (this.props.currentDestination !== prevProps.currentDestination && this.autocomplete) {
      this.autocomplete.setBias(this.props.currentDestination);
    }
    // We are staggering the setting of the search bar's absolute positioning, and the setting of fullscreen
    // This allows for a smooth transition of the search bar from where it's positioned on the page to fullscreen
    if (this.state.appContext === AppContext.MOBILE && this.props.fullscreen) {
      if (this.state.focus && this.state.touched && (!prevState.focus || !prevState.touched)) {
        document.querySelectorAll('.gplaces-current-location').forEach((b) => { b.setAttribute('style', 'display: none'); });
        document.querySelector('body').setAttribute('style', 'position: fixed; height: calc(100vh) !important; overflow: hidden !important; zoom: 1;');
        window.scroll({ top: -50, left: 0, behavior: 'smooth' });
        if (!this.state.fullscreen) {
          window.setTimeout(() => { this.setState({ fullscreen: true }); }, 50);
        }
      }

      if (prevState.fullscreen !== this.state.fullscreen) {
        const nav = document.getElementById('desktop-nav');
        if (this.state.fullscreen) {
          if (nav) {
            nav.setAttribute('style', 'display: none');
          }
          window.scroll({ top: -50, left: 0, behavior: 'smooth' });
        } else {
          if (this.state.focus) {
            window.setTimeout(() => { this.setState({ focus: false }); }, 500);
          }
          if (nav) {
            nav.setAttribute('style', 'display: block');
          }
          document.querySelectorAll('.gplaces-current-location').forEach((b) => { b.setAttribute('style', 'display: block'); });
          document.querySelector('body').setAttribute('style', 'zoom: 1;');
        }
      }

      if (this.state.orientation !== prevState.orientation) {
        this.setFullscreenStyles(true);
      }
    }
    this.typedValue = '';
  }

  trackEvent(eventProperties) {
    const { trackEvent, insights } = this.props;
    if (insights) {
      try {
        const pageProperties = pageProps(pick(this.props, ['app', 'currentSearch', 'hub', 'venue']));
        insights.trackAnalyticsEvent({
          ...pageProperties,
          ...eventProperties,
        });
      } catch (e) {
        console.warn(e);
      }
    } else {
      trackEvent(eventProperties);
    }
  }

  onBlur() {
    this.setState({ focus: false, selected: {} });
  }

  onChange() {
    const { value } = this.input;
    this.setTypedValue(value);
    this.setState({ focus: true });
    this.trackEvent(SEARCH_TEXTBOX_CHANGE);
  }

  onInputClick() {
    this.trackEvent(SEARCH_TEXTBOX_CLICK);
  }

  onTouchEnd() {
    // Need to track if the user has tapped the search box, since it is autofocused on page load
    if (!this.state.touched) {
      this.setState({ touched: true });
    }
  }

  onFocus() {
    const { app } = this.props;
    if (app === 'Home') {
      this.trackEvent(SEARCH_TEXTBOX_FOCUS);
    }
    this.clearInput();
    this.setState({ focus: true, loading: false });
  }

  /**
   * Handles List Navigation and Submission via keyboard
   * @param {event} event - javascript event
   * @return {undefined}
   */
  onKeyDown(event) {
    const { key } = event;
    if (key !== 'Tab' && !this.state.touched) {
      this.setState({ touched: true });
    }

    if (VALID_KEY_EVENTS.has(key)) {
      if (key !== 'Backspace') {
        event.preventDefault();
        event.stopPropagation();
      }

      const { predictions, selected } = this.state;

      // Navigate down the list
      if (key === 'ArrowDown') {
        switch (selected) {
          case {}:
            this.setSelection(predictions.firstPrediction());
            return;
          case predictions.lastPrediction():
            this.setSelection({});
            return;
          default:
            this.setSelection(predictions.nextPrediction(selected));
        }
        // Navigate up the list
      } else if (key === 'ArrowUp') {
        switch (selected) {
          case {}:
            this.setSelection(predictions.lastPrediction());
            return;
          case predictions.firstPrediction():
            this.setSelection({});
            return;
          default:
            this.setSelection(predictions.previousPrediction(selected));
        }
        // Submit the form
      } else if ((predictions.events.size || predictions.places.size) && key !== 'Backspace') {
        this.submitForm();
      }
    }
  }

  handleVisibilityChange(visible) {
    if (visible && (this.props.id !== 'menu-autocomplete' || (this.props.appContext === AppContext.MOBILE && this.props.showMobileMenu))) {
      this.trackEvent(SEARCH_TEXTBOX_IMPRESSION);
    }
  }

  setPredictions(newPredictions) {
    if (!this.state.gotEvents && newPredictions.events && newPredictions.events.size) {
      try {
        this.setState({ gotEvents: true });
      } catch (e) {
        this.setState({ gotEvents: true });
      }
    }
    let { predictions } = this.state;
    if (newPredictions.places) { predictions = predictions.set('places', newPredictions.places); }
    if (newPredictions.events) { predictions = predictions.set('events', newPredictions.events); }
    const disabled = !(predictions.events.size || predictions.places.size || this.input.value === '');
    this.setState({ predictions, disabled, selected: {} });
  }

  setSelection(selected, submit = false) {
    const { input, predictions } = this.state;
    const value = (predictions.getIn([selected.type, selected.index]) || {}).description || input;

    const callback = submit ? this.submitForm : () => {};
    this.setState({ selected }, callback);
    this.input.value = value;
  }

  setTypedValue(value) {
    const { routingStyle, brand } = this.props;

    this.autocomplete.getPredictions(value, this.setPredictions, null, { routingStyle, brand });
    this.setState({ input: value });
    this.input.value = value;
  }

  // We pre-cache the fullscreen styles needed, for the transition to FS
  setFullscreenStyles(override = false) {
    if (this.state.appContext === AppContext.MOBILE && (!this.fullscreenStyles || override)) {
      if (this.wrap && this.wrap.container) {
        const { top, left, width, height } = result(this, ['wrap', 'container', 'getBoundingClientRect'], {});
        if (height > 0 && width > 0) {
          this.fullscreenStyles = { top, left, width, height, transition: 'all 1s ease', position: 'fixed' };
        }
      }
    }
  }

  setOrientation() {
    const orientation = screen.orientation ? screen.orientation.angle : window.orientation;
    this.setState({ orientation });
  }

  unFocus() {
    this.setState({ fullscreen: false });
  }

  clearInput() {
    if (this.input) { this.input.value = null; }
  }

  setInput(value, overwrite = false) {
    if (this.input && (overwrite || !this.input.value)) {
      this.setState({ input: value });
      this.input.value = value;
    }
  }

  submitForm() {
    const {
      addMessageAndScrollToTop,
      app,
      changeUserInput,
      onSearch,
      routingStyle,
      insights,
    } = this.props;

    const { trackEvent } = this;
    if (app === 'Home') {
      trackEvent(SEARCH_BUTTON_CLICK);
    }

    const { input, predictions, disabled } = this.state;
    let { selected } = this.state;

    changeUserInput({ userInput: input });

    if (insights) { insights.flushEventQueue(); }
    window.setTimeout(() => {
      this.onBlur();

      // Do nothing if the form should be disabled
      if (disabled) { return; }

      // If there are no predictions, do a search current location
      if (!predictions.places.size && !predictions.events.size) {
        this.setState({ disabled: true });
        this.autocomplete.getCurrentLocation((coordinates) => {
          const { latitude, longitude, successfulRetrieval } = coordinates;

          if (successfulRetrieval) {
            redirectToParkingNearMe({ latitude, longitude }, this.additionalQueryParams);
          } else if (addMessageAndScrollToTop) {
            addMessageAndScrollToTop(denyGeolocationPermission);
          } else if (window.pwMessaging) {
            addMessages([new Message(denyGeolocationPermission)]);
            renderMessageAndScrollToTop();
          }
        });
      } else {
        // Otherwise look up the slug based off the place ID
        if (isNil(selected.type) || isNil(selected.index)) {
          selected = predictions.firstPrediction();
        }

        const prediction = predictions.getIn([selected.type, selected.index]);
        this.input.value = prediction.description;

        const resolvePrediction = (p) => {
          if (onSearch) {
            if (selected.type === 'events') {
              onSearch({ event: p });
            } else {
              onSearch({ place: p });
            }
          } else {
            const slug = get(p, 'slug', `/404?searchText=${input}`);
            const redirectPath = addQuery(
              `${window.location.origin}${slug}`,
              this.additionalQueryParams,
            );
            window.location = redirectPath;
          }
        };

        if (prediction.mustResolve) {
          this.autocomplete.resolvePrediction(prediction.id, resolvePrediction, { routingStyle });
          return;
        }

        resolvePrediction(prediction);
      }
    }, 200);
  }

  renderMapPin() {
    const { mapPin, pinClassName } = this.props;

    if (!mapPin) { return null; }

    return (
      <span className={`map-pin iconified-font iconified-pin ${pinClassName}`} />
    );
  }

  render() {
    let dropdown;
    const {
      dropdownClassName,
      id,
      inputClassName,
      placeholderId,
      buttonClassName,
      buttonText,
      showCancel,
      intl,
    } = this.props;
    let { placeholder } = this.props;
    const { focus, predictions, selected, touched, fullscreen } = this.state;

    /**
     * Only try to render the dropdown when both:
     * 1) the autocomplete service is up
     * 2) the input field is focused
     */
    if (this.autocomplete && focus) {
      dropdown = (
        <MapboxAutocompleteDropdown
          className={dropdownClassName}
          predictions={predictions}
          selected={selected}
          setSelection={this.setSelection}
          submitForm={this.submitForm}
        />
      );
    }
    const cancelButton = showCancel ? <a className="cancel-autocomplete text-color-white text-weight-book" onClick={this.unFocus}>cancel</a> : null;
    const active = ((predictions.places.size || predictions.events.size) && focus) ? 'active' : '';
    this.setFullscreenStyles();
    const fullscreenClasses = cx({
      'focused-autocomplete': this.props.fullscreen && fullscreen,
      'background-color-bp-dark-blue': this.props.fullscreen && fullscreen && get(this.props.brand, 'isBestParking', false),
    });

    if (placeholderId) {
      placeholder = intl.formatMessage({ id: placeholderId, defaultMessage: placeholder });
    }

    return (
      <ClickOutside
        onClickOutside={this.onBlur}
        style={(touched && focus && this.props.fullscreen && this.fullscreenStyles) || {}}
        className={fullscreenClasses}
        ref={(wrap) => { this.wrap = wrap; }}
      >
        <div style={{ display: 'flex' }} className={`${this.props.className} autocomplete-wrap`}>
          {this.renderMapPin()}
          <VisibilitySensor onChange={this.handleVisibilityChange} partialVisibility>
            <input
              autoComplete="off"
              className={`${inputClassName} ${active} fout-enabled`}
              id={id}
              onChange={this.onChange}
              onClick={this.onInputClick}
              onFocus={this.onFocus}
              onTouchEnd={this.onTouchEnd}
              onKeyDown={this.onKeyDown}
              placeholder={placeholder}
              ref={(input) => { this.input = input; }}
              type="search"
            />
          </VisibilitySensor>
          <MapboxAutocompleteButton
            className={`${buttonClassName} ${active}`}
            focus={focus}
            onClick={this.submitForm}
            text={buttonText}
          />
          {cancelButton}
        </div>
        {dropdown}
      </ClickOutside>
    );
  }
}

MapboxAutocomplete.propTypes = propTypes;
MapboxAutocomplete.defaultProps = defaultProps;

export default injectIntl(MapboxAutocomplete);
