import { takeEvery, put } from 'redux-saga/effects';
import cookie from 'react-cookie';
import moment from 'moment-timezone';

import { CLEANUP_RECEIPT_STATE } from 'action-creators/bookings/cleanup-receipt-state';
import { CLEANUP_BUSINESS_PAGE_STATE } from 'action-creators/brand/cleanup-business-page-state';
import { CLEANUP_HOME_PAGE_STATE } from 'action-creators/brand/cleanup-home-page-state';
import { CLEANUP_OAUTH_PAGE_STATE } from 'action-creators/brand/cleanup-oauth-page-state';
import { CLEANUP_HOW_IT_WORKS_STATE } from 'action-creators/brand/cleanup-how-it-works-state';
import { CLEANUP_OUR_APPS_PAGE_STATE } from 'action-creators/brand/cleanup-our-apps-page-state';
import { CLEANUP_CHECKOUT_STATE } from 'action-creators/checkout/cleanup-checkout-state';
import { CLEANUP_HUB_STATE } from 'action-creators/search/cleanup-hub-state';
import { CLEANUP_VENUE_STATE } from 'action-creators/search/cleanup-venue-state';
import { CLEANUP_SEARCH_STATE } from 'action-creators/search/cleanup-search-state';
import { CLEANUP_SIGN_UP_STATE } from 'action-creators/account/cleanup-sign-up-state';
import { CLEANUP_SIGN_IN_STATE } from 'action-creators/account/cleanup-sign-in-state';

import resetModalStateCreator from 'action-creators/modal/reset-modal-state';
import setModalStateCreator from 'action-creators/modal/set-modal-state';
import scrollToTop from 'action-creators/scroll-to-top';

export const CHANGE_MODAL_STATE = 'CHANGE_MODAL_STATE';

import { DISMISS_PARTNER_MODAL, DISMISS_PARTNER_MODAL_COOKIE_NAME } from 'action-creators/brand/dismiss-partner-modal';

export function* changeModalState(input) {
  yield put(resetModalStateCreator(input));
  yield put(setModalStateCreator(input));
  yield put(scrollToTop());
}

function* resetModalState() {
  yield put(resetModalStateCreator());
}

function* dismissPartnerModal() {
  cookie.save(DISMISS_PARTNER_MODAL_COOKIE_NAME, true, { path: '/', expires: moment().add(5, 'years').toDate() });
  yield;
}

export default function* root() {
  yield takeEvery(CHANGE_MODAL_STATE, changeModalState);

  yield takeEvery(CLEANUP_RECEIPT_STATE, resetModalState);
  yield takeEvery(CLEANUP_BUSINESS_PAGE_STATE, resetModalState);
  yield takeEvery(CLEANUP_HOME_PAGE_STATE, resetModalState);
  yield takeEvery(CLEANUP_OAUTH_PAGE_STATE, resetModalState);
  yield takeEvery(CLEANUP_HOW_IT_WORKS_STATE, resetModalState);
  yield takeEvery(CLEANUP_OUR_APPS_PAGE_STATE, resetModalState);
  yield takeEvery(CLEANUP_CHECKOUT_STATE, resetModalState);
  yield takeEvery(CLEANUP_HUB_STATE, resetModalState);
  yield takeEvery(CLEANUP_VENUE_STATE, resetModalState);
  yield takeEvery(CLEANUP_SEARCH_STATE, resetModalState);
  yield takeEvery(CLEANUP_SIGN_UP_STATE, resetModalState);
  yield takeEvery(CLEANUP_SIGN_IN_STATE, resetModalState);
  yield takeEvery(DISMISS_PARTNER_MODAL, dismissPartnerModal);
}
