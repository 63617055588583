import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import env from 'lib/env';

import LoginForm from 'components/common/header/business-header/login-form';
import EnrollForm from 'components/common/header/business-header/enroll-form';

class BusinessHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      displayLogin: false,
      displayEnroll: false,
      showMobileNav: false,
    };

    this.toggleLoginForm = this.toggleLoginForm.bind(this);
    this.toggleEnrollForm = this.toggleEnrollForm.bind(this);
    this.toggleNavbar = this.toggleNavbar.bind(this);
  }

  toggleLoginForm(e) {
    e.stopPropagation();
    e.preventDefault();

    this.setState({
      displayLogin: !this.state.displayLogin,
      displayEnroll: false,
    });
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

  toggleEnrollForm(e) {
    e.stopPropagation();
    e.preventDefault();

    this.setState({
      displayEnroll: !this.state.displayEnroll,
      displayLogin: false,
    });
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
  }

  toggleNavbar(e) {
    e.stopPropagation();
    e.preventDefault();

    this.setState({ showMobileNav: !this.state.showMobileNav });
  }

  renderLogin() {
    const { user } = this.props;
    const { ENTERPRISE_HOST } = env();

    if (user && user.isLoggedIn()) {
      return (
        <li className="user-menu dropdown">
          <a href="#" className="dropdown-toggle" data-toggle="dropdown">
            Hello, {user.firstname}<span className="username" />
            <i className="fa fa-caret-down" />
            <i className="fa fa-caret-up" />
          </a>
          <ul className="dropdown-menu">
            <li><a href={ENTERPRISE_HOST} className="business-portal-link" target="_blank">Business Portal</a></li>
            <li><a href="/signout/" className="logout">Sign Out</a></li>
          </ul>
        </li>
      );
    }

    return (
      <li className="login-nav">
        <a className="login" onClick={this.toggleLoginForm}>Log In</a>
      </li>
    );
  }

  render() {
    const navClasses = cx({
      'navbar': true,
      'navbar-fixed-top': !this.state.displayLogin,
      'position-relative': this.state.displayLogin,
    });

    const navbarClasses = cx({
      'navbar-collapse': true,
      'collapse': true,
      'in': this.state.showMobileNav,
    });

    const navbarToggleClasses = cx({
      'navbar-toggle': true,
      'collapsed': !this.state.showMobileNav,
    });

    return (
      <div>
        <LoginForm
          display={this.state.displayLogin}
          toggleForm={this.toggleLoginForm}
          toggleEnroll={this.toggleEnrollForm}
          signIn={this.props.signIn}
        />
        <nav className={navClasses}>
          <div className="container">
            <div className="navbar-header">
              <button type="button" className={navbarToggleClasses} onClick={this.toggleNavbar}>
                <i className="fa fa-2x fa-bars" />
                <img src="/static/images/business/icons/icon-close@2x.png" alt="Click to Close" className="icon-close" />
              </button>
              <a id="mobile-enroll" className="btn navbar-btn button visible-xs-inline-block enroll" onClick={this.toggleEnrollForm}>Enroll</a>
              <Link to={{ pathname: '/business/' }} className="navbar-brand">
                <img src="/static/images/business/logo@2x.png" alt="ParkWhiz for Business" />
              </Link>
            </div>
            <div id="navbar" className={navbarClasses}>
              <ul className="nav navbar-nav navbar-right nav-pills">
                <li><Link to={{ pathname: '/business/clients-visit-you' }}>Visitor Parking</Link></li>
                <li><Link to={{ pathname: '/business/visit-your-clients' }}>Employee Parking</Link></li>
                <li><Link to={{ pathname: '/business/faq' }}>FAQ</Link></li>
                <li><a href="mailto:business@parkwhiz.com">Contact Us</a></li>
                {this.renderLogin()}
                <li><a className="button enroll hidden-xs" onClick={this.toggleEnrollForm}>Enroll</a></li>
              </ul>
            </div>
          </div>
        </nav>
        <EnrollForm
          display={this.state.displayEnroll}
          toggleForm={this.toggleEnrollForm}
          businessEnroll={this.props.businessEnroll}
          addMessage={this.props.addMessage}
        />
      </div>
    );
  }
}

export default BusinessHeader;
