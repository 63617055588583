export const SEARCH_CHANGE_PARKING_TYPE = 'search-change-parking-type';

/**
 * Default behavior is for search results page. When user changes parking type,
 * clear selected locations, execute sagas/search getQuotes() and sagas/router pushLocation()
 *
 * @param  {boolean} clearSelected
 *         clear selected location and quote from search model. this will cause a page reload.
 * @param  {boolean} keepRoute
 *         when this is false router saga pushLocation() does not execute.
 * @param  {string} parkingType
 *         parking type to which search model should be set
 * @param  {boolean} shouldGetQuotes
 *         when this is _strictly_ false search saga getQuotes() does not execute.
 * @return {*}
 *        returns redux action
 */
const changeParkingType = ({
  clearSelected = true,
  keepRoute = false,
  parkingType,
  shouldGetQuotes = true,
}) => ({
  type: SEARCH_CHANGE_PARKING_TYPE,
  payload: {
    clearSelected,
    keepRoute,
    parkingType,
    shouldGetQuotes,
    searchType: 'bounds ',
  },
});

export default changeParkingType;
