import HostedField from 'models/braintree/hosted-field';
import Card from 'models/braintree/card';
import valid from 'card-validator';

export const BRAINTREE_FIELD_STATE_CHANGE = 'braintree-field-state-change';

export default function braintreeFieldStateChange({ event, cvv }) {
  if (!event || !event.target || !event.target.fieldKey) {
    return {
      type: BRAINTREE_FIELD_STATE_CHANGE,
    };
  }

  let newPaymentMethodUpdate = {
    [event.target.fieldKey]: new HostedField(event),
  };

  if (event.card) {
    newPaymentMethodUpdate = Object.assign(newPaymentMethodUpdate, {
      card: new Card(event.card),
    });
  }

  // if someone types in the CVV THEN the card, we need to retroactively re-validate the CVV field
  if (event.target.fieldKey === 'number' && newPaymentMethodUpdate.card) {
    const cvvValidity = valid.cvv(cvv, newPaymentMethodUpdate.card.code.size);
    Object.assign(newPaymentMethodUpdate, {
      cvv: new HostedField(
        Object.assign(cvvValidity, {
          isEmpty: true,
          target: {
            fieldKey: 'cvv',
          },
        }),
      ),
    });
  }

  return {
    type: BRAINTREE_FIELD_STATE_CHANGE,
    payload: {
      newPaymentMethodUpdate,
    },
  };
}
