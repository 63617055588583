export const SIGN_UP = 'sign-up';
const signUp = ({
  email,
  password,
  firstName,
  lastName,
  redirectURI,
}) => ({
  type: SIGN_UP,
  payload: {
    email,
    password,
    firstName,
    lastName,
    redirectURI,
  },
});
export default signUp;
