import { Record, Map } from 'immutable';
import moment from 'moment-timezone';
import { camelizeKeys } from 'humps';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import get from 'lodash/get';

export default class Review extends Record({
  rating: null,
  locationId: null,
  bookingId: null,
  createdAt: null,
}) {
  constructor(props) {
    if (!props) {
      super();
      return;
    }

    const reviewProps = camelizeKeys(omitBy(props, isNil));

    super({
      ...reviewProps,
      createdAt: moment(reviewProps.createdAt),
    });
  }
}

export const getReviewsFromEmbedded = ({ body }) => {
  const reviews = {};

  if (Array.isArray(body)) {
    body.forEach((element) => {
      const review = get(element, ['_embedded', 'pw:review'], null);
      if (review) {
        const newReview = new Review(review);

        if (newReview.bookingId) {
          reviews[newReview.bookingId.toString()] = newReview;
        }
      }
    });
  }

  return new Map(reviews);
};

export const Reviews = reviews => Map((reviews || []).filter(r => r).reduce((a, r) => {
  const review = new Review(r);
  a[review.bookingId.toString()] = review; // eslint-disable-line no-param-reassign
  return a;
}, {}));
