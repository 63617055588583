import { getEventsFromEmbedded } from 'models/event';
import { getLocationsFromEmbedded } from 'models/locations';
import { getReviewsFromEmbedded } from 'models/review';
import { getVenuesFromEmbedded } from 'models/venue';
import BookingsGroup from 'models/bookings-group';

export const GOT_PAST_BOOKINGS = 'GOT_PAST_BOOKINGS';
const gotUpcomingBookings = ({ body, queryType, bookingType, user, headers, requestAction }) => ({
  type: GOT_PAST_BOOKINGS,
  payload: { body, queryType, bookingType, user, headers, requestAction },
  models: {
    events: getEventsFromEmbedded,
    locations: getLocationsFromEmbedded,
    bookings: BookingsGroup.fromAPIResponse,
    venues: getVenuesFromEmbedded,
    reviews: getReviewsFromEmbedded,
  },
});
export default gotUpcomingBookings;
