import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

const propTypes = {
  hours: PropTypes.number,
};

const defaultProps = {
  hours: null,
};

const MinimumDuration = ({ hours }) => {
  if (!hours) { return null; }

  return (
    <li>
      <FormattedMessage
        id="search.min-duration-restriction"
        defaultMessage="for fewer than {hours} hours"
        values={{ hours }}
      />
    </li>
  );
};

MinimumDuration.propTypes = propTypes;
MinimumDuration.defaultProps = defaultProps;
export default MinimumDuration;
