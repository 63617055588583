import get from 'lodash/get';

// The order of locales under a TLD matters here
// The first locale will be the default locale for that TLD
export const VALID_LOCALES = {
  com: ['en-us'],
  ca: ['en-ca', 'fr-ca'],
};

export const LOCALE_REDIRECTS = {
  CA: 'ca',
  US: 'com',
};

export const DEFAULT_PARSED_DOMAIN = {
  subdomain: 'www',
  domain: 'parkwhiz',
  tld: 'com',
};

export const VALID_TLDS = Object.keys(VALID_LOCALES);
export const VALID_COUNTRY_CODES = Object.keys(LOCALE_REDIRECTS);


export function isValidLocale({ locale, tld } = {}) {
  if (tld) {
    return get(VALID_LOCALES, tld, []).includes(locale);
  }

  let validLocales = [];
  Object.keys(VALID_LOCALES).forEach((t) => {
    validLocales = [
      ...VALID_LOCALES[t],
      ...validLocales,
    ];
  });
  return validLocales.includes(locale);
}

export function defaultLocale(tld) {
  return get(VALID_LOCALES, [tld, 0], 'en-us');
}

export function localeRedirectURL({ tld = '', domain = '', subdomain = '', path = '' } = { tld: '', domain: '', subdomain: '', path: '' }, countryCode) {
  const redirectTLD = get(LOCALE_REDIRECTS, countryCode, null);
  if (!redirectTLD) { return null; }
  if (redirectTLD === tld) { return null; }
  return `https://${subdomain}.${domain}.${redirectTLD}${path}`;
}

export function countryName(countryCode) {
  switch (countryCode) {
    case 'CA':
      return 'CANADA';
    default:
      return 'USA';
  }
}

export function countryByLocale(locale) {
  switch (locale) {
    case 'en-ca':
    case 'fr-ca':
      return 'CA';
    default:
      return 'US';
  }
}

export function localizeImageURL(image, locale) {
  if (!locale) return image;
  const split = image.match(/@2x\./) ? '@2x.' : '.';
  return `${image.split(split)[0]}-${locale}${split}${image.split('.')[1]}`;
}

