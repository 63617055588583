import React from 'react';
import moment from 'moment-timezone';

class PackageEvents extends React.Component {
  displayText(event) {
    const startDate = moment(event.startTime);
    return (
      <div className="row">
        <div className="col-md-10 col-xs-9">{event.event_name}</div>
        <div className="col-md-2 col-xs-3">{startDate.format('MMM D')}</div>
      </div>
    );
  }

  render() {
    const events = this.props.events;
    if (!events) { return null; }
    const eventList = [];

    events.map((event, index) => {
      const background = (index % 2 === 1) ? 'background-color-white' : 'background-color-off-white';
      eventList.push(<div key={event.id} className={background}>{this.displayText(event)}</div>);
    });

    return (
      <div className="area to-top">
        <h3 className="h5">Package Includes:</h3>
        <div className="info-list">{eventList}</div>
      </div>
    );
  }
}

export default PackageEvents;
